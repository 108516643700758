import { Box, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import sectionEn from '../assets/Section-en.svg';
import section from '../assets/Section.svg';
import CampaignItem from './CampaignItem';
import PageView from './PageView';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useRef, useState, useEffect } from 'react';
import useSWR from 'swr';

export const fetcher = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export type CampaignType = {
  id: number;
  title: any;
  description: any;
  image: string;
  link: string;
}

export default function Campaigns() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const { data: campaigns, isLoading, error } = useSWR<CampaignType[]>(
    () => `api/campaigns`,
    (url: string) => fetcher(url),
  );

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        setScrollPosition(scrollContainerRef.current.scrollLeft);
      }
    };

    const checkScrollable = () => {
      if (scrollContainerRef.current) {
        setIsScrollable(scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll);
      checkScrollable();
    }

    window.addEventListener('resize', checkScrollable);

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -320, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 320, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        background: 'rgba(241, 245, 249, 1)',
      }}>
      <PageView
        sx={{
          display: 'flex',
          gap: '24px',
          py: 10,
          flexDirection: 'column',
        }}>
        {!isSmallScreen && (
          <img
            src={i18n.language.includes('tr') ? section : sectionEn}
            alt=""
            style={{
              maxWidth: '100%',
            }}
          />
        )}
        {campaigns && campaigns.length > 0 &&

          (<Box
            id="kampanyalar"
            sx={{
              py: '80px',
              textAlign: isSmallScreen ? 'center' : 'left',
            }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: '50px', md: '56px' },
                lineHeight: '61.6px',
                color: 'rgba(15,23,42,1)',
                pb: '24px',
              }}>
              {t('campaigns.title')}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: isScrollable ? 'flex-start' : 'center',
              }}
            >
              {isScrollable && (
                <IconButton
                  onClick={scrollLeft}
                  sx={{
                    position: 'absolute',
                    left: 0,
                    zIndex: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(5px)',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                  }}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              )}
              <Box
                ref={scrollContainerRef}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  px: 1,
                  overflowX: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {campaigns?.map((campaign) => (
                  <CampaignItem
                    key={campaign.id}
                    campaign={campaign}
                  />
                ))}
              </Box>
              {isScrollable && (
                <IconButton
                  onClick={scrollRight}
                  sx={{
                    position: 'absolute',
                    right: 0,
                    zIndex: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(5px)',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
            </Box>
          </Box>)}
      </PageView>
    </Box>
  );
}
