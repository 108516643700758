import { Box } from '@mui/material';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Appbar from './Appbar';
import Footer from './Footer';

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {

  const { i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 2px)',
        pt: '26px',
      }}>
      <Appbar />
      <Box
        sx={{
          flex: 1,
          zIndex: 1,
          pt: 11,
        }}>
        {children}
      </Box>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText={i18n.language.includes('tr') ? 'Kabul Et' : 'Accept'}
        cookieName="myAwesomeCookieName"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "white", fontSize: "13px", background: 'rgba(255, 133, 10, 1)' }}
        expires={150}
      >
        {
          i18n.language.includes('tr') ? (
            <>
              Bu web sitesi, size en iyi deneyimi sunmak için çerezleri kullanmaktadır.
              <span style={{ fontSize: "10px" }}>
                <span
                  style={{ color: 'rgba(255, 133, 10, 1)', cursor: 'pointer' }}
                  onClick={() => window.location.href = '/cookie-policy'}
                >
                  &nbsp; Çerez politikamızı &nbsp;
                </span>
                kabul etmek için 'Kabul Et' butonuna tıklayın.</span>
            </>
          ):(
            <>
              This website uses cookies to ensure you get the best experience on our website.
              <span style={{ fontSize: "10px" }}>
                <span
                  style={{ color: 'rgba(255, 133, 10, 1)', cursor: 'pointer' }}
                  onClick={() => window.location.href = '/cookie-policy'}
                >
                  &nbsp; Cookie Policy &nbsp;
                </span>
                Click 'Accept' to accept our cookie policy.</span>
            </>
          )
        }

      </CookieConsent>
    </Box>
  );
}
