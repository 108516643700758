import PageView from './PageView';
import { Box, Typography } from '@mui/material';
import ai from '../assets/A-I.svg';
import form from '../assets/form.svg';
import clinic from '../assets/clinic.svg';
import treat from '../assets/treat.svg';
import { useTranslation } from 'react-i18next';

export default function DentistProcess() {
  const { t } = useTranslation();

  const steps = [
    {
      title: t('dentistprocess.stepstitleone'),
      description: t('dentistprocess.stepssubtitleone'),
      img: form,
    },
    {
      title: t('dentistprocess.stepstitletwo'),
      description: t('dentistprocess.stepssubtitletwo'),
      img: ai,
    },
    {
      title: t('dentistprocess.stepstitlethree'),
      description: t('dentistprocess.stepssubtitlethree'),
      img: clinic,
    },
    {
      title: t('dentistprocess.stepstitlefour'),
      description: t('dentistprocess.stepssubtitlefour'),
      img: treat,
    },
  ];

  return (
    <Box id="surec">
      <PageView>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: '80px',
            textAlign: 'center',
            px: { xs: 2, md: 10 },
          }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { xs: '36px', md: '56px' },
              lineHeight: { xs: '40px', md: '61.6px' },
            }}>
            {t('dentistprocess.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: '18px', md: '22px' },
              lineHeight: { xs: '26px', md: '30.8px' },
              pt: '32px',
            }}>
            {t('dentistprocess.subtitle')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              pt: '16px',
              px: { xs: '20px', md: '263px' },
              color: 'rgba(108, 110, 122, 1)',
            }}>
            {t('dentistprocess.text')}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: '80px',
            display: 'flex',
            flexDirection: 'column',
            gap: '80px',
            position: 'relative',
            px: { xs: 2, md: 10 },
          }}>
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  md: index % 2 === 0 ? 'row' : 'row-reverse',
                },
                gap: { xs: '40px', md: '145.96px' },
                position: 'relative',
                alignItems: 'center',
              }}>
              <img
                src={step.img}
                alt={step.title}
                style={{
                  width: '100%',
                  maxWidth: '300px',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '32px', md: '48px' },
                    lineHeight: { xs: '36px', md: '52.8px' },
                    textAlign: { xs: 'center', md: 'left' },
                  }}>
                  {step.title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '16px', md: '18px' },
                    lineHeight: { xs: '22px', md: '25.2px' },
                    textAlign: 'left',
                    pt: '32px',
                  }}>
                  {step.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </PageView>
    </Box>
  );
}
