import { Box } from '@mui/material';
import Hero from '../components/Hero';
import Campaigns from '../components/Campaigns';
import Dentis from '../components/Dentis';
import DentisAI from '../components/DentistAI';
import DentistProcess from '../components/DentistProcess';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import WhatsAppFab from '../components/WhatsAppFab';

export default function Home() {

  const loc = useLocation();

  useEffect(() => {
    const hash = loc.hash;
    if (hash) {
      const element = document.querySelector(hash);
      console.log(hash)
      if (element) {
        const yOffset = -110;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [loc]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}>
      <Hero />
      <DentisAI />
      <DentistProcess />
      <Dentis />
      <Campaigns />
      <WhatsAppFab />
    </Box>
  );
}
