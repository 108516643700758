import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AI from '../assets/AI.svg';
import PageView from './PageView';
import google from '../assets/googleplay.png';
import app from '../assets/appstore.png';
import appQR from '../assets/appqr.svg';
import googleQR from '../assets/googleqr.svg';
import { useTranslation } from 'react-i18next';

export default function Hero() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        background: 'linear-gradient(to right, rgba(214, 247, 255, 1), rgba(255, 255, 255, 1))',
      }}
    >
      <PageView>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            gap: isSmallScreen ? '24px' : '60px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {isSmallScreen && (
            <img
              src={AI}
              alt="AI Illustration"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          )}
          <Box
            sx={{
              flex: 1,
              pt: '52px',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: '42px', md: '72px' },
                color: 'rgba(27,29,34,1)',
              }}
            >
              {t('hero.title')}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 400,
                color: 'rgba(50,53,62,1)',
                fontSize: { xs: '16px', md: '24px' },
                pt: '24px',
              }}
            >
              {t('hero.subtitle')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '48px',
                pb: { xs: '52px' },
              }}
            >
              <StoreButton
                imgSrc={app}
                imgAlt="appstore"
                text1={t('hero.iosText1')}
                text2={t('hero.iosText2')}
                qrCode={appQR}
                onClick={() => { window.open('https://apps.apple.com/tr/app/dentistoday/id6482289821', '_blank') }}
              />
              <StoreButton
                imgSrc={google}
                imgAlt="googleplay"
                text1={t('hero.androidText1')}
                text2={t('hero.androidText2')}
                qrCode={googleQR}
                onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.dentisTodayAndroid.app', '_blank') }}
              />
            </Box>
          </Box>
          {!isSmallScreen && (
            <img
              src={AI}
              alt="AI Illustration"
              style={{
                width: '50%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          )}
        </Box>
      </PageView>
    </Box>
  );
}

interface StoreButtonProps {
  imgSrc: string;
  imgAlt: string;
  text1: string;
  text2: string;
  qrCode: string;
  onClick: () => void;
}

const StoreButton: React.FC<StoreButtonProps> = ({ imgSrc, imgAlt, text1, text2, qrCode, onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          background: 'black',
          borderRadius: '15px',
          padding: '8px 16px',
          '&:hover': {
            background: 'black',
          },
        }}
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          style={{ width: '24px', height: '24px' }}
        />
        <Box sx={{ textAlign: 'left' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: '14px',
              color: 'rgba(240, 253, 250, 1)',
              textTransform: 'none',
            }}
          >
            {text1}
          </Typography>
          <Typography
            sx={{
              color: 'rgba(240, 253, 250, 1)',
              textTransform: 'none',
            }}
          >
            {text2}
          </Typography>
        </Box>
      </Button>
      <img src={qrCode} alt={`${imgAlt} QR Code`} style={{ width: '100px', height: '100px' }} />
    </Box>
  );
};
