import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.jpg';
export default function PrivacyPolicy() {

  const { i18n } = useTranslation();

  const tr = `
  <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
h1
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:34.15pt;
	text-indent:-28.35pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
h2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:76.6pt;
	text-align:justify;
	text-indent:-42.45pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.2pt;
	text-indent:-70.35pt;
	text-autospace:none;
	font-size:22.0pt;
	font-family:"Times New Roman",serif;
	font-weight:bold;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin:0in;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:19.95pt;
	text-align:justify;
	text-indent:-14.2pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.TableParagraph, li.TableParagraph, div.TableParagraph
	{mso-style-name:"Table Paragraph";
	margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{text-autospace:none;}
 /* Page Definitions */
 @page WordSection1
	{size:595.5pt 842.0pt;
	margin:96.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection3
	{page:WordSection3;}
@page WordSection4
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection4
	{page:WordSection4;}
@page WordSection5
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection5
	{page:WordSection5;}
@page WordSection6
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection6
	{page:WordSection6;}
@page WordSection7
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection7
	{page:WordSection7;}
@page WordSection8
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection8
	{page:WordSection8;}
@page WordSection9
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection9
	{page:WordSection9;}
@page WordSection10
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection10
	{page:WordSection10;}
@page WordSection11
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection11
	{page:WordSection11;}
@page WordSection12
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection12
	{page:WordSection12;}
@page WordSection13
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection13
	{page:WordSection13;}
@page WordSection14
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection14
	{page:WordSection14;}
@page WordSection15
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection15
	{page:WordSection15;}
@page WordSection16
	{size:595.5pt 842.0pt;
	margin:96.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection16
	{page:WordSection16;}
@page WordSection17
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection17
	{page:WordSection17;}
@page WordSection18
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection18
	{page:WordSection18;}
@page WordSection19
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection19
	{page:WordSection19;}
@page WordSection20
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 90.15pt 65.0pt;}
div.WordSection20
	{page:WordSection20;}
@page WordSection21
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection21
	{page:WordSection21;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoBodyText><span lang=TR style='font-size:22.0pt'>&nbsp;</span></p>

<p class=MsoBodyText><span lang=TR style='font-size:22.0pt'>&nbsp;</span></p>

<p class=MsoBodyText><span lang=TR style='font-size:22.0pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:15.4pt'><span lang=TR style='font-size:
22.0pt'>&nbsp;</span></p>

<h1>
KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI
</h1>

<p class=MsoBodyText><b><span lang=TR style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:4.05pt'>

<table cellpadding=0 cellspacing=0 align=center>
 <tr>
  <td height=0></td>
 </tr>
 <tr>
  <td></td>
  <td><img width=184 height=150 src="${logo}"></td>
 </tr>
</table>

<br clear=ALL>
</p>

<p class=MsoBodyText><b><span lang=TR style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:7.6pt'><b><span lang=TR
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoTitle align=center style='text-align:center;text-indent:0in'><span
lang=TR style='letter-spacing:-.1pt'>DENTISTODAY</span></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText><b><span lang=TR style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:11.05pt'><b><span lang=TR
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:.2pt;
margin-bottom:0in;margin-left:.2pt;margin-bottom:.0001pt;text-align:center'><b><span
lang=TR style='font-size:12.0pt'>Nisan <span style='letter-spacing:-.2pt'>2024</span></span></b></p>

</div>

<span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection2>

<h1 style='margin-top:3.7pt'><span lang=TR>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>AMAÇ<span style='letter-spacing:.1pt'> </span>VE<span
style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>KAPSAM</span></span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.3pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><b><span lang=TR style='font-size:11.0pt;line-height:115%'>DentisToday </span></b><span
lang=TR>olarak, müşterilerimiz, çalışanlarımız, ziyaretçilerimiz ve iş<span
style='letter-spacing:-.15pt'> </span>ortaklarımız<span style='letter-spacing:
-.05pt'> </span>da<span style='letter-spacing:-.05pt'> </span>dahil olmak
üzere, faaliyetlerimiz sırasında temasta bulunduğumuz tüm gerçek kişilerin,
Anayasal bir hak olan kişisel verilerinin korunmasına ve 6698 Sayılı Kişisel
Verilerin Korunması Kanunu’nda yer alan düzenlemelere uyum sağlanmasına azami
önemi vermekteyiz.</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.85pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Bu doğrultuda, Veri<span style='letter-spacing:-.4pt'> </span>Sorumlusu
sıfatıyla, kişisel verilerin hukuka aykırı<span style='letter-spacing:-.4pt'> </span>olarak
işlenmesini, bu verilere hukuka aykırı<span style='letter-spacing:-.1pt'> </span>olarak
erişilmesini<span style='letter-spacing:-.1pt'> </span>önlemek ve kişisel
verilerin muhafazasını sağlamak amacıyla, gerekli her türlü teknik ve idari
önlemleri almaktayız.</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><b><span lang=TR style='font-size:11.0pt;line-height:115%'>DentisToday </span></b><span
lang=TR>olarak, işbu Kişisel Verilerin Korunması ve İşlenmesi Politikası ile,
kişisel verilerin<span style='letter-spacing:-.75pt'> </span>toplanması,<span
style='letter-spacing:-.75pt'> </span>kullanılması,<span style='letter-spacing:
-.35pt'> </span>işlenmesi,<span style='letter-spacing:-.6pt'> </span>aktarılması,<span
style='letter-spacing:-.6pt'> </span>saklanması<span style='letter-spacing:
-.75pt'> </span>süreç<span style='letter-spacing:-.1pt'> </span>ve<span
style='letter-spacing:-.75pt'> </span>sistemlerimiz<span style='letter-spacing:
-.5pt'> </span>ile temel ilkelerimiz hakkında sizleri bilgilendirmeyi
amaçlamaktayız.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.25pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph align=left style='margin-left:34.15pt;text-align:
left;text-indent:-28.35pt'><b><span lang=TR style='font-size:12.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>TANIMLAR</span></b></p>

<p class=MsoBodyText style='margin-top:6.55pt'><b><span lang=TR
style='font-size:10.0pt'>&nbsp;</span></b></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:16.05pt'>
  <td width=167 valign=top style='width:125.55pt;border:solid black 1.0pt;
  border-right:none;padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>DentisToday</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border-top:solid black 1.0pt;
  border-left:none;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>DentisToday<span style='letter-spacing:-.4pt'>
  </span>Ve<span style='letter-spacing:-.05pt'> </span>Diş<span
  style='letter-spacing:-.1pt'> </span>Teknolojileri<span style='letter-spacing:
  -.2pt'> </span>Anonim<span style='letter-spacing:-.4pt'> </span><span
  style='letter-spacing:-.1pt'>Şirketi</span></span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Açık<span style='letter-spacing:-.35pt'> </span><span
  style='letter-spacing:-.2pt'>Rıza</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Belirli<span style='letter-spacing:.35pt'> </span>bir<span
  style='letter-spacing:.75pt'> </span>konuya<span style='letter-spacing:.85pt'>
  </span>ilişkin,<span style='letter-spacing:1.0pt'> </span>bilgilendirilmeye<span
  style='letter-spacing:.65pt'> </span>dayanan<span style='letter-spacing:.45pt'>
  </span>ve<span style='letter-spacing:.65pt'> </span><span style='letter-spacing:
  -.1pt'>özgür</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt'>iradeyle<span style='letter-spacing:-.2pt'> </span>açıklanan<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.2pt'>rıza.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Anonim<span style='letter-spacing:-.15pt'> </span>Hâle
  <span style='letter-spacing:-.1pt'>Getirme</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:115%'><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel<span
  style='letter-spacing:2.0pt'> </span>verilerin,<span style='letter-spacing:
  4.0pt'> </span>başka<span style='letter-spacing:4.0pt'> </span>verilerle<span
  style='letter-spacing:4.0pt'> </span>eşleştirilerek<span style='letter-spacing:
  4.0pt'> </span>dahi<span style='letter-spacing:2.0pt'> </span>hiçbir surette<span
  style='letter-spacing:2.8pt'> </span>kimliği<span style='letter-spacing:2.8pt'>
  </span>belirli<span style='letter-spacing:3.05pt'> </span>veya<span
  style='letter-spacing:3.2pt'> </span>belirlenebilir<span style='letter-spacing:
  3.3pt'> </span>bir<span style='letter-spacing:3.1pt'> </span>gerçek<span
  style='letter-spacing:3.0pt'> </span><span style='letter-spacing:-.1pt'>kişiyle</span></span></p>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.75pt'><span
  lang=TR style='font-size:12.0pt'>ilişkilendirilemeyecek<span
  style='letter-spacing:-.35pt'> </span>hâle<span style='letter-spacing:-.5pt'>
  </span><span style='letter-spacing:-.1pt'>getirilmesi.</span></span></p>
  </td>
 </tr>
 <tr style='height:63.6pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:63.6pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Başvuru<span style='letter-spacing:-.35pt'> </span><span
  style='letter-spacing:-.1pt'>Formu</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.6pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.6pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.65pt;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel veri
  sahiplerinin haklarını kullanmak için yapacakları başvuruyu içeren “6698
  Sayılı Kişisel Verilerin Korunması Kanunu<span style='letter-spacing:2.15pt'>
  </span>Gereğince<span style='letter-spacing:2.15pt'> </span>İlgili<span
  style='letter-spacing:1.95pt'> </span>Kişi<span style='letter-spacing:1.75pt'>
  </span>(Veri<span style='letter-spacing:1.7pt'> </span>Sahibi)<span
  style='letter-spacing:2.25pt'> </span>Tarafından<span style='letter-spacing:
  1.95pt'> </span><span style='letter-spacing:-.2pt'>Veri</span></span></p>
  <p class=TableParagraph style='margin-left:5.9pt;text-align:justify;
  line-height:13.55pt'><span lang=TR style='font-size:12.0pt'>Sorumlusuna<span
  style='letter-spacing:-.35pt'> </span>Yapılacak<span style='letter-spacing:
  -.15pt'> </span>Başvurulara<span style='letter-spacing:-.25pt'> </span>İlişkin<span
  style='letter-spacing:-.15pt'> </span>Başvuru<span style='letter-spacing:
  -.15pt'> </span><span style='letter-spacing:-.1pt'>Formu”.</span></span></p>
  </td>
 </tr>
 <tr style='height:15.8pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Çalışan</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><b><span
  lang=TR>DentisToday<span style='letter-spacing:-.2pt'> </span></span></b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>personeli.</span></p>
  </td>
 </tr>
 <tr style='height:47.75pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Çalışan<span style='letter-spacing:-.4pt'> </span><span
  style='letter-spacing:-.1pt'>Adayı</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><b><span
  lang=TR>DentisToday<span style='letter-spacing:1.1pt'> </span></span></b><span
  lang=TR style='font-size:12.0pt'>herhangi<span style='letter-spacing:.65pt'> </span>bir<span
  style='letter-spacing:1.2pt'> </span>yolla<span style='letter-spacing:1.05pt'>
  </span>iş<span style='letter-spacing:.75pt'> </span>başvurusunda<span
  style='letter-spacing:.85pt'> </span>bulunmuş<span style='letter-spacing:
  1.0pt'> </span><span style='letter-spacing:-.25pt'>ya</span></span></p>
  <p class=TableParagraph style='margin-top:.35pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;line-height:15.5pt'><span
  lang=TR style='font-size:12.0pt'>da<span style='letter-spacing:2.0pt'> </span>özgeçmiş<span
  style='letter-spacing:2.0pt'> </span>ve<span style='letter-spacing:2.0pt'> </span>ilgili<span
  style='letter-spacing:2.0pt'> </span>bilgilerini<span style='letter-spacing:
  2.0pt'> </span>şirketimizin<span style='letter-spacing:2.0pt'> </span>incelemesine<span
  style='letter-spacing:2.0pt'> </span>açmış olan gerçek kişiler</span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.2pt'>İmha</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Kişisel<span style='letter-spacing:2.95pt'> </span>verilerin<span
  style='letter-spacing:2.95pt'> </span>silinmesi,<span style='letter-spacing:
  3.25pt'> </span>yok<span style='letter-spacing:2.95pt'> </span>edilmesi<span
  style='letter-spacing:3.0pt'> </span>veya<span style='letter-spacing:2.9pt'> </span>anonim<span
  style='letter-spacing:2.75pt'> </span><span style='letter-spacing:-.2pt'>hale</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt;letter-spacing:-.1pt'>getirilmesi.</span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>İş<span style='letter-spacing:-.2pt'> </span><span
  style='letter-spacing:-.1pt'>Ortağı</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph><span lang=TR>&nbsp;</span></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><b><span
  lang=TR style='letter-spacing:-.1pt'>DentisToday’</span><span lang=TR>   </span></b><span
  lang=TR style='letter-spacing:-.25pt'>in</span><span lang=TR>   </span><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>ticari</span><span
  lang=TR style='font-size:12.0pt'>    <span style='letter-spacing:-.1pt'>faaliyetlerini</span>    <span
  style='letter-spacing:-.1pt'>yürütürken,</span>    <span style='letter-spacing:
  -.1pt'>birlikte</span></span></p>
  <p class=TableParagraph style='margin-top:.35pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;line-height:15.5pt'><span
  lang=TR style='font-size:12.0pt'>muhtelif<span style='letter-spacing:1.9pt'> </span>projeler<span
  style='letter-spacing:2.0pt'> </span>yürütmek,<span style='letter-spacing:
  2.0pt'> </span>hizmet<span style='letter-spacing:2.0pt'> </span>almak<span
  style='letter-spacing:2.0pt'> </span>gibi<span style='letter-spacing:2.0pt'> </span>amaçlarla<span
  style='letter-spacing:2.0pt'> </span>iş ortaklığı kurduğu taraflar.</span></p>
  </td>
 </tr>
 <tr style='height:15.8pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Kanun/KVKK</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>6698<span style='letter-spacing:-.05pt'> </span>Sayılı<span
  style='letter-spacing:-.05pt'> </span>Kişisel<span style='letter-spacing:
  -.5pt'> </span>Verilerin<span style='letter-spacing:-.05pt'> </span>Korunması
  <span style='letter-spacing:-.1pt'>Kanunu</span></span></p>
  </td>
 </tr>
 <tr style='height:31.85pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span lang=TR
  style='font-size:12.0pt'>Kişisel<span style='letter-spacing:-.35pt'> </span><span
  style='letter-spacing:-.2pt'>Veri</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph align=center style='margin-top:.05pt;margin-right:
  0in;margin-bottom:0in;margin-left:1.0pt;margin-bottom:.0001pt;text-align:
  center'><b><span lang=TR style='font-size:12.0pt;letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.65pt'><span
  lang=TR style='font-size:12.0pt'>Kimliği<span style='letter-spacing:-.75pt'> </span>belirli<span
  style='letter-spacing:-.6pt'> </span>veya<span style='letter-spacing:-.5pt'> </span>belirlenebilir<span
  style='letter-spacing:-.35pt'> </span>gerçek<span style='letter-spacing:-.2pt'>
  </span>kişiye<span style='letter-spacing:-.25pt'> </span>ilişkin<span
  style='letter-spacing:-.7pt'> </span>her<span style='letter-spacing:-.3pt'> </span><span
  style='letter-spacing:-.1pt'>türlü</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt;letter-spacing:-.1pt'>bilgi.</span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=167 valign=top style='width:125.55pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:35.9pt;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel<span
  style='letter-spacing:-.75pt'> </span>Verilerin <span style='letter-spacing:
  -.1pt'>İşlenmesi</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.95pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=center style='margin-left:1.0pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Kişisel<span style='letter-spacing:1.45pt'> </span>verilerin<span
  style='letter-spacing:1.6pt'> </span>tamamen<span style='letter-spacing:1.75pt'>
  </span>veya<span style='letter-spacing:1.75pt'> </span>kısmen<span
  style='letter-spacing:1.55pt'> </span>otomatik<span style='letter-spacing:
  2.05pt'> </span>olan<span style='letter-spacing:1.8pt'> </span>ya<span
  style='letter-spacing:1.75pt'> </span><span style='letter-spacing:-.25pt'>da</span></span></p>
  <p class=TableParagraph style='margin-top:.3pt;margin-right:5.25pt;
  margin-bottom:0in;margin-left:5.9pt;margin-bottom:.0001pt;line-height:15.5pt'><span
  lang=TR style='font-size:12.0pt'>herhangi<span style='letter-spacing:2.0pt'> </span>bir<span
  style='letter-spacing:2.0pt'> </span>veri<span style='letter-spacing:2.0pt'> </span>kayıt<span
  style='letter-spacing:2.0pt'> </span>sisteminin<span style='letter-spacing:
  2.0pt'> </span>parçası<span style='letter-spacing:2.0pt'> </span>olmak<span
  style='letter-spacing:2.0pt'> </span>kaydıyla<span style='letter-spacing:
  2.0pt'> </span><span style='letter-spacing:-.1pt'>otomatik</span>   <span
  style='letter-spacing:-.1pt'>olmayan</span>    <span style='letter-spacing:
  -.1pt'>yollarla</span>    <span style='letter-spacing:-.2pt'>elde</span>   <span
  style='letter-spacing:-.1pt'>edilmesi,</span>    <span style='letter-spacing:
  -.1pt'>kaydedilmesi,</span></span></p>
  </td>
 </tr>
</table>

</div>

<span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection3>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:79.45pt'>
  <td width=604 colspan=3 valign=top style='width:453.3pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.45pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.9pt;margin-bottom:
  0in;margin-left:147.15pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>depolanması,
  muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması,
  aktarılması, devralınması, elde edilebilir hâle getirilmesi,
  sınıflandırılması ya da kullanılmasının<span style='letter-spacing:2.85pt'>  
  </span>engellenmesi<span style='letter-spacing:2.85pt'>   </span>gibi<span
  style='letter-spacing:2.9pt'>   </span>veriler<span style='letter-spacing:
  2.9pt'>   </span><span style='letter-spacing:-.1pt'>üzerinde</span></span></p>
  <p class=TableParagraph style='margin-left:147.15pt;text-align:justify'><span
  lang=TR style='font-size:12.0pt'>gerçekleştirilen<span style='letter-spacing:
  -.35pt'> </span>her<span style='letter-spacing:-.25pt'> </span>türlü<span
  style='letter-spacing:-.1pt'> işlem.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:32.95pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel<span
  style='letter-spacing:-.75pt'> </span>Verilerin <span style='letter-spacing:
  -.1pt'>Silinmesi</span></span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:115%'><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel<span
  style='letter-spacing:1.75pt'> </span>verilerin<span style='letter-spacing:
  1.75pt'> </span>silinmesi,<span style='letter-spacing:2.0pt'> </span>kişisel<span
  style='letter-spacing:1.75pt'> </span>verilerin<span style='letter-spacing:
  2.0pt'> </span>ilgili<span style='letter-spacing:2.0pt'> </span>kullanıcılar
  için<span style='letter-spacing:2.55pt'> </span>hiçbir<span style='letter-spacing:
  2.7pt'> </span>şekilde<span style='letter-spacing:3.8pt'> </span>erişilemez<span
  style='letter-spacing:2.55pt'> </span>ve<span style='letter-spacing:3.8pt'> </span>tekrar<span
  style='letter-spacing:3.95pt'> </span>kullanılamaz<span style='letter-spacing:
  2.55pt'> </span><span style='letter-spacing:-.2pt'>hale</span></span></p>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.75pt'><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>getirilmesi.</span></p>
  </td>
 </tr>
 <tr style='height:47.75pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:116%'><b><span
  lang=TR style='font-size:12.0pt;line-height:116%'>Kişisel<span
  style='letter-spacing:-.75pt'> </span>Verilerin<span style='letter-spacing:
  -.75pt'> </span>Yok <span style='letter-spacing:-.1pt'>Edilmesi</span></span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Kişisel<span style='letter-spacing:3.75pt'> </span>verilerin<span
  style='letter-spacing:2.55pt'> </span>yok<span style='letter-spacing:2.6pt'> </span>edilmesi,<span
  style='letter-spacing:2.65pt'> </span>kişisel<span style='letter-spacing:
  3.9pt'> </span>verilerin<span style='letter-spacing:2.55pt'> </span>hiç<span
  style='letter-spacing:2.55pt'> </span><span style='letter-spacing:-.1pt'>kimse</span></span></p>
  <p class=TableParagraph style='margin-top:.55pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;line-height:15.5pt'><span
  lang=TR style='font-size:12.0pt'>tarafından hiçbir şekilde erişilemez, geri
  getirilemez ve tekrar kullanılamaz hale getirilmesi.</span></p>
  </td>
 </tr>
 <tr style='height:63.35pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:32.95pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel<span
  style='letter-spacing:-.75pt'> </span>Verilerin Anonim Hale <span
  style='letter-spacing:-.1pt'>Getirilmesi</span></span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.9pt;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel<span
  style='letter-spacing:-.75pt'> </span>verilerin<span style='letter-spacing:
  -.75pt'> </span>anonim<span style='letter-spacing:-.75pt'> </span>hale<span
  style='letter-spacing:-.75pt'> </span>getirilmesi,<span style='letter-spacing:
  -.75pt'> </span>kişisel<span style='letter-spacing:-.75pt'> </span>verilerin<span
  style='letter-spacing:-.7pt'> </span>başka verilerle eşleştirilse dahi hiçbir
  surette kimliği belirli veya belirlenebilir<span style='letter-spacing:3.75pt'>
  </span>bir<span style='letter-spacing:3.75pt'> </span>gerçek<span
  style='letter-spacing:3.65pt'> </span>kişiyle<span style='letter-spacing:
  3.85pt'> </span>ilişkilendirilemeyecek<span style='letter-spacing:3.7pt'> </span><span
  style='letter-spacing:-.2pt'>hale</span></span></p>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.75pt'><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>getirilmesi.</span></p>
  </td>
 </tr>
 <tr style='height:16.05pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.2pt'>Kurul</span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Kişisel<span style='letter-spacing:-.45pt'> </span>Verileri<span
  style='letter-spacing:-.25pt'> </span>Koruma<span style='letter-spacing:.2pt'>
  </span><span style='letter-spacing:-.1pt'>Kurulu</span></span></p>
  </td>
 </tr>
 <tr style='height:15.8pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Kurum</span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Kişisel<span style='letter-spacing:-.45pt'> </span>Verileri<span
  style='letter-spacing:-.25pt'> </span>Koruma<span style='letter-spacing:.2pt'>
  </span><span style='letter-spacing:-.1pt'>Kurumu</span></span></p>
  </td>
 </tr>
 <tr style='height:63.35pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Müşteri</span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph><span lang=TR>&nbsp;</span></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.5pt;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><b><span lang=TR>DentisToday </span></b><span lang=TR style='font-size:
  12.0pt;line-height:115%'>ile herhangi bir sözleşmesel ilişkisi olup
  olmadığına bakılmaksızın </span><b><span lang=TR>DentisToday </span></b><span
  lang=TR style='font-size:12.0pt;line-height:115%'>iş birimlerinin yürüttüğü<span
  style='letter-spacing:-.55pt'> </span>operasyonlar<span style='letter-spacing:
  -.15pt'> </span>kapsamında<span style='letter-spacing:-.05pt'> </span></span><b><span
  lang=TR>DentisToday’</span></b><span lang=TR>in<span style='letter-spacing:
  -.2pt'> </span></span><span lang=TR style='font-size:12.0pt;line-height:115%'>iş<span
  style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>ilişkileri</span></span></p>
  <p class=TableParagraph style='margin-left:5.9pt;text-align:justify;
  line-height:13.75pt'><span lang=TR style='font-size:12.0pt'>üzerinden<span
  style='letter-spacing:-.35pt'> </span>kişisel<span style='letter-spacing:
  -.05pt'> </span>verileri<span style='letter-spacing:-.3pt'> </span>elde<span
  style='letter-spacing:-.15pt'> </span>edilen<span style='letter-spacing:-.3pt'>
  </span>gerçek<span style='letter-spacing:-.05pt'> </span><span
  style='letter-spacing:-.1pt'>kişiler.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.7pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:116%'><b><span
  lang=TR style='font-size:12.0pt;line-height:116%'>Kullanıcı<span
  style='letter-spacing:-.6pt'> </span>/<span style='letter-spacing:-.6pt'> </span>Hasta<span
  style='letter-spacing:-.75pt'> </span>/ Hasta Adayı</span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><b><span
  lang=TR>DentisToday<span style='letter-spacing:.85pt'> </span></span></b><span
  lang=TR style='font-size:12.0pt'>ile<span style='letter-spacing:.6pt'> </span>faaliyet<span
  style='letter-spacing:.85pt'> </span>içerisinde<span style='letter-spacing:
  .6pt'> </span>bulunan<span style='letter-spacing:.4pt'> </span>veya<span
  style='letter-spacing:.6pt'> </span><span style='letter-spacing:-.1pt'>DentisToday</span></span></p>
  <p class=TableParagraph style='margin-top:.55pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;line-height:15.5pt'><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>uygulamasını</span><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.3pt'> </span><span lang=TR
  style='font-size:12.0pt;letter-spacing:-.1pt'>kullanarak iş</span><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.2pt'> </span><span lang=TR
  style='font-size:12.0pt;letter-spacing:-.1pt'>birimlerinin yürüttüğü</span><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.3pt'> </span><span lang=TR
  style='font-size:12.0pt;letter-spacing:-.1pt'>operasyonlar </span><span
  lang=TR style='font-size:12.0pt'>kapsamında kişisel verileri elde edilen
  gerçek kişiler.</span></p>
  </td>
 </tr>
 <tr style='height:79.15pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:79.15pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:115%'><b><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Özel<span style='letter-spacing:
  -.75pt'> </span>Nitelikli<span style='letter-spacing:-.75pt'> </span>Kişisel <span
  style='letter-spacing:-.2pt'>Veri</span></span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.15pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.15pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:5.15pt;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kişilerin ırkı,
  etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer
  inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı,
  cinsel hayatı, ceza mahkûmiyeti<span style='letter-spacing:1.5pt'>  </span>ve<span
  style='letter-spacing:1.6pt'>  </span>güvenlik<span style='letter-spacing:
  1.6pt'>  </span>tedbirleriyle<span style='letter-spacing:1.7pt'>  </span>ilgili<span
  style='letter-spacing:1.6pt'>  </span>verileri<span style='letter-spacing:
  1.55pt'>  </span><span style='letter-spacing:-.25pt'>ile</span></span></p>
  <p class=TableParagraph style='margin-left:5.9pt;text-align:justify;
  line-height:13.7pt'><span lang=TR style='font-size:12.0pt'>biyometrik<span
  style='letter-spacing:-.05pt'> </span>ve<span style='letter-spacing:-.3pt'> </span>genetik<span
  style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>veriler.</span></span></p>
  </td>
 </tr>
 <tr style='height:79.45pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:79.45pt'>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span lang=TR
  style='font-size:12.0pt'>Periyodik<span style='letter-spacing:-.65pt'> </span><span
  style='letter-spacing:-.2pt'>İmha</span></span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.45pt'>
  <p class=TableParagraph align=right style='margin-top:.1pt;margin-right:5.35pt;
  margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:right'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.45pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.75pt;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kanunda yer
  alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması
  durumunda kişisel verileri saklama ve imha politikasında belirtilen ve tekrar
  eden aralıklarla<span style='letter-spacing:3.4pt'> </span>resen<span
  style='letter-spacing:3.2pt'> </span>gerçekleştirilecek<span
  style='letter-spacing:3.45pt'> </span>silme,<span style='letter-spacing:3.75pt'>
  </span>yok<span style='letter-spacing:3.45pt'> </span>etme<span
  style='letter-spacing:3.45pt'> </span><span style='letter-spacing:-.2pt'>veya</span></span></p>
  <p class=TableParagraph style='margin-left:5.9pt;text-align:justify;
  line-height:13.7pt'><span lang=TR style='font-size:12.0pt'>anonim<span
  style='letter-spacing:-.25pt'> </span>hale<span style='letter-spacing:-.1pt'>
  </span>getirme<span style='letter-spacing:.15pt'> </span><span
  style='letter-spacing:-.1pt'>işlemi.</span></span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Politika</span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><b><span
  lang=TR>DentisToday<span style='letter-spacing:1.4pt'>  </span></span></b><span
  lang=TR style='font-size:12.0pt'>tarafından,<span style='letter-spacing:3.9pt'>
  </span>Kanun<span style='letter-spacing:3.5pt'> </span>uyarınca<span
  style='letter-spacing:3.95pt'> </span>hazırlanan,<span style='letter-spacing:
  1.3pt'>  </span><span style='letter-spacing:-.2pt'>işbu</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt'>kişisel<span style='letter-spacing:-.35pt'> </span>verilerin<span
  style='letter-spacing:-.35pt'> </span>korunması<span style='letter-spacing:
  -.15pt'> </span>ve<span style='letter-spacing:.1pt'> </span>işlenmesi<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>politikası.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.7pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span lang=TR
  style='font-size:12.0pt;letter-spacing:-.1pt'>Tedarikçi</span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph align=right style='margin-top:.05pt;margin-right:
  5.35pt;margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:
  right'><b><span lang=TR style='font-size:12.0pt;letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.65pt'><b><span
  lang=TR>DentisToday’<span style='letter-spacing:.65pt'> </span>in<span
  style='letter-spacing:.45pt'> </span></span></b><span lang=TR
  style='font-size:12.0pt'>ticari<span style='letter-spacing:.8pt'> </span>faaliyetlerini<span
  style='letter-spacing:.6pt'> </span>yürütürken,<span style='letter-spacing:
  1.15pt'> </span></span><b><span lang=TR style='letter-spacing:-.1pt'>DentisToda’in</span></b></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt'>emir<span style='letter-spacing:1.8pt'> </span>ve<span
  style='letter-spacing:1.5pt'> </span>talimatlarına<span style='letter-spacing:
  1.55pt'> </span>uygun<span style='letter-spacing:1.35pt'> </span>olarak,<span
  style='letter-spacing:1.65pt'> </span>sözleşme<span style='letter-spacing:
  1.55pt'> </span>temelli<span style='letter-spacing:1.4pt'> </span><span
  style='letter-spacing:-.1pt'>olarak,</span></span></p>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><b><span lang=TR>DentisToday’e<span
  style='letter-spacing:-.2pt'> </span></span></b><span lang=TR
  style='font-size:12.0pt'>hizmet<span style='letter-spacing:-.1pt'> </span>sunan<span
  style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.1pt'>taraflar.</span></span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Veri<span style='letter-spacing:-.4pt'> </span><span
  style='letter-spacing:-.1pt'>İşleyen</span></span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Veri<span style='letter-spacing:3.45pt'> </span>sorumlusunun<span
  style='letter-spacing:2.5pt'> </span>verdiği<span style='letter-spacing:2.5pt'>
  </span>yetkiye<span style='letter-spacing:3.95pt'> </span>dayanarak<span
  style='letter-spacing:2.7pt'> </span>onun<span style='letter-spacing:3.75pt'>
  </span><span style='letter-spacing:-.1pt'>adına</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt'>kişisel<span style='letter-spacing:-.25pt'> </span>verileri<span
  style='letter-spacing:-.2pt'> </span>işleyen<span style='letter-spacing:-.2pt'>
  </span>gerçek<span style='letter-spacing:.05pt'> </span>veya tüzel<span
  style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.2pt'>kişi.</span></span></p>
  </td>
 </tr>
 <tr style='height:31.85pt'>
  <td width=163 valign=top style='width:122.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Veri Kayıt<span style='letter-spacing:.15pt'>
  </span><span style='letter-spacing:-.1pt'>Sistemi</span></span></b></p>
  </td>
  <td width=25 valign=top style='width:18.9pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph align=right style='margin-right:5.35pt;text-align:
  right;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-left:5.9pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Kişisel<span style='letter-spacing:-.7pt'> </span>verilerin<span
  style='letter-spacing:-.35pt'> </span>belirli<span style='letter-spacing:
  -.75pt'> </span>kriterlere<span style='letter-spacing:-.7pt'> </span>göre<span
  style='letter-spacing:-.65pt'> </span>yapılandırılarak<span style='letter-spacing:
  -.35pt'> </span><span style='letter-spacing:-.1pt'>işlendiği</span></span></p>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.9pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt'>kayıt<span style='letter-spacing:-.25pt'> </span><span
  style='letter-spacing:-.1pt'>sistemini</span></span></p>
  </td>
 </tr>
</table>

</div>

<span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection4>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:47.7pt'>
  <td width=164 valign=top style='width:123.3pt;border:solid black 1.0pt;
  border-right:none;padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Veri<span style='letter-spacing:-.3pt'> </span><span
  style='letter-spacing:-.1pt'>Sorumlusu</span></span></b></p>
  </td>
  <td width=24 valign=top style='width:18.15pt;border-top:solid black 1.0pt;
  border-left:none;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph align=center style='margin-left:3.3pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:115%'><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Kişisel<span
  style='letter-spacing:1.3pt'> </span>verilerin<span style='letter-spacing:
  1.5pt'> </span>işleme<span style='letter-spacing:1.6pt'> </span>amaçlarını<span
  style='letter-spacing:1.55pt'> </span>ve<span style='letter-spacing:1.45pt'> </span>vasıtalarını<span
  style='letter-spacing:1.55pt'> </span>belirleyen, veri<span style='letter-spacing:
  1.4pt'>  </span>kayıt<span style='letter-spacing:1.7pt'>  </span>sisteminin<span
  style='letter-spacing:1.55pt'>  </span>kurulmasından<span style='letter-spacing:
  1.55pt'>  </span>ve<span style='letter-spacing:1.65pt'>  </span><span
  style='letter-spacing:-.1pt'>yönetilmesinden</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt'><span lang=TR
  style='font-size:12.0pt'>sorumlu<span style='letter-spacing:-.05pt'> </span>olan<span
  style='letter-spacing:-.3pt'> </span>gerçek<span style='letter-spacing:.15pt'>
  </span>veya<span style='letter-spacing:-.05pt'> </span>tüzel<span
  style='letter-spacing:-.45pt'> </span><span style='letter-spacing:-.2pt'>kişi.</span></span></p>
  </td>
 </tr>
 <tr style='height:15.85pt'>
  <td width=164 valign=top style='width:123.3pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt'>Veri<span style='letter-spacing:-.45pt'> </span>Sahibi/İlgili<span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.2pt'>Kişi</span></span></b></p>
  </td>
  <td width=24 valign=top style='width:18.15pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph align=center style='margin-left:3.3pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  lang=TR style='font-size:12.0pt'>Kişisel<span style='letter-spacing:-.25pt'> </span>verisi<span
  style='letter-spacing:-.2pt'> </span>işlenen<span style='letter-spacing:-.2pt'>
  </span>gerçek<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.2pt'>kişi.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=164 valign=top style='width:123.3pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Yönetmelik</span></b></p>
  </td>
  <td width=24 valign=top style='width:18.15pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=center style='margin-left:3.3pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.5pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;line-height:115%'><span lang=TR
  style='font-size:12.0pt;line-height:115%'>28.10.2017<span style='letter-spacing:
  4.0pt'> </span>Tarihli<span style='letter-spacing:4.0pt'> </span>Resmi<span
  style='letter-spacing:4.0pt'> </span>Gazete’de<span style='letter-spacing:
  4.0pt'> </span>yayımlanan<span style='letter-spacing:4.0pt'> </span>“Kişisel <span
  style='letter-spacing:-.1pt'>Verilerin</span>    <span style='letter-spacing:
  -.1pt'>Silinmesi,</span>    <span style='letter-spacing:-.25pt'>Yok</span>   <span
  style='letter-spacing:-.1pt'>Edilmesi</span>    <span style='letter-spacing:
  -.2pt'>veya</span>    Anonim<span style='letter-spacing:1.9pt'>  </span><span
  style='letter-spacing:-.2pt'>Hale</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.75pt'><span
  lang=TR style='font-size:12.0pt'>Getirilmesi<span style='letter-spacing:-.35pt'>
  </span>Hakkında<span style='letter-spacing:-.15pt'> </span><span
  style='letter-spacing:-.1pt'>Yönetmelik”.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.75pt'>
  <td width=164 valign=top style='width:123.3pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Ziyaretçi</span></b></p>
  </td>
  <td width=24 valign=top style='width:18.15pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph align=center style='margin-left:3.3pt;text-align:
  center;line-height:13.65pt'><b><span lang=TR style='font-size:12.0pt;
  letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:115%'><b><span
  lang=TR>DentisToday </span></b><span lang=TR style='font-size:12.0pt;
  line-height:115%'>‘<span style='letter-spacing:-.25pt'> </span>in<span
  style='letter-spacing:-.05pt'> </span>faaliyet<span style='letter-spacing:
  -.05pt'> </span>gösterdiği<span style='letter-spacing:-.5pt'> </span>fiziksel<span
  style='letter-spacing:-.3pt'> </span>yerleşkelere<span style='letter-spacing:
  -.35pt'> </span>çeşitli amaçlarla<span style='letter-spacing:1.6pt'> </span>girmiş<span
  style='letter-spacing:1.6pt'> </span>olan<span style='letter-spacing:1.45pt'>
  </span>veya<span style='letter-spacing:1.85pt'> </span>internet<span
  style='letter-spacing:1.9pt'> </span>sitelerimizi<span style='letter-spacing:
  1.5pt'> </span>ziyaret<span style='letter-spacing:1.95pt'> </span><span
  style='letter-spacing:-.2pt'>eden</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt'><span lang=TR
  style='font-size:12.0pt'>gerçek <span style='letter-spacing:-.1pt'>kişiler.</span></span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:4.8pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoListParagraph align=left style='margin-top:.05pt;margin-right:0in;
margin-bottom:0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:left;
text-indent:-28.35pt'><b><span lang=TR style='font-size:12.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt'>KİŞİSEL<span
style='letter-spacing:-.2pt'> </span>VERİLERİN<span style='letter-spacing:-.1pt'>
</span>KORUNMASINA<span style='letter-spacing:-.15pt'> </span>İLİŞKİN<span
style='letter-spacing:-.1pt'> </span>USUL<span style='letter-spacing:-.15pt'> </span>VE<span
style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>ESASLAR</span></span></b></p>

<p class=MsoBodyText style='margin-top:4.1pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoListParagraph align=left style='margin-left:34.15pt;text-align:
left;text-indent:-28.35pt'><b><span lang=TR style='font-size:12.0pt'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=TR style='font-size:12.0pt'>GENEL<span style='letter-spacing:-.25pt'> </span><span
style='letter-spacing:-.1pt'>İLKELER</span></span></b></p>

<p class=MsoBodyText style='margin-top:4.05pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.65pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Anayasa’nın m. 20/III kişisel verilerin ancak kanunda
öngörülen hallerde veya kişinin açık rızasıyla<span style='letter-spacing:-.5pt'>
</span>işlenebileceği<span style='letter-spacing:-.6pt'> </span>belirtilerek<span
style='letter-spacing:-.65pt'> </span>kişisel<span style='letter-spacing:-.6pt'>
</span>verilerin<span style='letter-spacing:-.4pt'> </span>korunması<span
style='letter-spacing:-.75pt'> </span>güvence<span style='letter-spacing:-.7pt'>
</span>altına<span style='letter-spacing:-.7pt'> </span>alınmıştır.<span
style='letter-spacing:-.55pt'> </span>Kişisel veri sahiplerine tanınan bu hak
doğrultusunda <b>DentisToday</b>, kişisel verileri ilgili mevzuatta belirtilen
ilkeler doğrultusunda veya kişinin açık rızasının bulunduğu durumlarda
aşağıdaki ilkelere uygun olarak işlemektedir:</span></p>

<p class=MsoBodyText style='margin-top:2.15pt'><span lang=TR>&nbsp;</span></p>

<h2><span lang=TR style='letter-spacing:-.25pt'>3.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Hukuka<span style='letter-spacing:-.15pt'> </span>ve<span
style='letter-spacing:-.15pt'> </span>Dürüstlük<span style='letter-spacing:
-.3pt'> </span>Kuralına<span style='letter-spacing:-.1pt'> </span>Uygun<span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>İşleme:</span></span></h2>

<p class=MsoBodyText style='margin-top:1.8pt;margin-right:5.9pt;margin-bottom:
0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday, Kişisel Veriler’in işlenmesinde, tüm hukuki
düzenlemeler ve dürüstlük kuralına uygun hareket etmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.45pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:5.75pt;
margin-bottom:0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:left;
text-indent:0in;line-height:113%'><b><span lang=TR style='font-size:12.0pt;
line-height:113%;letter-spacing:-.25pt'>3.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
Veriler’in Doğru ve Gerektiğinde Güncel Olmasını Sağlama </span></b><span
lang=TR style='font-size:12.0pt;line-height:113%'>DentisToday<span
style='letter-spacing:-.4pt'> </span>tarafından işlenen Kişisel<span
style='letter-spacing:-.45pt'> </span>Veriler’in doğru<span style='letter-spacing:
-.25pt'> </span>ve<span style='letter-spacing:-.05pt'> </span>güncel<span
style='letter-spacing:-.25pt'> </span>duruma<span style='letter-spacing:-.05pt'>
</span>uygun<span style='letter-spacing:-.25pt'> </span>olması için<span
style='letter-spacing:-.15pt'> </span>gerekli<span style='letter-spacing:-.35pt'>
</span>tedbirler alınmakta ve işlenmekte<span style='letter-spacing:-.2pt'> </span>olan<span
style='letter-spacing:-.15pt'> </span>verilerin<span style='letter-spacing:
-.15pt'> </span>gerçek durumu yansıtmasını sağlamak<span style='letter-spacing:
1.75pt'> </span>amacıyla<span style='letter-spacing:1.95pt'> </span>bilgilendirmelerde<span
style='letter-spacing:1.7pt'> </span>bulunularak<span style='letter-spacing:
1.75pt'> </span>Veri Sahipleri’ne<span style='letter-spacing:1.7pt'> </span>gerekli<span
style='letter-spacing:1.8pt'> </span>imkânlar <span style='letter-spacing:-.1pt'>tanınmaktadır.</span></span></p>

<p class=MsoBodyText style='margin-top:2.65pt'><span lang=TR>&nbsp;</span></p>

<h2><span lang=TR style='letter-spacing:-.25pt'>3.1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Belirli,<span style='letter-spacing:-.05pt'> </span>Açık<span
style='letter-spacing:-.35pt'> </span>ve<span style='letter-spacing:-.2pt'> </span>Meşru<span
style='letter-spacing:-.15pt'> </span>Amaçlarla<span style='letter-spacing:
-.1pt'> İşleme</span></span></h2>

<p class=MsoBodyText style='margin-top:2.05pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday, meşru ve hukuka uygun olan kişisel veri işleme
amacını açık ve kesin olarak belirlemektedir. DentisToday, yürütmekte olduğu
ticari faaliyet ile bağlantılı ve bunlar için gerekli olan kadar işlemektedir.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.25pt'><span lang=TR>&nbsp;</span></p>

<h2><span lang=TR style='letter-spacing:-.25pt'>3.1.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>İşlendikleri<span style='letter-spacing:-.25pt'> </span>Amaçla<span
style='letter-spacing:-.25pt'> </span>Bağlantılı,<span style='letter-spacing:
-.1pt'> </span>Sınırlı<span style='letter-spacing:-.25pt'> </span>ve<span
style='letter-spacing:-.25pt'> </span>Ölçülü <span style='letter-spacing:-.2pt'>Olma</span></span></h2>

<p class=MsoBodyText style='margin-top:1.8pt;margin-right:6.05pt;margin-bottom:
0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday, Kişisel Verileri belirlenen amaçların
gerçekleştirilebilmesine elverişli bir biçimde işlemekte ve amacın
gerçekleştirilmesiyle ilgili olmayan veya ihtiyaç duyulmayan Kişisel Veriler’in
işlenmesinden kaçınmaktadır.</span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection5>

<h2 style='margin-top:3.7pt;margin-right:7.85pt;margin-bottom:0in;margin-left:
76.65pt;margin-bottom:.0001pt;text-indent:-42.5pt;line-height:115%'><span
lang=TR style='letter-spacing:-.25pt'>3.1.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>İlgili<span style='letter-spacing:-.25pt'> </span>Mevzuatta<span
style='letter-spacing:-.25pt'> </span>Öngörülen<span style='letter-spacing:
-.1pt'> </span>veya<span style='letter-spacing:-.25pt'> </span>İşlendikleri<span
style='letter-spacing:-.25pt'> </span>Amaç<span style='letter-spacing:-.3pt'> </span>İçin<span
style='letter-spacing:-.2pt'> </span>Gerekli<span style='letter-spacing:-.25pt'>
</span>Olan<span style='letter-spacing:-.25pt'> </span>Süre Kadar Muhafaza Etme</span></h2>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.0pt;margin-bottom:
0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday tarafından işlenen kişisel veriler, ancak
ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar
muhafaza edilmektedir. Bu kapsamda, DentisToday,<span style='letter-spacing:
-.75pt'> </span>ilgili<span style='letter-spacing:-.75pt'> </span>mevzuatta<span
style='letter-spacing:-.75pt'> </span>verilerin<span style='letter-spacing:
-.75pt'> </span>saklanması<span style='letter-spacing:-.75pt'> </span>için<span
style='letter-spacing:-.75pt'> </span>öngörülen<span style='letter-spacing:
-.75pt'> </span>bir<span style='letter-spacing:-.75pt'> </span>süre<span
style='letter-spacing:-.75pt'> </span>varsa<span style='letter-spacing:-.75pt'>
</span>bu<span style='letter-spacing:-.75pt'> </span>süreye uymakta; böyle bir
süre yoksa verileri, ancak işlendikleri amaç için gerekli olan süre kadar
muhafaza etmektedir. İşlenen verilerin saklama süreleri politikamızın devamında
ayrıntılı olarak bildirilmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>3.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.35pt'> </span>VERİLERİN<span
style='letter-spacing:-.05pt'> </span>TOPLANMASI,<span style='letter-spacing:
-.05pt'> </span>İŞLENMESİ<span style='letter-spacing:-.25pt'> </span>VE<span
style='letter-spacing:-.15pt'> </span>İŞLENME<span style='letter-spacing:-.2pt'>
</span><span style='letter-spacing:-.1pt'>AMAÇLARI</span></span></h1>

<p class=MsoBodyText style='margin-top:4.1pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><b><span lang=TR>DentisToday </span></b><span lang=TR>tarafından işlenen
kişisel verileriniz, verilen sağlık, hekim hizmetleri ile tanı<span
style='letter-spacing:-.05pt'> </span>ve bilgilendirmelere bağlı olarak
değişebilmekle birlikte otomatik ya da otomatik olmayan yöntemlerle, çağrı
merkezi, internet sitesi, online hizmetler, online/mobil uygulamalar ve benzeri<span
style='letter-spacing:-.6pt'> </span>vasıtalarla<span style='letter-spacing:
-.2pt'> </span>sözlü,<span style='letter-spacing:-.05pt'> </span>yazılı<span
style='letter-spacing:-.15pt'> </span>ya<span style='letter-spacing:-.2pt'> </span>da<span
style='letter-spacing:-.2pt'> </span>elektronik<span style='letter-spacing:
-.15pt'> </span>olarak<span style='letter-spacing:-.4pt'> </span>toplanmaktadır.<span
style='letter-spacing:-.05pt'> </span>Sağlık verileriniz<span style='letter-spacing:
-.2pt'> </span>başta olmak üzere özel<span style='letter-spacing:-.25pt'> </span>nitelikli<span
style='letter-spacing:-.2pt'> </span>kişisel verileriniz ve genel nitelikli
kişisel verileriniz ile aşağıda yer alan kişisel verileriniz, <b>DentisToday </b>tarafından
yine aşağıda belirtilen sağlık hizmeti amaçlarıyla bağlantılı, sınırlı ve
ölçülü şekilde işlenebilecektir;</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.9pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
115%'><span lang=TR style='font-size:10.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimlik
bilgileriniz</span></i></b><span lang=TR style='font-size:12.0pt;line-height:
115%'>: Adınız, soyadınız, T.C. Kimlik numaranız, pasaport numaranız veya
geçici T.C. Kimlik numaranız, doğum yeri ve tarihiniz, medeni haliniz,
cinsiyetiniz, sigorta veya hasta protokol numaranız ve sizi<span
style='letter-spacing:-.05pt'> </span>tanımlayabileceğimiz diğer kimlik
verileriniz.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.6pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
115%'><span lang=TR style='font-size:10.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span lang=TR style='font-size:12.0pt;line-height:115%'>İletişim
Bilgileriniz</span></i></b><span lang=TR style='font-size:12.0pt;line-height:
115%'>: Adresiniz, konumunuz, telefon numaranız, elektronik posta adresiniz ve
sair iletişim verileriniz, müşteri temsilcileri ya da hasta hizmetleri
tarafından çağrı merkezi standartları gereği tutulan sesli görüşme kayıtlarınız
ile elektronik posta, mektup veya sair vasıtalar aracılığı ile tarafımızla
iletişime geçtiğinizde elde edilen kişisel verileriniz.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.4pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
116%'><span lang=TR style='font-size:10.0pt;line-height:116%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span lang=TR style='font-size:12.0pt;line-height:116%'>Muhasebe/Finans
Bilgileriniz</span></i></b><span lang=TR style='font-size:12.0pt;line-height:
116%'>: Banka hesap numaranız, IBAN numaranız, kredi<span style='letter-spacing:
-.15pt'> </span>kartı bilginiz, faturalama bilgileriniz gibi finansal
verileriniz.</span></p>

<p class=MsoListParagraph style='margin-left:41.75pt;text-indent:-17.95pt;
line-height:13.45pt'><span lang=TR style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Sağlık<span
style='letter-spacing:1.0pt'> </span>hizmetlerinin<span style='letter-spacing:
.75pt'> </span>planlaması<span style='letter-spacing:.6pt'> </span>amacıyla<span
style='letter-spacing:.7pt'> </span>özel<span style='letter-spacing:.35pt'> </span>sağlık<span
style='letter-spacing:.8pt'> </span>sigortasına<span style='letter-spacing:
.95pt'> </span>ilişkin<span style='letter-spacing:1.0pt'> </span>verileriniz<span
style='letter-spacing:1.0pt'> </span><span style='letter-spacing:-.25pt'>ve</span></span></p>

<p class=MsoNormal style='margin-top:1.95pt;margin-right:0in;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:justify'><b><i><span
lang=TR style='font-size:12.0pt'>Sosyal<span style='letter-spacing:-.1pt'> </span>Güvenlik<span
style='letter-spacing:-.05pt'> </span>Kurumu<span style='letter-spacing:.1pt'> </span></span></i></b><span
lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>verileriniz.</span></p>

<p class=MsoListParagraph style='margin-top:2.0pt;margin-right:5.9pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:115%'><span lang=TR style='font-size:10.0pt;line-height:115%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:115%'>Sizlere
uygun olarak sunulacak olan hastane veya diş polikliniklerini ziyaret etmeniz
halinde güvenlik ve denetim amaçlı tutulmakta olan kamera kayıtları
görüntüleriniz.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.95pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
115%'><span lang=TR style='font-size:10.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span lang=TR style='font-size:12.0pt;line-height:115%'>Sağlık
Bilgileriniz</span></i></b><span lang=TR style='font-size:12.0pt;line-height:
115%'>: Laboratuvar sonuçlarınız, test sonuçlarınız, muayene verileriniz,
randevu bilgileriniz, röntgen sonuçlarınız, radyolojik sonuçlar, check-up
bilgileriniz, reçete<span style='letter-spacing:-.1pt'> </span>bilgileriniz<span
style='letter-spacing:-.1pt'> </span>dahil<span style='letter-spacing:-.5pt'> </span>ancak<span
style='letter-spacing:-.05pt'> </span>bunlarla<span style='letter-spacing:-.1pt'>
</span>sınırlı<span style='letter-spacing:-.25pt'> </span>olmaksızın<span
style='letter-spacing:-.3pt'> </span>ön<span style='letter-spacing:-.3pt'> </span>tanı,
kesin<span style='letter-spacing:-.3pt'> </span>tanı, ıbbî<span
style='letter-spacing:-.3pt'> </span>teşhis, tedavi ve bakım hizmetlerinin
yürütülmesi sırasında veya bunların bir sonucu olarak elde edilen her türlü
sağlık ve cinsel hayata ilişkin kişisel verileriniz.</span></p>

<p class=MsoListParagraph style='margin-top:.1pt;margin-right:5.7pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
115%'><span lang=TR style='font-size:10.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR><a href="http://www.theaident.com/"><span
style='font-size:12.0pt;line-height:115%;color:windowtext'>www.theaident.com</span></a></span><span
lang=TR style='font-size:12.0pt;line-height:115%'> sitesine veya </span><span
lang=TR><a href="mailto:aident@hs01.kep.tr"><span style='font-size:12.0pt;
line-height:115%;color:windowtext'>aident@hs01.kep.</span><span
style='font-size:12.0pt;line-height:115%;color:windowtext;text-decoration:none'>tr</span></a></span><span
lang=TR style='font-size:12.0pt;line-height:115%'> adresine gönderdiğiniz veya <b><i>DentisToday
</i></b>unvanlı mobil uygulamaya girdiğiniz sağlık verileriniz ve sair kişisel <span
style='letter-spacing:-.1pt'>verileriniz.</span></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.95pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
115%'><span lang=TR style='font-size:10.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=TR style='font-size:12.0pt;line-height:115%'>DentisToday</span></b><span
lang=TR style='font-size:12.0pt;line-height:115%'>’e iş<span style='letter-spacing:
-.3pt'> </span>başvurusunda bulunmanız<span style='letter-spacing:-.2pt'> </span>halinde<span
style='letter-spacing:-.25pt'> </span>bu<span style='letter-spacing:-.2pt'> </span>hususta<span
style='letter-spacing:-.2pt'> </span>temin<span style='letter-spacing:-.4pt'> </span>edilen<span
style='letter-spacing:-.4pt'> </span>özgeçmiş dâhil sair kişisel verileriniz
ile DentisToday çalışanı ya da ilişkili çalışan olmanız halinde hizmet akdiniz
ve işe yatkınlığınız ile ilgili her türlü kişisel verileriniz.</span></p>

</div>

<span lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection6>

<p class=MsoListParagraph style='margin-top:3.5pt;margin-right:0in;margin-bottom:
0in;margin-left:41.75pt;margin-bottom:.0001pt;text-indent:-17.95pt'><span
lang=TR style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Mobil<span
style='letter-spacing:-.85pt'> </span>uygulama<span style='letter-spacing:-.5pt'>
</span>veya<span style='letter-spacing:-.6pt'> </span>web<span
style='letter-spacing:-.7pt'> </span>sitemizi<span style='letter-spacing:-.85pt'>
</span>kullanırken<span style='letter-spacing:-.75pt'> </span>tarafınızca<span
style='letter-spacing:-.6pt'> </span>gönderilen<span style='letter-spacing:
-.55pt'> </span>yorum<span style='letter-spacing:-.7pt'> </span>ve<span
style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>yazılar.</span></span></p>

<p class=MsoListParagraph style='margin-top:2.0pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:115%'><span lang=TR style='font-size:10.0pt;line-height:115%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:115%'>Mobil<span
style='letter-spacing:-.65pt'> </span>uygulama<span style='letter-spacing:-.25pt'>
</span>veya<span style='letter-spacing:-.45pt'> </span>web<span
style='letter-spacing:-.65pt'> </span>sitemizi<span style='letter-spacing:-.75pt'>
</span>kullanırken,<span style='letter-spacing:-.1pt'> </span>hizmetlerimizden<span
style='letter-spacing:-.4pt'> </span>yararlanabilmek<span style='letter-spacing:
-.2pt'> </span>için tarafınıza veya tarafınızca veri sahibinin onayıyla
gönderilen röntgen, radyolojik belgeler ile ağız içi, diş ve ilgili fotoğraf ve
videolarınız ve bunlarla bağlantılı diğer <span style='letter-spacing:-.1pt'>veriler.</span></span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><b><span lang=TR>DentisToday </span></b><span lang=TR>tarafından elde
edilen her türlü kişisel veriniz (Özel nitelikli kişisel veriler de dahil fakat
bunlarla sınırlı olmamak kaydıyla), genel olarak kamu sağlığının korunması,
koruyucu<span style='letter-spacing:-.15pt'> </span>hekimlik,<span
style='letter-spacing:-.3pt'> </span>ön<span style='letter-spacing:-.6pt'> </span>tanı,<span
style='letter-spacing:-.3pt'> </span>kesin<span style='letter-spacing:-.4pt'> </span>tanı,<span
style='letter-spacing:-.05pt'> </span>hekim<span style='letter-spacing:-.35pt'>
</span>yönlendirmesi,<span style='letter-spacing:-.05pt'> </span>yapay<span
style='letter-spacing:-.25pt'> </span>zekâ<span style='letter-spacing:-.2pt'> </span>ve<span
style='letter-spacing:-.15pt'> </span>makine<span style='letter-spacing:-.45pt'>
</span>öğrenmesi yönetimi dahil tıbbî teşhis, tedavi ve bakım hizmetlerinin
yürütülmesi amacıyla işlenebilecek ise de aşağıdaki amaçlar ile de
işlenebilecektir:</span></p>

<p class=MsoBodyText style='margin-top:.25pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph align=left style='margin-left:41.8pt;text-align:left;
text-indent:-.25in;line-height:13.75pt'><span lang=TR style='font-size:10.0pt;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Kimliğinizi<span
style='letter-spacing:-.7pt'> </span>teyit<span style='letter-spacing:.1pt'> </span><span
style='letter-spacing:-.2pt'>etme.</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:5.3pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:100%'><span lang=TR style='font-size:10.0pt;
line-height:100%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:100%'>Kamu<span
style='letter-spacing:4.0pt'> </span>sağlığının<span style='letter-spacing:
4.0pt'> </span>korunması,<span style='letter-spacing:4.0pt'> </span>koruyucu<span
style='letter-spacing:4.0pt'> </span>hekimlik,<span style='letter-spacing:4.0pt'>
</span>tıbbî<span style='letter-spacing:4.0pt'> </span>teşhis,<span
style='letter-spacing:4.0pt'> </span>tedavi<span style='letter-spacing:4.0pt'> </span>ve<span
style='letter-spacing:4.0pt'> </span>bakım hizmetlerinin<span style='letter-spacing:
-.75pt'> </span>yürütülmesi,<span style='letter-spacing:-.5pt'> </span>sağlık<span
style='letter-spacing:-.45pt'> </span>hizmetleri<span style='letter-spacing:
-.75pt'> </span>ile<span style='letter-spacing:-.5pt'> </span>finansmanının<span
style='letter-spacing:-.7pt'> </span>planlanması<span style='letter-spacing:
-.75pt'> </span>ve<span style='letter-spacing:-.5pt'> </span>yönetimi.</span></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:6.25pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:100%'><span lang=TR style='font-size:10.0pt;
line-height:100%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:100%'>İlgili
mevzuat uyarınca Sağlık Bakanlığı ve diğer kamu kurum ve kuruluşları ile talep
edilen bilgilerin paylaşılması.</span></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:5.9pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:100%'><span lang=TR style='font-size:10.0pt;
line-height:100%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:100%'>Onayınız
ile hastane veya diş polikliniklerinin günlük operasyonların planlanması ve <span
style='letter-spacing:-.1pt'>yönetilmesi.</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:6.1pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:100%'><span lang=TR style='font-size:10.0pt;
line-height:100%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:100%'>Hastane<span
style='letter-spacing:4.0pt'> </span>Yönetimi,<span style='letter-spacing:4.0pt'>
</span>Hasta<span style='letter-spacing:4.0pt'> </span>Hakları,<span
style='letter-spacing:4.0pt'> </span>Hasta<span style='letter-spacing:4.0pt'> </span>Deneyimi<span
style='letter-spacing:4.0pt'> </span>bölümleri<span style='letter-spacing:4.0pt'>
</span>tarafından<span style='letter-spacing:4.0pt'> </span>hasta<span
style='letter-spacing:2.0pt'> </span>memnuniyetinin ölçülmesi, arttırılması ve
araştırılması.</span></p>

<p class=MsoListParagraph align=left style='margin-left:41.8pt;text-align:left;
text-indent:-.25in;line-height:13.55pt'><span lang=TR style='font-size:10.0pt;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>İlaç<span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>temini.</span></span></p>

<p class=MsoListParagraph align=left style='margin-left:41.8pt;text-align:left;
text-indent:-.25in;line-height:13.75pt'><span lang=TR style='font-size:10.0pt;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Randevu<span
style='letter-spacing:-.3pt'> </span>almanız halinde<span style='letter-spacing:
-.2pt'> </span>randevu<span style='letter-spacing:-.2pt'> </span>hakkında<span
style='letter-spacing:-.2pt'> </span>sizi<span style='letter-spacing:-.2pt'> </span>haberdar<span
style='letter-spacing:-.1pt'> edebilme.</span></span></p>

<p class=MsoListParagraph align=left style='margin-left:41.8pt;text-align:left;
text-indent:-.25in;line-height:13.75pt'><span lang=TR style='font-size:10.0pt;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Talep<span
style='letter-spacing:-.2pt'> </span>etmeniz<span style='letter-spacing:.1pt'> </span>halinde<span
style='letter-spacing:-.1pt'> </span>size<span style='letter-spacing:-.15pt'> </span>en<span
style='letter-spacing:-.3pt'> </span>uygun<span style='letter-spacing:-.35pt'> </span>hekime<span
style='letter-spacing:.15pt'> </span><span style='letter-spacing:-.1pt'>yönlendirme.</span></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.3pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
98%'><span lang=TR style='font-size:10.0pt;line-height:98%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:98%'>Tarafınızca
gönderilen<span style='letter-spacing:-.15pt'> </span>röntgen, radyolojik
belgeler ile ağız içi, diş ve ilgili<span style='letter-spacing:-.15pt'> </span>fotoğraf<span
style='letter-spacing:-.1pt'> </span>ve videolarınız ile bunlarla bağlantılı<span
style='letter-spacing:-.15pt'> </span>diğer verilerinizi yönlendirilen hekim
ile paylaşma.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.9pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
98%'><span lang=TR style='font-size:10.0pt;line-height:98%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:98%'>Yapay zeka
kullanılacak olan uygulamamız ve web sitemizden aldığınız hizmetlerin gelişmesi
amacıyla analiz olarak kullanılması.</span></p>

<p class=MsoListParagraph style='margin-top:.1pt;margin-right:6.1pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
98%'><span lang=TR style='font-size:10.0pt;line-height:98%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:98%'>Online
alışveriş hizmetimizi kullanmanız halinde istek, talep ve ihtiyaçlarınız
doğrultusunda size en iyi hizmeti verebilmek için gerekli analizlerin
planlanması.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.0pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt'><span
lang=TR style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Hasta Hizmetleri, Mali
İşler, Pazarlama bölümleri tarafından sağlık hizmetlerinizin finansmanı,
tetkik, teşhis ve tedavi giderlerinizin karşılanması, müstehaklık sorgusu
kapsamında özel sigorta şirketler ile talep edilen bilgilerin paylaşılması.</span></p>

<p class=MsoListParagraph style='margin-left:41.75pt;text-indent:-17.95pt;
line-height:13.7pt'><span lang=TR style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Yasal<span
style='letter-spacing:-.35pt'> </span>ve<span style='letter-spacing:-.15pt'> </span>düzenleyici<span
style='letter-spacing:-.3pt'> </span>gereksinimlerin<span style='letter-spacing:
-.1pt'> </span>yerine<span style='letter-spacing:-.1pt'> getirilmesi.</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:.25pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:98%'><span lang=TR style='font-size:10.0pt;
line-height:98%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:98%'>Kalite,
Hasta Deneyimi, Bilgi Sistemleri bölümleri tarafından risk yönetimi ve kalite
geliştirme aktivitelerinin yerine getirilmesi.</span></p>

<p class=MsoListParagraph align=left style='margin-top:.25pt;margin-right:5.9pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:98%'><span lang=TR style='font-size:10.0pt;
line-height:98%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:98%'>Hasta<span
style='letter-spacing:-.75pt'> </span>Hizmetleri,<span style='letter-spacing:
-.7pt'> </span>Mali<span style='letter-spacing:-.8pt'> </span>İşler,<span
style='letter-spacing:-.55pt'> </span>Pazarlama<span style='letter-spacing:
-.45pt'> </span>bölümleri<span style='letter-spacing:-.75pt'> </span>tarafından<span
style='letter-spacing:-.75pt'> </span>hizmetlerimiz<span style='letter-spacing:
-.7pt'> </span>karşılığında faturalandırma yapılması ve anlaşmalı olan
kurumlarla ilişkinizin teyit edilmesi.</span></p>

<p class=MsoListParagraph align=left style='margin-top:.2pt;margin-right:0in;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-.25in;line-height:13.75pt'><span lang=TR style='font-size:10.0pt;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=TR style='font-size:12.0pt'>DentisToday<span
style='letter-spacing:.05pt'> </span></span></b><span lang=TR style='font-size:
12.0pt'>ile<span style='letter-spacing:-.15pt'> </span>anlaşmalı<span
style='letter-spacing:-.5pt'> </span>olan<span style='letter-spacing:-.35pt'> </span>kurumlarla<span
style='letter-spacing:.05pt'> </span>ilişkinizi<span style='letter-spacing:
-.5pt'> </span>teyit<span style='letter-spacing:.15pt'> </span><span
style='letter-spacing:-.1pt'>etme.</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:5.75pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:102%'><span lang=TR style='font-size:10.0pt;
line-height:102%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:102%'>Doğabilecek<span
style='letter-spacing:4.0pt'> </span>uyuşmazlıklarda<span style='letter-spacing:
4.0pt'> </span>delil<span style='letter-spacing:4.0pt'> </span>olarak<span
style='letter-spacing:4.0pt'> </span>kullanılması<span style='letter-spacing:
4.0pt'> </span>amaçlarıyla<span style='letter-spacing:4.0pt'> </span>sınırlı<span
style='letter-spacing:4.0pt'> </span>olarak <span style='letter-spacing:-.1pt'>işlemektedir.</span></span></p>

<p class=MsoBodyText style='margin-top:13.3pt;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>İlgili mevzuat uyarınca elde edilen ve işlenen Kişisel
Verileriniz, <b>DentisToday </b>veya <b>DentisToday</b>’e<span
style='letter-spacing:-.25pt'> </span>ait fiziki<span style='letter-spacing:
-.45pt'> </span>arşivler<span style='letter-spacing:-.15pt'> </span>ve/veya
bilişim<span style='letter-spacing:-.4pt'> </span>sistemlerine nakledilerek<span
style='letter-spacing:-.15pt'> </span>hem<span style='letter-spacing:-.6pt'> </span>dijital<span
style='letter-spacing:-.45pt'> </span>ortamda hem de fiziki ortamda muhafaza
altında tutulabilecektir.</span></p>

<p class=MsoBodyText style='margin-top:1.95pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.0pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Ayrıca<span style='letter-spacing:-.15pt'> </span>yukarıda<span
style='letter-spacing:-.4pt'> </span>belirtilen<span style='letter-spacing:
-.55pt'> </span>kişisel<span style='letter-spacing:-.5pt'> </span>verileriniz,<span
style='letter-spacing:-.25pt'> </span>3359<span style='letter-spacing:-.1pt'> </span>sayılı<span
style='letter-spacing:-.5pt'> </span>Sağlık<span style='letter-spacing:-.35pt'>
</span>Hizmetleri<span style='letter-spacing:-.75pt'> </span>Temel<span
style='letter-spacing:-.35pt'> </span>Kanunu,<span style='letter-spacing:-.25pt'>
</span>663 sayılı<span style='letter-spacing:3.45pt'> </span>Sağlık<span
style='letter-spacing:3.7pt'> </span>Bakanlığı<span style='letter-spacing:3.5pt'>
</span>ve<span style='letter-spacing:3.7pt'> </span>Bağlı<span
style='letter-spacing:3.55pt'> </span>Kuruluşlarının<span style='letter-spacing:
3.95pt'> </span>Teşkilat<span style='letter-spacing:3.95pt'> </span>ve<span
style='letter-spacing:3.7pt'> </span>Görevleri<span style='letter-spacing:3.3pt'>
</span>Hakkında<span style='letter-spacing:3.9pt'> </span><span
style='letter-spacing:-.1pt'>Kanun</span></span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection7>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Hükmünde Kararname, Özel Hastaneler Yönetmeliği, Kişisel
Sağlık Verilerinin İşlenmesi ve Mahremiyetinin Korunması Yönetmeliği ve Sağlık
Bakanlığı düzenlemeleri ve sair mevzuat hükümleri çerçevesinde işlenebilecek
olup, <b>DentisToday</b>’a ve/veya onayınız halinde tavsiye edeceğimiz
hekimlerimize ait fiziki arşivler ve bilişim sistemlerine nakledilerek, hem
dijital hem de fiziki ortamda muhafaza altında tutulabilecektir.</span></p>

<p class=MsoBodyText style='margin-top:2.4pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>3.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.3pt'> </span>VERİLERİN<span
style='letter-spacing:-.25pt'> </span>İŞLENME<span style='letter-spacing:-.25pt'>
</span><span style='letter-spacing:-.1pt'>ŞARTLARI</span></span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.4pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Kanun ile kişisel verilerin işlenme koşulları<span
style='letter-spacing:-.1pt'> </span>düzenlenmiş olup, DentisToday<span
style='letter-spacing:-.2pt'> </span>tarafından<span style='letter-spacing:
-.1pt'> </span>kişisel veriler aşağıda belirtilen söz konusu koşullara uygun
olarak işlenmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Kanun'da sayılan istisnalar dışında, DentisToday ancak veri
sahiplerinin <b>açık rızasını </b>temin etmek suretiyle kişisel veri işlemektedir.
Kanun’da sayılan aşağıdaki hallerin varlığı durumunda ise, veri sahibinin <b>açık
rızası olmasa dahi </b>kişisel veriler işlenebilmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.05pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:41.8pt;text-indent:-.25in'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Kanunlarda<span
style='letter-spacing:-.3pt'> </span>açıkça<span style='letter-spacing:-.25pt'>
</span><span style='letter-spacing:-.1pt'>öngörülmesi,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:6.0pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Fiili
imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına
hukuki<span style='letter-spacing:-.55pt'> </span>geçerlilik<span
style='letter-spacing:-.1pt'> </span>tanınmayan<span style='letter-spacing:
-.35pt'> </span>kişinin<span style='letter-spacing:-.35pt'> </span>kendisinin
ya da<span style='letter-spacing:-.15pt'> </span>bir<span style='letter-spacing:
-.05pt'> </span>başkasının hayatı<span style='letter-spacing:-.55pt'> </span>veya<span
style='letter-spacing:-.15pt'> </span>beden bütünlüğünün korunması için zorunlu
olması,</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:5.7pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
111%'><span lang=TR style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:111%'>Bir
sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla
sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</span></p>

<p class=MsoListParagraph align=left style='margin-top:.45pt;margin-right:0in;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-.25in'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Veri<span
style='letter-spacing:-.65pt'> </span>sorumlusunun<span style='letter-spacing:
-.3pt'> </span>hukuki<span style='letter-spacing:-.3pt'> </span>yükümlülüğünü<span
style='letter-spacing:.1pt'> </span>yerine<span style='letter-spacing:-.1pt'> </span>getirebilmesi<span
style='letter-spacing:-.3pt'> </span>için<span style='letter-spacing:-.1pt'> </span>zorunlu<span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>olması,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-.25in'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Veri<span
style='letter-spacing:-.6pt'> </span>sahibinin<span style='letter-spacing:-.25pt'>
</span>kendisi<span style='letter-spacing:-.45pt'> </span>tarafından<span
style='letter-spacing:-.25pt'> </span>alenileştirilmiş<span style='letter-spacing:
-.15pt'> </span><span style='letter-spacing:-.1pt'>olması,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-.25in'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Bir<span style='letter-spacing:
-.2pt'> </span>hakkın<span style='letter-spacing:-.4pt'> </span>tesisi,
kullanılması<span style='letter-spacing:-.4pt'> </span>veya<span
style='letter-spacing:-.15pt'> </span>korunması<span style='letter-spacing:
-.15pt'> </span>için<span style='letter-spacing:.05pt'> </span>veri<span
style='letter-spacing:-.35pt'> </span>işlemenin<span style='letter-spacing:
-.15pt'> </span>zorunlu<span style='letter-spacing:-.1pt'> olması,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.15pt;margin-right:
5.75pt;margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:
left;text-indent:-18.05pt;line-height:111%'><span lang=TR style='font-size:
12.0pt;line-height:111%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:111%'>Veri<span
style='letter-spacing:4.0pt'> </span>sahibinin<span style='letter-spacing:4.0pt'>
</span>temel<span style='letter-spacing:4.0pt'> </span>hak      <span
style='letter-spacing:-.3pt'>ve</span>   <span style='letter-spacing:-.1pt'>özgürlüklerine</span>    <span
style='letter-spacing:-.1pt'>zarar</span>    <span style='letter-spacing:-.1pt'>vermemek</span>    <span
style='letter-spacing:-.1pt'>kaydıyla,</span>   <span style='letter-spacing:
-.2pt'>veri </span>sorumlusunun meşru menfaatleri için veri işlenmesinin
zorunlu olması.</span></p>

<p class=MsoBodyText style='margin-top:2.9pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>3.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.25pt'> </span>VERİLERİN<span
style='letter-spacing:-.2pt'> </span>KORUNMASINA<span style='letter-spacing:
-.2pt'> </span>İLİŞKİN<span style='letter-spacing:-.2pt'> </span><span
style='letter-spacing:-.1pt'>ÖNLEMLER</span></span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday,<span style='letter-spacing:-.75pt'> </span>KVK<span
style='letter-spacing:-.75pt'> </span>Kanunu’nun<span style='letter-spacing:
-.75pt'> </span>12.<span style='letter-spacing:-.75pt'> </span>maddesine<span
style='letter-spacing:-.75pt'> </span>uygun<span style='letter-spacing:-.75pt'>
</span>olarak,<span style='letter-spacing:-.75pt'> </span>işlemekte<span
style='letter-spacing:-.75pt'> </span>olduğu<span style='letter-spacing:-.75pt'>
</span>kişisel<span style='letter-spacing:-.75pt'> </span>verilerin hukuka
aykırı<span style='letter-spacing:-.05pt'> </span>olarak işlenmesini<span
style='letter-spacing:-.05pt'> </span>önlemek, verilere hukuka aykırı olarak
erişilmesini<span style='letter-spacing:-.05pt'> </span>önlemek ve verilerin
muhafazasını sağlamak için uygun güvenlik düzeyini sağlamaya yönelik gerekli
teknik ve idari tedbirleri almakta, bu kapsamda gerekli denetimleri yapmak veya
<span style='letter-spacing:-.1pt'>yaptırmaktadır.</span></span></p>

<p class=MsoBodyText style='margin-top:2.15pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Bu<span style='letter-spacing:-.75pt'> </span>kapsamda<span
style='letter-spacing:-.75pt'> </span>DentisToday<span style='letter-spacing:
-.75pt'> </span>tarafından<span style='letter-spacing:-.75pt'> </span>gerekli<span
style='letter-spacing:-.75pt'> </span>(a)<span style='letter-spacing:-.75pt'> </span>idari<span
style='letter-spacing:-.75pt'> </span>ve<span style='letter-spacing:-.7pt'> </span>(b)<span
style='letter-spacing:-.55pt'> </span>teknik<span style='letter-spacing:-.65pt'>
</span>tedbirler<span style='letter-spacing:-.55pt'> </span>alınmalı,<span
style='letter-spacing:-.55pt'> </span>(c)<span style='letter-spacing:-.55pt'> </span>Şirket
bünyesinde denetim sistemi kurulmalı ve (d) kişisel verilerin kanuni olmayan
yollarla ifşası durumunda KVK Kanunu’nda öngörülen tedbirler alınmalıdır.
DentisToday, kişisel verilerin hukuka uygun işlenmesini sağlamak için,
teknolojik imkânlar ve uygulama maliyetine göre teknik ve idari tedbirler
almaktadır.</span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection8>

<h2 style='margin-top:3.7pt;margin-right:6.1pt;margin-bottom:0in;margin-left:
62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;line-height:115%'><span
lang=TR style='letter-spacing:-.05pt'>A.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Kişisel Verilerin Hukuka Uygun İşlenmesini Sağlamak
ve Kişisel Verilere Hukuka Aykırı Erişilmesini Önlemek ve Kişisel Verilerin
Güvenli Ortamda Saklanması için Alınan Teknik Tedbirler</span></h2>

<p class=MsoBodyText style='margin-top:2.1pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:15.1pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;line-height:
113%'><span lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
verilerin korunmasına ilişkin olarak, teknolojinin imkân verdiği ölçüde teknik
önlemler alınmalı ve alınan önlemler periyodik olarak güncellenerek ve yenilenmektedir,</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Teknik<span
style='letter-spacing:-.25pt'> </span>konularda,<span style='letter-spacing:
-.1pt'> </span>uzman<span style='letter-spacing:-.35pt'> </span>personel<span
style='letter-spacing:-.15pt'> </span>istihdam<span style='letter-spacing:-.55pt'>
</span><span style='letter-spacing:-.1pt'>edilmektedir,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.9pt;margin-right:15.55pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Alınan
önlemlerin uygulanmasına yönelik düzenli aralıklarla denetim <span
style='letter-spacing:-.1pt'>yapılmaktadır,</span></span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:15.65pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:106%'><span lang=TR style='font-size:12.0pt;line-height:106%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:106%'>Güvenliği<span
style='letter-spacing:-.55pt'> </span>temin<span style='letter-spacing:-.15pt'>
</span>edecek yazılım<span style='letter-spacing:-.35pt'> </span>ve<span
style='letter-spacing:-.2pt'> </span>donanımlar<span style='letter-spacing:
-.15pt'> </span>kurulmaktadır.<span style='letter-spacing:-.1pt'> </span>Ağ<span
style='letter-spacing:-.15pt'> </span>güvenliği ve uygulama güvenliği
sağlanmaktadır.</span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:16.0pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Şirket
tarafından işlenen tüm veriler bulut sistemde saklanmaktadır. Ayrıca bulut
sistem sağlayıcı firmaların veri güvenliği ile alakalı ulusal ve uluslararası
güvenlik sertifikaları bulunmaktadır.</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:35.45pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:105%'><span lang=TR style='font-size:12.0pt;line-height:105%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:105%'>Bilgi<span
style='letter-spacing:-.3pt'> </span>teknolojileri<span style='letter-spacing:
-.3pt'> </span>sistemleri<span style='letter-spacing:-.5pt'> </span>tedarik,
geliştirme<span style='letter-spacing:-.1pt'> </span>ve<span style='letter-spacing:
-.1pt'> </span>bakımı<span style='letter-spacing:-.5pt'> </span>kapsamındaki
güvenlik önlemleri alınmaktadır.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.05pt'><span lang=TR>&nbsp;</span></p>

<h2 style='margin-top:0in;margin-right:6.05pt;margin-bottom:0in;margin-left:
62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;line-height:115%'><span
lang=TR style='letter-spacing:-.05pt'>B.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Kişisel Verilerin Hukuka Uygun İşlenmesini Sağlamak
ve Kişisel Verilere Hukuka Aykırı Erişilmesini Önlemek ve Kişisel Verilerin
Güvenli Ortamda Saklanması için Alınan İdari Tedbirler</span></h2>

<p class=MsoBodyText style='margin-top:1.8pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:15.7pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;line-height:
113%'><span lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%;
letter-spacing:-.1pt'>DentisToday</span><span lang=TR style='font-size:12.0pt;
line-height:113%;letter-spacing:-.35pt'> </span><span lang=TR style='font-size:
12.0pt;line-height:113%;letter-spacing:-.1pt'>kişisel verilerin korunması
hukukuna ilişkin olarak çalışanlarını </span><span lang=TR style='font-size:
12.0pt;line-height:113%'>bilgilendirmekte ve eğitmektedir.</span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:15.45pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:115%'>DentisToday
tarafından kişisel verilerin hukuka uygun olarak aktarıldığı kişiler<span
style='letter-spacing:-.75pt'> </span>ile<span style='letter-spacing:-.75pt'> </span>akdedilen<span
style='letter-spacing:-.75pt'> </span>sözleşmelere;<span style='letter-spacing:
-.75pt'> </span>kişisel<span style='letter-spacing:-.75pt'> </span>verilerin<span
style='letter-spacing:-.75pt'> </span>aktarıldığı<span style='letter-spacing:
-.75pt'> </span>kişilerin,<span style='letter-spacing:-.75pt'> </span>kişisel
verilerin<span style='letter-spacing:-.75pt'> </span>korunması<span
style='letter-spacing:-.75pt'> </span>amacıyla<span style='letter-spacing:-.75pt'>
</span>gerekli<span style='letter-spacing:-.75pt'> </span>güvenlik<span
style='letter-spacing:-.75pt'> </span>tedbirlerini<span style='letter-spacing:
-.75pt'> </span>alacağına<span style='letter-spacing:-.75pt'> </span>ve<span
style='letter-spacing:-.75pt'> </span>kendi kuruluşlarında bu tedbirlere
uyulmasını sağlayacağına ilişkin hükümler <span style='letter-spacing:-.1pt'>eklenmektedir.</span></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:15.4pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;line-height:
113%'><span lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>DentisToday
tarafından gerçekleştirilen süreçler detaylı olarak incelenmeli, süreç<span
style='letter-spacing:-.75pt'> </span>kapsamında<span style='letter-spacing:
-.6pt'> </span>yürütülen<span style='letter-spacing:-.75pt'> </span>kişisel<span
style='letter-spacing:-.75pt'> </span>veri<span style='letter-spacing:-.75pt'> </span>işleme<span
style='letter-spacing:-.4pt'> </span>faaliyetleri<span style='letter-spacing:
-.75pt'> </span>her<span style='letter-spacing:-.5pt'> </span>birim<span
style='letter-spacing:-.75pt'> </span>özelinde tespit edilmelidir. Bu kapsamda,
yürütülen veri işleme faaliyetlerinin KVK Kanunu'nda öngörülen kişisel veri
işleme şartlarına uygunluğunun sağlanması için atılması gereken adımlar
belirlenmelidir.</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:15.55pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>DentisToday,
şirket yapılarına göre hukuksal uyum gerekliliklerinin sağlanması için yerine
getirilmesi gereken uygulamaları tespit etmeli, bu uygulamaların denetimini ve
sürekliliğini sağlamak için gerekli idari tedbirler, Şirket içi politikalar ve
eğitimler düzenlemelidir.</span></p>

<p class=MsoListParagraph style='margin-top:.35pt;margin-right:15.8pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>DentisToday
ile çalışanlar arasındaki hukuki ilişkiyi yöneten sözleşme ve belgelere,
DentisToday’ın<span style='letter-spacing:-.1pt'> </span>talimatları<span
style='letter-spacing:-.05pt'> </span>ve kanunla getirilen istisnalar dışında,
kişisel verileri işlememe, ifşa etmeme ve kullanmama yükümlülüğü getiren</span></p>

</div>

<span lang=TR style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection9>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:15.7pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>kayıtlar konulmakta ve bu konuda çalışanların farkındalığı
yaratılmakta ve denetimler yürütülmektedir.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:15.2pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;line-height:
115%'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:115%'>Çalışanlar,
öğrendikleri kişisel verileri KVK Kanunu hükümlerine aykırı olarak<span
style='letter-spacing:-.75pt'> </span>başkasına<span style='letter-spacing:
-.75pt'> </span>açıklayamayacağı<span style='letter-spacing:-.75pt'> </span>ve<span
style='letter-spacing:-.75pt'> </span>işleme<span style='letter-spacing:-.75pt'>
</span>amacı<span style='letter-spacing:-.75pt'> </span>dışında<span
style='letter-spacing:-.75pt'> </span>kullanamayacağı ve bu yükümlülüğün
görevden ayrılmalarından sonra da devam edeceği konusunda bilgilendirilmekte ve
bu doğrultuda kendilerinden gerekli taahhütler alınmaktadır.</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:15.85pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:111%'><span lang=TR style='font-size:12.0pt;line-height:111%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:111%'>Görev
değişikliği olan ya da işten ayrılan çalışanların bu alandaki yetkileri <span
style='letter-spacing:-.1pt'>kaldırılmaktadır.</span></span></p>

<p class=MsoListParagraph style='margin-top:.5pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>İmzalanan<span
style='letter-spacing:-.45pt'> </span>sözleşmeler<span style='letter-spacing:
-.1pt'> </span>veri<span style='letter-spacing:-.5pt'> </span>güvenliği<span
style='letter-spacing:-.1pt'> </span>hükümleri<span style='letter-spacing:-.1pt'>
içermektedir.</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:16.1pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
veri içeren fiziksel ortamlara giriş çıkışlarla ilgili gerekli güvenlik
önlemleri alınmaktadır.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:15.4pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
veri içeren fiziksel ortamların dış risklere (yangın, sel vb.) karşı güvenliği
sağlanmaktadır. Kişisel veri içeren ortamların güvenliği sağlanmaktadır.
Kişisel veriler mümkün olduğunca azaltılmaktadır.</span></p>

<p class=MsoBodyText style='margin-top:2.5pt'><span lang=TR>&nbsp;</span></p>

<h2 align=left style='margin-left:41.75pt;text-align:left;text-indent:-17.95pt'><span
lang=TR style='letter-spacing:-.05pt'>C.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Kişisel<span style='letter-spacing:-.55pt'> </span>Verilerin<span
style='letter-spacing:-.2pt'> </span>Korunmasına<span style='letter-spacing:
-.2pt'> </span>İlişkin<span style='letter-spacing:.05pt'> </span>Alınan<span
style='letter-spacing:-.25pt'> </span>Tedbirlerin<span style='letter-spacing:
-.15pt'> </span>Denetim<span style='letter-spacing:-.35pt'> </span><span
style='letter-spacing:-.1pt'>Faaliyetleri</span></span></h2>

<p class=MsoBodyText style='margin-top:3.9pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:5.55pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:115%'>DentisToday, KVK Kanunu’nun
12. Maddesin uygun olarak, kişisel verilerin korunması ve güvenliğinin
sağlanması kapsamında alınan teknik ve idari tedbirlerin gerekli denetimlerini
yapmakta veya yaptırmaktadır. Bu denetim sonuçları DentisToday’nun iç işleyişi
kapsamında konu ile ilgili birime raporlanmakta ve alınan tedbirlerin
iyileştirilmesi için gerekli faaliyetler <span style='letter-spacing:-.1pt'>yürütülmektedir.</span></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:115%'>Çalışanların<span
style='letter-spacing:-.1pt'> </span>Kişisel<span style='letter-spacing:-.35pt'>
</span>Verilerin Korunması<span style='letter-spacing:-.35pt'> </span>konusundaki<span
style='letter-spacing:-.35pt'> </span>bilgi<span style='letter-spacing:-.1pt'> </span>ve
farkındalığının arttırılması amacıyla düzenli aralıklarla eğitimler
tekrarlanarak, eğitimlere paralel olarak bilgilerin ölçülmesi amacıyla teste
tabi tutulmaktadır. Test sonucu ortaya çıkan sonuçlar hakkında personel
bilgilendirilmekte sonraki eğitim döneminde eksiklikler tamamlanmaktadır.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.05pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>3.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>ÖZEL<span style='letter-spacing:-.3pt'> </span>NİTELİKLİ<span
style='letter-spacing:-.25pt'> </span>KİŞİSEL<span style='letter-spacing:-.2pt'>
</span>VERİLERİN<span style='letter-spacing:-.15pt'> </span>İŞLENME<span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>ŞARTLARI</span></span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.9pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Veri Sahibi açısından korunmasının çeşitli açılardan daha
kritik önem teşkil ettiğine inanılan Özel Nitelikli Kişisel Veriler’in
işlenmesinde, DentisToday tarafından özel hassasiyet <span style='letter-spacing:
-.1pt'>gösterilmektedir.</span></span></p>

<p class=MsoBodyText style='margin-top:1.8pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.85pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Kanun’un 6. maddesinde, hukuka aykırı olarak işlendiğinde
kişilerin mağduriyetine veya ayrımcılığa sebep olma riski taşıyan bir takım
kişisel veriler “özel nitelikli” olarak belirlenmiştir.<span style='letter-spacing:
-.75pt'> </span>Bu<span style='letter-spacing:-.75pt'> </span>veriler;<span
style='letter-spacing:-.75pt'> </span>ırk,<span style='letter-spacing:-.65pt'> </span>etnik<span
style='letter-spacing:-.7pt'> </span>köken,<span style='letter-spacing:-.6pt'> </span>siyasi<span
style='letter-spacing:-.75pt'> </span>düşünce,<span style='letter-spacing:-.4pt'>
</span>felsefi<span style='letter-spacing:-.75pt'> </span>inanç,<span
style='letter-spacing:-.6pt'> </span>din, mezhep<span style='letter-spacing:
-.45pt'> </span>veya<span style='letter-spacing:-.75pt'> </span>diğer inançlar,
kılık ve kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat,
ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve
genetik verilerdir.</span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection10>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:5.95pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Özel Nitelikli Kişisel Veriler, DentisToday tarafından,
Kanun’a uygun bir şekilde, Kurul’ca belirlenecek yeterli önlemlerin alınması
kaydıyla, aşağıdaki şartların varlığı halinde <span style='letter-spacing:-.1pt'>işlenmektedir:</span></span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Veri<span
style='letter-spacing:-.5pt'> </span>Sahibi’nin<span style='letter-spacing:
-.3pt'> </span>açık<span style='letter-spacing:-.05pt'> </span>rızası<span
style='letter-spacing:-.1pt'> </span>var<span style='letter-spacing:.25pt'> </span>ise<span
style='letter-spacing:-.1pt'> </span><span style='letter-spacing:-.2pt'>veya</span></span></p>

<p class=MsoListParagraph style='margin-top:1.85pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Veri<span
style='letter-spacing:-.5pt'> </span>Sahibi’nin<span style='letter-spacing:
-.3pt'> </span>açık<span style='letter-spacing:-.05pt'> </span>rızası<span
style='letter-spacing:-.05pt'> </span>yok<span style='letter-spacing:.1pt'> </span>ise;
<i>a) İlgili<span style='letter-spacing:-.05pt'> </span>kişinin<span
style='letter-spacing:-.3pt'> </span>açık<span style='letter-spacing:-.1pt'> </span>rızasının<span
style='letter-spacing:.1pt'> </span><span style='letter-spacing:-.1pt'>olması,</span></i></span></p>

<p class=MsoNormal style='margin-top:2.0pt;margin-right:29.35pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><i><span lang=TR style='font-size:12.0pt;line-height:115%'>b)<span
style='letter-spacing:-.2pt'> </span>Kanunlarda<span style='letter-spacing:
-.25pt'> </span>açıkça<span style='letter-spacing:-.25pt'> </span>öngörülmesi,<span
style='letter-spacing:-.15pt'> </span>c)<span style='letter-spacing:-.2pt'> </span>Fiili<span
style='letter-spacing:-.45pt'> </span>imkânsızlık<span style='letter-spacing:
-.3pt'> </span>nedeniyle<span style='letter-spacing:-.3pt'> </span>rızasını
açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik</span></i></p>

<p class=MsoNormal style='margin-left:83.85pt;text-align:justify;line-height:
13.75pt'><i><span lang=TR style='font-size:12.0pt'>tanınmayan<span
style='letter-spacing:-.2pt'> </span>kişinin,<span style='letter-spacing:.05pt'>
</span>kendisinin<span style='letter-spacing:-.1pt'> </span>ya<span
style='letter-spacing:-.05pt'> </span>da<span style='letter-spacing:-.3pt'> </span>bir<span
style='letter-spacing:-.15pt'> </span>başkasının<span style='letter-spacing:
-.1pt'> </span>hayatı<span style='letter-spacing:-.05pt'> </span>veya<span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>beden</span></span></i></p>

<p class=MsoNormal style='margin-top:2.1pt;margin-right:15.25pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><i><span lang=TR style='font-size:12.0pt;line-height:115%'>bütünlüğünün
korunması için zorunlu olması, ç) İlgili kişinin alenileştirdiği kişisel<span
style='letter-spacing:-.15pt'> </span>verilere<span style='letter-spacing:-.2pt'>
</span>ilişkin<span style='letter-spacing:-.15pt'> </span>ve<span
style='letter-spacing:-.2pt'> </span>alenileştirme<span style='letter-spacing:
-.25pt'> </span>iradesine<span style='letter-spacing:-.2pt'> </span>uygun<span
style='letter-spacing:-.15pt'> </span>olması,<span style='letter-spacing:-.1pt'>
</span>d)<span style='letter-spacing:-.3pt'> </span>Bir<span style='letter-spacing:
-.25pt'> </span>hakkın tesisi, kullanılması veya korunması için zorunlu olması,
e) Sır saklama</span></i></p>

<p class=MsoNormal style='margin-left:83.85pt;line-height:115%'><i><span
lang=TR style='font-size:12.0pt;line-height:115%'>yükümlülüğü<span
style='letter-spacing:-.15pt'> </span>altında<span style='letter-spacing:-.15pt'>
</span>bulunan<span style='letter-spacing:-.4pt'> </span>kişiler<span
style='letter-spacing:-.3pt'> </span>veya<span style='letter-spacing:-.15pt'> </span>yetkili<span
style='letter-spacing:-.15pt'> </span>kurum<span style='letter-spacing:-.2pt'> </span>ve<span
style='letter-spacing:-.2pt'> </span>kuruluşlarca,<span style='letter-spacing:
-.05pt'> </span>kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis,
tedavi ve bakım</span></i></p>

<p class=MsoNormal style='margin-top:0in;margin-right:8.65pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><i><span
lang=TR style='font-size:12.0pt;line-height:115%'>hizmetlerinin<span
style='letter-spacing:-.25pt'> </span>yürütülmesi<span style='letter-spacing:
-.25pt'> </span>ile<span style='letter-spacing:-.3pt'> </span>sağlık<span
style='letter-spacing:-.3pt'> </span>hizmetlerinin<span style='letter-spacing:
-.25pt'> </span>planlanması,<span style='letter-spacing:-.15pt'> </span>yönetimi<span
style='letter-spacing:-.25pt'> </span>ve finansmanı amacıyla gerekli olması, f)
İstihdam, iş sağlığı ve güvenliği, sosyal güvenlik, sosyal hizmetler ve sosyal
yardım alanlarındaki hukuki</span></i></p>

<p class=MsoNormal style='margin-top:0in;margin-right:13.3pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><i><span lang=TR style='font-size:12.0pt;line-height:115%'>yükümlülüklerin<span
style='letter-spacing:-.2pt'> </span>yerine<span style='letter-spacing:-.25pt'>
</span>getirilmesi<span style='letter-spacing:-.2pt'> </span>için<span
style='letter-spacing:-.2pt'> </span>zorunlu<span style='letter-spacing:-.2pt'>
</span>olması,<span style='letter-spacing:-.1pt'> </span>g)<span
style='letter-spacing:-.15pt'> </span>Siyasi,<span style='letter-spacing:-.3pt'>
</span>felsefi,<span style='letter-spacing:-.3pt'> </span>dini veya sendikal
amaçlarla kurulan vakıf, dernek ve diğer kâr amacı gütmeyen kuruluş ya da
oluşumların, tâbi oldukları mevzuata ve amaçlarına uygun</span></i></p>

<p class=MsoNormal style='margin-left:83.85pt;line-height:115%'><i><span
lang=TR style='font-size:12.0pt;line-height:115%'>olmak, faaliyet alanlarıyla
sınırlı olmak ve üçüncü kişilere açıklanmamak kaydıyla;<span style='letter-spacing:
-.15pt'> </span>mevcut<span style='letter-spacing:-.2pt'> </span>veya<span
style='letter-spacing:-.2pt'> </span>eski<span style='letter-spacing:-.2pt'> </span>üyelerine<span
style='letter-spacing:-.25pt'> </span>ve<span style='letter-spacing:-.25pt'> </span>mensuplarına<span
style='letter-spacing:-.2pt'> </span>veyahut<span style='letter-spacing:-.2pt'>
</span>bu<span style='letter-spacing:-.2pt'> </span>kuruluş<span
style='letter-spacing:-.25pt'> </span>ve oluşumlarla düzenli olarak temasta
olan kişilere yönelik olması halinde </span></i><span lang=TR style='font-size:
12.0pt;line-height:115%;letter-spacing:-.1pt'>işlenebilir.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:11.75pt'><span lang=TR>&nbsp;</span></p>

<h1 style='margin-right:5.8pt;line-height:115%'><span lang=TR>3.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='letter-spacing:-.2pt'>ÖZEL</span><span lang=TR>     <span
style='letter-spacing:-.1pt'>NİTELİKLİ</span>    <span style='letter-spacing:
-.1pt'>KİŞİSEL</span>     <span style='letter-spacing:-.1pt'>VERİLERİN</span>    <span
style='letter-spacing:-.1pt'>KORUNMASINA</span>     <span style='letter-spacing:
-.1pt'>İLİŞKİN ÖNLEMLER</span></span></h1>

<p class=MsoBodyText style='margin-top:1.75pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.3pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday,<span style='letter-spacing:-.75pt'> </span>Kanun’un<span
style='letter-spacing:-.75pt'> </span>6.<span style='letter-spacing:-.7pt'> </span>Maddesinde<span
style='letter-spacing:-.5pt'> </span>yer<span style='letter-spacing:-.6pt'> </span>alan,<span
style='letter-spacing:-.6pt'> </span>Özel<span style='letter-spacing:-.75pt'> </span>Nitelikli<span
style='letter-spacing:-.75pt'> </span>Kişisel<span style='letter-spacing:-.75pt'>
</span>Veriler’in<span style='letter-spacing:-.45pt'> </span>işlenmesinde,
Kurul’un 31.01.2018 Tarihli ve 2018/10 Numaralı kararı uyarınca, veri sorumlusu
sıfatıyla, aşağıda belirtilen önlemleri almaktadır:</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph align=left style='margin-top:.05pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:62.45pt;margin-bottom:.0001pt;text-align:left;
text-indent:-28.35pt;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%;letter-spacing:-.05pt'>A.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR style='font-size:12.0pt;line-height:115%'>Özel<span
style='letter-spacing:-.4pt'> </span>nitelikli<span style='letter-spacing:-.4pt'>
</span>kişisel<span style='letter-spacing:-.4pt'> </span>verilerin<span
style='letter-spacing:-.4pt'> </span>güvenliğine yönelik sistemli,<span
style='letter-spacing:-.1pt'> </span>kuralları<span style='letter-spacing:-.4pt'>
</span>net bir<span style='letter-spacing:-.15pt'> </span>şekilde belli,
yönetilebilir ve sürdürülebilir ayrı bir politika belirlenmiştir.</span></p>

<p class=MsoBodyText style='margin-top:1.95pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph align=left style='margin-top:.05pt;margin-right:0in;
margin-bottom:0in;margin-left:62.45pt;margin-bottom:.0001pt;text-align:left;
text-indent:-28.3pt'><b><span lang=TR style='font-size:12.0pt;letter-spacing:
-.05pt'>B.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR style='font-size:12.0pt'>Özel<span
style='letter-spacing:-.5pt'> </span>nitelikli<span style='letter-spacing:-.35pt'>
</span>kişisel<span style='letter-spacing:-.4pt'> </span>verilerin<span
style='letter-spacing:-.15pt'> </span>işlenmesi<span style='letter-spacing:
-.35pt'> </span>süreçlerinde<span style='letter-spacing:.05pt'> </span>yer<span
style='letter-spacing:-.1pt'> </span>alan<span style='letter-spacing:-.35pt'> </span>Çalışan’lara<span
style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.1pt'>yönelik,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.4pt;line-height:113%'><span lang=TR style='font-size:12.0pt;
line-height:113%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kanun ve
buna bağlı yönetmelikler<span style='letter-spacing:1.65pt'> </span>ile Özel
Nitelikli Kişisel Veri güvenliği konularında düzenli olarak eğitimler
verilmektedir,</span></p>

<p class=MsoListParagraph align=left style='margin-top:.15pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Gizlilik<span
style='letter-spacing:-.4pt'> </span>sözleşmelerinin<span style='letter-spacing:
-.4pt'> </span><span style='letter-spacing:-.1pt'>yapılmaktadır,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.15pt;margin-right:
6.2pt;margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:
left;text-indent:-21.4pt;line-height:111%'><span lang=TR style='font-size:12.0pt;
line-height:111%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:111%'>Verilere
erişim yetkisine sahip kullanıcıların, yetki kapsamları ve süreleri net olarak
tanımlanmaktadır,</span></p>

<p class=MsoListParagraph align=left style='margin-top:.5pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Periyodik<span
style='letter-spacing:-.15pt'> </span>olarak<span style='letter-spacing:.05pt'>
</span>yetki<span style='letter-spacing:-.55pt'> </span>kontrolleri<span
style='letter-spacing:-.5pt'> </span><span style='letter-spacing:-.1pt'>gerçekleştirilmektedir,</span></span></p>

</div>

<span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection11>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:5.4pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Görev
değişikliği olan ya da işten ayrılan Çalışanlar’ın bu alandaki yetkileri derhal
kaldırılmaktadır. Bu kapsamda, Veri Sorumlusu tarafından kendisine tahsis
edilen envanteri iade almaktadır.</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.8pt;margin-bottom:
0in;margin-left:62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;line-height:
115%'><b><span lang=TR style='font-size:12.0pt;line-height:115%;letter-spacing:
-.05pt'>C.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR style='font-size:12.0pt;line-height:115%'>Özel
Nitelikli Kişisel Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği
ortamlar, elektronik ortam ise,</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:5.7pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;line-height:
111%'><span lang=TR style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:111%'>Kişisel
Veriler üzerinde gerçekleştirilen tüm hareketlerin işlem kayıtları güvenli
olarak loglanmaktadır,</span></p>

<p class=MsoListParagraph style='margin-top:.5pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel<span
style='letter-spacing:-.15pt'> </span>Veriler’in bulunduğu ortamlara ait
güvenlik güncellemelerinin sürekli takip edilmekte, gerekli güvenlik testleri
düzenli olarak yapılmakta/yaptırılmakta, test sonuçları kayıt altına
alınmaktadır,</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.8pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
Veriler’e bir yazılım aracılığı ile erişiliyorsa bu yazılıma ait kullanıcı
yetkilendirmeleri yapılmakta, bu yazılımların güvenlik testleri düzenli olarak
yapılmakta/yaptırılmakta, test sonuçları kayıt altına alınmaktadır,</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
Veriler’e uzaktan erişim gerekiyorsa en az iki kademeli kimlik doğrulama
sisteminin sağlanmaktadır.</span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.8pt;margin-bottom:
0in;margin-left:62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;line-height:
115%'><b><span lang=TR style='font-size:12.0pt;line-height:115%;letter-spacing:
-.05pt'>D.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR style='font-size:12.0pt;line-height:115%'>Özel
Nitelikli Kişisel Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği
ortamlar, fiziksel ortam ise;</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:5.8pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Özel
Nitelikli Kişisel Veriler’in bulunduğu ortamın niteliğine göre yeterli güvenlik<span
style='letter-spacing:-.5pt'> </span>önlemleri<span style='letter-spacing:-.7pt'>
</span>(elektrik<span style='letter-spacing:-.5pt'> </span>kaçağı,<span
style='letter-spacing:-.2pt'> </span>yangın,<span style='letter-spacing:-.4pt'>
</span>su<span style='letter-spacing:-.5pt'> </span>baskını,<span
style='letter-spacing:-.2pt'> </span>hırsızlık<span style='letter-spacing:-.5pt'>
</span>vb.<span style='letter-spacing:-.4pt'> </span>durumlara karşı)
alınmaktadır,</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:6.3pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Bu
ortamların fiziksel güvenliğinin sağlanarak yetkisiz giriş çıkışlar <span
style='letter-spacing:-.1pt'>engellenmektedir.</span></span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:62.45pt;text-indent:-28.3pt'><b><span
lang=TR style='font-size:12.0pt;letter-spacing:-.05pt'>E.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR style='font-size:12.0pt'>Özel<span
style='letter-spacing:-.45pt'> </span>Nitelikli Kişisel<span style='letter-spacing:
-.45pt'> </span>Veriler<span style='letter-spacing:.1pt'> </span><span
style='letter-spacing:-.1pt'>aktarılacaksa</span></span></p>

<p class=MsoListParagraph style='margin-top:2.15pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
Verilerin e-posta yoluyla aktarılması gerekiyorsa şifreli olarak kurumsal
e-posta adresiyle veya Kayıtlı Elektronik Posta (KEP) hesabı kullanılarak
aktarılmaktadır,</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:5.95pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Taşınabilir
Bellek, CD, DVD gibi ortamlar yoluyla aktarılması gerekiyorsa kriptografik
yöntemlerle şifrelenmekte ve kriptografik anahtar farklı ortamda <span
style='letter-spacing:-.1pt'>tutulmaktadır,</span></span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.8pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel<span
style='letter-spacing:-.55pt'> </span>Veriler’in<span style='letter-spacing:
-.35pt'> </span>kağıt<span style='letter-spacing:-.15pt'> </span>ortamı<span
style='letter-spacing:-.35pt'> </span>yoluyla<span style='letter-spacing:-.4pt'>
</span>aktarımı<span style='letter-spacing:-.55pt'> </span>gerekiyorsa<span
style='letter-spacing:-.4pt'> </span>evrakın<span style='letter-spacing:-.35pt'>
</span>çalınması, kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi
risklere karşı gerekli önlemler alınmakta ve evrak “Gizli” formatta
gönderilmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:5.55pt;
margin-bottom:0in;margin-left:62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;
line-height:115%'><b><span lang=TR style='font-size:12.0pt;line-height:115%;
letter-spacing:-.05pt'>F.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR style='font-size:12.0pt;line-height:115%'>Yukarıda
belirtilen önlemlerin yanı sıra Kişisel Verileri Koruma Kurumunun internet
sitesinde yayımlanan Kişisel Veri Güvenliği Rehberinde belirtilen uygun
güvenlik düzeyini temin etmeye yönelik teknik ve idari tedbirler de dikkate <span
style='letter-spacing:-.1pt'>alınmalıdır.</span></span></p>

</div>

<span lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection12>

<h1 style='margin-top:3.7pt'><span lang=TR>3.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>GÖRÜNTÜ<span style='letter-spacing:-.3pt'> </span>KAYITLARININ<span
style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>İŞLENMESİ</span></span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday,<span style='letter-spacing:-.2pt'> </span>faaliyet<span
style='letter-spacing:-.2pt'> </span>konusu<span style='letter-spacing:-.45pt'>
</span>olan<span style='letter-spacing:-.7pt'> </span>ağız<span
style='letter-spacing:-.25pt'> </span>içi<span style='letter-spacing:-.65pt'> </span>fotoğrafların,<span
style='letter-spacing:-.15pt'> </span>yapay<span style='letter-spacing:-.75pt'>
</span>zeka<span style='letter-spacing:-.5pt'> </span>teknolojisi<span
style='letter-spacing:-.65pt'> </span>ile<span style='letter-spacing:-.5pt'> </span>gerekirse<span
style='letter-spacing:-.5pt'> </span>ön tanı<span style='letter-spacing:-.75pt'>
</span>ve<span style='letter-spacing:-.4pt'> </span>uygulamaya<span
style='letter-spacing:-.4pt'> </span>göre<span style='letter-spacing:-.4pt'> </span>en<span
style='letter-spacing:-.35pt'> </span>yakın<span style='letter-spacing:-.55pt'>
</span>tercih<span style='letter-spacing:-.55pt'> </span>ettiğiniz<span
style='letter-spacing:-.15pt'> </span>hekim<span style='letter-spacing:-.5pt'> </span>veya<span
style='letter-spacing:-.4pt'> </span>kliğine<span style='letter-spacing:-.15pt'>
</span>yönlendirmek<span style='letter-spacing:-.1pt'> </span>için<span
style='letter-spacing:-.55pt'> </span>gerekli bilgilendirmelerin sağlanması
amacıyla ve bunların yanında güvenliğin sağlanması amacıyla, tüm bina ve
tesislerinde, birim ve şubelerinde, güvenliğin sağlanması amacıyla, güvenlik
kamerası ile giriş ve çıkışların takibi amacıyla, Kanun ve sair mevzuat
hükümlerine ve Politika’da yer alan ilkelere uygun şekilde kişisel verileri
saklamakta ve gerektiğinde <span style='letter-spacing:-.1pt'>işlemektedir.</span></span></p>

<p class=MsoBodyText style='margin-top:2.15pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:5.8pt;margin-bottom:0in;
margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%'>Yapay Zeka kullanımına dair
şirket politikamız KVKK tarafından yayımlanan “<i>Yapay Zekâ Alanında Kişisel
Verilerin Korunmasına Dair Tavsiyeler” </i>rehberine uygun olarak <span
style='letter-spacing:-.1pt'>hazırlanmıştır.</span></span></p>

<p class=MsoBodyText style='margin-top:1.8pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.6pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Bu amaçla hizmetlerimizin sunulması ve geliştirme
süreçlerimizde aşağıda yer alan kurul tavsiyelerine uygun olarak hareket
etmekteyiz:</span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.85pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Tasarımda, ulusal ve
uluslararası düzenleme ve/veya belgelerle tutarlı olarak kişisel veri
mahremiyetini esas alan bir yaklaşım gözetilmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.3pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Temel hak ve özgürlükler
üzerindeki muhtemel olumsuz sonuçlar gözetilerek, uygun risk önleme ve azaltma
tedbirlerine dayalı ihtiyatlı bir yaklaşım benimsenmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.25pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Veri<span style='letter-spacing:
-.5pt'> </span>toplama<span style='letter-spacing:-.15pt'> </span>da<span
style='letter-spacing:-.15pt'> </span>dâhil<span style='letter-spacing:-.5pt'> </span>olmak<span
style='letter-spacing:-.1pt'> </span>üzere<span style='letter-spacing:-.15pt'> </span>veri<span
style='letter-spacing:-.35pt'> </span>işlemenin<span style='letter-spacing:
-.1pt'> </span>her<span style='letter-spacing:-.05pt'> </span>aşamasında,<span
style='letter-spacing:-.25pt'> </span>temel<span style='letter-spacing:-.35pt'>
</span>hak<span style='letter-spacing:-.1pt'> </span>ve<span style='letter-spacing:
-.15pt'> </span>özgürlükler gözetilerek, ilgili<span style='letter-spacing:
-.4pt'> </span>kişiler üzerinde meydana gelebilecek ayrımcılık riski veya diğer
olumsuz etkiler ve önyargılar önlenmektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.3pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.55pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kullanılan kişisel verilerin
kalitesi, niteliği, kaynağı, miktarı, kategori ve içeriği değerlendirilerek
asgari veri kullanımına gidilmeli; geliştirilen modelin doğruluğu sürekli <span
style='letter-spacing:-.1pt'>izlenmektedir.</span></span></p>

<p class=MsoBodyText style='margin-top:2.35pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.25pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Bağlamından<span
style='letter-spacing:-.25pt'> </span>koparılmış<span style='letter-spacing:
-.15pt'> </span>algoritma modelleri, bireyler ve<span style='letter-spacing:
-.05pt'> </span>toplum<span style='letter-spacing:-.45pt'> </span>üzerinde<span
style='letter-spacing:-.05pt'> </span>olumsuz<span style='letter-spacing:-.05pt'>
</span>etkilere sebep olma riski açısından dikkatle değerlendirilmektedir.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.95pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>İnsan hakları temelli, etik
ve sosyal yönelimli yapay zekâ uygulamalarının tasarlanmasına ve potansiyel
önyargıların tespit edilmesine katkıda bulunabilecek akademik kurumlarla
irtibata geçilmeli; şeffaflık ve paydaş katılımının zor olabileceği alanlarda tarafsız
uzman kişi ve kuruluşların görüşü alınmaktadır.</span></p>

<p class=MsoBodyText style='margin-top:2.4pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.15pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Bireylere, görüşlerini<span
style='letter-spacing:-.1pt'> </span>ve<span style='letter-spacing:-.15pt'> </span>kişisel<span
style='letter-spacing:-.35pt'> </span>gelişimlerini<span style='letter-spacing:
-.35pt'> </span>etkileyen<span style='letter-spacing:-.35pt'> </span>teknolojilere<span
style='letter-spacing:-.15pt'> </span>dayalı<span style='letter-spacing:-.05pt'>
</span>işlemelerle ilgili itiraz hakkı tanınmaktadır.</span></p>

</div>

<span lang=TR style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection13>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:5.85pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Yapay zekâ sistemlerinin
kişisel verileri analiz etme ve kullanma gücü göz önüne alındığında, kişisel
verilerin işlenmesinde, ilgili kişilerin ulusal ve uluslararası mevzuattan
doğan hakları korunmaktadır.</span></p>

<p class=MsoBodyText style='margin-top:1.35pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.2pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Uygulamalardan<span
style='letter-spacing:-.75pt'> </span>özellikle<span style='letter-spacing:
-.75pt'> </span>etkilenmesi<span style='letter-spacing:-.75pt'> </span>muhtemel<span
style='letter-spacing:-.75pt'> </span>olan<span style='letter-spacing:-.75pt'> </span>bireylerin<span
style='letter-spacing:-.75pt'> </span>ve<span style='letter-spacing:-.75pt'> </span>grupların<span
style='letter-spacing:-.75pt'> </span>aktif<span style='letter-spacing:-.75pt'>
</span>katılımına dayalı risk değerlendirmesi teşvik edilmektedir.</span></p>

<p class=MsoBodyText style='margin-top:1.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:6.1pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Bireylerin<span
style='letter-spacing:-.45pt'> </span>münhasıran<span style='letter-spacing:
-.65pt'> </span>kendi<span style='letter-spacing:-.6pt'> </span>görüşleri<span
style='letter-spacing:-.75pt'> </span>dikkate<span style='letter-spacing:-.45pt'>
</span>alınmaksızın<span style='letter-spacing:-.65pt'> </span>otomatik<span
style='letter-spacing:-.15pt'> </span>işlemeye<span style='letter-spacing:-.45pt'>
</span>dayalı<span style='letter-spacing:-.75pt'> </span>olarak kendilerini
etkileyecek bir karara maruz kalmamalarını sağlayacak ürün ve hizmetler <span
style='letter-spacing:-.1pt'>tasarlanmaktadır.</span></span></p>

<p class=MsoBodyText style='margin-top:1.35pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.5pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Üretimde kişilik haklarına
daha az müdahale eden alternatifler de sunulmalı, kullanıcıların seçim yapma
özgürlüğü güvence altına alınmaktadır.</span></p>

<p class=MsoBodyText style='margin-top:1.25pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.25pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Ürün ve hizmetlerin
tasarımından başlayarak yaşam döngüsü boyunca kişisel verilerin korunması
hukukuna uygunluk açısından tüm<span style='letter-spacing:-.15pt'> </span>paydaşlar
için hesap verebilirliği sağlayacak algoritmalar benimsenmektedir.</span></p>

<p class=MsoBodyText style='margin-top:1.35pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.3pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kullanıcının veri işleme
faaliyetini durdurabilme hakkı tanınmalı ve kullanıcılara ait verilerin
silinmesi, yok edilmesi veya anonim hale getirilmesi imkânı tasarlanmaktadır.</span></p>

<p class=MsoBodyText style='margin-top:1.25pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.4pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Uygulama ile etkileşime giren
kişiler, kişisel veri işleme faaliyetinin gerekçeleri, kişisel verilerin
işlenmesinde kullanılan yöntemlerin detayları ile muhtemel sonuçları hakkında
aydınlatılmalı<span style='letter-spacing:-.8pt'> </span>ve<span
style='letter-spacing:-.75pt'> </span>gerekli<span style='letter-spacing:-.8pt'>
</span>haller<span style='letter-spacing:-.75pt'> </span>için<span
style='letter-spacing:-.85pt'> </span>etkili<span style='letter-spacing:-.8pt'>
</span>bir<span style='letter-spacing:-.75pt'> </span>veri<span
style='letter-spacing:-.85pt'> </span>işleme<span style='letter-spacing:-.75pt'>
</span>onay<span style='letter-spacing:-.75pt'> </span>mekanizması<span
style='letter-spacing:-.85pt'> </span>tasarlanmaktadır.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:3.55pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>3.8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.35pt'> </span>VERİLERİN<span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>AKTARILMASI</span></span></h1>

<p class=MsoBodyText style='margin-top:3.8pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.5pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday, hukuka uygun olarak elde etmiş olduğu Kişisel
Verileri, veri işleme amaçları doğrultusunda, gerekli<span style='letter-spacing:
-.55pt'> </span>güvenlik<span style='letter-spacing:-.1pt'> </span>önlemlerini<span
style='letter-spacing:-.35pt'> </span>alarak, Veri<span style='letter-spacing:
-.55pt'> </span>Sahibi’nin<span style='letter-spacing:-.1pt'> </span>Kişisel<span
style='letter-spacing:-.55pt'> </span>Verileri’ni<span style='letter-spacing:
-.1pt'> </span>ve/veya Özel Nitelikli Kişisel Verileri’ni sınırlı olarak üçüncü
kişilere aktarabilmektedir. Bu doğrultuda, DentisToday, Kişisel verileri, işbu
Politika’da belirtilen işleme şartlarından ve aşağıda belirtilen şartlardan
birinin varlığı halinde üçüncü kişilere aktarabilecektir.</span></p>

<p class=MsoBodyText style='margin-top:2.25pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:83.75pt;text-indent:-21.3pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Veri<span
style='letter-spacing:-.45pt'> </span>Sahibi’nin<span style='letter-spacing:
-.25pt'> </span>açık<span style='letter-spacing:-.05pt'> </span>rızası var<span
style='letter-spacing:.3pt'> </span><span style='letter-spacing:-.2pt'>ise,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Kanunlarda<span
style='letter-spacing:-.15pt'> </span>Kişisel<span style='letter-spacing:-.65pt'>
</span>Veri’nin<span style='letter-spacing:-.05pt'> </span>aktarılacağına<span
style='letter-spacing:-.05pt'> </span>ilişkin<span style='letter-spacing:-.25pt'>
</span>açık bir<span style='letter-spacing:-.2pt'> </span>düzenleme<span
style='letter-spacing:-.05pt'> </span>var<span style='letter-spacing:.1pt'> </span><span
style='letter-spacing:-.2pt'>ise,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.9pt;margin-right:5.95pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Veri
Sahibi’nin veya başkasının hayatı veya beden bütünlüğünün korunması için
zorunlu ise ve Veri Sahibi fiili imkânsızlık nedeniyle rızasını açıklayamayacak
durumda ise veya rızasına hukuki geçerlilik tanınmıyorsa;</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Bir
sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olmak kaydıyla
sözleşmenin taraflarına ait kişisel verinin aktarılması gerekli ise,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-21.4pt;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>DentisToday’nun
hukuki yükümlülüğünü yerine getirmesi için kişisel veri aktarımı zorunlu ise,</span></p>

</div>

<span lang=TR style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection14>

<p class=MsoListParagraph align=left style='margin-top:.05in;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Kişisel<span
style='letter-spacing:-.45pt'> </span>Veriler,<span style='letter-spacing:.1pt'>
</span>Veri<span style='letter-spacing:-.4pt'> </span>Sahibi<span
style='letter-spacing:-.25pt'> </span>tarafından<span style='letter-spacing:
-.25pt'> </span>alenileştirilmiş<span style='letter-spacing:.15pt'> </span><span
style='letter-spacing:-.2pt'>ise,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:6.0pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.4pt;line-height:113%'><span lang=TR style='font-size:12.0pt;
line-height:113%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel<span
style='letter-spacing:2.0pt'> </span>Veri<span style='letter-spacing:2.0pt'> </span>aktarımı<span
style='letter-spacing:2.0pt'> </span>bir<span style='letter-spacing:2.0pt'> </span>hakkın<span
style='letter-spacing:2.0pt'> </span>tesisi,<span style='letter-spacing:2.0pt'>
</span>kullanılması<span style='letter-spacing:2.0pt'> </span>veya<span
style='letter-spacing:2.0pt'> </span>korunması<span style='letter-spacing:2.0pt'>
</span>için zorunlu ise,</span></p>

<p class=MsoListParagraph align=left style='margin-top:.15pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.4pt;line-height:111%'><span lang=TR style='font-size:12.0pt;
line-height:111%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:111%'>Veri<span
style='letter-spacing:3.85pt'> </span>Sahibi’nin<span style='letter-spacing:
4.0pt'> </span>temel<span style='letter-spacing:4.0pt'> </span>hak<span
style='letter-spacing:4.0pt'> </span>ve<span style='letter-spacing:4.0pt'> </span>özgürlüklerine<span
style='letter-spacing:4.0pt'> </span>zarar<span style='letter-spacing:4.0pt'> </span>vermemek<span
style='letter-spacing:4.0pt'> </span>kaydıyla, DentisToday’nun meşru
menfaatleri için kişisel veri aktarımı zorunlu ise.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.95pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>3.9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.45pt'> </span>VERİLERİN<span
style='letter-spacing:-.3pt'> </span>YURTDIŞINA<span style='letter-spacing:
-.3pt'> </span><span style='letter-spacing:-.1pt'>AKTARILMASI</span></span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-left:5.8pt;text-align:justify'><span
lang=TR>DentisToday,<span style='letter-spacing:-.05pt'> </span>kural<span
style='letter-spacing:-.5pt'> </span>olarak<span style='letter-spacing:-.05pt'>
</span>Kişisel<span style='letter-spacing:-.45pt'> </span>Verileri<span
style='letter-spacing:-.3pt'> </span>yurtdışına<span style='letter-spacing:
-.05pt'> </span><span style='letter-spacing:-.1pt'>aktarmamaktadır.</span></span></p>

<p class=MsoBodyText style='margin-top:2.05pt;margin-right:5.3pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Ancak<span style='letter-spacing:-.35pt'> </span>aşağıdaki<span
style='letter-spacing:-.75pt'> </span>durumların<span style='letter-spacing:
-.1pt'> </span>varlığı<span style='letter-spacing:-.5pt'> </span>halinde<span
style='letter-spacing:-.4pt'> </span>gerekli<span style='letter-spacing:-.5pt'>
</span>tedbir<span style='letter-spacing:-.25pt'> </span>ve<span
style='letter-spacing:-.4pt'> </span>önlemleri<span style='letter-spacing:-.75pt'>
</span>alarak, mevzuata<span style='letter-spacing:-.4pt'> </span>uygun olmak
şartıyla aktarım yapabileceğini beyan etmektedir. Meşru ve hukuka uygun Kişisel
Veri işleme amaçları doğrultusunda Veri Sahibi’nin kişisel verilerini, açık
rızası var ise veya Veri Sahibi’nin açık rızası yok ise, aktarımın yapılacağı
ülke, ülke içerisindeki sektörler veya uluslararası kuruluşlar hakkında
yeterlilik kararı bulunması halinde aktarım yapılabilecektir.</span></p>

<p class=MsoBodyText style='margin-top:2.15pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.1pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday’a<span style='letter-spacing:-.75pt'> </span>ait<span
style='letter-spacing:-.2pt'> </span>mobil<span style='letter-spacing:-.75pt'> </span>uygulama<span
style='letter-spacing:-.45pt'> </span>ve<span style='letter-spacing:-.7pt'> </span>website<span
style='letter-spacing:-.7pt'> </span>üzerinden<span style='letter-spacing:-.75pt'>
</span>toplanan<span style='letter-spacing:-.75pt'> </span>tüm<span
style='letter-spacing:-.75pt'> </span>veriler<span style='letter-spacing:-.3pt'>
</span>yurtiçinde<span style='letter-spacing:-.7pt'> </span>kurulu bir bulut
sisteminde saklanmakta olup, bu verilerde yurtdışı veri aktarımı
bulunmamaktadır.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.3pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.25pt'> </span>VERİLERİN<span
style='letter-spacing:-.15pt'> </span>SAKLANMA<span style='letter-spacing:-.3pt'>
</span>SÜRELERİNE<span style='letter-spacing:-.2pt'> </span>İLİŞKİN<span
style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>ESASLAR</span></span></h1>

<p class=MsoBodyText style='margin-top:4.1pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Kişisel veriler, DentisToday tarafından ilgili mevzuatta
öngörülen süreler boyunca ve hukuki yükümlülükleri doğrultusunda
saklanmaktadır. Saklama süreleri, Verbis sisteminde kamuya açık olarak
gösterilmektedir.</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.6pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Kişisel verilerin ne kadar süre boyunca saklanması
gerektiğine ilişkin mevzuatta bir süre düzenlenmemişse, DentisToday o veriyi
işlerken yürüttüğü faaliyet ile bağlantılı olarak DentisToday<span
style='letter-spacing:-.4pt'> </span>uygulamaları ile<span style='letter-spacing:
-.05pt'> </span>ticari<span style='letter-spacing:-.45pt'> </span>teamüller
doğrultusunda işlenmesini<span style='letter-spacing:-.25pt'> </span>gerektiren
süre<span style='letter-spacing:-.05pt'> </span>kadar işlenmekte daha sonra
silinmekte, yok edilmekte veya anonim hale getirilmektedir. İşlenme amacı<span
style='letter-spacing:-.5pt'> </span>sona<span style='letter-spacing:-.35pt'> </span>ermiş<span
style='letter-spacing:-.4pt'> </span>kişisel<span style='letter-spacing:-.5pt'>
</span>veriler<span style='letter-spacing:-.05pt'> </span>ile<span
style='letter-spacing:-.35pt'> </span>kişisel<span style='letter-spacing:-.5pt'>
</span>veri<span style='letter-spacing:-.75pt'> </span>sahipleri<span
style='letter-spacing:-.75pt'> </span>tarafından<span style='letter-spacing:
-.55pt'> </span>silinmesi/anonimleştirilmesi talep edilmiş olan kişisel
veriler, ilgili mevzuat ve DentisToday’in belirlediği saklama sürelerinin<span
style='letter-spacing:-.75pt'> </span>de<span style='letter-spacing:-.75pt'> </span>sonuna<span
style='letter-spacing:-.75pt'> </span>gelinmişse;<span style='letter-spacing:
-.75pt'> </span>yalnızca<span style='letter-spacing:-.75pt'> </span>olası<span
style='letter-spacing:-.75pt'> </span>hukuki<span style='letter-spacing:-.75pt'>
</span>uyuşmazlıklarda<span style='letter-spacing:-.75pt'> </span>delil<span
style='letter-spacing:-.75pt'> </span>teşkil<span style='letter-spacing:-.75pt'>
</span>etmesi<span style='letter-spacing:-.75pt'> </span>veya kişisel veriye
bağlı ilgili hakkın ileri sürülebilmesi veya savunmanın tesis edilmesi amacıyla
saklanabilmektedir. DentisToday kişisel verilerin saklanma sürelerini
belirlerken ilgili mevzuatta<span style='letter-spacing:-.1pt'> </span>öngörülen<span
style='letter-spacing:-.1pt'> </span>zamanaşımı<span style='letter-spacing:
-.1pt'> </span>sürelerini<span style='letter-spacing:-.3pt'> </span>esas
almaktadır. Bu amaç doğrultusunda saklanan kişisel verilere yalnızca ilgili
hukuki uyuşmazlıkta kullanılması gerektiği zaman sınırlı kişiler tarafından
erişilmekte olup bu amaç dışında başka bir amaçla erişilmemektedir. Bu sürenin
sonunda da kişisel veriler silinmekte, yok edilmekte veya anonim hale
getirilmektedir.</span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection15>

<h1 style='margin-top:3.7pt;margin-right:38.05pt;margin-bottom:0in;margin-left:
34.15pt;margin-bottom:.0001pt;line-height:115%'><span lang=TR>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.35pt'> </span>VERİLERİN<span
style='letter-spacing:-.2pt'> </span>SİLİNMESİ,<span style='letter-spacing:
-.2pt'> </span>YOK<span style='letter-spacing:-.05pt'> </span>EDİLMESİ<span
style='letter-spacing:-.4pt'> </span>VE<span style='letter-spacing:-.35pt'> </span>ANONİM<span
style='letter-spacing:-.15pt'> </span>HALE <span style='letter-spacing:-.1pt'>GETİRİLMESİ</span></span></h1>

<p class=MsoBodyText style='margin-top:2.05pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.9pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Kişisel Veriler, Kanunu’nun 7. Maddesinde ve Türk Ceza
Kanunu’nun 138. maddesinde ve düzenlendiği üzere ilgili kanun hükümlerine uygun
olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
kalkması halinde, re’sen veya kişisel veri sahibinin talebi üzerine,
DentisToday tarafından silinir, yok edilir veya anonim hâle getirilir.</span></p>

<p class=MsoBodyText style='margin-top:1.9pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>KVKK m.7 maddesinde ayrıca kişisel verilerin silinmesi, yok
edilmesi ve anonim hale getirilmesi<span style='letter-spacing:-.55pt'> </span>düzenlenmiştir.<span
style='letter-spacing:-.3pt'> </span>Buna<span style='letter-spacing:-.4pt'> </span>göre,<span
style='letter-spacing:-.3pt'> </span>kişisel<span style='letter-spacing:-.35pt'>
</span>verilerin<span style='letter-spacing:-.2pt'> </span>hukuka<span
style='letter-spacing:-.4pt'> </span>uygun<span style='letter-spacing:-.6pt'> </span>olarak<span
style='letter-spacing:-.15pt'> </span>işlenmiş<span style='letter-spacing:-.3pt'>
</span>olmasına rağmen, işlenmesini<span style='letter-spacing:-.75pt'> </span>gerektiren<span
style='letter-spacing:-.6pt'> </span>sebeplerin<span style='letter-spacing:
-.6pt'> </span>ortadan<span style='letter-spacing:-.4pt'> </span>kalkması<span
style='letter-spacing:-.6pt'> </span>hâlinde<span style='letter-spacing:-.2pt'>
</span>bu<span style='letter-spacing:-.4pt'> </span>veriler,<span
style='letter-spacing:-.3pt'> </span>resen<span style='letter-spacing:-.4pt'> </span>veya<span
style='letter-spacing:-.2pt'> </span>ilgili kişinin talebi üzerine şirketimizce
silinir, yok edilir veya anonim hâle getirilir.</span></p>

<p class=MsoBodyText style='margin-top:2.15pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-left:5.8pt;text-align:justify'><span
lang=TR>Buna<span style='letter-spacing:-.15pt'> </span><span style='letter-spacing:
-.1pt'>göre;</span></span></p>

<p class=MsoBodyText style='margin-top:4.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.9pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel verileri işlemeye
esas teşkil eden ilgili mevzuat hükümlerinin değiştirilmesi veya <span
style='letter-spacing:-.1pt'>ilgası,</span></span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:6.1pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Taraflar arasındaki
sözleşmenin hiç kurulmamış olması, sözleşmenin geçerli olmaması, sözleşmenin
kendiliğinden sona ermesi, sözleşmenin feshi veya sözleşmeden dönülmesi,</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.9pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel verilerin işlenmesini<span
style='letter-spacing:-.15pt'> </span>gerektiren amacın ortadan kalkması,
Kişisel verileri işlemenin hukuka veya dürüstlük kuralına aykırı olduğunun
tespit edilmesi,</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.45pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel verileri işlemenin
sadece açık rıza şartına istinaden gerçekleştiği hallerde, ilgili kişinin
rızasını geri alması,</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.85pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>İlgili kişinin, Kanunun 11.
maddesinin 1. fıkrasının (e) ve (f) bentlerindeki hakları çerçevesinde kişisel
verileri işleme faaliyetine ilişkin yaptığı başvurunun şirketimiz tarafından
kabul edilmesi,</span></p>

<p class=MsoBodyText style='margin-top:2.35pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.85pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Şirketimizin, ilgili kişi
tarafından kişisel verilerinin silinmesi veya yok edilmesi talebi ile kendisine
yapılan başvuruyu<span style='letter-spacing:-.2pt'> </span>reddetmesi,<span
style='letter-spacing:-.1pt'> </span>verdiği<span style='letter-spacing:-.4pt'>
</span>cevabın yetersiz<span style='letter-spacing:-.25pt'> </span>bulunması<span
style='letter-spacing:-.2pt'> </span>veya Kanunda öngörülen süre içinde cevap
vermemesi hallerinde; Kurula şikayette bulunulması ve bu talebin Kurul
tarafından uygun bulunması,</span></p>

<p class=MsoBodyText style='margin-top:2.4pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.85pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel verilerin
saklanmasını gerektiren azami sürenin geçmiş olmasına rağmen, kişisel verileri
daha uzun süre saklamayı haklı kılacak herhangi bir şartın mevcut olmaması,</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.95pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kanunun 5. ve 6.
maddelerindeki kişisel verilerin işlenmesini gerektiren şartların ortadan <span
style='letter-spacing:-.1pt'>kalkması,</span></span></p>

<p class=MsoBodyText style='margin-top:2.15pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-left:8.9pt;text-align:justify'><span
lang=TR>gibi<span style='letter-spacing:-.3pt'> </span>hallerde<span
style='letter-spacing:-.25pt'> </span>kişisel<span style='letter-spacing:-.2pt'>
</span>veriler<span style='letter-spacing:-.1pt'> </span>silinmekte,<span
style='letter-spacing:-.1pt'> </span>yok<span style='letter-spacing:-.2pt'> </span>edilmekte<span
style='letter-spacing:-.25pt'> </span>veya<span style='letter-spacing:-.2pt'> </span>anonim<span
style='letter-spacing:-.2pt'> </span>hale<span style='letter-spacing:-.2pt'> </span><span
style='letter-spacing:-.1pt'>getirilmektedir.</span></span></p>

</div>

<span lang=TR style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection16>

<h1 style='margin-top:5.4pt'><span lang=TR>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>YÜKÜMLÜLÜKLER<span style='letter-spacing:-.1pt'> </span>VE<span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>HAKLAR</span></span></h1>

<p class=MsoBodyText style='margin-top:4.35pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:102.9pt;
margin-bottom:0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:left;
text-indent:-28.35pt;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;line-height:115%'>DENTİSTODAY’NUN<span
style='letter-spacing:-.65pt'> </span>AYDINLATMA<span style='letter-spacing:
-.65pt'> </span>VE<span style='letter-spacing:-.7pt'> </span>BİLGİLENDİRME <span
style='letter-spacing:-.1pt'>YÜKÜMLÜLÜĞÜ</span></span></b></p>

<p class=MsoBodyText style='margin-top:1.75pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-left:5.8pt;line-height:115%'><span lang=TR>DentisToday,
Kanun’un 10. maddesine uygun olarak, kişisel verilerin elde edilmesi sırasında
Veri Sahiplerini aydınlatmaktadır. Bu kapsamda DentisToday;</span></p>

<p class=MsoListParagraph align=left style='margin-top:.05pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Veri<span
style='letter-spacing:-.65pt'> </span>Sorumlusu<span style='letter-spacing:
-.1pt'> </span>ve<span style='letter-spacing:-.1pt'> </span>varsa<span
style='letter-spacing:-.15pt'> </span>temsilcisinin<span style='letter-spacing:
-.3pt'> </span><span style='letter-spacing:-.1pt'>kimliği,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Kişisel<span
style='letter-spacing:-.5pt'> </span>Veriler’in<span style='letter-spacing:
-.05pt'> </span>hangi<span style='letter-spacing:-.25pt'> </span>amaçla<span
style='letter-spacing:.15pt'> </span><span style='letter-spacing:-.1pt'>işleneceği,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>İşlenen<span
style='letter-spacing:-.15pt'> </span>Kişisel<span style='letter-spacing:-.5pt'>
</span>Verilerin<span style='letter-spacing:-.25pt'> </span>kimlere<span
style='letter-spacing:-.1pt'> </span>ve<span style='letter-spacing:-.1pt'> </span>hangi<span
style='letter-spacing:-.25pt'> </span>amaçla<span style='letter-spacing:.15pt'>
</span><span style='letter-spacing:-.1pt'>aktarılabileceği,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:1.85pt;margin-right:
0in;margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Kişisel<span
style='letter-spacing:-.4pt'> </span>Veri<span style='letter-spacing:-.4pt'> </span>toplamanın<span
style='letter-spacing:.05pt'> </span>yöntemi<span style='letter-spacing:-.15pt'>
</span>ve hukuki<span style='letter-spacing:-.2pt'> </span>sebebi<span
style='letter-spacing:.1pt'> </span><span style='letter-spacing:-.25pt'>ile</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.15pt;margin-right:
151.45pt;margin-bottom:0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:
left;text-indent:56.65pt;line-height:113%'><span lang=TR style='font-size:12.0pt;
line-height:113%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Veri<span
style='letter-spacing:-.7pt'> </span>Sahibi’nin<span style='letter-spacing:
-.3pt'> </span>sahip<span style='letter-spacing:-.3pt'> </span>olduğu<span
style='letter-spacing:-.15pt'> </span>hakları<span style='letter-spacing:-.7pt'>
</span>konusunda Veri Sahibi’ne bilgi vermektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Anayasa’nın<span style='letter-spacing:-.35pt'> </span>20.
maddesinde<span style='letter-spacing:-.2pt'> </span>herkesin,<span
style='letter-spacing:-.05pt'> </span>kendisiyle ilgili<span style='letter-spacing:
-.55pt'> </span>kişisel<span style='letter-spacing:-.15pt'> </span>veriler<span
style='letter-spacing:-.1pt'> </span>hakkında bilgilendirilme hakkına<span
style='letter-spacing:-.75pt'> </span>sahip<span style='letter-spacing:-.65pt'>
</span>olduğu<span style='letter-spacing:-.65pt'> </span>ortaya<span
style='letter-spacing:-.65pt'> </span>konulmuştur.<span style='letter-spacing:
-.7pt'> </span>Bu<span style='letter-spacing:-.65pt'> </span>doğrultuda<span
style='letter-spacing:-.65pt'> </span>Kanun’un<span style='letter-spacing:-.75pt'>
</span>11.<span style='letter-spacing:-.3pt'> </span>maddesinde<span
style='letter-spacing:-.65pt'> </span>kişisel<span style='letter-spacing:-.75pt'>
</span>veri sahibinin hakları arasında “bilgi talep etme” de sayılmıştır.
DentisToday bu kapsamda, Anayasa’nın<span style='letter-spacing:-.3pt'> </span>20.
ve<span style='letter-spacing:-.1pt'> </span>Kanun’un<span style='letter-spacing:
-.3pt'> </span>11. maddelerine<span style='letter-spacing:-.1pt'> </span>uygun<span
style='letter-spacing:-.3pt'> </span>olarak<span style='letter-spacing:-.05pt'>
</span>Veri<span style='letter-spacing:-.5pt'> </span>Sahibi’nin bilgi<span
style='letter-spacing:-.3pt'> </span>talep<span style='letter-spacing:-.05pt'> </span>etmesi
durumunda<span style='letter-spacing:-.45pt'> </span>gerekli<span
style='letter-spacing:-.4pt'> </span>bilgilendirmeyi<span style='letter-spacing:
-.4pt'> </span>yapmaktadır.<span style='letter-spacing:-.3pt'> </span>Bu<span
style='letter-spacing:-.4pt'> </span>konu<span style='letter-spacing:-.4pt'> </span>ile<span
style='letter-spacing:-.2pt'> </span>ilgili<span style='letter-spacing:-.55pt'>
</span>ayrıntılı<span style='letter-spacing:-.35pt'> </span>bilgiye<span
style='letter-spacing:-.45pt'> </span>Politika’nın Veri Sahibi’nin Hakları
bölümünden ve Aydınlatma Metninden ulaşabilirsiniz.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.4pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>6.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>VERİ<span style='letter-spacing:-.5pt'> </span>SAHİBİ’NİN<span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>HAKLARI</span></span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-left:5.8pt;text-align:justify'><span
lang=TR>Veri<span style='letter-spacing:-.5pt'> </span>Sahipleri, aşağıdaki<span
style='letter-spacing:-.3pt'> </span>haklara<span style='letter-spacing:-.1pt'>
sahiptirler:</span></span></p>

<p class=MsoBodyText style='margin-top:4.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:19.9pt;text-indent:-14.1pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>DentisToday’in<span
style='letter-spacing:-.4pt'> </span>Veri<span style='letter-spacing:-.55pt'> </span>Sahibi’nin<span
style='letter-spacing:-.35pt'> </span>kişisel<span style='letter-spacing:-.1pt'>
</span>verilerini<span style='letter-spacing:-.35pt'> </span>işleyip<span
style='letter-spacing:.1pt'> </span>işlemediğini<span style='letter-spacing:
-.5pt'> </span><span style='letter-spacing:-.1pt'>öğrenme,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Eğer DentisToday nezdinde
Kişisel Veriler işleniyorsa, bu veri işleme faaliyeti hakkında bilgi talep
etme,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Eğer DentisToday nezdinde
Kişisel Veriler işleniyorsa, kişisel veri işleme faaliyetinin amacını ve
işlenme amacına uygun kullanılıp kullanmadığını öğrenme,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Eğer Kişisel Veriler
yurtiçinde veya yurtdışında üçüncü kişilere aktarılıyorsa, bu üçüncü kişiler
hakkında bilgi talep etme,</span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:6.3pt;margin-bottom:
0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:111%'><span lang=TR
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:111%'>Kişisel Veriler’in eksik veya
yanlış işlenmiş olması halinde, bunların düzeltilmesini talep <span
style='letter-spacing:-.1pt'>etme,</span></span></p>

<p class=MsoListParagraph style='margin-top:.7pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:111%'><span lang=TR
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:111%'>Kişisel<span
style='letter-spacing:-.15pt'> </span>Veriler’in DentisToday<span
style='letter-spacing:-.05pt'> </span>nezdinde eksik veya yanlış işlenmiş
olması halinde, Kişisel Veriler’in aktarıldığı üçüncü kişilere bu durumun
bildirilmesini talep etme,</span></p>

<p class=MsoListParagraph style='margin-top:.5pt;margin-right:6.05pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel<span
style='letter-spacing:-.35pt'> </span>Veriler’in Kanun<span style='letter-spacing:
-.1pt'> </span>ve ilgili<span style='letter-spacing:-.35pt'> </span>diğer kanun<span
style='letter-spacing:-.1pt'> </span>hükümlerine uygun<span style='letter-spacing:
-.1pt'> </span>olarak işlenmiş<span style='letter-spacing:-.05pt'> </span>olmasına
rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde Kişisel
Veriler’in silinmesini, yok edilmesini veya anonim hale getirilmesini
istiyorum.</span></p>

</div>

<span lang=TR style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection17>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:6.1pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel Veriler’in
işlenmesini gerektiren sebepler ortadan kalktıysa, bu durumun Kişisel
Veriler’in aktarıldığı üçüncü kişilere bildirilmesini talep etme,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>DentisToday<span
style='letter-spacing:-.75pt'> </span>tarafından<span style='letter-spacing:
-.5pt'> </span>işlenen<span style='letter-spacing:-.65pt'> </span>Kişisel<span
style='letter-spacing:-.75pt'> </span>Veriler’in<span style='letter-spacing:
-.45pt'> </span>münhasıran<span style='letter-spacing:-.65pt'> </span>otomatik<span
style='letter-spacing:-.45pt'> </span>sistemler<span style='letter-spacing:
-.35pt'> </span>vasıtasıyla analiz edilmesi ve bu analiz neticesinde ilgili
kişinin (Veri Sahibi) aleyhine bir sonuç doğduğunu düşündüğü sonuçların ortaya
çıkması halinde, bu sonuca itiraz etme.</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:6.0pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel<span
style='letter-spacing:-.75pt'> </span>Veriler’in<span style='letter-spacing:
-.55pt'> </span>kanuna<span style='letter-spacing:-.35pt'> </span>aykırı<span
style='letter-spacing:-.75pt'> </span>olarak<span style='letter-spacing:-.05pt'>
</span>işlenmesi<span style='letter-spacing:-.5pt'> </span>sebebiyle<span
style='letter-spacing:-.35pt'> </span>zarara<span style='letter-spacing:-.35pt'>
</span>uğranması<span style='letter-spacing:-.5pt'> </span>halinde<span
style='letter-spacing:-.35pt'> </span>zararın giderilmesini talep etme.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.15pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.1pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Veri Sahipleri, Kanun’un uygulanmasına ilişkin taleplerini,
“6698 Sayılı Kişisel Verilerin Korunması<span style='letter-spacing:-.75pt'> </span>Kanunu<span
style='letter-spacing:-.75pt'> </span>Gereğince<span style='letter-spacing:
-.75pt'> </span>İlgili<span style='letter-spacing:-.75pt'> </span>Kişi<span
style='letter-spacing:-.75pt'> </span>(Veri<span style='letter-spacing:-.75pt'>
</span>Sahibi)<span style='letter-spacing:-.75pt'> </span>Tarafından<span
style='letter-spacing:-.75pt'> </span>Veri<span style='letter-spacing:-.75pt'> </span>Sorumlusuna<span
style='letter-spacing:-.75pt'> </span>Yapılacak Başvurulara<span
style='letter-spacing:-.75pt'> </span>İlişkin<span style='letter-spacing:-.75pt'>
</span>Başvuru<span style='letter-spacing:-.75pt'> </span>Formu”nu,<span
style='letter-spacing:-.4pt'> </span>yazılı<span style='letter-spacing:-.75pt'>
</span>olarak<span style='letter-spacing:-.7pt'> </span>ve<span
style='letter-spacing:-.75pt'> </span>Kurul’un<span style='letter-spacing:-.75pt'>
</span>belirleyeceği<span style='letter-spacing:-.75pt'> </span>diğer<span
style='letter-spacing:-.4pt'> </span>yöntemler ile DentisToday’e iletebilirler.</span></p>

<p class=MsoBodyText style='margin-top:2.45pt'><span lang=TR>&nbsp;</span></p>

<h1 style='margin-left:34.1pt;text-align:justify;text-indent:-28.3pt'><span
lang=TR>6.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='letter-spacing:-.1pt'>İSTİSNALAR</span></h1>

<p class=MsoBodyText style='margin-top:3.85pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.5pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>Kanun’un<span style='letter-spacing:-.15pt'> </span>28.
Maddesi<span style='letter-spacing:-.35pt'> </span>uyarınca, aşağıdaki haller
Kanun<span style='letter-spacing:-.15pt'> </span>kapsamı<span style='letter-spacing:
-.1pt'> </span>dışında tutulduğundan, Veri Sahipleri, yukarıda belirtilen
haklarını ileri süremezler:</span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.85pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-indent:0in;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel verilerin, üçüncü
kişilere verilmemek ve veri güvenliğine ilişkin yükümlülüklere uyulmak<span
style='letter-spacing:-.35pt'> </span>kaydıyla<span style='letter-spacing:-.4pt'>
</span>gerçek<span style='letter-spacing:-.35pt'> </span>kişiler<span
style='letter-spacing:-.25pt'> </span>tarafından<span style='letter-spacing:
-.55pt'> </span>tamamen<span style='letter-spacing:-.55pt'> </span>kendisiyle
veya<span style='letter-spacing:-.15pt'> </span>aynı<span style='letter-spacing:
-.5pt'> </span>konutta<span style='letter-spacing:-.4pt'> </span>yaşayan<span
style='letter-spacing:-.55pt'> </span>aile fertleriyle ilgili faaliyetler
kapsamında işlenmesi.</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:5.8pt;margin-bottom:.0001pt;text-indent:0in;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma,
planlama ve istatistik gibi amaçlarla işlenmesi.</span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:5.8pt;margin-bottom:.0001pt;text-indent:0in;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini,
ekonomik<span style='letter-spacing:-.75pt'> </span>güvenliği,<span
style='letter-spacing:-.75pt'> </span>özel<span style='letter-spacing:-.75pt'> </span>hayatın<span
style='letter-spacing:-.75pt'> </span>gizliliğini<span style='letter-spacing:
-.75pt'> </span>veya<span style='letter-spacing:-.75pt'> </span>kişilik<span
style='letter-spacing:-.75pt'> </span>haklarını<span style='letter-spacing:
-.75pt'> </span>ihlal<span style='letter-spacing:-.75pt'> </span>etmemek<span
style='letter-spacing:-.75pt'> </span>ya<span style='letter-spacing:-.75pt'> </span>da<span
style='letter-spacing:-.75pt'> </span>suç<span style='letter-spacing:-.75pt'> </span>teşkil
etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade
özgürlüğü kapsamında işlenmesi.</span></p>

<p class=MsoListParagraph style='margin-top:.35pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:5.8pt;margin-bottom:.0001pt;text-indent:0in;
line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel
verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya
ekonomik<span style='letter-spacing:-.3pt'> </span>güvenliği<span
style='letter-spacing:-.5pt'> </span>sağlamaya yönelik<span style='letter-spacing:
-.3pt'> </span>olarak<span style='letter-spacing:-.3pt'> </span>kanunla<span
style='letter-spacing:-.35pt'> </span>görev<span style='letter-spacing:-.3pt'> </span>ve<span
style='letter-spacing:-.1pt'> </span>yetki<span style='letter-spacing:-.5pt'> </span>verilmiş<span
style='letter-spacing:-.2pt'> </span>kamu<span style='letter-spacing:-.3pt'> </span>kurum<span
style='letter-spacing:-.5pt'> </span>ve kuruluşları tarafından yürütülen
önleyici, koruyucu ve istihbarı faaliyetler kapsamında <span style='letter-spacing:
-.1pt'>işlenmesi.</span></span></p>

<p class=MsoListParagraph style='margin-top:.4pt;margin-right:6.3pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-indent:0in;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel verilerin soruşturma,
kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları
veya infaz mercileri tarafından işlenmesi.</span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.9pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday’in Aydınlatma Yükümlülüğü, Kanun’un 28/2
maddesi uyarınca aşağıdaki hâllerde uygulanmaz:</span></p>

<p class=MsoBodyText style='margin-top:2.1pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:19.9pt;text-indent:-14.1pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Kişisel<span
style='letter-spacing:-.85pt'> </span>veri<span style='letter-spacing:-.75pt'> </span>işlemenin<span
style='letter-spacing:-.6pt'> </span>suç<span style='letter-spacing:-.45pt'> </span>işlenmesinin<span
style='letter-spacing:-.65pt'> </span>önlenmesi<span style='letter-spacing:
-.6pt'> </span>veya<span style='letter-spacing:-.45pt'> </span>suç<span
style='letter-spacing:-.65pt'> </span>soruşturması<span style='letter-spacing:
-.6pt'> </span>için<span style='letter-spacing:-.6pt'> </span>gerekli<span
style='letter-spacing:-.75pt'> </span><span style='letter-spacing:-.1pt'>olması.</span></span></p>

<p class=MsoBodyText style='margin-top:4.15pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:19.9pt;text-indent:-14.1pt'><span
lang=TR style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>İlgili<span
style='letter-spacing:-.4pt'> </span>kişinin<span style='letter-spacing:-.25pt'>
</span>kendisi<span style='letter-spacing:-.5pt'> </span>tarafından<span
style='letter-spacing:-.3pt'> </span>alenileştirilmiş<span style='letter-spacing:
-.2pt'> </span>kişisel<span style='letter-spacing:-.3pt'> </span>verilerin<span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>işlenmesi.</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:5.4pt;
margin-bottom:0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel veri işlemenin
kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu kurum ve<span
style='letter-spacing:3.7pt'> </span>kuruluşları<span style='letter-spacing:
3.5pt'> </span>ile<span style='letter-spacing:3.7pt'> </span>kamu<span
style='letter-spacing:3.7pt'> </span>kurumu<span style='letter-spacing:3.7pt'> </span>niteliğindeki<span
style='letter-spacing:3.75pt'> </span>meslek<span style='letter-spacing:3.7pt'>
</span>kuruluşlarınca,<span style='letter-spacing:3.85pt'> </span>denetleme<span
style='letter-spacing:3.7pt'> </span>veya</span></p>

</div>

<span lang=TR style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection18>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:0in;margin-bottom:
0in;margin-left:19.95pt;margin-bottom:.0001pt;line-height:115%'><span lang=TR>düzenleme
görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli
<span style='letter-spacing:-.1pt'>olması.</span></span></p>

<p class=MsoListParagraph align=left style='margin-right:5.45pt;text-align:
left;line-height:113%'><span lang=TR style='font-size:12.0pt;line-height:113%;
font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt;line-height:113%'>Kişisel<span
style='letter-spacing:-.75pt'> </span>veri<span style='letter-spacing:-.85pt'> </span>işlemenin<span
style='letter-spacing:-.75pt'> </span>bütçe,<span style='letter-spacing:-.75pt'>
</span>vergi<span style='letter-spacing:-.85pt'> </span>ve<span
style='letter-spacing:-.75pt'> </span>mali<span style='letter-spacing:-.8pt'> </span>konulara<span
style='letter-spacing:-.65pt'> </span>ilişkin<span style='letter-spacing:-.75pt'>
</span>olarak<span style='letter-spacing:-.75pt'> </span>Devletin<span
style='letter-spacing:-.85pt'> </span>ekonomik<span style='letter-spacing:-.6pt'>
</span>ve<span style='letter-spacing:-.65pt'> </span>mali çıkarlarının
korunması için gerekli olması.</span></p>

<p class=MsoBodyText style='margin-top:2.4pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>KİŞİSEL<span style='letter-spacing:-.35pt'> </span>VERİLERİN<span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>KATEGORİZASYONU</span></span></h1>

<p class=MsoBodyText style='margin-top:4.05pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday nezdinde; DentisToday’in meşru ve hukuka uygun
kişisel veri işleme amaçları doğrultusunda, Kanun’un 5. maddesinde belirtilen
kişisel veri işleme şartlarından bir veya birkaçına<span style='letter-spacing:
-.75pt'> </span>dayalı<span style='letter-spacing:-.75pt'> </span>ve<span
style='letter-spacing:-.75pt'> </span>sınırlı<span style='letter-spacing:-.75pt'>
</span>olarak,<span style='letter-spacing:-.75pt'> </span>başta<span
style='letter-spacing:-.75pt'> </span>kişisel<span style='letter-spacing:-.75pt'>
</span>verilerin<span style='letter-spacing:-.75pt'> </span>işlenmesine<span
style='letter-spacing:-.65pt'> </span>ilişkin<span style='letter-spacing:-.75pt'>
</span>4.<span style='letter-spacing:-.55pt'> </span>maddede<span
style='letter-spacing:-.7pt'> </span>belirtilen ilkeler olmak üzere Kanun’da
belirtilen genel ilkelere ve Kanun’da düzenlenen bütün yükümlülüklere<span
style='letter-spacing:-.7pt'> </span>uyularak<span style='letter-spacing:-.65pt'>
</span>ve<span style='letter-spacing:-.7pt'> </span>Politika<span
style='letter-spacing:-.7pt'> </span>kapsamındaki<span style='letter-spacing:
-.6pt'> </span>süjelerle<span style='letter-spacing:-.7pt'> </span>(Müşteriler,
Kullanıcılar,<span style='letter-spacing:-.55pt'> </span>Hasta<span
style='letter-spacing:-.7pt'> </span>ve Hasta yakınları, Ziyaretçi, Çalışan
Adayı, Şirket Yetkilileri, Tedarikçiler, İş Ortakları vb.) sınırlı olarak
aşağıda belirtilen kategorilerdeki kişisel veriler, Kanun’un 10. maddesi
uyarınca ilgili kişiler bilgilendirilmek suretiyle işlenmektedir.</span></p>

<p class=MsoBodyText style='margin-top:4.8pt'><span lang=TR style='font-size:
10.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:50.15pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:50.15pt'>
  <p class=TableParagraph style='margin-top:8.95pt;margin-right:0in;margin-bottom:
  0in;margin-left:27.35pt;margin-bottom:.0001pt'><b><span lang=TR
  style='font-size:12.0pt'>KİŞİSEL<span style='letter-spacing:-.25pt'> </span><span
  style='letter-spacing:-.2pt'>VERİ</span></span></b></p>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:10.55pt;margin-bottom:.0001pt'><b><span lang=TR
  style='font-size:12.0pt;letter-spacing:-.1pt'>KATEGORİZASYONU</span></b></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:50.15pt'>
  <p class=TableParagraph style='margin-top:3.05pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-left:.6pt;text-align:center'><b><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>AÇIKLAMA</span></b></p>
  </td>
 </tr>
 <tr style='height:131.5pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:131.5pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:2.2pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span lang=TR
  style='font-size:12.0pt'>Kimlik<span style='letter-spacing:-.15pt'> </span><span
  style='letter-spacing:-.1pt'>Bilgisi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:131.5pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.95pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.75pt'> </span>şekilde<span
  style='letter-spacing:-.6pt'> </span>veya<span style='letter-spacing:-.45pt'>
  </span>veri<span style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir
  parçası olarak otomatik olmayan şekilde işlenen; kişinin<span
  style='letter-spacing:.5pt'> </span>kimliğine<span style='letter-spacing:
  .65pt'> </span>dair<span style='letter-spacing:.8pt'> </span>bilgilerin<span
  style='letter-spacing:.75pt'> </span>bulunduğu<span style='letter-spacing:
  .7pt'> </span>verilerdir;<span style='letter-spacing:.6pt'> </span>ad-<span
  style='letter-spacing:-.1pt'>soyad,</span></span></p>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:2.45pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>T.C.
  kimlik numarası, uyruk bilgisi, anne adı-baba adı, doğum yeri, doğum tarihi,
  cinsiyet gibi bilgileri içeren ehliyet, nüfus cüzdanı ve pasaport gibi
  belgeler ile vergi numarası, SGK numarası, imza bilgisi, taşıt plakası v.b.
  bilgiler</span></p>
  </td>
 </tr>
 <tr style='height:84.0pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:6.05pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:3.35pt;margin-bottom:.0001pt'><span lang=TR style='font-size:
  12.0pt'>İletişim<span style='letter-spacing:-.3pt'> </span><span
  style='letter-spacing:-.1pt'>Bilgisi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.95pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.75pt'> </span>şekilde<span
  style='letter-spacing:-.65pt'> </span>veya<span style='letter-spacing:-.45pt'>
  </span>veri<span style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir
  parçası olarak otomatik olmayan şekilde işlenen; telefon numarası, adres,
  e-mail adresi, faks numarası, IP adresi, sosyal medya hesap bilgisi vb. gibi
  bilgiler</span></p>
  </td>
 </tr>
 <tr style='height:147.35pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:147.35pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:10.15pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span lang=TR
  style='font-size:12.0pt'>Lokasyon<span style='letter-spacing:-.35pt'> </span><span
  style='letter-spacing:-.1pt'>Verisi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:147.35pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.6pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.65pt'> </span>şekilde<span
  style='letter-spacing:-.45pt'> </span>veya<span style='letter-spacing:-.45pt'>
  </span>veri<span style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir
  parçası olarak otomatik olmayan şekilde işlenen; kişisel veri sahibinin
  DentisToday iş birimleri tarafından yürütülen operasyonlar çerçevesinde,
  DentisToday’in ürün ve hizmetlerinin kullanımı sırasında veya işbirliği
  içerisinde olduğumuz kurumların çalışanlarının DentisToday araçlarını
  kullanırken<span style='letter-spacing:-.7pt'> </span>bulunduğu<span
  style='letter-spacing:-.25pt'> </span>yerin<span style='letter-spacing:-.7pt'>
  </span>konumunu<span style='letter-spacing:-.5pt'> </span>tespit<span
  style='letter-spacing:-.25pt'> </span>eden<span style='letter-spacing:-.5pt'>
  </span>bilgiler;<span style='letter-spacing:-.65pt'> </span>GPS lokasyonu,
  seyahat verileri v.b.</span></p>
  </td>
 </tr>
</table>

</div>

<span lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection19>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:2.05in'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:2.05in'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:2.5pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt;line-height:115%'><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Aile<span style='letter-spacing:
  -.75pt'> </span>Bireyleri<span style='letter-spacing:-.75pt'> </span>ve<span
  style='letter-spacing:-.65pt'> </span>Yakın <span style='letter-spacing:-.1pt'>Bilgisi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:2.05in'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.55pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.75pt'> </span>şekilde<span
  style='letter-spacing:-.5pt'> </span>veya<span style='letter-spacing:-.2pt'> </span>veri<span
  style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir parçası olarak
  otomatik olmayan şekilde işlenen; DentisToday’in iş birimleri tarafından
  yürütülen operasyonlar çerçevesinde, DentisToday’in<span style='letter-spacing:
  -.3pt'> </span>sunduğu<span style='letter-spacing:-.15pt'> </span>ürün<span
  style='letter-spacing:-.15pt'> </span>ve<span style='letter-spacing:-.15pt'> </span>hizmetlerle
  ilgili veya DentisToday’in ve kişisel veri sahibinin hukuki ve diğer
  menfaatlerini korumak amacıyla kişisel veri sahibinin aile bireyleri (örn.
  eş, anne, baba, çocuk), yakınları ve acil durumlarda ulaşılabilecek diğer
  kişiler hakkındaki bilgiler</span></p>
  </td>
 </tr>
 <tr style='height:99.85pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:6.05pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:0in;margin-right:24.65pt;
  margin-bottom:0in;margin-left:3.35pt;margin-bottom:.0001pt;line-height:115%'><span
  lang=TR style='font-size:12.0pt;line-height:115%;letter-spacing:-.1pt'>Fiziksel</span><span
  lang=TR style='font-size:12.0pt;line-height:115%'>             <span
  style='letter-spacing:-.2pt'>Mekan </span>Güvenlik Bilgisi</span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.5pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.6pt'> </span>şekilde<span
  style='letter-spacing:-.4pt'> </span>veya<span style='letter-spacing:-.4pt'> </span>veri<span
  style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir parçası olarak
  otomatik olmayan şekilde işlenen; <span style='letter-spacing:-.1pt'>fiziksel
  mekana</span><span style='letter-spacing:-.15pt'> </span><span
  style='letter-spacing:-.1pt'>girişte, fiziksel</span><span style='letter-spacing:
  -.4pt'> </span><span style='letter-spacing:-.1pt'>mekanın içerisinde</span><span
  style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>kalış</span><span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>sırasında
  </span>alınan<span style='letter-spacing:-.75pt'> </span>kayıtlar<span
  style='letter-spacing:-.75pt'> </span>ve<span style='letter-spacing:-.75pt'> </span>belgelere<span
  style='letter-spacing:-.75pt'> </span>ilişkin<span style='letter-spacing:
  -.75pt'> </span>kişisel<span style='letter-spacing:-.75pt'> </span>veriler;<span
  style='letter-spacing:-.75pt'> </span>kamera<span style='letter-spacing:-.75pt'>
  </span>kayıtları ve güvenlik noktasında alınan kayıtlar v.b.</span></p>
  </td>
 </tr>
 <tr style='height:131.5pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:131.5pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:2.25pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span lang=TR
  style='font-size:12.0pt'>Finansal<span style='letter-spacing:-.45pt'> </span><span
  style='letter-spacing:-.1pt'>Bilgi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:131.5pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.6pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.65pt'> </span>şekilde<span
  style='letter-spacing:-.45pt'> </span>veya<span style='letter-spacing:-.45pt'>
  </span>veri<span style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir
  parçası olarak otomatik olmayan şekilde işlenen; DentisToday’in kişisel veri
  sahibi ile kurmuş olduğu hukuki ilişkinin tipine göre yaratılan her türlü
  finansal sonucu gösteren bilgi, belge ve kayıtlara ilişkin işlenen kişisel
  veriler ile banka hesap numarası, IBAN numarası, kredi kartı bilgisi,
  finansal profil, malvarlığı verisi, gelir bilgisi gibi veriler</span></p>
  </td>
 </tr>
 <tr style='height:83.95pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:83.95pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:6.05pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span lang=TR
  style='font-size:12.0pt'>Görsel/İşitsel<span style='letter-spacing:-.65pt'> </span><span
  style='letter-spacing:-.2pt'>Bilgi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:83.95pt'>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:3.0pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.65pt'>
  </span>fotoğraf<span style='letter-spacing:-.7pt'> </span>ve<span
  style='letter-spacing:-.65pt'> </span>kamera<span style='letter-spacing:-.65pt'>
  </span>kayıtları<span style='letter-spacing:-.75pt'> </span>(Fiziksel<span
  style='letter-spacing:-.75pt'> </span>Mekan<span style='letter-spacing:-.5pt'>
  </span>Güvenlik Bilgisi kapsamında giren kayıtlar hariç), ses kayıtları ile
  kişisel veri içeren belgelerin kopyası niteliğindeki belgelerde yer alan <span
  style='letter-spacing:-.1pt'>veriler</span></span></p>
  </td>
 </tr>
 <tr style='height:99.85pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.2pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span lang=TR
  style='font-size:12.0pt'>Özlük<span style='letter-spacing:-.35pt'> </span><span
  style='letter-spacing:-.1pt'>Bilgisi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.9pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.75pt'> </span>şekilde<span
  style='letter-spacing:-.55pt'> </span>veya<span style='letter-spacing:-.45pt'>
  </span>veri<span style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir
  parçası olarak otomatik olmayan şekilde işlenen; DentisToday ile çalışma
  ilişkisi içerisinde olan gerçek kişilerin özlük haklarının oluşmasına temel
  olacak bilgilerin elde edilmesine yönelik işlenen her türlü kişisel veri</span></p>
  </td>
 </tr>
 <tr style='height:99.85pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:6.05pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:0in;margin-right:17.05pt;
  margin-bottom:0in;margin-left:3.35pt;margin-bottom:.0001pt;line-height:115%'><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Özel<span style='letter-spacing:
  2.0pt'> </span>Nitelikli<span style='letter-spacing:2.0pt'> </span>Kişisel <span
  style='letter-spacing:-.2pt'>Veri</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.7pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.75pt'> </span>şekilde<span
  style='letter-spacing:-.4pt'> </span>veya<span style='letter-spacing:-.45pt'>
  </span>veri<span style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir
  parçası olarak otomatik olmayan şekilde işlenen; KVK Kanunu’nun 6. maddesinde
  belirtilen veriler (örn. kan grubu<span style='letter-spacing:-.45pt'> </span>da<span
  style='letter-spacing:-.45pt'> </span>dahil<span style='letter-spacing:-.6pt'>
  </span>sağlık<span style='letter-spacing:-.4pt'> </span>verileri,<span
  style='letter-spacing:-.15pt'> </span>tanı<span style='letter-spacing:-.6pt'>
  </span>ve<span style='letter-spacing:-.45pt'> </span>ön<span
  style='letter-spacing:-.65pt'> </span>tanı<span style='letter-spacing:-.75pt'>
  </span>bilgileri,<span style='letter-spacing:-.15pt'> </span>biyometrik <span
  style='letter-spacing:-.1pt'>veriler,</span><span style='letter-spacing:-.25pt'>
  </span><span style='letter-spacing:-.1pt'>din</span><span style='letter-spacing:
  -.3pt'> </span><span style='letter-spacing:-.1pt'>ve</span><span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>üye</span><span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>olunan</span><span
  style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.1pt'>dernek</span><span
  style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>bilgisi,</span><span
  style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>parmak</span>
  <span style='letter-spacing:-.1pt'>izi</span><span style='letter-spacing:
  -.25pt'> </span><span style='letter-spacing:-.1pt'>kayıtları</span><span
  style='letter-spacing:-.65pt'> </span><span style='letter-spacing:-.1pt'>gibi)</span></span></p>
  </td>
 </tr>
</table>

</div>

<span lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection20>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:84.0pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph style='margin-top:11.9pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:17.05pt;
  margin-bottom:0in;margin-left:3.35pt;margin-bottom:.0001pt;line-height:115%'><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Talep/Şikayet<span
  style='letter-spacing:-.75pt'> </span>Yönetimi <span style='letter-spacing:
  -.1pt'>Bilgisi</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.9pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>Kimliği
  belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık<span
  style='letter-spacing:-.75pt'> </span>olan;<span style='letter-spacing:-.75pt'>
  </span>kısmen<span style='letter-spacing:-.75pt'> </span>veya<span
  style='letter-spacing:-.75pt'> </span>tamamen<span style='letter-spacing:
  -.75pt'> </span>otomatik<span style='letter-spacing:-.7pt'> </span>şekilde<span
  style='letter-spacing:-.65pt'> </span>veya<span style='letter-spacing:-.45pt'>
  </span>veri<span style='letter-spacing:-.75pt'> </span>kayıt sisteminin bir
  parçası olarak otomatik olmayan şekilde işlenen; DentisToday’e yöneltilmiş
  olan her türlü talep veya şikayetin alınması ve değerlendirilmesine ilişkin
  kişisel veriler</span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText style='margin-top:2.5pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.95pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
115%'><span lang=TR>DentisToday, Politika’da düzenlenmiş, hususlara ve Kanun’un
8. ve 9. maddelerine uygun olarak, Politika ile yönetilen veri sahiplerinin
kişisel verilerini aşağıda sıralanan kişi kategorilerine aktarılabilir:</span></p>

<p class=MsoListParagraph align=left style='margin-top:.2pt;margin-right:0in;
margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span lang=TR style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>DentisToday<span
style='letter-spacing:-.3pt'> </span>iş<span style='letter-spacing:-.2pt'> </span><span
style='letter-spacing:-.1pt'>ortaklarına,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span lang=TR style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>DentisToday<span
style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.1pt'>tedarikçilerine,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.0pt;margin-right:0in;
margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span lang=TR style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>DentisToday<span
style='letter-spacing:-.45pt'> </span>ile<span style='letter-spacing:-.15pt'> </span>çalışan<span
style='letter-spacing:-.4pt'> </span>diş<span style='letter-spacing:-.25pt'> </span>hekimleri,<span
style='letter-spacing:-.05pt'> </span>doktorlar<span style='letter-spacing:
-.1pt'> </span>ve sağlık<span style='letter-spacing:-.1pt'> kliniklerine,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span lang=TR style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>DentisToday<span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>hissedarlarına,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span lang=TR style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>DentisToday<span
style='letter-spacing:-.45pt'> </span>şirket<span style='letter-spacing:.25pt'>
</span><span style='letter-spacing:-.1pt'>yetkililerine,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.0pt;margin-right:0in;
margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span lang=TR style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Hukuken yetkili<span
style='letter-spacing:-.4pt'> </span>kamu<span style='letter-spacing:.05pt'> </span>kurum<span
style='letter-spacing:-.2pt'> </span>ve <span style='letter-spacing:-.1pt'>kuruluşlarına</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span lang=TR style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='font-size:12.0pt'>Hukuken<span
style='letter-spacing:.05pt'> </span>yetkili<span style='letter-spacing:-.35pt'>
</span>özel<span style='letter-spacing:-.15pt'> </span>hukuk<span
style='letter-spacing:.1pt'> </span><span style='letter-spacing:-.1pt'>kişilerine</span></span></p>

<p class=MsoBodyText style='margin-top:4.35pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-left:5.8pt;line-height:115%'><span lang=TR>Aktarımda<span
style='letter-spacing:-.1pt'> </span>bulunulan<span style='letter-spacing:-.05pt'>
</span>yukarıda<span style='letter-spacing:-.1pt'> </span>belirtilen<span
style='letter-spacing:-.3pt'> </span>kişilerin<span style='letter-spacing:-.3pt'>
</span>kapsamı<span style='letter-spacing:-.25pt'> </span>ve veri<span
style='letter-spacing:-.5pt'> </span>aktarım<span style='letter-spacing:-.5pt'>
</span>amaçları<span style='letter-spacing:-.5pt'> </span>aşağıda <span
style='letter-spacing:-.1pt'>belirtilmektedir.</span></span></p>

<p class=MsoBodyText style='margin-top:4.7pt'><span lang=TR style='font-size:
10.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:49.9pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:49.9pt'>
  <p class=TableParagraph align=center style='margin-top:.55pt;margin-right:
  0in;margin-bottom:0in;margin-left:.45pt;margin-bottom:.0001pt;text-align:
  center;line-height:15.8pt'><b><span lang=TR style='font-size:12.0pt'>Veri<span
  style='letter-spacing:-.75pt'> </span>Aktarımı <span style='letter-spacing:
  -.1pt'>Yapılabilecek Kişiler</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:49.9pt'>
  <p class=TableParagraph style='margin-top:4.0pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-left:2.55pt;text-align:
  center'><b><span lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>Tanımı</span></b></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:49.9pt'>
  <p class=TableParagraph style='margin-top:4.0pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:37.15pt'><b><span lang=TR
  style='font-size:12.0pt'>Veri<span style='letter-spacing:-.15pt'> </span>Aktarım<span
  style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.2pt'>Amacı</span></span></b></p>
  </td>
 </tr>
 <tr style='height:144.95pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:144.95pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:10.15pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:5.5pt'><b><span lang=TR
  style='font-size:12.0pt'>İş<span style='letter-spacing:-.2pt'> </span><span
  style='letter-spacing:-.1pt'>Ortağı</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:144.95pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.6pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>DentisToday’in<span
  style='letter-spacing:-.75pt'> </span>ticari<span style='letter-spacing:-.75pt'>
  </span>faaliyetlerini yürütürken bizzat veya Grup Şirketleri ile birlikte
  muhtelif projeler yürütmek, hizmet almak gibi<span style='letter-spacing:
  -.25pt'> </span>amaçlarla iş<span style='letter-spacing:-.15pt'> </span>ortaklığı<span
  style='letter-spacing:-.25pt'> </span>kurduğu tarafları tanımlamaktadır.</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:144.95pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.65pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>İş
  ortaklığının kurulma amaçlarının yerine getirilmesini <span style='letter-spacing:
  -.1pt'>temin</span><span style='letter-spacing:-.2pt'> </span><span
  style='letter-spacing:-.1pt'>etmek</span><span style='letter-spacing:-.15pt'>
  </span><span style='letter-spacing:-.1pt'>amacıyla</span><span
  style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>sınırlı</span><span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>olarak</span></span></p>
  </td>
 </tr>
 <tr style='height:113.25pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:113.25pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:8.1pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:5.5pt'><b><span lang=TR
  style='font-size:12.0pt;letter-spacing:-.1pt'>Tedarikçi</span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:113.25pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.2pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>DentisToday’in
  ticari faaliyetlerini yürütürken<span style='letter-spacing:-.75pt'> </span>DentisToday’in<span
  style='letter-spacing:-.75pt'> </span>emir<span style='letter-spacing:-.75pt'>
  </span>ve talimatlarına uygun olarak sözleşme temelli olarak DentisToday’e
  hizmet sunan tarafları tanımlamaktadır.</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:113.25pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.2pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>DentisToday’in
  tedarikçiden dış kaynaklı olarak temin ettiği ve DentisToday’in ticari
  faaliyetlerini yerine getirmek için gerekli <span style='letter-spacing:-.1pt'>hizmetlerin</span>               <span
  style='letter-spacing:-.1pt'>DentisToday’e </span>sunulmasını<span
  style='letter-spacing:2.45pt'>  </span>sağlamak<span style='letter-spacing:
  2.6pt'>  </span><span style='letter-spacing:-.1pt'>amacıyla</span></span></p>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify'><span
  lang=TR style='font-size:12.0pt'>sınırlı<span style='letter-spacing:-.45pt'> </span><span
  style='letter-spacing:-.1pt'>olarak.</span></span></p>
  </td>
 </tr>
</table>

</div>

<span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection21>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:97.45pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:97.45pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.45pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:5.5pt'><b><span lang=TR
  style='font-size:12.0pt;letter-spacing:-.1pt'>Hissedarlar</span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:97.45pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.35pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:116%'><span
  lang=TR style='font-size:12.0pt;line-height:116%;letter-spacing:-.1pt'>DentisToday’in</span><span
  lang=TR style='font-size:12.0pt;line-height:116%'>          <span
  style='letter-spacing:-.3pt'>ve</span>          <span style='letter-spacing:
  -.2pt'>Grup </span>Şirketler’in hissedarı<span style='letter-spacing:-.45pt'>
  </span>gerçek<span style='letter-spacing:-.05pt'> </span><span
  style='letter-spacing:-.1pt'>kişiler</span></span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:97.45pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.65pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>İlgili
  mevzuat hükümlerine göre DentisToday’in şirketler hukuku, etkinlik yönetimi
  ve kurumsal iletişim süreçleri kapsamında yürüttüğü faaliyetlerin <span
  style='letter-spacing:-.1pt'>amaçlarıyla</span></span></p>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify'><span
  lang=TR style='font-size:12.0pt'>sınırlı<span style='letter-spacing:-.55pt'> </span><span
  style='letter-spacing:-.1pt'>olarak</span></span></p>
  </td>
 </tr>
 <tr style='height:110.85pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:110.85pt'>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span lang=TR style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:7.15pt'><span lang=TR
  style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:5.5pt'><b><span lang=TR
  style='font-size:12.0pt'>Şirket<span style='letter-spacing:-.25pt'> </span><span
  style='letter-spacing:-.1pt'>Yetkilileri</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:110.85pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:5.25pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>DentisToday
  yönetim kurulu üyeleri ve diğer yetkili gerçek <span style='letter-spacing:
  -.1pt'>kişiler</span></span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:110.85pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.4pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>İlgili
  mevzuat hükümlerine göre DentisToday’in<span style='letter-spacing:-.75pt'> </span>ticari<span
  style='letter-spacing:-.75pt'> </span>faaliyetlerine ilişkin<span
  style='letter-spacing:-.05pt'> </span>stratejilerin<span style='letter-spacing:
  -.25pt'> </span>tasarlanması, en üst düzeyde yönetiminin sağlanması<span
  style='letter-spacing:-.25pt'> </span>ve<span style='letter-spacing:-.1pt'> </span>denetim<span
  style='letter-spacing:-.25pt'> </span>amaçlarıyla sınırlı olarak</span></p>
  </td>
 </tr>
 <tr style='height:65.75pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:65.75pt'>
  <p class=TableParagraph style='margin-top:9.9pt;margin-right:3.8pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><b><span lang=TR style='font-size:12.0pt;line-height:115%'>Hukuken<span
  style='letter-spacing:-.3pt'> </span>Yetkili Kamu<span style='letter-spacing:
  -.75pt'> </span>Kurum<span style='letter-spacing:-.75pt'> </span>ve <span
  style='letter-spacing:-.1pt'>Kuruluşları</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:65.75pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.8pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>İlgili
  mevzuat hükümlerine göre DentisToday’den bilgi ve belge almaya<span
  style='letter-spacing:3.65pt'> </span>yetkili<span style='letter-spacing:
  3.35pt'> </span>kamu<span style='letter-spacing:3.5pt'> </span>kurum<span
  style='letter-spacing:3.35pt'> </span><span style='letter-spacing:-.25pt'>ve</span></span></p>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.55pt'><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>kuruluşları</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:65.75pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.65pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>İlgili
  kamu kurum ve kuruluşlarının hukuki yetkisi dahilinde<span style='letter-spacing:
  .05pt'> </span>talep<span style='letter-spacing:.1pt'> </span>ettiği<span
  style='letter-spacing:-.3pt'> </span>amaçla<span style='letter-spacing:.1pt'>
  </span><span style='letter-spacing:-.1pt'>sınırlı</span></span></p>
  <p class=TableParagraph style='margin-left:5.25pt;line-height:13.55pt'><span
  lang=TR style='font-size:12.0pt;letter-spacing:-.1pt'>olarak</span></p>
  </td>
 </tr>
 <tr style='height:52.3pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:52.3pt'>
  <p class=TableParagraph style='margin-top:3.15pt;margin-right:5.3pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  lang=TR style='font-size:12.0pt;line-height:115%'>Hukuken<span
  style='letter-spacing:-.75pt'> </span>Yetkili Özel Hukuk <span
  style='letter-spacing:-.1pt'>Kişileri</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:52.3pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.8pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>İlgili
  mevzuat hükümlerine göre DentisToday’den bilgi ve belge almaya yetkili özel
  hukuk kişileri</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:52.3pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.65pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%'>İlgili<span
  style='letter-spacing:-.55pt'> </span>özel<span style='letter-spacing:-.75pt'>
  </span>hukuk<span style='letter-spacing:-.4pt'> </span>kişilerinin<span
  style='letter-spacing:-.2pt'> </span>hukuki yetkisi dahilinde talep ettiği
  amaçla sınırlı olarak</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal><span lang=TR>&nbsp;</span></p>

</div>

</body>

</html>
  `

  const en = `
  <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Helvetica;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:Courier;
	panose-1:2 7 4 9 2 2 5 2 4 4;}
@font-face
	{font-family:"Tms Rmn";
	panose-1:2 2 6 3 4 5 5 2 3 4;}
@font-face
	{font-family:Helv;
	panose-1:2 11 6 4 2 2 2 3 2 4;}
@font-face
	{font-family:"New York";
	panose-1:2 4 5 3 6 5 6 2 3 4;}
@font-face
	{font-family:System;
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:Batang;
	panose-1:2 3 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:PMingLiU;
	panose-1:2 2 5 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Gothic";
	panose-1:2 11 6 9 7 2 5 8 2 4;}
@font-face
	{font-family:Dotum;
	panose-1:2 11 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:SimHei;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:MingLiU;
	panose-1:2 2 5 9 0 0 0 0 0 0;}
@font-face
	{font-family:Mincho;
	panose-1:2 2 6 9 4 3 5 8 3 5;}
@font-face
	{font-family:Gulim;
	panose-1:2 11 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:Century;
	panose-1:2 4 6 4 5 5 5 2 3 4;}
@font-face
	{font-family:"Angsana New";
	panose-1:2 2 6 3 5 4 5 2 3 4;}
@font-face
	{font-family:"Cordia New";
	panose-1:2 11 3 4 2 2 2 2 2 4;}
@font-face
	{font-family:Mangal;
	panose-1:2 4 5 3 5 2 3 3 2 2;}
@font-face
	{font-family:Latha;
	panose-1:2 0 4 0 0 0 0 0 0 0;}
@font-face
	{font-family:Sylfaen;
	panose-1:1 10 5 2 5 3 6 3 3 3;}
@font-face
	{font-family:Vrinda;
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:Raavi;
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:Shruti;
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:Sendnya;
	panose-1:0 0 4 0 0 0 0 0 0 0;}
@font-face
	{font-family:Gautami;
	panose-1:2 0 5 0 0 0 0 0 0 0;}
@font-face
	{font-family:Tunga;
	panose-1:0 0 4 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Estrangelo Edessa";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Yu Gothic";
	panose-1:2 11 4 0 0 0 0 0 0 0;}
@font-face
	{font-family:DengXian;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Calibri Light";
	panose-1:2 15 3 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Palatino Linotype";
	panose-1:2 4 5 2 5 5 5 3 3 4;}
@font-face
	{font-family:Verdana;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"DengXian Light";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Segoe UI Emoji";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:Cambria;
	panose-1:2 4 5 3 5 4 6 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
h1
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:34.15pt;
	text-indent:-28.35pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
h2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:62.45pt;
	text-align:justify;
	text-indent:-42.5pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.35pt;
	text-indent:-79.95pt;
	text-autospace:none;
	font-size:22.0pt;
	font-family:"Times New Roman",serif;
	font-weight:bold;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin:0in;
	text-align:justify;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:41.8pt;
	text-align:justify;
	text-indent:-21.4pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.TableParagraph, li.TableParagraph, div.TableParagraph
	{mso-style-name:"Table Paragraph";
	margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
span.msoDel
	{mso-style-name:"";
	text-decoration:line-through;
	color:red;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{text-autospace:none;}
 /* Page Definitions */
 @page WordSection1
	{size:595.5pt 842.0pt;
	margin:96.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection3
	{page:WordSection3;}
@page WordSection4
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection4
	{page:WordSection4;}
@page WordSection5
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection5
	{page:WordSection5;}
@page WordSection6
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection6
	{page:WordSection6;}
@page WordSection7
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection7
	{page:WordSection7;}
@page WordSection8
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection8
	{page:WordSection8;}
@page WordSection9
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection9
	{page:WordSection9;}
@page WordSection10
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection10
	{page:WordSection10;}
@page WordSection11
	{size:595.5pt 842.0pt;
	margin:82.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection11
	{page:WordSection11;}
@page WordSection12
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection12
	{page:WordSection12;}
@page WordSection13
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection13
	{page:WordSection13;}
@page WordSection14
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection14
	{page:WordSection14;}
@page WordSection15
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection15
	{page:WordSection15;}
@page WordSection16
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection16
	{page:WordSection16;}
@page WordSection17
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection17
	{page:WordSection17;}
@page WordSection18
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection18
	{page:WordSection18;}
@page WordSection19
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection19
	{page:WordSection19;}
@page WordSection20
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection20
	{page:WordSection20;}
@page WordSection21
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection21
	{page:WordSection21;}
@page WordSection22
	{size:595.5pt 842.0pt;
	margin:69.0pt 65.0pt 59.0pt 65.0pt;}
div.WordSection22
	{page:WordSection22;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoBodyText align=left style='text-align:left'><span style='font-size:
22.0pt'>&nbsp;</span></p>

<p class=MsoBodyText align=left style='text-align:left'><span style='font-size:
22.0pt'>&nbsp;</span></p>

<p class=MsoBodyText align=left style='text-align:left'><span style='font-size:
22.0pt'>&nbsp;</span></p>

<p class=MsoBodyText align=left style='margin-top:15.4pt;text-align:left'><span
style='font-size:22.0pt'>&nbsp;</span></p>

<h1>
PERSONAL DATA PROTECTION AND PROCESSING POLICY
</h1>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:10.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='margin-top:10.95pt;text-align:left'>

<table cellpadding=0 cellspacing=0 align=center>
 <tr>
  <td height=0></td>
 </tr>
 <tr>
  <td></td>
  <td><img width=184 height=150  src="${logo}"></td>
 </tr>
</table>

<br clear=ALL>
</p>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='margin-top:8.1pt;text-align:left'><b><span
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoTitle align=center style='margin-right:.15pt;text-align:center;
text-indent:0in'><span style='letter-spacing:-.1pt'>DENTISTODAY</span></p>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='text-align:left'><b><span
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText align=left style='margin-top:11.0pt;text-align:left'><b><span
style='font-size:22.0pt'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-top:.05pt;margin-right:0in;
margin-bottom:0in;margin-left:.35pt;margin-bottom:.0001pt;text-align:center'><b><span
style='font-size:12.0pt'>2024,<span style='letter-spacing:.1pt'> </span><span
style='letter-spacing:-.1pt'>April</span></span></b></p>

</div>

<span style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection2>

<h1 style='margin-top:3.7pt'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>PURPOSE<span style='letter-spacing:-.3pt'> </span>AND<span
style='letter-spacing:-.1pt'> </span><span style='letter-spacing:-.2pt'>SCOPE</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.0pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>We, as
DentisToday pay uttermost attention to the protection of personal data, which
is a Constitutional right, of all real persons that we are in contact with
during our activities, including our customers, employees, visitors and
business partners and to the compliance of regulations stated under the
Protection of Personal Data Law numbered 6698.</p>

<p class=MsoBodyText align=left style='margin-top:2.2pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Accordingly, we,
in the capacity of the Data Controller, take all kinds of technical and
administrative<span style='letter-spacing:-.75pt'> </span>measures<span
style='letter-spacing:-.75pt'> </span>required<span style='letter-spacing:-.75pt'>
</span>for<span style='letter-spacing:-.7pt'> </span>the<span style='letter-spacing:
-.75pt'> </span>purpose<span style='letter-spacing:-.7pt'> </span>of<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>prevention<span
style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>illegal<span
style='letter-spacing:-.75pt'> </span>process<span style='letter-spacing:-.75pt'>
</span>of<span style='letter-spacing:-.55pt'> </span>personal data, illegal
access to the same and ensuring the retention of the personal data.</p>

<p class=MsoBodyText align=left style='margin-top:2.2pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.65pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>We,<span
style='letter-spacing:-.75pt'> </span>as<span style='letter-spacing:-.75pt'> </span>DentisToday,<span
style='letter-spacing:-.75pt'> </span>aim<span style='letter-spacing:-.75pt'> </span>to<span
style='letter-spacing:-.75pt'> </span>inform<span style='letter-spacing:-.75pt'>
</span>you<span style='letter-spacing:-.75pt'> </span>with<span
style='letter-spacing:-.75pt'> </span>this<span style='letter-spacing:-.75pt'> </span>Protection<span
style='letter-spacing:-.75pt'> </span>and<span style='letter-spacing:-.75pt'> </span>Process<span
style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>Personal<span
style='letter-spacing:-.75pt'> </span>Data<span style='letter-spacing:-.75pt'> </span>Policy
on<span style='letter-spacing:-.75pt'> </span>our<span style='letter-spacing:
-.6pt'> </span>collection,<span style='letter-spacing:-.3pt'> </span>usage,<span
style='letter-spacing:-.3pt'> </span>process,<span style='letter-spacing:-.55pt'>
</span>transfer,<span style='letter-spacing:-.3pt'> </span>retention<span
style='letter-spacing:-.65pt'> </span>processes<span style='letter-spacing:
-.5pt'> </span>and<span style='letter-spacing:-.05pt'> </span>systems<span
style='letter-spacing:-.5pt'> </span>of<span style='letter-spacing:-.75pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.45pt'> </span>and
our fundamental principles in this regard.</p>

<p class=MsoBodyText align=left style='text-align:left'>&nbsp;</p>

<p class=MsoBodyText align=left style='margin-top:4.25pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph align=left style='margin-left:34.15pt;text-align:
left;text-indent:-28.35pt'><b><span style='font-size:12.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;letter-spacing:-.1pt'>DEFINITIONS</span></b></p>

<p class=MsoBodyText align=left style='margin-top:6.55pt;text-align:left'><b><span
style='font-size:10.0pt'>&nbsp;</span></b></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:16.05pt'>
  <td width=163 valign=top style='width:122.2pt;border:solid black 1.0pt;
  border-right:none;padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>DentisToday</span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border-top:solid black 1.0pt;
  border-left:none;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Aıdent<span style='letter-spacing:.1pt'> </span>Sağlık<span
  style='letter-spacing:.05pt'> </span>ve<span style='letter-spacing:-.2pt'> </span>Diş<span
  style='letter-spacing:-.25pt'> </span>Teknolojileri<span style='letter-spacing:
  -.4pt'> </span>Anonim<span style='letter-spacing:-.5pt'> </span><span
  style='letter-spacing:-.1pt'>Şirketi</span></span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Explicit<span style='letter-spacing:-.2pt'> </span><span
  style='letter-spacing:-.1pt'>Consent</span></span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Consent<span style='letter-spacing:.6pt'> </span>that<span
  style='letter-spacing:.8pt'> </span>is<span style='letter-spacing:.5pt'> </span>declared<span
  style='letter-spacing:.6pt'> </span>on<span style='letter-spacing:.4pt'> </span>a<span
  style='letter-spacing:.55pt'> </span>specific<span style='letter-spacing:
  .75pt'> </span>subject,<span style='letter-spacing:.7pt'> </span>by<span
  style='letter-spacing:.15pt'> </span>basing<span style='letter-spacing:.85pt'>
  </span>it<span style='letter-spacing:.6pt'> </span><span style='letter-spacing:
  -.25pt'>on</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.85pt;margin-bottom:.0001pt'><span style='font-size:12.0pt'>the<span
  style='letter-spacing:-.15pt'> </span>information<span style='letter-spacing:
  -.2pt'> </span>provided<span style='letter-spacing:-.1pt'> </span>and<span
  style='letter-spacing:-.1pt'> </span>with<span style='letter-spacing:-.1pt'> </span>free<span
  style='letter-spacing:-.1pt'> </span><span style='letter-spacing:-.2pt'>will.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Anonymization</span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>Rendering<span style='letter-spacing:
  4.0pt'> </span>personal<span style='letter-spacing:2.0pt'> </span>data<span
  style='letter-spacing:4.0pt'> </span>associated<span style='letter-spacing:
  4.0pt'> </span>with<span style='letter-spacing:2.0pt'> </span>an<span
  style='letter-spacing:4.0pt'> </span>identified<span style='letter-spacing:
  4.0pt'> </span>or identifiable<span style='letter-spacing:2.8pt'> </span>real<span
  style='letter-spacing:2.75pt'> </span>person,<span style='letter-spacing:
  3.05pt'> </span>under<span style='letter-spacing:3.1pt'> </span>any<span
  style='letter-spacing:2.7pt'> </span>circumstances,<span style='letter-spacing:
  3.05pt'> </span>even<span style='letter-spacing:3.0pt'> </span><span
  style='letter-spacing:-.25pt'>by</span></span></p>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.75pt'><span
  style='font-size:12.0pt'>matching<span style='letter-spacing:-.05pt'> </span>the<span
  style='letter-spacing:-.05pt'> </span>same<span style='letter-spacing:-.05pt'>
  </span>with<span style='letter-spacing:-.2pt'> </span>other<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>data.</span></span></p>
  </td>
 </tr>
 <tr style='height:79.4pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:79.4pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Application<span style='letter-spacing:-.15pt'> </span><span
  style='letter-spacing:-.2pt'>Form</span></span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.4pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.4pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.9pt;margin-bottom:
  0in;margin-left:5.85pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>“Application Form
  regarding the Applications to be Made by the Person Concerned (Data Owner) to
  the Data Controller as per<span style='letter-spacing:-.05pt'> </span>the
  Protection<span style='letter-spacing:-.1pt'> </span>of<span
  style='letter-spacing:-.25pt'> </span>Personal<span style='letter-spacing:
  -.35pt'> </span>Data Law Numbered 6698”<span style='letter-spacing:-.4pt'> </span>that
  includes<span style='letter-spacing:1.7pt'> </span>the<span style='letter-spacing:
  1.9pt'> </span>application<span style='letter-spacing:1.7pt'> </span>of<span
  style='letter-spacing:1.55pt'> </span>personal<span style='letter-spacing:
  1.5pt'> </span>data<span style='letter-spacing:1.65pt'> </span>owners<span
  style='letter-spacing:1.85pt'> </span>to<span style='letter-spacing:2.0pt'> </span><span
  style='letter-spacing:-.1pt'>exercise</span></span></p>
  <p class=TableParagraph style='margin-left:5.85pt;text-align:justify'><span
  style='font-size:12.0pt'>their<span style='letter-spacing:-.3pt'> </span><span
  style='letter-spacing:-.1pt'>rights.</span></span></p>
  </td>
 </tr>
 <tr style='height:15.85pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Employee</span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>DentisToday<span style='letter-spacing:-.5pt'> </span><span
  style='letter-spacing:-.1pt'>personnel.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.7pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Employee<span style='letter-spacing:-.3pt'> </span><span
  style='letter-spacing:-.1pt'>Candidate</span></span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>Real<span style='letter-spacing:
  2.0pt'> </span>persons<span style='letter-spacing:2.0pt'> </span>who<span
  style='letter-spacing:2.0pt'> </span>made<span style='letter-spacing:2.0pt'> </span>job<span
  style='letter-spacing:2.0pt'> </span>applications<span style='letter-spacing:
  2.0pt'> </span>by<span style='letter-spacing:2.0pt'> </span>any<span
  style='letter-spacing:2.0pt'> </span>means<span style='letter-spacing:2.0pt'>
  </span>to DentisToday<span style='letter-spacing:1.75pt'> </span>and<span
  style='letter-spacing:2.5pt'> </span>who<span style='letter-spacing:2.55pt'> </span>provided<span
  style='letter-spacing:2.55pt'> </span>her/his<span style='letter-spacing:
  2.45pt'> </span>CV<span style='letter-spacing:2.3pt'> </span>and<span
  style='letter-spacing:2.55pt'> </span>the<span style='letter-spacing:2.3pt'> </span><span
  style='letter-spacing:-.1pt'>related</span></span></p>
  <p class=TableParagraph style='margin-left:5.85pt'><span style='font-size:
  12.0pt'>information<span style='letter-spacing:-.4pt'> </span>to<span
  style='letter-spacing:-.1pt'> </span>our<span style='letter-spacing:-.25pt'> </span>Company’s<span
  style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>assessment.</span></span></p>
  </td>
 </tr>
 <tr style='height:15.8pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Destruction</span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Erasure,<span style='letter-spacing:.1pt'> </span>destruction<span
  style='letter-spacing:-.25pt'> </span>or<span style='letter-spacing:-.15pt'> </span>anonymization<span
  style='letter-spacing:-.2pt'> </span>of<span style='letter-spacing:-.4pt'> </span>personal<span
  style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>data.</span></span></p>
  </td>
 </tr>
 <tr style='height:63.35pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Business<span style='letter-spacing:-.15pt'> </span><span
  style='letter-spacing:-.1pt'>Partner</span></span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:5.05pt;margin-bottom:
  0in;margin-left:5.85pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>Parties with which
  DentisToday, establishes a business partnership for the purposes such as
  conducting various projects,<span style='letter-spacing:1.55pt'>  </span>while<span
  style='letter-spacing:1.55pt'>  </span>DentisToday<span style='letter-spacing:
  1.4pt'>  </span>carries<span style='letter-spacing:1.5pt'>  </span>out<span
  style='letter-spacing:1.75pt'>  </span>its<span style='letter-spacing:1.55pt'> 
  </span><span style='letter-spacing:-.1pt'>commercial</span></span></p>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.75pt'><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>activities.</span></p>
  </td>
 </tr>
 <tr style='height:16.05pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>LAW/PPDL/KVKK</span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:16.05pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Protection<span style='letter-spacing:-.25pt'> </span>of<span
  style='letter-spacing:-.35pt'> </span>Personal<span style='letter-spacing:
  -.4pt'> </span>Data Law Numbered<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.2pt'>6698</span></span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Personal<span style='letter-spacing:-.4pt'> </span><span
  style='letter-spacing:-.2pt'>Data</span></span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>All<span style='letter-spacing:-.3pt'> </span>kinds<span
  style='letter-spacing:-.05pt'> </span>of<span style='letter-spacing:-.05pt'> </span>information<span
  style='letter-spacing:-.2pt'> </span>regarding<span style='letter-spacing:
  .05pt'> </span>an<span style='letter-spacing:.1pt'> </span>identified<span
  style='letter-spacing:.05pt'> </span>or<span style='letter-spacing:.15pt'> </span><span
  style='letter-spacing:-.1pt'>identifiable</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.85pt;margin-bottom:.0001pt'><span style='font-size:12.0pt'>real<span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>person.</span></span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=163 valign=top style='width:122.2pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Processing<span style='letter-spacing:-.3pt'> </span><span
  style='letter-spacing:-.1pt'>Personal</span></span></b></p>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span style='font-size:12.0pt;
  letter-spacing:-.2pt'>Data</span></b></p>
  </td>
  <td width=26 valign=top style='width:19.35pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=right style='margin-right:5.4pt;text-align:
  right;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.85pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Any<span style='letter-spacing:.4pt'> </span>operation<span
  style='letter-spacing:.55pt'> </span>performed<span style='letter-spacing:
  .8pt'> </span>on<span style='letter-spacing:.55pt'> </span>personal<span
  style='letter-spacing:.35pt'> </span>data<span style='letter-spacing:.75pt'> </span>such<span
  style='letter-spacing:.55pt'> </span>as<span style='letter-spacing:.7pt'> </span><span
  style='letter-spacing:-.1pt'>collection,</span></span></p>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.85pt;margin-bottom:.0001pt'><span style='font-size:12.0pt;
  letter-spacing:-.1pt'>recording,</span><span style='font-size:12.0pt'>    <span
  style='letter-spacing:-.1pt'>storage,</span>    <span style='letter-spacing:
  -.1pt'>retention,</span>    <span style='letter-spacing:-.1pt'>alteration,</span>    re-<span
  style='letter-spacing:-.1pt'>organization,</span></span></p>
  </td>
 </tr>
</table>

</div>

<span style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection3>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:63.6pt'>
  <td width=604 colspan=3 valign=top style='width:453.25pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.6pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.9pt;margin-bottom:
  0in;margin-left:147.15pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>disclosure,
  transferring, taking over, making available, classification or preventing the
  use thereof, fully or partially through<span style='letter-spacing:-.15pt'> </span>automatic<span
  style='letter-spacing:.3pt'> </span>means<span style='letter-spacing:-.05pt'>
  </span>or<span style='letter-spacing:.2pt'> </span>provided<span
  style='letter-spacing:.1pt'> </span>that<span style='letter-spacing:.1pt'> </span>the<span
  style='letter-spacing:.1pt'> </span>process<span style='letter-spacing:.2pt'>
  </span>is a<span style='letter-spacing:.1pt'> </span><span style='letter-spacing:
  -.2pt'>part</span></span></p>
  <p class=TableParagraph style='margin-left:147.15pt;text-align:justify;
  line-height:13.55pt'><span style='font-size:12.0pt'>of<span style='letter-spacing:
  -.45pt'> </span>any<span style='letter-spacing:-.2pt'> </span>data<span
  style='letter-spacing:.05pt'> </span>registry<span style='letter-spacing:
  -.45pt'> </span>system,<span style='letter-spacing:.2pt'> </span>through<span
  style='letter-spacing:-.2pt'> </span>non-automatic<span style='letter-spacing:
  .25pt'> </span><span style='letter-spacing:-.1pt'>means.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:115%'><b><span
  style='font-size:12.0pt;line-height:115%'>Erasure<span style='letter-spacing:
  -.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>Personal <span
  style='letter-spacing:-.2pt'>Data</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Erasure<span style='letter-spacing:.6pt'> </span>of<span
  style='letter-spacing:.55pt'> </span>personal<span style='letter-spacing:
  .5pt'> </span>data<span style='letter-spacing:.9pt'> </span>is<span
  style='letter-spacing:.8pt'> </span>rendering<span style='letter-spacing:
  .95pt'> </span>the<span style='letter-spacing:.9pt'> </span>same<span
  style='letter-spacing:.85pt'> </span>that<span style='letter-spacing:.95pt'> </span>cannot<span
  style='letter-spacing:.95pt'> </span><span style='letter-spacing:-.25pt'>be</span></span></p>
  <p class=TableParagraph style='margin-top:.35pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;line-height:15.5pt'><span
  style='font-size:12.0pt'>accessed<span style='letter-spacing:1.95pt'> </span>to<span
  style='letter-spacing:1.95pt'> </span>and<span style='letter-spacing:1.95pt'>
  </span>cannot<span style='letter-spacing:1.95pt'> </span>be<span
  style='letter-spacing:1.9pt'> </span>used<span style='letter-spacing:1.95pt'>
  </span>by<span style='letter-spacing:1.45pt'> </span>the<span
  style='letter-spacing:1.9pt'> </span>related<span style='letter-spacing:1.95pt'>
  </span>users<span style='letter-spacing:2.0pt'> </span>in<span
  style='letter-spacing:1.7pt'> </span>any <span style='letter-spacing:-.1pt'>means.</span></span></p>
  </td>
 </tr>
 <tr style='height:31.9pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.9pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Destruction<span style='letter-spacing:-.1pt'> </span><span
  style='letter-spacing:-.25pt'>of</span></span></b></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span style='font-size:12.0pt'>Personal<span
  style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.2pt'>Data</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.9pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.9pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Destruction<span style='letter-spacing:.15pt'> </span>of
  personal data<span style='letter-spacing:.6pt'> </span>is<span
  style='letter-spacing:.3pt'> </span>rendering<span style='letter-spacing:
  .4pt'> </span>the<span style='letter-spacing:.35pt'> </span>same<span
  style='letter-spacing:.4pt'> </span>that<span style='letter-spacing:.65pt'> </span><span
  style='letter-spacing:-.1pt'>cannot</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:12.0pt'>be<span
  style='letter-spacing:-.15pt'> </span>reached,<span style='letter-spacing:
  .25pt'> </span>restored<span style='letter-spacing:.05pt'> </span>and<span
  style='letter-spacing:.05pt'> </span>re-used<span style='letter-spacing:.05pt'>
  </span>by<span style='letter-spacing:-.45pt'> </span>any<span
  style='letter-spacing:-.45pt'> </span>person<span style='letter-spacing:.05pt'>
  </span>by<span style='letter-spacing:-.2pt'> </span>any<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.1pt'>means.</span></span></p>
  </td>
 </tr>
 <tr style='height:63.3pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:63.3pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:28.6pt;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  style='font-size:12.0pt;line-height:115%'>Anonymization<span
  style='letter-spacing:-.75pt'> </span>of Personal Data</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.3pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.3pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.85pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>Anonymization of
  personal data is rendering the same that cannot be associated with an
  identified or identifiable real person,<span style='letter-spacing:1.05pt'> </span>under<span
  style='letter-spacing:.9pt'> </span>any<span style='letter-spacing:.4pt'> </span>circumstances,<span
  style='letter-spacing:1.0pt'> </span>even<span style='letter-spacing:.85pt'> </span>by<span
  style='letter-spacing:.65pt'> </span>matching<span style='letter-spacing:
  1.15pt'> </span>the<span style='letter-spacing:.85pt'> </span><span
  style='letter-spacing:-.2pt'>same</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;text-align:justify;
  line-height:13.7pt'><span style='font-size:12.0pt'>with<span
  style='letter-spacing:-.2pt'> </span>other<span style='letter-spacing:.1pt'> </span><span
  style='letter-spacing:-.1pt'>data.</span></span></p>
  </td>
 </tr>
 <tr style='height:15.85pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Board</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.85pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Protection<span style='letter-spacing:-.1pt'> </span>of<span
  style='letter-spacing:-.3pt'> </span>Personal<span style='letter-spacing:
  -.3pt'> </span>Data<span style='letter-spacing:.15pt'> </span><span
  style='letter-spacing:-.2pt'>Board</span></span></p>
  </td>
 </tr>
 <tr style='height:15.8pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Authority</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:15.8pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>Protection<span style='letter-spacing:-.1pt'> </span>of<span
  style='letter-spacing:-.3pt'> </span>Personal<span style='letter-spacing:
  -.3pt'> </span>Data<span style='letter-spacing:.15pt'> </span><span
  style='letter-spacing:-.1pt'>Authority</span></span></p>
  </td>
 </tr>
 <tr style='height:79.4pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:79.4pt'>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span style='font-size:12.0pt;
  letter-spacing:-.1pt'>Customer</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.4pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.4pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.8pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>Regardless<span
  style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>whether<span
  style='letter-spacing:-.75pt'> </span>there<span style='letter-spacing:-.75pt'>
  </span>is<span style='letter-spacing:-.75pt'> </span>any<span
  style='letter-spacing:-.75pt'> </span>contractual<span style='letter-spacing:
  -.75pt'> </span>relationship<span style='letter-spacing:-.75pt'> </span>with
  DentisToday, real persons whose personal data has been obtained through the
  business relationship of DentisToday within<span style='letter-spacing:-.1pt'>
  </span>the<span style='letter-spacing:.2pt'> </span>scope<span
  style='letter-spacing:-.05pt'> </span>of<span style='letter-spacing:-.35pt'> </span>operations<span
  style='letter-spacing:.1pt'> </span>conducted by<span style='letter-spacing:
  -.25pt'> </span>the<span style='letter-spacing:.2pt'> </span>business<span
  style='letter-spacing:.15pt'> </span><span style='letter-spacing:-.1pt'>units</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;text-align:justify;
  line-height:13.7pt'><span style='font-size:12.0pt'>of<span style='letter-spacing:
  -.1pt'> DentisToday.</span></span></p>
  </td>
 </tr>
 <tr style='height:63.55pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:63.55pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:27.05pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  style='font-size:12.0pt;line-height:115%'>User / Patient / Patient<span
  style='letter-spacing:-.75pt'> </span>Candidate</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.55pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.55pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.85pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>Reals<span
  style='letter-spacing:-.75pt'> </span>persons<span style='letter-spacing:
  -.75pt'> </span>whose<span style='letter-spacing:-.75pt'> </span>personal<span
  style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.75pt'>
  </span>has<span style='letter-spacing:-.75pt'> </span>been<span
  style='letter-spacing:-.75pt'> </span>obtained<span style='letter-spacing:
  -.75pt'> </span>within<span style='letter-spacing:-.75pt'> </span>the scope
  of operations conducted by business units by entering into<span
  style='letter-spacing:3.1pt'> </span>activity<span style='letter-spacing:
  2.8pt'> </span>with<span style='letter-spacing:2.8pt'> </span>DentisToday<span
  style='letter-spacing:2.5pt'> </span>or<span style='letter-spacing:3.1pt'> </span>by<span
  style='letter-spacing:2.55pt'> </span>using<span style='letter-spacing:3.3pt'>
  </span><span style='letter-spacing:-.1pt'>DentisToday</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.55pt'><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>application</span></p>
  </td>
 </tr>
 <tr style='height:79.15pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:79.15pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:27.05pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  style='font-size:12.0pt;line-height:115%'>Personal<span style='letter-spacing:
  -.75pt'> </span>Data<span style='letter-spacing:-.75pt'> </span>of Special
  Nature</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.15pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:79.15pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:5.0pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>Data<span
  style='letter-spacing:-.45pt'> </span>of<span style='letter-spacing:-.55pt'> </span>persons<span
  style='letter-spacing:-.25pt'> </span>related<span style='letter-spacing:
  -.2pt'> </span>to<span style='letter-spacing:-.2pt'> </span>race,<span
  style='letter-spacing:-.3pt'> </span>ethnic<span style='letter-spacing:-.25pt'>
  </span>origin,<span style='letter-spacing:-.1pt'> </span>political<span
  style='letter-spacing:-.6pt'> </span>opinion, philosophical belief, religion,
  sect or other belief, clothing, membership to associations, foundations or
  trade-unions, health,<span style='letter-spacing:.3pt'> </span>sexual<span
  style='letter-spacing:.35pt'> </span>life,<span style='letter-spacing:.4pt'> </span>convictions<span
  style='letter-spacing:.45pt'> </span>and<span style='letter-spacing:.3pt'> </span>security<span
  style='letter-spacing:.1pt'> </span>measures,<span style='letter-spacing:
  .4pt'> </span>and<span style='letter-spacing:.35pt'> </span><span
  style='letter-spacing:-.25pt'>the</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;text-align:justify;
  line-height:13.7pt'><span style='font-size:12.0pt'>biometric<span
  style='letter-spacing:-.25pt'> </span>and<span style='letter-spacing:-.15pt'>
  </span>genetic<span style='letter-spacing:-.2pt'> data.</span></span></p>
  </td>
 </tr>
 <tr style='height:95.3pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:95.3pt'>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span style='font-size:12.0pt'>Periodical<span
  style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>Destruction</span></span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:95.3pt'>
  <p class=TableParagraph align=center style='margin-top:.1pt;margin-right:
  0in;margin-bottom:0in;margin-left:.55pt;margin-bottom:.0001pt;text-align:
  center'><b><span style='font-size:12.0pt;letter-spacing:-.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:95.3pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.95pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>In the event where the
  conditions of processing personal data specified<span style='letter-spacing:
  -.2pt'> </span>under<span style='letter-spacing:-.15pt'> </span>the<span
  style='letter-spacing:-.25pt'> </span>Law<span style='letter-spacing:-.25pt'>
  </span>are<span style='letter-spacing:-.25pt'> </span>fully<span
  style='letter-spacing:-.6pt'> </span>disappeared,<span style='letter-spacing:
  -.1pt'> </span>the<span style='letter-spacing:-.5pt'> </span>operation<span
  style='letter-spacing:-.45pt'> </span>of the erasure,<span style='letter-spacing:
  -.05pt'> </span>destruction<span style='letter-spacing:-.15pt'> </span>or<span
  style='letter-spacing:-.1pt'> </span>anonymization<span style='letter-spacing:
  -.15pt'> </span>of<span style='letter-spacing:-.25pt'> </span>personal<span
  style='letter-spacing:-.35pt'> </span>data<span style='letter-spacing:-.15pt'>
  </span>that is<span style='letter-spacing:-.7pt'> </span>specified<span
  style='letter-spacing:-.55pt'> </span>under<span style='letter-spacing:-.45pt'>
  </span>the<span style='letter-spacing:-.6pt'> </span>retention<span
  style='letter-spacing:-.75pt'> </span>and<span style='letter-spacing:-.55pt'>
  </span>destruction<span style='letter-spacing:-.75pt'> </span>of<span
  style='letter-spacing:-.75pt'> </span>personal<span style='letter-spacing:
  -.75pt'> </span>data policy<span style='letter-spacing:3.95pt'> </span>and<span
  style='letter-spacing:2.75pt'> </span>that<span style='letter-spacing:3.0pt'>
  </span>shall<span style='letter-spacing:2.8pt'> </span>be<span
  style='letter-spacing:2.65pt'> </span>realized<span style='letter-spacing:
  2.75pt'> </span>ex<span style='letter-spacing:2.5pt'> </span>officio<span
  style='letter-spacing:3.0pt'> </span>at<span style='letter-spacing:2.75pt'> </span><span
  style='letter-spacing:-.1pt'>repetitive</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.65pt'><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>intervals.</span></p>
  </td>
 </tr>
 <tr style='height:31.85pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Policy</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>This<span style='letter-spacing:1.3pt'> </span>protection<span
  style='letter-spacing:1.3pt'> </span>and<span style='letter-spacing:1.5pt'> </span>process<span
  style='letter-spacing:1.4pt'> </span>of<span style='letter-spacing:1.15pt'> </span>personal<span
  style='letter-spacing:1.05pt'> </span>data<span style='letter-spacing:1.5pt'>
  </span>policy<span style='letter-spacing:1.05pt'> </span>that<span
  style='letter-spacing:1.85pt'> </span><span style='letter-spacing:-.25pt'>was</span></span></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:12.0pt'>prepared<span
  style='letter-spacing:.05pt'> </span>by<span style='letter-spacing:-.4pt'> </span>DentisToday<span
  style='letter-spacing:-.4pt'> </span>as per<span style='letter-spacing:.15pt'>
  </span>the<span style='letter-spacing:.05pt'> </span><span style='letter-spacing:
  -.2pt'>Law.</span></span></p>
  </td>
 </tr>
 <tr style='height:63.35pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Supplier</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:63.35pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.8pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;text-align:justify;line-height:
  115%'><span style='font-size:12.0pt;line-height:115%'>Parties who provide
  services to DentisToday, when DentisToday<span style='letter-spacing:-.75pt'>
  </span>conducts<span style='letter-spacing:-.45pt'> </span>its<span
  style='letter-spacing:-.6pt'> </span>commercial<span style='letter-spacing:
  -.65pt'> </span>activities,<span style='letter-spacing:-.2pt'> </span>in<span
  style='letter-spacing:-.25pt'> </span>compliance with<span style='letter-spacing:
  3.8pt'> </span>the<span style='letter-spacing:3.9pt'> </span>orders<span
  style='letter-spacing:1.3pt'>  </span>and<span style='letter-spacing:1.35pt'> 
  </span>instructions<span style='letter-spacing:1.3pt'>  </span>of<span
  style='letter-spacing:3.8pt'> </span>DentisToday,<span style='letter-spacing:
  1.4pt'>  </span>on<span style='letter-spacing:3.95pt'> </span><span
  style='letter-spacing:-.5pt'>a</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;text-align:justify;
  line-height:13.75pt'><span style='font-size:12.0pt'>contractual<span
  style='letter-spacing:-.1pt'> basis.</span></span></p>
  </td>
 </tr>
 <tr style='height:31.85pt'>
  <td width=168 valign=top style='width:126.05pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Clinic</span></b></p>
  </td>
  <td width=21 valign=top style='width:15.4pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph align=center style='margin-left:.55pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.85pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>All<span style='letter-spacing:-.95pt'> </span>kinds<span
  style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.8pt'> </span>health<span
  style='letter-spacing:-.85pt'> </span>clinics<span style='letter-spacing:
  -.75pt'> </span>who<span style='letter-spacing:-.45pt'> </span>provide<span
  style='letter-spacing:-.65pt'> </span>services<span style='letter-spacing:
  -.75pt'> </span>to<span style='letter-spacing:-.65pt'> </span><span
  style='letter-spacing:-.1pt'>DentisToday</span></span></p>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:12.0pt'>including<span
  style='letter-spacing:2.9pt'> </span>Dentist<span style='letter-spacing:3.25pt'>
  </span>services,<span style='letter-spacing:3.15pt'> </span>when<span
  style='letter-spacing:3.0pt'> </span>DentisToday<span style='letter-spacing:
  2.8pt'> </span>conducts<span style='letter-spacing:2.95pt'> </span><span
  style='letter-spacing:-.25pt'>its</span></span></p>
  </td>
 </tr>
</table>

</div>

<span style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection4>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:47.7pt'>
  <td width=604 colspan=3 valign=top style='width:453.2pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:2.95pt;margin-bottom:
  0in;margin-left:147.15pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>commercial or health or
  DentisToday’s application activities, in<span style='letter-spacing:-.65pt'> </span>compliance<span
  style='letter-spacing:-.3pt'> </span>with<span style='letter-spacing:-.5pt'> </span>the<span
  style='letter-spacing:-.3pt'> </span>orders<span style='letter-spacing:-.35pt'>
  </span>and<span style='letter-spacing:-.25pt'> </span>instructions<span
  style='letter-spacing:-.4pt'> </span>of<span style='letter-spacing:-.6pt'> </span><span
  style='letter-spacing:-.1pt'>DentisToday,</span></span></p>
  <p class=TableParagraph style='margin-left:147.15pt'><span style='font-size:
  12.0pt'>on<span style='letter-spacing:-.15pt'> </span>a<span
  style='letter-spacing:.05pt'> </span>contractual<span style='letter-spacing:
  -.15pt'> </span><span style='letter-spacing:-.1pt'>basis.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=165 valign=top style='width:123.9pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Data<span style='letter-spacing:.1pt'> </span><span
  style='letter-spacing:-.1pt'>Processor</span></span></b></p>
  </td>
  <td width=23 valign=top style='width:17.55pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=center style='margin-left:2.7pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>The real person or legal entity
  that processes personal data on behalf<span style='letter-spacing:1.7pt'> </span>of<span
  style='letter-spacing:1.85pt'> </span>the<span style='letter-spacing:2.15pt'>
  </span>data<span style='letter-spacing:1.95pt'> </span>controller<span
  style='letter-spacing:2.25pt'> </span>by<span style='letter-spacing:2.0pt'> </span>basing<span
  style='letter-spacing:2.2pt'> </span>on<span style='letter-spacing:2.0pt'> </span>the<span
  style='letter-spacing:2.2pt'> </span><span style='letter-spacing:-.1pt'>authorization</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.75pt'><span
  style='font-size:12.0pt'>granted<span style='letter-spacing:-.05pt'> </span>by<span
  style='letter-spacing:-.4pt'> </span>the<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.2pt'>same.</span></span></p>
  </td>
 </tr>
 <tr style='height:47.75pt'>
  <td width=165 valign=top style='width:123.9pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Data<span style='letter-spacing:-.15pt'> </span>Registry<span
  style='letter-spacing:-.1pt'> System</span></span></b></p>
  </td>
  <td width=23 valign=top style='width:17.55pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph align=center style='margin-left:2.7pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.75pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>The<span style='letter-spacing:
  4.0pt'> </span>registry<span style='letter-spacing:4.0pt'> </span>system<span
  style='letter-spacing:4.0pt'> </span>through<span style='letter-spacing:4.0pt'>
  </span>which<span style='letter-spacing:4.0pt'> </span>the<span
  style='letter-spacing:4.0pt'> </span>personal<span style='letter-spacing:
  4.0pt'> </span>data<span style='letter-spacing:4.0pt'> </span>is processed<span
  style='letter-spacing:-.4pt'> </span>into<span style='letter-spacing:-.55pt'>
  </span>through<span style='letter-spacing:-.55pt'> </span>by<span
  style='letter-spacing:-.7pt'> </span>being<span style='letter-spacing:-.3pt'>
  </span>structured<span style='letter-spacing:-.3pt'> </span>according<span
  style='letter-spacing:-.3pt'> </span>to<span style='letter-spacing:-.2pt'> </span><span
  style='letter-spacing:-.1pt'>certain</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.75pt'><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>criteria.</span></p>
  </td>
 </tr>
 <tr style='height:47.45pt'>
  <td width=165 valign=top style='width:123.9pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.45pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Data<span style='letter-spacing:.1pt'> </span><span
  style='letter-spacing:-.1pt'>Controller</span></span></b></p>
  </td>
  <td width=23 valign=top style='width:17.55pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.45pt'>
  <p class=TableParagraph align=center style='margin-left:2.7pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.45pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>The<span style='letter-spacing:
  -.1pt'> </span>real<span style='letter-spacing:-.5pt'> </span>person<span
  style='letter-spacing:-.3pt'> </span>or legal<span style='letter-spacing:
  -.3pt'> </span>entity<span style='letter-spacing:-.55pt'> </span>that
  determines<span style='letter-spacing:-.15pt'> </span>the purpose<span
  style='letter-spacing:-.1pt'> </span>and means<span style='letter-spacing:
  3.6pt'> </span>of<span style='letter-spacing:3.4pt'> </span>processing<span
  style='letter-spacing:3.7pt'> </span>personal<span style='letter-spacing:
  3.3pt'> </span>data<span style='letter-spacing:3.75pt'> </span>and<span
  style='letter-spacing:3.7pt'> </span>is<span style='letter-spacing:3.65pt'> </span>responsible<span
  style='letter-spacing:3.95pt'> </span><span style='letter-spacing:-.25pt'>for</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.75pt'><span
  style='font-size:12.0pt'>establishing<span style='letter-spacing:-.1pt'> </span>and<span
  style='letter-spacing:.05pt'> </span>managing<span style='letter-spacing:
  -.05pt'> </span>the<span style='letter-spacing:-.15pt'> </span>data<span
  style='letter-spacing:-.15pt'> </span>registry<span style='letter-spacing:
  -.55pt'> </span><span style='letter-spacing:-.1pt'>system.</span></span></p>
  </td>
 </tr>
 <tr style='height:31.65pt'>
  <td width=165 valign=top style='width:123.9pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt'>Data<span style='letter-spacing:.1pt'> </span><span
  style='letter-spacing:-.1pt'>Owner/Person</span></span></b></p>
  <p class=TableParagraph style='margin-top:2.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span style='font-size:12.0pt;
  letter-spacing:-.1pt'>Concerned</span></b></p>
  </td>
  <td width=23 valign=top style='width:17.55pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph align=center style='margin-left:2.7pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:31.65pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt'><span
  style='font-size:12.0pt'>The real<span style='letter-spacing:-.35pt'> </span>person<span
  style='letter-spacing:-.15pt'> </span>whose personal<span style='letter-spacing:
  -.35pt'> </span>data<span style='letter-spacing:.05pt'> </span>is <span
  style='letter-spacing:-.1pt'>processed</span></span></p>
  </td>
 </tr>
 <tr style='height:47.7pt'>
  <td width=165 valign=top style='width:123.9pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Regulation</span></b></p>
  </td>
  <td width=23 valign=top style='width:17.55pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph align=center style='margin-left:2.7pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.7pt'>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>“The Regulation of<span
  style='letter-spacing:-.1pt'> </span>Erasure, Destruction or Anonymization of
  Personal<span style='letter-spacing:3.0pt'> </span>Data”<span
  style='letter-spacing:3.45pt'> </span>announced<span style='letter-spacing:
  3.7pt'> </span>in<span style='letter-spacing:3.3pt'> </span>the<span
  style='letter-spacing:3.45pt'> </span>Official<span style='letter-spacing:
  3.0pt'> </span>Gazette<span style='letter-spacing:3.25pt'> </span><span
  style='letter-spacing:-.1pt'>dated</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt'><span style='font-size:
  12.0pt;letter-spacing:-.1pt'>28.10.2017.</span></p>
  </td>
 </tr>
 <tr style='height:47.5pt'>
  <td width=165 valign=top style='width:123.9pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:13.65pt'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>Visitor</span></b></p>
  </td>
  <td width=23 valign=top style='width:17.55pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph align=center style='margin-left:2.7pt;text-align:
  center;line-height:13.65pt'><b><span style='font-size:12.0pt;letter-spacing:
  -.5pt'>:</span></b></p>
  </td>
  <td width=416 valign=top style='width:311.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:47.5pt'>
  <p class=TableParagraph style='margin-top:0in;margin-right:4.85pt;margin-bottom:
  0in;margin-left:5.95pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>Real<span style='letter-spacing:
  4.0pt'> </span>persons     <span style='letter-spacing:-.2pt'>who</span>   entered<span
  style='letter-spacing:4.0pt'> </span>the    physical<span style='letter-spacing:
  4.0pt'> </span>premises     <span style='letter-spacing:-.2pt'>that </span>DentisToday<span
  style='letter-spacing:-.85pt'> </span>conducts<span style='letter-spacing:
  -.45pt'> </span>activities<span style='letter-spacing:-.15pt'> </span>for<span
  style='letter-spacing:-.5pt'> </span>various<span style='letter-spacing:-.4pt'>
  </span>purposes<span style='letter-spacing:-.15pt'> </span>or<span
  style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>visited</span></span></p>
  <p class=TableParagraph style='margin-left:5.95pt;line-height:13.75pt'><span
  style='font-size:12.0pt'>our<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.1pt'>websites.</span></span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText align=left style='margin-top:2.95pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:34.45pt;
margin-bottom:0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:left;
text-indent:-28.35pt;line-height:116%'><b><span style='font-size:12.0pt;
line-height:116%'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:116%'>PROCEDURES<span
style='letter-spacing:-.3pt'> </span>AND<span style='letter-spacing:-.35pt'> </span>PRINCIPLES<span
style='letter-spacing:-.3pt'> </span>REGARDING<span style='letter-spacing:-.3pt'>
</span>THE<span style='letter-spacing:-.4pt'> </span>PROTECTION<span
style='letter-spacing:-.3pt'> </span>OF PERSONAL DATA</span></b></p>

<p class=MsoBodyText align=left style='margin-top:1.7pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoListParagraph align=left style='margin-left:34.15pt;text-align:
left;text-indent:-28.35pt'><b><span style='font-size:12.0pt'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
style='font-size:12.0pt'>GENERAL<span style='letter-spacing:-.4pt'> </span><span
style='letter-spacing:-.2pt'>RULES</span></span></b></p>

<p class=MsoBodyText align=left style='margin-top:3.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.45pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>The protection of
personal data has been granted by stating it under Article 20/III of the
Constitution<span style='letter-spacing:-.05pt'> </span>that personal<span
style='letter-spacing:-.3pt'> </span>data can<span style='letter-spacing:-.05pt'>
</span>be processed only<span style='letter-spacing:-.3pt'> </span>under the
circumstances specified under the<span style='letter-spacing:-.75pt'> </span>law<span
style='letter-spacing:-.55pt'> </span>or<span style='letter-spacing:-.6pt'> </span>upon<span
style='letter-spacing:-.65pt'> </span>explicit<span style='letter-spacing:-.25pt'>
</span>consent<span style='letter-spacing:-.45pt'> </span>of<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.55pt'> </span>person.<span
style='letter-spacing:-.4pt'> </span>In<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.55pt'> </span>direction<span style='letter-spacing:
-.65pt'> </span>of<span style='letter-spacing:-.75pt'> </span>such<span
style='letter-spacing:-.65pt'> </span>rights<span style='letter-spacing:-.55pt'>
</span>granted<span style='letter-spacing:-.65pt'> </span>to<span
style='letter-spacing:-.45pt'> </span>personal data owners, DentisToday
processes personal data in accordance with the principles stated under the
relevant legislation or in the circumstances where there is an explicit consent
of the person, as per the following rules:</p>

<p class=MsoBodyText align=left style='margin-top:2.4pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:5.95pt;
margin-bottom:0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:left;
text-indent:0in;line-height:113%'><b><span style='font-size:12.0pt;line-height:
113%;letter-spacing:-.25pt'>3.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:113%'>Processing
in Compliance with the Law and Principles of Bona Fides: </span></b><span
style='font-size:12.0pt;line-height:113%'>DentisToday acts<span
style='letter-spacing:1.5pt'> </span>in<span style='letter-spacing:1.2pt'> </span>accordance<span
style='letter-spacing:1.4pt'> </span>with<span style='letter-spacing:1.2pt'> </span>all<span
style='letter-spacing:1.2pt'> </span>of the<span style='letter-spacing:1.55pt'>
</span>legal regulations<span style='letter-spacing:1.3pt'> </span>and<span
style='letter-spacing:1.4pt'> </span>the<span style='letter-spacing:1.35pt'> </span>principles<span
style='letter-spacing:1.3pt'> </span>of bonafide in its processing of Personal
Data.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.35pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph align=left style='margin-top:.05pt;margin-right:5.6pt;
margin-bottom:0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:left;
text-indent:0in;line-height:115%'><b><span style='font-size:12.0pt;line-height:
115%;letter-spacing:-.25pt'>3.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:115%'>Ensuring
that Personal Data is Accurate and Up-to-date, when Necessary. </span></b><span
style='font-size:12.0pt;line-height:115%'>In order for the Personal Data
processed by DentisToday to be accurate and up-to-date, necessary<span
style='letter-spacing:-.85pt'> </span>measures<span style='letter-spacing:-.75pt'>
</span>are<span style='letter-spacing:-.75pt'> </span>taken<span
style='letter-spacing:-.85pt'> </span>and<span style='letter-spacing:-.75pt'> </span>to<span
style='letter-spacing:-.75pt'> </span>that<span style='letter-spacing:-.85pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.75pt'> </span>being<span
style='letter-spacing:-.75pt'> </span>processed<span style='letter-spacing:
-.75pt'> </span>reflect<span style='letter-spacing:-.7pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>real<span style='letter-spacing:-1.1pt'> </span>situation,
necessary opportunities are provided to the Data Owners by informing them.</span></p>

</div>

<span style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection5>

<h2 style='margin-top:3.7pt;margin-right:0in;margin-bottom:0in;margin-left:
76.6pt;margin-bottom:.0001pt;text-indent:-42.45pt'><span style='letter-spacing:
-.25pt'>3.1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Processing<span style='letter-spacing:-.25pt'> </span>for<span
style='letter-spacing:-.35pt'> </span>Specific,<span style='letter-spacing:
-.05pt'> </span>Explicit<span style='letter-spacing:-.1pt'> </span>and<span
style='letter-spacing:-.1pt'> </span>Legitimate<span style='letter-spacing:
-.35pt'> </span><span style='letter-spacing:-.1pt'>Purposes</span></h2>

<p class=MsoBodyText style='margin-top:1.8pt;margin-right:5.6pt;margin-bottom:
0in;margin-left:34.15pt;margin-bottom:.0001pt;line-height:115%'>DentisToday,<span
style='letter-spacing:-.75pt'> </span>determines<span style='letter-spacing:
-.75pt'> </span>its<span style='letter-spacing:-.75pt'> </span>purpose<span
style='letter-spacing:-.75pt'> </span>for<span style='letter-spacing:-.75pt'> </span>processing<span
style='letter-spacing:-.6pt'> </span>personal<span style='letter-spacing:-.75pt'>
</span>data,<span style='letter-spacing:-.75pt'> </span>which<span
style='letter-spacing:-.7pt'> </span>is<span style='letter-spacing:-.6pt'> </span>legitimate<span
style='letter-spacing:-.75pt'> </span>and legal, explicitly and specifically.
DentisToday<span style='letter-spacing:-.25pt'> </span>processes the personal
data as much as is necessary for and in connection with the commercial activity
it carries out.</p>

<p class=MsoBodyText align=left style='margin-top:2.05pt;text-align:left'>&nbsp;</p>

<h2 style='margin-top:0in;margin-right:21.55pt;margin-bottom:0in;margin-left:
76.65pt;margin-bottom:.0001pt;line-height:115%'><span style='letter-spacing:
-.25pt'>3.1.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Processing<span style='letter-spacing:-.1pt'> </span>for<span
style='letter-spacing:-.4pt'> </span>being<span style='letter-spacing:-.1pt'> </span>Relevant<span
style='letter-spacing:-.05pt'> </span>with,<span style='letter-spacing:-.25pt'>
</span>Limited<span style='letter-spacing:-.1pt'> </span>to<span
style='letter-spacing:-.35pt'> </span>and<span style='letter-spacing:-.1pt'> </span>Proportionate<span
style='letter-spacing:-.15pt'> </span>to<span style='letter-spacing:-.35pt'> </span>the
Purpose for which it is Processed</h2>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.9pt;margin-bottom:
0in;margin-left:34.15pt;margin-bottom:.0001pt;line-height:115%'>DentisToday
processes Personal Data in a way that is appropriate for the realization of the<span
style='letter-spacing:-.15pt'> </span>determined<span style='letter-spacing:
-.1pt'> </span>purposes<span style='letter-spacing:-.2pt'> </span>and<span
style='letter-spacing:-.1pt'> </span>avoids<span style='letter-spacing:-.2pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>processing of<span style='letter-spacing:
-.45pt'> </span>Personal<span style='letter-spacing:-.5pt'> </span>Data<span
style='letter-spacing:-.35pt'> </span>that is not related<span
style='letter-spacing:-.35pt'> </span>to the realization of the purpose or is
not needed.</p>

<p class=MsoBodyText align=left style='margin-top:2.15pt;text-align:left'>&nbsp;</p>

<h2 style='margin-top:.05pt;margin-right:56.4pt;margin-bottom:0in;margin-left:
76.65pt;margin-bottom:.0001pt;line-height:115%'><span style='letter-spacing:
-.25pt'>3.1.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Being Retained for<span style='letter-spacing:-.05pt'> </span>the
Period of Time Specified by the Relevant Legislation<span style='letter-spacing:
-.1pt'> </span>or<span style='letter-spacing:-.45pt'> </span>Required<span
style='letter-spacing:-.15pt'> </span>for<span style='letter-spacing:-.45pt'> </span>the<span
style='letter-spacing:-.2pt'> </span>Purpose<span style='letter-spacing:-.2pt'>
</span>for<span style='letter-spacing:-.45pt'> </span>which<span
style='letter-spacing:-.15pt'> </span>It<span style='letter-spacing:-.1pt'> </span>is<span
style='letter-spacing:-.25pt'> </span>Processed</h2>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.65pt;margin-bottom:
0in;margin-left:34.15pt;margin-bottom:.0001pt;line-height:115%'>The personal
data processed by DentisToday is retained only for the period of time specified
by<span style='letter-spacing:-.65pt'> </span>the<span style='letter-spacing:
-.2pt'> </span>relevant legislation<span style='letter-spacing:-.4pt'> </span>or<span
style='letter-spacing:-.15pt'> </span>required for<span style='letter-spacing:
-.55pt'> </span>the<span style='letter-spacing:-.2pt'> </span>purpose<span
style='letter-spacing:-.2pt'> </span>for<span style='letter-spacing:-.3pt'> </span>which<span
style='letter-spacing:-.15pt'> </span>it is processed. Within this context,
DentisToday<span style='letter-spacing:-.05pt'> </span>complies with the period
of time, if any, specified for the<span style='letter-spacing:-.4pt'> </span>retention<span
style='letter-spacing:-.6pt'> </span>of<span style='letter-spacing:-.75pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.4pt'> </span>under<span
style='letter-spacing:-.25pt'> </span>the<span style='letter-spacing:-.4pt'> </span>relevant<span
style='letter-spacing:-.1pt'> </span>legislation;<span style='letter-spacing:
-.35pt'> </span>if<span style='letter-spacing:-.75pt'> </span>there<span
style='letter-spacing:-.15pt'> </span>is<span style='letter-spacing:-.45pt'> </span>no<span
style='letter-spacing:-.1pt'> </span>specified<span style='letter-spacing:-.35pt'>
</span>period of<span style='letter-spacing:-.05pt'> </span>time, then it
retains such personal data for the period of<span style='letter-spacing:-.05pt'>
</span>time required by the purpose for which it is processed.</p>

<p class=MsoBodyText align=left style='margin-top:2.05pt;text-align:left'>&nbsp;</p>

<h1 style='margin-top:.05pt;margin-right:6.05pt;margin-bottom:0in;margin-left:
34.15pt;margin-bottom:.0001pt;line-height:115%'>3.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='letter-spacing:-.1pt'>COLLECTION,</span>    <span
style='letter-spacing:-.1pt'>PROCESSING</span>   <span style='letter-spacing:
-.1pt'>PERSONAL</span>    <span style='letter-spacing:-.2pt'>DATA</span>    <span
style='letter-spacing:-.2pt'>AND</span>    <span style='letter-spacing:-.1pt'>PROCESSING
PURPOSES</span></h1>

<p class=MsoBodyText align=left style='margin-top:1.7pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt'>Although your personal data
processed by DentisToday may vary depending on the health, physician services,
diagnosis and information provided, it is collected through automatic or
non-automatic<span style='letter-spacing:-.2pt'> </span>ways,<span
style='letter-spacing:-.1pt'> </span>via<span style='letter-spacing:-.2pt'> </span>call<span
style='letter-spacing:-.35pt'> </span>centres,<span style='letter-spacing:-.1pt'>
</span>internet sites,<span style='letter-spacing:-.1pt'> </span>online<span
style='letter-spacing:-.2pt'> </span>services,<span style='letter-spacing:-.1pt'>
</span>online/mobile<span style='letter-spacing:-.2pt'> </span>applications and
similar manners, in writing, orally or electronically. Your personal<span
style='letter-spacing:-.1pt'> </span>data of<span style='letter-spacing:-.05pt'>
</span>special nature, particularly<span style='letter-spacing:-.3pt'> </span>your<span
style='letter-spacing:-.2pt'> </span>health<span style='letter-spacing:-.55pt'>
</span>data<span style='letter-spacing:-.35pt'> </span>and<span
style='letter-spacing:-.1pt'> </span>your<span style='letter-spacing:-.2pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.35pt'> </span>in<span
style='letter-spacing:-.55pt'> </span>general<span style='letter-spacing:-.5pt'>
</span>nature<span style='letter-spacing:-.35pt'> </span>and<span
style='letter-spacing:-.55pt'> </span>the<span style='letter-spacing:-.35pt'> </span>ones<span
style='letter-spacing:-.4pt'> </span>stated<span style='letter-spacing:-.3pt'> </span>below
can be processed by DentisToday being relevant with, limited to and
proportionate to the purposes of health care services given below;</p>

<p class=MsoListParagraph style='margin-right:5.8pt;text-indent:-18.05pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span style='font-size:12.0pt'>Your Identity Information</span></i></b><span
style='font-size:12.0pt'>: Your name, surname, T.R. Identity number, passport
number or<span style='letter-spacing:-.15pt'> </span>temporary<span
style='letter-spacing:-.5pt'> </span>T.C. Identity<span style='letter-spacing:
-.5pt'> </span>Number, date<span style='letter-spacing:-.3pt'> </span>of<span
style='letter-spacing:-.15pt'> </span>birth, place<span style='letter-spacing:
-.05pt'> </span>of<span style='letter-spacing:-.15pt'> </span>birth, marital<span
style='letter-spacing:-.25pt'> </span>status, gender, insurance or patient
protocol<span style='letter-spacing:-.1pt'> </span>number, and your other
identity<span style='letter-spacing:-.1pt'> </span>data<span style='letter-spacing:
-.15pt'> </span>that we can identify you,</span></p>

<p class=MsoListParagraph style='margin-right:5.7pt;text-indent:-18.05pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span style='font-size:12.0pt'>Your<span style='letter-spacing:
-.1pt'> </span>Contact Information: </span></i></b><span style='font-size:12.0pt'>Your<span
style='letter-spacing:-.15pt'> </span>address, location, telephone number,
electronic mail address and other contact information, your voice call
recordings kept by customer representatives or patient services in accordance
with call centre standards, your personal<span style='letter-spacing:-.35pt'> </span>data<span
style='letter-spacing:-.2pt'> </span>that is<span style='letter-spacing:-.05pt'>
</span>obtained when you communicate with<span style='letter-spacing:-.15pt'> </span>us<span
style='letter-spacing:-.05pt'> </span>via electronic mail, mail or other means.</span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:5.95pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:98%'><span style='font-size:10.0pt;line-height:98%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span style='font-size:12.0pt;line-height:98%'>Your<span
style='letter-spacing:-.75pt'> </span>Accounting/Finance<span style='letter-spacing:
-.75pt'> </span>Information:<span style='letter-spacing:-.75pt'> </span></span></i></b><span
style='font-size:12.0pt;line-height:98%'>Your<span style='letter-spacing:-.6pt'>
</span>financial<span style='letter-spacing:-.75pt'> </span>data<span
style='letter-spacing:-.75pt'> </span>such<span style='letter-spacing:-.75pt'> </span>as<span
style='letter-spacing:-.55pt'> </span>your<span style='letter-spacing:-.6pt'> </span>bank<span
style='letter-spacing:-.7pt'> </span>account number, IBAN number, credit card
information, invoicing information.</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.6pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:98%'><span style='font-size:10.0pt;line-height:98%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:98%'>Your<span
style='letter-spacing:-.45pt'> </span>data<span style='letter-spacing:-.55pt'> </span>regarding<span
style='letter-spacing:-.3pt'> </span>private<span style='letter-spacing:-.35pt'>
</span>health<span style='letter-spacing:-.3pt'> </span>insurance<span
style='letter-spacing:-.25pt'> </span>and<span style='letter-spacing:-.3pt'> </span>data<span
style='letter-spacing:-.3pt'> </span><b><i>of<span style='letter-spacing:-.45pt'>
</span>Social<span style='letter-spacing:-.25pt'> </span>Security<span
style='letter-spacing:-.35pt'> </span>Institution<span style='letter-spacing:
-.3pt'> </span></i></b>for the purpose of planning health care services,</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:6.5pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
98%'><span style='font-size:10.0pt;line-height:98%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:98%'>Your camera
footage is kept for security and supervision purposes in case you visit
hospitals or dental clinics to be offered to you appropriately,</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:5.3pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
98%'><span style='font-size:10.0pt;line-height:98%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span style='font-size:12.0pt;line-height:98%'>Your<span
style='letter-spacing:-.75pt'> </span>Health<span style='letter-spacing:-.7pt'>
</span>Information:<span style='letter-spacing:-.5pt'> </span></span></i></b><span
style='font-size:12.0pt;line-height:98%'>All<span style='letter-spacing:-.75pt'>
</span>kinds<span style='letter-spacing:-.55pt'> </span>of<span
style='letter-spacing:-.75pt'> </span>personal<span style='letter-spacing:-.75pt'>
</span>data<span style='letter-spacing:-.75pt'> </span>of<span
style='letter-spacing:-.6pt'> </span>yours<span style='letter-spacing:-.55pt'> </span>related<span
style='letter-spacing:-.65pt'> </span>to<span style='letter-spacing:-.2pt'> </span>your<span
style='letter-spacing:-.35pt'> </span>health<span style='letter-spacing:-.65pt'>
</span>and sexual life obtained while conducting or as a result of the<span
style='letter-spacing:.8pt'> </span>preliminary diagnosis,<span
style='letter-spacing:.85pt'> </span>final</span></p>

</div>

<span style='font-size:12.0pt;line-height:98%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection6>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:5.6pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt'>diagnosis,<span style='letter-spacing:
-.45pt'> </span>medical<span style='letter-spacing:-.75pt'> </span>diagnosis<span
style='letter-spacing:-.5pt'> </span>treatment<span style='letter-spacing:-.2pt'>
</span>and<span style='letter-spacing:-.5pt'> </span>nursing<span
style='letter-spacing:-.25pt'> </span>services<span style='letter-spacing:-.4pt'>
</span>including<span style='letter-spacing:-.45pt'> </span>data<span
style='letter-spacing:-.55pt'> </span>such<span style='letter-spacing:-.75pt'> </span>as<span
style='letter-spacing:-.6pt'> </span>your laboratory results, test results,
physical examination data, appointment information, roentgen results,
radiologic results, check-up results.</p>

<p class=MsoListParagraph style='margin-top:.1pt;margin-right:5.95pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Your<span style='letter-spacing:
-.5pt'> </span>health<span style='letter-spacing:-.6pt'> </span>data<span
style='letter-spacing:-.4pt'> </span>and<span style='letter-spacing:-.35pt'> </span>other<span
style='letter-spacing:-.25pt'> </span>personal<span style='letter-spacing:-.75pt'>
</span>data<span style='letter-spacing:-.6pt'> </span>that<span
style='letter-spacing:-.1pt'> </span>you<span style='letter-spacing:-.35pt'> </span>have<span
style='letter-spacing:-.4pt'> </span>sent<span style='letter-spacing:-.1pt'> </span>to<span
style='letter-spacing:-.55pt'> </span>the<span style='letter-spacing:-.4pt'> </span>Application<span
style='letter-spacing:-.6pt'> </span>or<span style='letter-spacing:-.25pt'> </span>sent<span
style='letter-spacing:-.35pt'> </span>to the<span style='letter-spacing:-.4pt'>
</span>e-mail<span style='letter-spacing:-.55pt'> </span>address<u><span
style='letter-spacing:-.35pt'> </span></u></span><a
href="mailto:aident@hs01.kep.tr"><span style='font-size:12.0pt;color:windowtext'>aident@hs01.kep.tr</span></a><span
style='font-size:12.0pt;letter-spacing:-.6pt'> </span><span style='font-size:
12.0pt'>or<span style='letter-spacing:-.25pt'> </span>uploaded<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.55pt'> </span>the<span
style='letter-spacing:-.2pt'> </span>mobile<span style='letter-spacing:-.4pt'> </span>application<span
style='letter-spacing:-.35pt'> </span>having<span style='letter-spacing:-.35pt'>
</span>the tradename of DentisToday.</span></p>

<p class=MsoListParagraph style='margin-right:5.4pt;text-indent:-18.05pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>In case you make a job application
to DentisToday<b>, </b>your CV and other personal data that are<span
style='letter-spacing:-.15pt'> </span>provided in<span style='letter-spacing:
-.1pt'> </span>this respect and in<span style='letter-spacing:-.1pt'> </span>case
you are an<span style='letter-spacing:-.1pt'> </span>employee of<span
style='letter-spacing:-.25pt'> </span>DentisToday<span style='letter-spacing:
-.25pt'> </span>or its related employee, your employment contract and all kinds
of your personal data with regard to your work talent.</span></p>

<p class=MsoListParagraph style='margin-right:5.5pt;text-indent:-18.05pt;
line-height:100%'><span style='font-size:10.0pt;line-height:100%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>Comments and
notes are sent by your side while you are using the mobile application or our
website.</span></p>

<p class=MsoListParagraph style='margin-right:5.6pt;text-indent:-18.05pt;
line-height:100%'><span style='font-size:10.0pt;line-height:100%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>X-ray,<span
style='letter-spacing:-.65pt'> </span>radiologic<span style='letter-spacing:
-.6pt'> </span>documents<span style='letter-spacing:-.65pt'> </span>and<span
style='letter-spacing:-.3pt'> </span>intraoral,<span style='letter-spacing:
-.45pt'> </span>dental<span style='letter-spacing:-.75pt'> </span>and<span
style='letter-spacing:-.55pt'> </span>other<span style='letter-spacing:-.45pt'>
</span>photos<span style='letter-spacing:-.65pt'> </span>and<span
style='letter-spacing:-.55pt'> </span>videos<span style='letter-spacing:-.65pt'>
</span>and<span style='letter-spacing:-.55pt'> </span>other data<span
style='letter-spacing:-.05pt'> </span>related to these that have been sent by
you or<span style='letter-spacing:-.15pt'> </span>upon<span style='letter-spacing:
-.25pt'> </span>the approval<span style='letter-spacing:-.25pt'> </span>of<span
style='letter-spacing:-.15pt'> </span>the data<span style='letter-spacing:-.3pt'>
</span>owner by you on behalf of her/him in order to get benefit from the
services while using the mobile application or the website.</span></p>

<p class=MsoBodyText style='margin-top:13.05pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt'>All<span style='letter-spacing:
-.7pt'> </span>kinds<span style='letter-spacing:-.35pt'> </span>of<span
style='letter-spacing:-.45pt'> </span>your<span style='letter-spacing:-.2pt'> </span>personal<span
style='letter-spacing:-.7pt'> </span>data<span style='letter-spacing:-.3pt'> </span>(including<span
style='letter-spacing:-.25pt'> </span>but not<span style='letter-spacing:-.05pt'>
</span>limited<span style='letter-spacing:-.25pt'> </span>to<span
style='letter-spacing:-.3pt'> </span>the<span style='letter-spacing:-.35pt'> </span>personal<span
style='letter-spacing:-.7pt'> </span>data<span style='letter-spacing:-.55pt'> </span>of<span
style='letter-spacing:-.7pt'> </span>special<span style='letter-spacing:-.2pt'>
</span>nature) obtained by DentisToday can be processed, generally, to process
public health, to conduct preventive medicine, preliminary and final medical
diagnosis, physician referral, medical diagnosis,<span style='letter-spacing:
-.3pt'> </span>treatment<span style='letter-spacing:-.2pt'> </span>and<span
style='letter-spacing:-.25pt'> </span>nursing<span style='letter-spacing:-.25pt'>
</span>services<span style='letter-spacing:-.35pt'> </span>including<span
style='letter-spacing:-.25pt'> </span>artificial<span style='letter-spacing:
-.4pt'> </span>intelligence<span style='letter-spacing:-.25pt'> </span>and<span
style='letter-spacing:-.25pt'> </span>machine<span style='letter-spacing:-.25pt'>
</span>learning management, and can be also processed for and limited to the
following purposes:</p>

<p class=MsoBodyText align=left style='margin-top:.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-left:41.75pt;text-indent:-17.95pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.15pt'> </span>confirm<span style='letter-spacing:-.15pt'> </span>your<span
style='letter-spacing:.15pt'> </span><span style='letter-spacing:-.1pt'>identity.</span></span></p>

<p class=MsoListParagraph style='margin-top:.1pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.1pt'> </span>protect<span style='letter-spacing:-.05pt'> </span>public<span
style='letter-spacing:-.1pt'> </span>health,<span style='letter-spacing:-.2pt'>
</span>to conduct preventive medicine, medical<span style='letter-spacing:-.2pt'>
</span>diagnosis, treatment and nursing services, planning and management of
health care services and their <span style='letter-spacing:-.1pt'>financing.</span></span></p>

<p class=MsoListParagraph style='margin-right:6.2pt;text-indent:-18.05pt;
line-height:100%'><span style='font-size:10.0pt;line-height:100%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>To share
information requested by<span style='letter-spacing:-.1pt'> </span>the Ministry<span
style='letter-spacing:-.1pt'> </span>of Health and other public institutions
and organizations as per the related legislation.</span></p>

<p class=MsoListParagraph style='margin-right:6.2pt;text-indent:-18.05pt;
line-height:100%'><span style='font-size:10.0pt;line-height:100%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>To plan and
manage daily operations in hospitals or dental clinics upon your <span
style='letter-spacing:-.1pt'>confirmation.</span></span></p>

<p class=MsoListParagraph style='margin-right:5.8pt;text-indent:-18.05pt;
line-height:100%'><span style='font-size:10.0pt;line-height:100%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>To measure,
increase and search the satisfaction of patients by the Hospital Management,
Patient Rights, and Patient Experience departments.</span></p>

<p class=MsoListParagraph align=left style='text-align:left;text-indent:-.25in;
line-height:13.55pt'><span style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.1pt'> </span>supply<span style='letter-spacing:-.05pt'> </span><span
style='letter-spacing:-.1pt'>medicines.</span></span></p>

<p class=MsoListParagraph align=left style='text-align:left;text-indent:-.25in;
line-height:13.75pt'><span style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.25pt'> </span>inform<span style='letter-spacing:-.4pt'> </span>you<span
style='letter-spacing:-.1pt'> </span>regarding<span style='letter-spacing:.05pt'>
</span>your<span style='letter-spacing:-.1pt'> </span>appointment<span
style='letter-spacing:.3pt'> </span>in<span style='letter-spacing:-.35pt'> </span>case
you<span style='letter-spacing:-.15pt'> </span>get<span style='letter-spacing:
.1pt'> </span>an<span style='letter-spacing:-.35pt'> </span><span
style='letter-spacing:-.1pt'>appointment.</span></span></p>

<p class=MsoListParagraph align=left style='text-align:left;text-indent:-.25in;
line-height:13.75pt'><span style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.25pt'> </span>direct<span style='letter-spacing:.15pt'> </span>you<span
style='letter-spacing:-.15pt'> </span>to<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:.1pt'> </span>most<span style='letter-spacing:-.1pt'> </span>appropriate<span
style='letter-spacing:-.2pt'> </span>physician<span style='letter-spacing:-.1pt'>
</span>for<span style='letter-spacing:-.05pt'> </span>you,<span
style='letter-spacing:-.05pt'> </span>in<span style='letter-spacing:-.35pt'> </span>case<span
style='letter-spacing:.1pt'> </span>you<span style='letter-spacing:-.1pt'>
request.</span></span></p>

<p class=MsoListParagraph style='margin-right:6.1pt;text-indent:-18.05pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To share your X-ray, radiologic
documents and intraoral and extra oral and related photos and videos that you
have sent and other data of yours in connection with these with the physician.</span></p>

<p class=MsoListParagraph style='margin-right:6.1pt;text-indent:-18.05pt;
line-height:100%'><span style='font-size:10.0pt;line-height:100%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>To use as
analysis for the purpose of improving the application that will be used as
artificial intelligence and services that you have received from our website.</span></p>

<p class=MsoListParagraph style='margin-right:6.4pt;text-indent:-18.05pt;
line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>To plan required
analysis in order to offer you the most appropriate service according to your
requests, demands and needs, in case you use our shopping services.</span></p>

<p class=MsoListParagraph style='margin-right:5.9pt;text-indent:-18.05pt;
line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>To<span
style='letter-spacing:-.3pt'> </span>fulfil<span style='letter-spacing:-.5pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>financing<span style='letter-spacing:-.3pt'>
</span>of<span style='letter-spacing:-.45pt'> </span>your<span
style='letter-spacing:-.2pt'> </span>health<span style='letter-spacing:-.55pt'>
</span>services, your<span style='letter-spacing:-.2pt'> </span>costs<span
style='letter-spacing:-.65pt'> </span>of<span style='letter-spacing:-.7pt'> </span>examination,<span
style='letter-spacing:-.2pt'> </span>diagnosis<span style='letter-spacing:-.4pt'>
</span>and treatment by the Patient Services, Financial Affairs, Marketing
departments, to share information requested by private insurance companies
within the document to which Social Security Institution you are registered.</span></p>

<p class=MsoListParagraph style='margin-left:41.75pt;text-indent:-17.95pt'><span
style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
.05pt'> </span>fulfil<span style='letter-spacing:-.15pt'> </span>legal<span
style='letter-spacing:-.4pt'> </span>and<span style='letter-spacing:.05pt'> </span>regulatory<span
style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.1pt'>requirements.</span></span></p>

</div>

<span style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection7>

<p class=MsoListParagraph align=left style='margin-top:3.5pt;margin-right:6.4pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.05pt;line-height:100%'><span style='font-size:10.0pt;
line-height:100%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>To<span
style='letter-spacing:1.4pt'> </span>conduct<span style='letter-spacing:1.45pt'>
</span>risk<span style='letter-spacing:1.65pt'> </span>management<span
style='letter-spacing:1.65pt'> </span>and<span style='letter-spacing:1.4pt'> </span>quality
development<span style='letter-spacing:1.65pt'> </span>activities<span
style='letter-spacing:1.3pt'> </span>by Quality,<span style='letter-spacing:
1.5pt'> </span>Patient Experience, Information Security departments.</span></p>

<p class=MsoListParagraph align=left style='margin-right:6.1pt;text-align:left;
text-indent:-18.05pt;line-height:100%'><span style='font-size:10.0pt;
line-height:100%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:100%'>To<span
style='letter-spacing:2.0pt'> </span>invoice<span style='letter-spacing:2.0pt'>
</span>against<span style='letter-spacing:2.0pt'> </span>our<span
style='letter-spacing:2.0pt'> </span>services<span style='letter-spacing:2.0pt'>
</span>and<span style='letter-spacing:2.0pt'> </span>to<span style='letter-spacing:
2.0pt'> </span>confirm<span style='letter-spacing:2.0pt'> </span>your<span
style='letter-spacing:2.0pt'> </span>relationship<span style='letter-spacing:
2.0pt'> </span>with<span style='letter-spacing:2.0pt'> </span>contracted
institutions by Patient Services, Financial Affairs, Marketing departments.</span></p>

<p class=MsoListParagraph align=left style='text-align:left;text-indent:-.25in;
line-height:13.55pt'><span style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.25pt'> </span>confirm<span style='letter-spacing:-.15pt'> </span>your<span
style='letter-spacing:-.05pt'> </span>relationship<span style='letter-spacing:
-.15pt'> </span>with<span style='letter-spacing:-.1pt'> </span>institutions<span
style='letter-spacing:-.25pt'> </span>contracted<span style='letter-spacing:
-.1pt'> DentisToday.</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:.05pt;text-align:left;
text-indent:-.25in'><span style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.1pt'> </span>use<span style='letter-spacing:-.15pt'> </span>as<span
style='letter-spacing:-.15pt'> </span>evidence<span style='letter-spacing:.1pt'>
</span>in<span style='letter-spacing:-.3pt'> </span>disputes<span
style='letter-spacing:-.2pt'> </span>to<span style='letter-spacing:.15pt'> </span>have<span
style='letter-spacing:-.1pt'> arisen.</span></span></p>

<p class=MsoBodyText align=left style='text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:100%'>Your Personal<span
style='letter-spacing:-.3pt'> </span>Data<span style='letter-spacing:-.1pt'> </span>obtained
and process as per<span style='letter-spacing:-.2pt'> </span>the related
legislation, can<span style='letter-spacing:-.05pt'> </span>be retained both<span
style='letter-spacing:-.05pt'> </span>in digital and physical media by
transferring the same to DentisToday or to physical achieves and/or information
systems belong to DentisToday<b>.</b></p>

<p class=MsoBodyText style='margin-top:13.45pt;margin-right:5.5pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt'>Furthermore, your personal data
stated above, can be processed within the framework of the provisions of Health
Care Services Basic Law numbered 3359, Decree numbered 663 on the Organization
and Duties of the Ministry of Health and its Affiliates, Regulation on Private
Hospitals, Regulation<span style='letter-spacing:-.3pt'> </span>on<span
style='letter-spacing:-.4pt'> </span>the<span style='letter-spacing:-.2pt'> </span>Processing<span
style='letter-spacing:-.05pt'> </span>of<span style='letter-spacing:-.55pt'> </span>Personal<span
style='letter-spacing:-.3pt'> </span>Health<span style='letter-spacing:-.35pt'>
</span>Data<span style='letter-spacing:-.2pt'> </span>and<span
style='letter-spacing:-.15pt'> </span>Protection<span style='letter-spacing:
-.3pt'> </span>of<span style='letter-spacing:-.55pt'> </span>Privacy<span
style='letter-spacing:-.1pt'> </span>and regulations of the Ministry of Health
and other legislation, and your personal data can be retained both in digital
and physical media by transferring the same to DentisToday or to physical
achieves and/or information systems belong to our physicians that we advise
upon your consent.</p>

<p class=MsoBodyText align=left style='margin-top:.6pt;text-align:left'>&nbsp;</p>

<h1>3.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>CONDITIONS<span style='letter-spacing:-.1pt'> </span>FOR<span
style='letter-spacing:-.05pt'> </span>PROCESSING<span style='letter-spacing:
-.05pt'> </span>OF<span style='letter-spacing:-.15pt'> </span>PERSONAL<span
style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.2pt'>DATA</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>The processing
conditions of personal<span style='letter-spacing:-.05pt'> </span>data have
been regulated by<span style='letter-spacing:-.05pt'> </span>the Law and
personal<span style='letter-spacing:-.05pt'> </span>data is processed by
DentisToday in accordance with the conditions specified below.</p>

<p class=MsoBodyText align=left style='margin-top:1.95pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Other than the
exceptions stated under the Law, DentisToday process personal data only by
obtaining<span style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:
-.75pt'> </span><b>explicit<span style='letter-spacing:-.75pt'> </span>consent<span
style='letter-spacing:-.75pt'> </span></b>of<span style='letter-spacing:-.75pt'>
</span>personal<span style='letter-spacing:-.75pt'> </span>data<span
style='letter-spacing:-.75pt'> </span>owners.<span style='letter-spacing:-.75pt'>
</span>Personal<span style='letter-spacing:-.75pt'> </span>data<span
style='letter-spacing:-.75pt'> </span>may<span style='letter-spacing:-.75pt'> </span>be<span
style='letter-spacing:-.75pt'> </span>processed<span style='letter-spacing:
-.75pt'> </span><b>without seeking the explicit consent </b>of the data owner
only in circumstances where one of the following conditions is met:</p>

<p class=MsoBodyText align=left style='margin-top:2.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='text-indent:-.25in'><span style='font-size:
12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Data<span style='letter-spacing:
-.1pt'> </span>processing<span style='letter-spacing:.15pt'> </span>is<span
style='letter-spacing:-.15pt'> </span>clearly<span style='letter-spacing:-.3pt'>
</span>provided<span style='letter-spacing:.15pt'> </span>for by<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:.15pt'> </span><span
style='letter-spacing:-.2pt'>laws,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:112%'><span style='font-size:12.0pt;line-height:112%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:112%'>Data<span
style='letter-spacing:-.75pt'> </span>processing<span style='letter-spacing:
-.75pt'> </span>is<span style='letter-spacing:-.7pt'> </span>mandatory<span
style='letter-spacing:-.75pt'> </span>for<span style='letter-spacing:-.7pt'> </span>the<span
style='letter-spacing:-.65pt'> </span>protection<span style='letter-spacing:
-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>life<span
style='letter-spacing:-.65pt'> </span>or<span style='letter-spacing:-.75pt'> </span>physical<span
style='letter-spacing:-.75pt'> </span>integrity<span style='letter-spacing:
-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.65pt'> </span>person or of any other person who is
bodily incapable of giving her/his consent due to actual impossibility or whose
consent is not deemed legally valid,</span></p>

<p class=MsoListParagraph style='margin-top:.45pt;margin-right:5.85pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Processing of
personal data belonging to the parties of an agreement is necessary provided
that it is directly related to the conclusion or fulfilment of such agreement,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Data processing
is mandatory for the data controller to be able to perform its legal <span
style='letter-spacing:-.1pt'>obligations,</span></span></p>

<p class=MsoListParagraph style='margin-top:.2pt;text-indent:-.25in'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>The<span style='letter-spacing:
-.2pt'> </span>data<span style='letter-spacing:-.05pt'> </span>is<span
style='letter-spacing:.05pt'> </span>made<span style='letter-spacing:-.1pt'> </span>available<span
style='letter-spacing:-.05pt'> </span>to<span style='letter-spacing:-.05pt'> </span>the<span
style='letter-spacing:-.05pt'> </span>public<span style='letter-spacing:.15pt'>
</span>by<span style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:
-.05pt'> </span>data<span style='letter-spacing:-.1pt'> </span>owner<span
style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.1pt'>her/himself,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;text-indent:-.25in'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Data<span style='letter-spacing:
-.25pt'> </span>processing<span style='letter-spacing:.1pt'> </span>is
mandatory<span style='letter-spacing:-.3pt'> </span>for<span style='letter-spacing:
-.25pt'> </span>the<span style='letter-spacing:-.1pt'> </span>establishment,
exercise<span style='letter-spacing:-.15pt'> </span>or<span style='letter-spacing:
-.2pt'> </span>protection<span style='letter-spacing:-.35pt'> </span>of<span
style='letter-spacing:-.45pt'> </span>any<span style='letter-spacing:-.3pt'> </span><span
style='letter-spacing:-.1pt'>right,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:112%'><span style='font-size:12.0pt;line-height:112%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:112%'>Data<span
style='letter-spacing:-.75pt'> </span>processing<span style='letter-spacing:
-.75pt'> </span>is<span style='letter-spacing:-.45pt'> </span>mandatory<span
style='letter-spacing:-.75pt'> </span>for<span style='letter-spacing:-.55pt'> </span>the<span
style='letter-spacing:-.45pt'> </span>legitimate<span style='letter-spacing:
-.45pt'> </span>interests<span style='letter-spacing:-.75pt'> </span>of<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.7pt'> </span>data<span
style='letter-spacing:-.7pt'> </span>controller,<span style='letter-spacing:
-.1pt'> </span>provided that this processing shall not violate the fundamental
rights and freedoms of the data <span style='letter-spacing:-.1pt'>owner.</span></span></p>

</div>

<span style='font-size:12.0pt;line-height:112%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection8>

<h1 style='margin-top:3.7pt'>3.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>PRECAUTIONS<span style='letter-spacing:-.1pt'> </span>REGARDING<span
style='letter-spacing:-.1pt'> </span>THE<span style='letter-spacing:-.2pt'> </span>PROTECTION<span
style='letter-spacing:-.1pt'> </span>OF<span style='letter-spacing:-.2pt'> </span>PERSONAL<span
style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.2pt'>DATA</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>DentisToday takes
technical and administrative precautions to provide an adequate level of
security<span style='letter-spacing:-.5pt'> </span>in<span style='letter-spacing:
-.25pt'> </span>accordance<span style='letter-spacing:-.3pt'> </span>with<span
style='letter-spacing:-.25pt'> </span>Article<span style='letter-spacing:-.3pt'>
</span>12<span style='letter-spacing:-.25pt'> </span>of<span style='letter-spacing:
-.65pt'> </span>the KVKK<span style='letter-spacing:-.3pt'> </span>in<span
style='letter-spacing:-.25pt'> </span>order<span style='letter-spacing:-.4pt'> </span>to<span
style='letter-spacing:-.2pt'> </span>prevent<span style='letter-spacing:-.05pt'>
</span>unlawful<span style='letter-spacing:-.45pt'> </span>processing<span
style='letter-spacing:-.25pt'> </span>of personal<span style='letter-spacing:
-.75pt'> </span>data,<span style='letter-spacing:-.75pt'> </span>to<span
style='letter-spacing:-.75pt'> </span>prevent<span style='letter-spacing:-.75pt'>
</span>unlawful<span style='letter-spacing:-.75pt'> </span>access<span
style='letter-spacing:-.75pt'> </span>to<span style='letter-spacing:-.75pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.75pt'> </span>and<span
style='letter-spacing:-.75pt'> </span>to<span style='letter-spacing:-.6pt'> </span>ensure<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.7pt'> </span>retention<span
style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>personal
data and it makes the necessary<span style='letter-spacing:-.05pt'> </span>audits
or has the same made within this context.</p>

<p class=MsoBodyText align=left style='margin-top:2.2pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.6pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Within<span
style='letter-spacing:-.3pt'> </span>this scope,<span style='letter-spacing:
-.2pt'> </span>the<span style='letter-spacing:-.1pt'> </span>necessary<span
style='letter-spacing:-.5pt'> </span>(a) administrative and<span
style='letter-spacing:-.05pt'> </span>(b) technical<span style='letter-spacing:
-.3pt'> </span>precautions<span style='letter-spacing:-.15pt'> </span>shall<span
style='letter-spacing:-.05pt'> </span>be<span style='letter-spacing:-.1pt'> </span>taken
by<span style='letter-spacing:-.75pt'> </span>DentisToday,<span
style='letter-spacing:-.75pt'> </span>(c)<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>auditing<span style='letter-spacing:-.75pt'>
</span>system<span style='letter-spacing:-.75pt'> </span>shall<span
style='letter-spacing:-.75pt'> </span>be<span style='letter-spacing:-.75pt'> </span>established<span
style='letter-spacing:-.65pt'> </span>under<span style='letter-spacing:-.6pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>structure<span style='letter-spacing:
-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.7pt'> </span>Company n<span style='letter-spacing:-.6pt'>
</span>the<span style='letter-spacing:-.4pt'> </span>event<span
style='letter-spacing:-.15pt'> </span>where<span style='letter-spacing:-.65pt'>
</span>the<span style='letter-spacing:-.4pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.4pt'> </span>is<span
style='letter-spacing:-.45pt'> </span>disclosed<span style='letter-spacing:
-.15pt'> </span>in<span style='letter-spacing:-.35pt'> </span>illegal<span
style='letter-spacing:-.55pt'> </span>means<span style='letter-spacing:-.45pt'>
</span>shall<span style='letter-spacing:-.35pt'> </span>be<span
style='letter-spacing:-.4pt'> </span>taken<span style='letter-spacing:-.6pt'> </span>by<span
style='letter-spacing:-.35pt'> </span>DentisToday. DentisToday takes the
technical and administrative precautions in order for the processing of
personal data legally, according to the technological possibilities and
implementation costs.</p>

<p class=MsoBodyText align=left style='margin-top:2.4pt;text-align:left'>&nbsp;</p>

<h2 style='margin-right:6.05pt;text-indent:-28.35pt;line-height:115%'><span
style='letter-spacing:-.05pt'>A.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Technical Precautions Taken to Ensure Legal Processing of
Personal Data, and<span style='letter-spacing:-.05pt'> </span>to<span
style='letter-spacing:-.05pt'> </span>Prevent Illegal<span style='letter-spacing:
-.3pt'> </span>Access<span style='letter-spacing:-.15pt'> </span>to<span
style='letter-spacing:-.05pt'> </span>Personal<span style='letter-spacing:-.3pt'>
</span>Data<span style='letter-spacing:-.05pt'> </span>and<span
style='letter-spacing:-.05pt'> </span>to<span style='letter-spacing:-.05pt'> </span>Retain
Personal<span style='letter-spacing:-.3pt'> </span>Data<span style='letter-spacing:
-.05pt'> </span>in a Secure Environment</h2>

<p class=MsoBodyText align=left style='margin-top:1.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:15.75pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>With<span
style='letter-spacing:-.3pt'> </span>regard<span style='letter-spacing:-.3pt'> </span>to<span
style='letter-spacing:-.15pt'> </span>the<span style='letter-spacing:-.1pt'> </span>protection<span
style='letter-spacing:-.3pt'> </span>of<span style='letter-spacing:-.45pt'> </span>personal<span
style='letter-spacing:-.45pt'> </span>data,<span style='letter-spacing:-.2pt'> </span>technical<span
style='letter-spacing:-.45pt'> </span>precautions<span style='letter-spacing:
-.15pt'> </span>shall<span style='letter-spacing:-.05pt'> </span>be taken<span
style='letter-spacing:-.65pt'> </span>as<span style='letter-spacing:-.5pt'> </span>far<span
style='letter-spacing:-.3pt'> </span>as<span style='letter-spacing:-.5pt'> </span>the<span
style='letter-spacing:-.45pt'> </span>technological<span style='letter-spacing:
-.75pt'> </span>possibilities<span style='letter-spacing:-.5pt'> </span>allow<span
style='letter-spacing:-.4pt'> </span>and<span style='letter-spacing:-.4pt'> </span>the<span
style='letter-spacing:-.45pt'> </span>precautions<span style='letter-spacing:
-.5pt'> </span>taken are updated and renewed periodically,</span></p>

<p class=MsoListParagraph align=left style='margin-top:.3pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>A<span style='letter-spacing:-.5pt'>
</span>qualified<span style='letter-spacing:-.15pt'> </span>employee<span
style='letter-spacing:.1pt'> </span>is<span style='letter-spacing:-.2pt'> </span>employed<span
style='letter-spacing:.05pt'> </span>for<span style='letter-spacing:-.25pt'> </span>technical<span
style='letter-spacing:-.1pt'> matters,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Auditing<span style='letter-spacing:
-.1pt'> </span>is<span style='letter-spacing:-.05pt'> </span>made<span
style='letter-spacing:-.1pt'> </span>at<span style='letter-spacing:.05pt'> </span>regular<span
style='letter-spacing:.15pt'> </span>intervals<span style='letter-spacing:-.05pt'>
</span>for<span style='letter-spacing:-.15pt'> </span>the<span
style='letter-spacing:-.2pt'> </span>execution<span style='letter-spacing:-.35pt'>
</span>of<span style='letter-spacing:-.55pt'> </span>precautions<span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>taken,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Software<span style='letter-spacing:
-.1pt'> </span>and<span style='letter-spacing:-.05pt'> </span>hardware<span
style='letter-spacing:-.1pt'> </span>ensure<span style='letter-spacing:-.1pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>security<span style='letter-spacing:-.3pt'>
</span>is<span style='letter-spacing:-.1pt'> established,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.85pt;margin-right:15.6pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Access authority
to personal data processed by DentisToday is classified under the relevant
company employee in line with the intended processing <span style='letter-spacing:
-.1pt'>purpose.</span></span></p>

<p class=MsoBodyText align=left style='margin-top:2.45pt;text-align:left'>&nbsp;</p>

<h2 style='margin-right:5.95pt;text-indent:-28.35pt;line-height:115%'><span
style='letter-spacing:-.05pt'>B.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Administrative Precautions Taken to Ensure Legal Processing of
Personal Data, and to Prevent Illegal Access to Personal Data and to Retain
Personal Data in a Secure Environment</h2>

<p class=MsoBodyText align=left style='margin-top:1.65pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>DentisToday
informs and trains its employees regarding personal data protection law.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.25pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>Contracts
concluded by DentisToday with persons to whom personal data is transferred in
accordance with the law; Provisions are added stating that the persons to whom<span
style='letter-spacing:-.2pt'> </span>personal<span style='letter-spacing:-.2pt'>
</span>data are<span style='letter-spacing:-.25pt'> </span>transferred will
take<span style='letter-spacing:-.25pt'> </span>the necessary<span
style='letter-spacing:-.2pt'> </span>security measures<span style='letter-spacing:
-.25pt'> </span>to<span style='letter-spacing:-.15pt'> </span>protect personal<span
style='letter-spacing:-.55pt'> </span>data<span style='letter-spacing:-.2pt'> </span>and<span
style='letter-spacing:-.15pt'> </span>ensure<span style='letter-spacing:-.2pt'>
</span>that<span style='letter-spacing:-.35pt'> </span>these measures<span
style='letter-spacing:-.25pt'> </span>are<span style='letter-spacing:-.2pt'> </span>complied
with in their own organizations.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.65pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>The processes
carried out by DentisToday should be examined in detail, and the personal data
processing activities carried out within the scope of the process should be determined
specifically for each unit. In this context, the steps<span style='letter-spacing:
-.75pt'> </span>to<span style='letter-spacing:-.55pt'> </span>be<span
style='letter-spacing:-.75pt'> </span>taken<span style='letter-spacing:-.75pt'>
</span>to<span style='letter-spacing:-.5pt'> </span>ensure<span
style='letter-spacing:-.6pt'> </span>that<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.6pt'> </span>data<span style='letter-spacing:-.75pt'> </span>processing<span
style='letter-spacing:-.6pt'> </span>activities<span style='letter-spacing:
-.65pt'> </span>carried<span style='letter-spacing:-.6pt'> </span>out<span
style='letter-spacing:-.55pt'> </span>comply</span></p>

</div>

<span style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection9>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:6.25pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'>with the
personal data processing conditions stipulated in the Personal Data Protection
Law should be determined.</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>DentisToday must
determine the practices that need to be fulfilled to ensure legal<span
style='letter-spacing:-.2pt'> </span>compliance<span style='letter-spacing:
-.05pt'> </span>requirements<span style='letter-spacing:-.1pt'> </span>according<span
style='letter-spacing:-.05pt'> </span>to company<span style='letter-spacing:
-.2pt'> </span>structures, and<span style='letter-spacing:-.2pt'> </span>organize
the necessary administrative measures, in-company policies and training to
ensure the control and continuity of these practices.</span></p>

<p class=MsoListParagraph style='margin-top:.65pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>In the contracts
and documents governing the legal relationship between DentisToday<span
style='letter-spacing:-.6pt'> </span>and<span style='letter-spacing:-.15pt'> </span>employees,<span
style='letter-spacing:-.05pt'> </span>records<span style='letter-spacing:-.2pt'>
</span>are<span style='letter-spacing:-.4pt'> </span>placed<span
style='letter-spacing:-.15pt'> </span>that<span style='letter-spacing:-.15pt'> </span>impose<span
style='letter-spacing:-.15pt'> </span>the<span style='letter-spacing:-.4pt'> </span>obligation<span
style='letter-spacing:-.35pt'> </span>not to<span style='letter-spacing:-.25pt'>
</span>process,<span style='letter-spacing:-.4pt'> </span>disclose<span
style='letter-spacing:-.3pt'> </span>or<span style='letter-spacing:-.4pt'> </span>use<span
style='letter-spacing:-.3pt'> </span>personal<span style='letter-spacing:-.65pt'>
</span>data,<span style='letter-spacing:-.4pt'> </span>except<span
style='letter-spacing:-.25pt'> </span>for DentisToday's<span style='letter-spacing:
-.2pt'> </span>instructions and exceptions imposed by law, and employees'
awareness on this issue is created and inspections are carried out.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>Employees are
informed that they<span style='letter-spacing:-.25pt'> </span>cannot disclose
the personal<span style='letter-spacing:-.25pt'> </span>data<span
style='letter-spacing:-.1pt'> </span>they<span style='letter-spacing:-.05pt'> </span>have
learned to anyone else in violation of the provisions of the Personal Data
Protection<span style='letter-spacing:-.55pt'> </span>Law<span
style='letter-spacing:-.3pt'> </span>or<span style='letter-spacing:-.2pt'> </span>use<span
style='letter-spacing:-.35pt'> </span>it<span style='letter-spacing:-.05pt'> </span>for<span
style='letter-spacing:-.2pt'> </span>purposes<span style='letter-spacing:-.6pt'>
</span>other<span style='letter-spacing:-.45pt'> </span>than<span
style='letter-spacing:-.55pt'> </span>the<span style='letter-spacing:-.35pt'> </span>purpose<span
style='letter-spacing:-.6pt'> </span>of<span style='letter-spacing:-.65pt'> </span>processing,<span
style='letter-spacing:-.2pt'> </span>and that this obligation will continue
after they leave office, and the necessary commitments are taken from them in
this regard.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>The<span
style='letter-spacing:-.45pt'> </span>authorities<span style='letter-spacing:
-.5pt'> </span>of<span style='letter-spacing:-.75pt'> </span>employees<span
style='letter-spacing:-.5pt'> </span>who<span style='letter-spacing:-.15pt'> </span>change<span
style='letter-spacing:-.45pt'> </span>their<span style='letter-spacing:-.3pt'> </span>duties<span
style='letter-spacing:-.5pt'> </span>or<span style='letter-spacing:-.55pt'> </span>leave<span
style='letter-spacing:-.45pt'> </span>their<span style='letter-spacing:-.1pt'> </span>jobs<span
style='letter-spacing:-.25pt'> </span>in<span style='letter-spacing:-.6pt'> </span>this
area are removed.</span></p>

<p class=MsoListParagraph style='margin-left:83.75pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Signed contracts<span
style='letter-spacing:-.1pt'> </span>contain<span style='letter-spacing:-.25pt'>
</span>data<span style='letter-spacing:-.05pt'> </span>security<span
style='letter-spacing:-.45pt'> </span><span style='letter-spacing:-.1pt'>provisions.</span></span></p>

<p class=MsoListParagraph style='margin-top:1.65pt;margin-right:5.8pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Necessary
security measures are taken regarding entry and exit to physical environments
containing personal data.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.85pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Physical
environments containing personal data are secured against external risks (fire,
flood, etc.). The security<span style='letter-spacing:-.1pt'> </span>of<span
style='letter-spacing:-.05pt'> </span>environments containing personal<span
style='letter-spacing:-.1pt'> </span>data is ensured. Personal data is reduced
as much as possible.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.45pt;text-align:left'>&nbsp;</p>

<h2 align=left style='margin-left:41.75pt;text-align:left;text-indent:-17.95pt'><span
style='letter-spacing:-.05pt'>C.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Auditing<span style='letter-spacing:-.25pt'> </span>Activities<span
style='letter-spacing:-.2pt'> </span>on<span style='letter-spacing:-.2pt'> </span>Precautions<span
style='letter-spacing:-.1pt'> </span>Taken<span style='letter-spacing:.3pt'> </span>for<span
style='letter-spacing:-.3pt'> </span>the<span style='letter-spacing:-.05pt'> </span>Protection<span
style='letter-spacing:.05pt'> </span>of<span style='letter-spacing:-.15pt'> </span>Personal<span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.2pt'>Data</span></h2>

<p class=MsoBodyText align=left style='margin-top:4.2pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>In<span
style='letter-spacing:-.7pt'> </span>accordance<span style='letter-spacing:
-.35pt'> </span>with<span style='letter-spacing:-.55pt'> </span>Article<span
style='letter-spacing:-.35pt'> </span>12<span style='letter-spacing:-.3pt'> </span>of<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.35pt'> </span>KVK<span
style='letter-spacing:-.55pt'> </span>Law,<span style='letter-spacing:-.2pt'> </span>DentisToday<span
style='letter-spacing:-.75pt'> </span>carries<span style='letter-spacing:-.4pt'>
</span>out<span style='letter-spacing:-.5pt'> </span>or<span style='letter-spacing:
-.45pt'> </span>has it carried<span style='letter-spacing:-.15pt'> </span>out<span
style='letter-spacing:-.35pt'> </span>the<span style='letter-spacing:-.2pt'> </span>necessary<span
style='letter-spacing:-.6pt'> </span>audits<span style='letter-spacing:-.25pt'>
</span>of<span style='letter-spacing:-.55pt'> </span>the<span style='letter-spacing:
-.45pt'> </span>technical<span style='letter-spacing:-.6pt'> </span>and<span
style='letter-spacing:-.15pt'> </span>administrative measures taken<span
style='letter-spacing:-.45pt'> </span>within<span style='letter-spacing:-.45pt'>
</span>the<span style='letter-spacing:-.25pt'> </span>scope<span
style='letter-spacing:-.5pt'> </span>of<span style='letter-spacing:-.6pt'> </span>protecting<span
style='letter-spacing:-.2pt'> </span>and<span style='letter-spacing:-.2pt'> </span>ensuring<span
style='letter-spacing:-.2pt'> </span>the<span style='letter-spacing:-.25pt'> </span>security<span
style='letter-spacing:-.65pt'> </span>of<span style='letter-spacing:-.6pt'> </span>personal<span
style='letter-spacing:-.4pt'> </span>data. These audit results are reported to
the relevant unit within the scope of DentisToday's internal operation and
necessary activities are carried out to improve the measures taken.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.8pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:5.9pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-indent:-14.2pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In<span
style='letter-spacing:-.55pt'> </span>order<span style='letter-spacing:-.45pt'>
</span>to<span style='letter-spacing:-.2pt'> </span>increase<span
style='letter-spacing:-.35pt'> </span>employees'<span style='letter-spacing:
-.55pt'> </span>knowledge<span style='letter-spacing:-.35pt'> </span>and<span
style='letter-spacing:-.3pt'> </span>awareness<span style='letter-spacing:-.4pt'>
</span>about<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>Protection of Personal<span
style='letter-spacing:-.05pt'> </span>Data, training is repeated at regular
intervals and tests are carried out in parallel with the training to measure
the information. The staff is informed<span style='letter-spacing:-.3pt'> </span>about<span
style='letter-spacing:-.3pt'> </span>the<span style='letter-spacing:-.35pt'> </span>results<span
style='letter-spacing:-.4pt'> </span>of<span style='letter-spacing:-.65pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>test<span style='letter-spacing:-.05pt'> </span>and<span
style='letter-spacing:-.3pt'> </span>any<span style='letter-spacing:-.5pt'> </span>deficiencies<span
style='letter-spacing:-.4pt'> </span>are<span style='letter-spacing:-.35pt'> </span>completed<span
style='letter-spacing:-.05pt'> </span>in<span style='letter-spacing:-.5pt'> </span>the
next training period.</span></p>

</div>

<span style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection10>

<h1 style='margin-top:3.7pt;margin-right:5.95pt;margin-bottom:0in;margin-left:
34.15pt;margin-bottom:.0001pt;line-height:115%'>3.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>CONDITIONS<span style='letter-spacing:4.0pt'> </span>FOR<span
style='letter-spacing:4.0pt'> </span>PROCESSING<span style='letter-spacing:
4.0pt'> </span>OF<span style='letter-spacing:4.0pt'> </span>PERSONAL<span
style='letter-spacing:4.0pt'> </span>DATA<span style='letter-spacing:4.0pt'> </span>OF<span
style='letter-spacing:4.0pt'> </span>SPECIAL <span style='letter-spacing:-.1pt'>NATURE</span></h1>

<p class=MsoBodyText align=left style='margin-top:2.05pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.25pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>DentisToday shows
special sensitivity in the processing of Special Personal Data, whose
protection is<span style='letter-spacing:-.05pt'> </span>believed to be of<span
style='letter-spacing:-.1pt'> </span>more critical importance for<span
style='letter-spacing:-.1pt'> </span>the Data<span style='letter-spacing:-.25pt'>
</span>Owner in<span style='letter-spacing:-.2pt'> </span>various<span
style='letter-spacing:-.05pt'> </span>aspects.</p>

<p class=MsoBodyText align=left style='margin-top:1.95pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.4pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>In Article 6 of
the Law, certain personal data that pose the risk of causing victimization or
discrimination<span style='letter-spacing:-.3pt'> </span>when<span
style='letter-spacing:-.3pt'> </span>processed<span style='letter-spacing:-.05pt'>
</span>unlawfully<span style='letter-spacing:-.3pt'> </span>are<span
style='letter-spacing:-.1pt'> </span>designated<span style='letter-spacing:
-.05pt'> </span>as<span style='letter-spacing:-.15pt'> </span>&quot;special<span
style='letter-spacing:-.3pt'> </span>nature&quot;.<span style='letter-spacing:
-.2pt'> </span>These<span style='letter-spacing:-.1pt'> </span>data;<span
style='letter-spacing:-.3pt'> </span>Data regarding race, ethnic origin,
political thought, philosophical belief, religion, sect or other beliefs,
appearance and clothing, association, foundation or union membership, health,
sexual life, criminal conviction and security measures, as well as biometric
and genetic data.</p>

<p class=MsoBodyText align=left style='margin-top:2.15pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Personal Data of
Special Nature is processed by DentisToday in accordance with the Law, provided
that adequate measures to be determined by the Board are taken, in the presence
of the following conditions:</p>

<p class=MsoBodyText align=left style='margin-top:2.05pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='text-indent:-.25in'><span style='font-size:
12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>If<span style='letter-spacing:
-.5pt'> </span>the<span style='letter-spacing:-.1pt'> </span>Data<span
style='letter-spacing:-.15pt'> </span>Owner has<span style='letter-spacing:
-.2pt'> </span>explicit<span style='letter-spacing:.15pt'> </span>consent<span
style='letter-spacing:.2pt'> </span><span style='letter-spacing:-.25pt'>or</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:5.5pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:115%'><span style='font-size:12.0pt;line-height:115%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>If the Data Owner
does not have explicit consent; <i>a) The relevant person has explicit consent,
b) It is clearly foreseen by law, c) It is necessary for the protection of the
life or physical integrity of the person who is unable to express his consent
due to actual impossibility<span style='letter-spacing:-.35pt'> </span>or<span
style='letter-spacing:-.2pt'> </span>whose<span style='letter-spacing:-.4pt'> </span>consent<span
style='letter-spacing:-.3pt'> </span>is<span style='letter-spacing:-.45pt'> </span>not<span
style='letter-spacing:-.3pt'> </span>given<span style='letter-spacing:-.35pt'> </span>legal<span
style='letter-spacing:-.35pt'> </span>validity,<span style='letter-spacing:
-.25pt'> </span>d)<span style='letter-spacing:-.5pt'> </span>It<span
style='letter-spacing:-.3pt'> </span>is<span style='letter-spacing:-.45pt'> </span>related<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.55pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>personal data made public by the relevant
person and is in accordance with the will to make it public, d) It is mandatory
for the establishment, use or protection of a right, e) The protection of
public health, the execution of preventive medicine, medical diagnosis,
treatment and care services and the planning, management and financing of
health services<span style='letter-spacing:-.75pt'> </span>by<span
style='letter-spacing:-.75pt'> </span>persons<span style='letter-spacing:-.75pt'>
</span>under<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>obligation<span style='letter-spacing:
-.75pt'> </span>of<span style='letter-spacing:-.7pt'> </span>confidentiality<span
style='letter-spacing:-.75pt'> </span>or<span style='letter-spacing:-.75pt'> </span>authorized<span
style='letter-spacing:-.7pt'> </span>institutions<span style='letter-spacing:
-.75pt'> </span>and organizations. f) It is necessary for the fulfillment of
legal obligations in the fields of employment, occupational<span
style='letter-spacing:-.1pt'> </span>health<span style='letter-spacing:-.15pt'>
</span>and<span style='letter-spacing:-.15pt'> </span>safety,<span
style='letter-spacing:-.05pt'> </span>social security, social<span
style='letter-spacing:-.1pt'> </span>services<span style='letter-spacing:-.05pt'>
</span>and social assistance,<span style='letter-spacing:-.75pt'> </span>g)<span
style='letter-spacing:-.5pt'> </span>Foundations,<span style='letter-spacing:
-.55pt'> </span>associations<span style='letter-spacing:-.65pt'> </span>and<span
style='letter-spacing:-.6pt'> </span>other<span style='letter-spacing:-.75pt'> </span>non-profit<span
style='letter-spacing:-.75pt'> </span>organizations<span style='letter-spacing:
-.7pt'> </span>established for political, philosophical, religious or union
purposes. or entities, provided that it is in accordance with the legislation
they are subject to and their purposes, is limited to their fields of activity
and is not disclosed to third parties; It may be processed if it is directed
towards current or former members and members or persons who are in regular
contact with these organizations and entities.</i></span></p>

<p class=MsoBodyText align=left style='margin-top:2.2pt;text-align:left'><i>&nbsp;</i></p>

<h1 style='margin-right:9.5pt;line-height:115%'>3.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>PRECAUTIONS<span style='letter-spacing:-.25pt'> </span>REGARDING<span
style='letter-spacing:-.25pt'> </span>THE<span style='letter-spacing:-.35pt'> </span>PROTECTION<span
style='letter-spacing:-.25pt'> </span>OF<span style='letter-spacing:-.35pt'> </span>PERSONAL<span
style='letter-spacing:-.35pt'> </span>DATA<span style='letter-spacing:-.3pt'> </span>OF
SPECIAL NATURE</h1>

<p class=MsoBodyText align=left style='margin-top:1.75pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>DentisToday, as
the data controller, takes the following precautions for the processing of
Personal<span style='letter-spacing:-.3pt'> </span>Data<span style='letter-spacing:
-.1pt'> </span>of<span style='letter-spacing:-.2pt'> </span>Special<span
style='letter-spacing:-.3pt'> </span>Nature stated under Article 6<span
style='letter-spacing:-.05pt'> </span>of<span style='letter-spacing:-.2pt'> </span>the
Law, pursuant to<span style='letter-spacing:-.05pt'> </span>the Decision<span
style='letter-spacing:-.05pt'> </span>of the Board dated 31.01.2018 and
numbered 2018/10:</p>

<p class=MsoBodyText align=left style='margin-top:1.95pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:6.3pt;
margin-bottom:0in;margin-left:62.45pt;margin-bottom:.0001pt;text-align:left;
text-indent:-28.35pt;line-height:116%'><b><span style='font-size:12.0pt;
line-height:116%;letter-spacing:-.05pt'>A.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:12.0pt;line-height:116%'>A<span
style='letter-spacing:-.65pt'> </span>separate,<span style='letter-spacing:
-.35pt'> </span>systematic,<span style='letter-spacing:-.15pt'> </span>manageable<span
style='letter-spacing:-.5pt'> </span>and<span style='letter-spacing:-.45pt'> </span>sustainable<span
style='letter-spacing:-.5pt'> </span>policy<span style='letter-spacing:-.65pt'>
</span>has<span style='letter-spacing:-.55pt'> </span>been<span
style='letter-spacing:-.65pt'> </span>determined<span style='letter-spacing:
-.2pt'> </span>for the security of personal data of special nature,</span></p>

</div>

<span style='font-size:12.0pt;line-height:116%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection11>

<p class=MsoListParagraph style='margin-top:3.3pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;
line-height:115%'><b><span style='font-size:12.0pt;line-height:115%;letter-spacing:
-.05pt'>B.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:12.0pt;line-height:115%'>With regard
to employees working for the processing of personal data of special <span
style='letter-spacing:-.1pt'>nature,</span></span></p>

<p class=MsoListParagraph style='margin-top:.35pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:111%'><span
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>Training is
provided regularly to employees on the Law and its related regulations and the
security of Personal Data of Special Nature,</span></p>

<p class=MsoListParagraph style='margin-top:.45pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Confidentiality<span
style='letter-spacing:-.55pt'> </span>agreements<span style='letter-spacing:
-.1pt'> </span>are<span style='letter-spacing:-.05pt'> </span><span
style='letter-spacing:-.1pt'>concluded,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>For<span
style='letter-spacing:-.2pt'> </span>users<span style='letter-spacing:-.4pt'> </span>who<span
style='letter-spacing:-.05pt'> </span>have<span style='letter-spacing:-.35pt'> </span>authority<span
style='letter-spacing:-.75pt'> </span>to<span style='letter-spacing:-.3pt'> </span>access<span
style='letter-spacing:-.4pt'> </span>data,<span style='letter-spacing:-.45pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>scope<span style='letter-spacing:-.35pt'>
</span>and<span style='letter-spacing:-.55pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>duration<span style='letter-spacing:-.55pt'>
</span>of<span style='letter-spacing:-.7pt'> </span>their authority are defined
clearly,</span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Authorization<span
style='letter-spacing:-.4pt'> </span>controls<span style='letter-spacing:-.25pt'>
</span>are<span style='letter-spacing:.05pt'> </span>made<span
style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>periodically,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:5.8pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Authorities of
employees whose jobs are changed or who are resigned from their jobs in this
field are removed immediately. Within this context, the inventory<span
style='letter-spacing:-.75pt'> </span>allocated<span style='letter-spacing:
-.35pt'> </span>to<span style='letter-spacing:-.3pt'> </span>such<span
style='letter-spacing:-.55pt'> </span>employees<span style='letter-spacing:
-.2pt'> </span>by<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.4pt'> </span>Data<span style='letter-spacing:-.4pt'> </span>Controller<span
style='letter-spacing:-.05pt'> </span>is<span style='letter-spacing:-.45pt'> </span>returned<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.5pt'> </span>the
Data Controller.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.35pt;margin-bottom:
0in;margin-left:62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;line-height:
115%'><b><span style='font-size:12.0pt;line-height:115%;letter-spacing:-.05pt'>C.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:12.0pt;line-height:115%'>If the environment
that the Personal Data of Special Nature is processed, retained and/or accessed
is an electronic environment,</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Personal<span style='letter-spacing:
-.55pt'> </span>Data<span style='letter-spacing:-.1pt'> </span>is<span
style='letter-spacing:-.1pt'> </span>retained<span style='letter-spacing:.2pt'>
</span>by<span style='letter-spacing:-.5pt'> </span>using cryptographic<span
style='letter-spacing:.2pt'> </span><span style='letter-spacing:-.1pt'>methods,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Cryptographic<span
style='letter-spacing:-.2pt'> </span>keys<span style='letter-spacing:-.15pt'> </span>are<span
style='letter-spacing:-.05pt'> </span>kept<span style='letter-spacing:-.05pt'> </span>in<span
style='letter-spacing:-.25pt'> </span>secure<span style='letter-spacing:-.1pt'>
</span>and<span style='letter-spacing:-.05pt'> </span>different <span
style='letter-spacing:-.1pt'>environments,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.85pt;margin-right:6.1pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Operation records
of all movements realized on the Personal Data are logged <span
style='letter-spacing:-.1pt'>securely.</span></span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Security updates
for the environments where the Personal Data kept are monitored<span
style='letter-spacing:-.4pt'> </span>constantly,<span style='letter-spacing:
-.25pt'> </span>the<span style='letter-spacing:-.4pt'> </span>necessary<span
style='letter-spacing:-.75pt'> </span>security<span style='letter-spacing:-.75pt'>
</span>test<span style='letter-spacing:-.3pt'> </span>is<span style='letter-spacing:
-.45pt'> </span>made<span style='letter-spacing:-.4pt'> </span>regularly<span
style='letter-spacing:-.75pt'> </span>on<span style='letter-spacing:-.6pt'> </span>the<span
style='letter-spacing:-.4pt'> </span>same by us or by others appointed by us,
and test results are recorded.</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.65pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In<span
style='letter-spacing:-.55pt'> </span>case<span style='letter-spacing:-.35pt'> </span>Personal<span
style='letter-spacing:-.75pt'> </span>Data<span style='letter-spacing:-.35pt'> </span>is<span
style='letter-spacing:-.4pt'> </span>accessed<span style='letter-spacing:-.3pt'>
</span>via<span style='letter-spacing:-.35pt'> </span>software,<span
style='letter-spacing:-.45pt'> </span>the<span style='letter-spacing:-.6pt'> </span>authorization<span
style='letter-spacing:-.15pt'> </span>of<span style='letter-spacing:-.7pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>user<span style='letter-spacing:-.2pt'> </span>for
such<span style='letter-spacing:-.75pt'> </span>software<span style='letter-spacing:
-.75pt'> </span>is<span style='letter-spacing:-.75pt'> </span>made,<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>necessary<span
style='letter-spacing:-.75pt'> </span>security<span style='letter-spacing:-.75pt'>
</span>test<span style='letter-spacing:-.75pt'> </span>is<span
style='letter-spacing:-.75pt'> </span>made<span style='letter-spacing:-.75pt'> </span>regularly<span
style='letter-spacing:-.75pt'> </span>on<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>same by us or by others appointed by us,
and test results are recorded.</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:5.2pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In case it is
required to access Personal Data remotely, at least a two-stage identity
verification system is provided.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.1pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.35pt;margin-bottom:
0in;margin-left:62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;line-height:
115%'><b><span style='font-size:12.0pt;line-height:115%;letter-spacing:-.05pt'>D.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:12.0pt;line-height:115%'>If the
environment that the Personal Data of Special Nature is processed, retained
and/or accessed is a physical environment;</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Adequate<span
style='letter-spacing:-.75pt'> </span>security<span style='letter-spacing:-.75pt'>
</span>precautions<span style='letter-spacing:-.75pt'> </span>(against<span
style='letter-spacing:-.75pt'> </span>electrical<span style='letter-spacing:
-.75pt'> </span>leakage,<span style='letter-spacing:-.75pt'> </span>fire,<span
style='letter-spacing:-.75pt'> </span>flood,<span style='letter-spacing:-.75pt'>
</span>theft,<span style='letter-spacing:-.75pt'> </span>etc.) according to the
nature of the environment where Personal Data of Special Nature placed are
taken,</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.8pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Unauthorized
entry<span style='letter-spacing:-.25pt'> </span>and exists are prevented by
ensuring the physical security of such environments.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.1pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-left:62.45pt;text-indent:-28.3pt'><b><span
style='font-size:12.0pt;letter-spacing:-.05pt'>E.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:12.0pt'>In<span style='letter-spacing:
-.25pt'> </span>case<span style='letter-spacing:.05pt'> </span>Personal<span
style='letter-spacing:-.35pt'> </span>Data<span style='letter-spacing:-.2pt'> </span>of<span
style='letter-spacing:-.3pt'> </span>Special<span style='letter-spacing:-.35pt'>
</span>Nature<span style='letter-spacing:.05pt'> </span>is going<span
style='letter-spacing:.1pt'> </span>to<span style='letter-spacing:.1pt'> </span>be<span
style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.1pt'>transferred</span></span></p>

<p class=MsoListParagraph style='margin-top:2.15pt;margin-right:5.65pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In<span
style='letter-spacing:-.3pt'> </span>case<span style='letter-spacing:-.15pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>Personal<span style='letter-spacing:-.55pt'>
</span>Data<span style='letter-spacing:-.15pt'> </span>needs<span
style='letter-spacing:-.2pt'> </span>to be<span style='letter-spacing:-.4pt'> </span>transferred<span
style='letter-spacing:-.1pt'> </span>via<span style='letter-spacing:-.15pt'> </span>e-mail,
it is<span style='letter-spacing:-.2pt'> </span>transferred in encrypted form,
via a corporate e-mail address or by using a Registered Electronic Mail (REM)
account,</span></p>

</div>

<span style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection12>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:5.35pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In<span
style='letter-spacing:-.75pt'> </span>case<span style='letter-spacing:-.7pt'> </span>it<span
style='letter-spacing:-.35pt'> </span>needs<span style='letter-spacing:-.7pt'> </span>to<span
style='letter-spacing:-.55pt'> </span>be<span style='letter-spacing:-.65pt'> </span>transferred<span
style='letter-spacing:-.6pt'> </span>via<span style='letter-spacing:-.65pt'> </span>environments<span
style='letter-spacing:-.7pt'> </span>such<span style='letter-spacing:-.75pt'> </span>as<span
style='letter-spacing:-.7pt'> </span>a<span style='letter-spacing:-.65pt'> </span>Memory<span
style='letter-spacing:-.75pt'> </span>stick,<span style='letter-spacing:-.5pt'>
</span>CD, DVD, it is encrypted by cryptographic methods and the cryptographic
key is kept in a different environment.</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.3pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In case the
transferring is carried out between servers in different physical environments,
data transfer is carried out by establishing a VPN between servers or by the
sFTP method.</span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In case it needs
to be transferred via paper media, necessary precautions are taken against
risks such as theft, loss, or being seen by<span style='letter-spacing:-.1pt'> </span>unauthorized
persons, and the document is sent in a &quot;Confidential&quot; format.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.2pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.8pt;margin-bottom:
0in;margin-left:62.45pt;margin-bottom:.0001pt;text-indent:-28.35pt;line-height:
115%'><b><span style='font-size:12.0pt;line-height:115%;letter-spacing:-.05pt'>F.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:12.0pt;line-height:115%'>In<span
style='letter-spacing:-.75pt'> </span>addition<span style='letter-spacing:-.75pt'>
</span>to<span style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:
-.75pt'> </span>precautions<span style='letter-spacing:-.75pt'> </span>stated<span
style='letter-spacing:-.75pt'> </span>above,<span style='letter-spacing:-.75pt'>
</span>technical<span style='letter-spacing:-.75pt'> </span>and<span
style='letter-spacing:-.75pt'> </span>administrative<span style='letter-spacing:
-.75pt'> </span>precautions are also taken into account for ensuring the
appropriate security<span style='letter-spacing:-.1pt'> </span>level<span
style='letter-spacing:-.1pt'> </span>stated under the<span style='letter-spacing:
-.25pt'> </span>Personal<span style='letter-spacing:-.65pt'> </span>Data<span
style='letter-spacing:-.25pt'> </span>Security<span style='letter-spacing:-.65pt'>
</span>Guidance<span style='letter-spacing:-.25pt'> </span>announced<span
style='letter-spacing:-.2pt'> </span>on<span style='letter-spacing:-.65pt'> </span>the<span
style='letter-spacing:-.25pt'> </span>website<span style='letter-spacing:-.25pt'>
</span>of<span style='letter-spacing:-.6pt'> </span>the<span style='letter-spacing:
-.25pt'> </span>Protection<span style='letter-spacing:-.45pt'> </span>of
Personal Data Authority</span></p>

<p class=MsoBodyText align=left style='text-align:left'>&nbsp;</p>

<p class=MsoBodyText align=left style='margin-top:4.45pt;text-align:left'>&nbsp;</p>

<h1 style='margin-top:.05pt;margin-right:0in;margin-bottom:0in;margin-left:
26.85pt;margin-bottom:.0001pt;text-indent:-21.05pt'>3.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span>PROCESSING<span style='letter-spacing:-.1pt'> </span>OF<span
style='letter-spacing:-.15pt'> </span>IMAGE<span style='letter-spacing:-.1pt'>
RECORDS</span></h1>

<p class=MsoBodyText align=left style='margin-top:1.9pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:107%'>DentisToday uses
intraoral photographs, which are the subject of its activity, to provide the
necessary information to direct you to your preferred physician or clinic
according to the preliminary diagnosis and application, if necessary, with
artificial intelligence technology, as well<span style='letter-spacing:-.75pt'>
</span>as<span style='letter-spacing:-.5pt'> </span>to<span style='letter-spacing:
-.35pt'> </span>ensure<span style='letter-spacing:-.45pt'> </span>security<span
style='letter-spacing:-.6pt'> </span>in<span style='letter-spacing:-.6pt'> </span>all<span
style='letter-spacing:-.4pt'> </span>its<span style='letter-spacing:-.5pt'> </span>buildings<span
style='letter-spacing:-.5pt'> </span>and<span style='letter-spacing:-.4pt'> </span>facilities,<span
style='letter-spacing:-.3pt'> </span>units<span style='letter-spacing:-.5pt'> </span>and<span
style='letter-spacing:-.15pt'> </span>branches.<span style='letter-spacing:
-.3pt'> </span>It<span style='letter-spacing:-.35pt'> </span>stores<span
style='letter-spacing:-.5pt'> </span>personal data and processes it when
necessary, in accordance with the provisions of<span style='letter-spacing:
-.05pt'> </span>the Law and other legislation and the principles contained in
the Policy, for the purpose of monitoring entrances and exits with security
cameras.</p>

<p class=MsoBodyText align=left style='margin-top:9.1pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.2pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:107%'>Our company<span
style='letter-spacing:-.5pt'> </span>policy<span style='letter-spacing:-.25pt'>
</span>on<span style='letter-spacing:-.25pt'> </span>the<span style='letter-spacing:
-.05pt'> </span>use<span style='letter-spacing:-.05pt'> </span>of<span
style='letter-spacing:-.15pt'> </span>Artificial<span style='letter-spacing:
-.25pt'> </span>Intelligence<span style='letter-spacing:-.05pt'> </span>has<span
style='letter-spacing:-.1pt'> </span>been<span style='letter-spacing:-.25pt'> </span>prepared
in<span style='letter-spacing:-.25pt'> </span>accordance<span style='letter-spacing:
-.05pt'> </span>with the<span style='letter-spacing:-.75pt'> </span>&quot;Recommendations<span
style='letter-spacing:-.75pt'> </span>on<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>Protection<span style='letter-spacing:
-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>Personal<span
style='letter-spacing:-.75pt'> </span>Data<span style='letter-spacing:-.75pt'> </span>in<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>Field<span
style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>Artificial<span
style='letter-spacing:-.75pt'> </span>Intelligence&quot; guide published by Law
Numbered 6698. (“KVKK”)</p>

<p class=MsoBodyText style='margin-top:7.9pt;margin-right:6.2pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:107%'>For<span
style='letter-spacing:-.75pt'> </span>this<span style='letter-spacing:-.75pt'> </span>purpose,<span
style='letter-spacing:-.75pt'> </span>we<span style='letter-spacing:-.75pt'> </span>act<span
style='letter-spacing:-.75pt'> </span>in<span style='letter-spacing:-.75pt'> </span>accordance<span
style='letter-spacing:-.75pt'> </span>with<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>following<span style='letter-spacing:
-.75pt'> </span>board<span style='letter-spacing:-.75pt'> </span>recommendations<span
style='letter-spacing:-.75pt'> </span>in<span style='letter-spacing:-.75pt'> </span>our<span
style='letter-spacing:-.75pt'> </span>service delivery and development
processes:</p>

<p class=MsoListParagraph style='margin-top:8.2pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-7.7pt;
line-height:105%'><span style='font-size:12.0pt;line-height:105%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:105%'>An approach based on personal data
privacy is taken into consideration in the design, consistent with national and
international regulations and/or documents.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.65pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.6pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>A cautious approach based on
appropriate risk prevention and mitigation measures is adopted,<span
style='letter-spacing:-.25pt'> </span>taking into account<span
style='letter-spacing:-.1pt'> </span>the<span style='letter-spacing:-.15pt'> </span>possible
negative consequences<span style='letter-spacing:-.2pt'> </span>on<span
style='letter-spacing:-.1pt'> </span>fundamental<span style='letter-spacing:
-.55pt'> </span>rights and freedoms.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.45pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.8pt;text-indent:-7.7pt;
line-height:107%'><span style='font-size:12.0pt;line-height:107%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:107%'>At every stage of data processing,
including data collection, fundamental rights and freedoms are respected, and
the risk of discrimination or other negative effects and prejudices that may
occur on the relevant persons are prevented.</span></p>

</div>

<span style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection13>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:5.9pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>Minimum data usage should be achieved
by evaluating the quality, nature, source, quantity,<span style='letter-spacing:
-.15pt'> </span>category<span style='letter-spacing:-.65pt'> </span>and<span
style='letter-spacing:-.25pt'> </span>content<span style='letter-spacing:-.25pt'>
</span>of<span style='letter-spacing:-.65pt'> </span>the<span style='letter-spacing:
-.3pt'> </span>personal<span style='letter-spacing:-.65pt'> </span>data<span
style='letter-spacing:-.55pt'> </span>used;<span style='letter-spacing:-.45pt'>
</span>The<span style='letter-spacing:-.3pt'> </span>accuracy<span
style='letter-spacing:-.65pt'> </span>of<span style='letter-spacing:-.65pt'> </span>the<span
style='letter-spacing:-.3pt'> </span>developed model is constantly monitored.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.4pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:6.4pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>Algorithm models taken out of context
are carefully evaluated for the risk of causing negative effects on individuals
and society.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.35pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>Academic institutions that can
contribute to the design of human rights-based, ethical and socially oriented
artificial intelligence applications and the detection of potential biases
should be contacted;<span style='letter-spacing:-.05pt'> </span>Opinions of
impartial<span style='letter-spacing:-.05pt'> </span>experts and organizations
are sought in areas where transparency and stakeholder participation may be
difficult.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.55pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:6.3pt;text-indent:-7.7pt;
line-height:105%'><span style='font-size:12.0pt;line-height:105%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:105%'>Individuals<span style='letter-spacing:
-.15pt'> </span>are<span style='letter-spacing:-.15pt'> </span>given<span
style='letter-spacing:-.35pt'> </span>the<span style='letter-spacing:-.15pt'> </span>right
to<span style='letter-spacing:-.1pt'> </span>object to processing based<span
style='letter-spacing:-.1pt'> </span>on<span style='letter-spacing:-.35pt'> </span>technologies<span
style='letter-spacing:-.2pt'> </span>that affect their views and personal
development.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.65pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.75pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>Considering the power of artificial
intelligence systems to analyze and use personal data,<span style='letter-spacing:
-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>rights<span
style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>relevant<span style='letter-spacing:-.35pt'>
</span>persons<span style='letter-spacing:-.7pt'> </span>arising<span
style='letter-spacing:-.35pt'> </span>from<span style='letter-spacing:-.7pt'> </span>national<span
style='letter-spacing:-.75pt'> </span>and<span style='letter-spacing:-.35pt'> </span>international<span
style='letter-spacing:-.75pt'> </span>legislation are protected in the
processing of personal data.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.4pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:6.05pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>Risk assessment based on the active
participation of individuals and groups that are likely to be particularly
affected by the practices is encouraged.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.9pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>Products and services are designed to
ensure that individuals are not exposed to a decision that will affect them
based on automatic processing, without taking into account their own opinions.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.45pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.8pt;text-indent:-7.7pt;
line-height:105%'><span style='font-size:12.0pt;line-height:105%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:105%'>Alternatives<span style='letter-spacing:
-.55pt'> </span>that<span style='letter-spacing:-.15pt'> </span>interfere<span
style='letter-spacing:-.2pt'> </span>less<span style='letter-spacing:-.5pt'> </span>with<span
style='letter-spacing:-.65pt'> </span>personal<span style='letter-spacing:-.75pt'>
</span>rights<span style='letter-spacing:-.5pt'> </span>should<span
style='letter-spacing:-.15pt'> </span>be<span style='letter-spacing:-.45pt'> </span>offered<span
style='letter-spacing:-.15pt'> </span>in<span style='letter-spacing:-.2pt'> </span>production,<span
style='letter-spacing:-.3pt'> </span>and users' freedom of choice is
guaranteed.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.65pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.9pt;text-indent:-7.7pt;
line-height:107%'><span style='font-size:12.0pt;line-height:107%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:107%'>Algorithms are adopted to ensure
accountability for all stakeholders in terms of compliance with<span
style='letter-spacing:-.2pt'> </span>personal<span style='letter-spacing:-.2pt'>
</span>data protection law, starting from<span style='letter-spacing:-.4pt'> </span>the
design<span style='letter-spacing:-.2pt'> </span>of<span style='letter-spacing:
-.35pt'> </span>products<span style='letter-spacing:-.05pt'> </span>and
services throughout their life cycle.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.0pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:6.3pt;text-indent:-7.7pt;
line-height:106%'><span style='font-size:12.0pt;line-height:106%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:106%'>The<span style='letter-spacing:-.75pt'>
</span>user's<span style='letter-spacing:-.7pt'> </span>right<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.5pt'> </span>stop<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.75pt'> </span>processing<span
style='letter-spacing:-.55pt'> </span>should<span style='letter-spacing:-.35pt'>
</span>be<span style='letter-spacing:-.6pt'> </span>granted<span
style='letter-spacing:-.55pt'> </span>and<span style='letter-spacing:-.55pt'> </span>the<span
style='letter-spacing:-.6pt'> </span>possibility<span style='letter-spacing:
-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>deleting,
destroying or anonymizing user data is envisaged.</span></p>

<p class=MsoBodyText align=left style='margin-top:1.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.8pt;text-indent:-7.7pt;
line-height:107%'><span style='font-size:12.0pt;line-height:107%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:107%'>Persons interacting with the
application should be informed about the reasons for the personal data
processing activity, the details of the methods used in the processing of
personal<span style='letter-spacing:-.55pt'> </span>data<span style='letter-spacing:
-.2pt'> </span>and<span style='letter-spacing:-.15pt'> </span>the<span
style='letter-spacing:-.2pt'> </span>possible<span style='letter-spacing:-.2pt'>
</span>consequences,<span style='letter-spacing:-.05pt'> </span>and<span
style='letter-spacing:-.15pt'> </span>an<span style='letter-spacing:-.35pt'> </span>effective<span
style='letter-spacing:-.2pt'> </span>data<span style='letter-spacing:-.2pt'> </span>processing<span
style='letter-spacing:-.15pt'> </span>approval mechanism is designed when
necessary.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.1pt;text-align:left'>&nbsp;</p>

<h1>3.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>TRANSFER<span style='letter-spacing:-.15pt'> </span>OF<span
style='letter-spacing:-.15pt'> </span>PERSONAL<span style='letter-spacing:-.15pt'>
</span><span style='letter-spacing:-.2pt'>DATA</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.9pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.95pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>DentisToday, may<span
style='letter-spacing:-.2pt'> </span>transfer Personal<span style='letter-spacing:
-.2pt'> </span>Data and/or Personal<span style='letter-spacing:-.4pt'> </span>Data
of<span style='letter-spacing:-.35pt'> </span>Special<span style='letter-spacing:
-.2pt'> </span>Nature belonging to the Data<span style='letter-spacing:-.1pt'> </span>Owner
that it legally<span style='letter-spacing:-.3pt'> </span>obtained, in<span
style='letter-spacing:-.05pt'> </span>accordance with<span style='letter-spacing:
-.05pt'> </span>the purpose<span style='letter-spacing:-.1pt'> </span>of<span
style='letter-spacing:-.2pt'> </span>processing data, by taking<span
style='letter-spacing:1.6pt'> </span>necessary<span style='letter-spacing:1.35pt'>
</span>security<span style='letter-spacing:1.15pt'> </span>precautions,<span
style='letter-spacing:1.7pt'> </span>to<span style='letter-spacing:1.65pt'> </span>third<span
style='letter-spacing:1.6pt'> </span>persons<span style='letter-spacing:1.5pt'>
</span>on<span style='letter-spacing:1.35pt'> </span>a<span style='letter-spacing:
1.55pt'> </span>limited<span style='letter-spacing:1.6pt'> </span>basis.<span
style='letter-spacing:1.7pt'> </span>In<span style='letter-spacing:1.35pt'> </span>this<span
style='letter-spacing:1.5pt'> </span>direction,</p>

</div>

<span style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection14>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:6.35pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>DentisToday<span
style='letter-spacing:-.3pt'> </span>can<span style='letter-spacing:-.05pt'> </span>transfer
Personal<span style='letter-spacing:-.3pt'> </span>Data<span style='letter-spacing:
-.35pt'> </span>to<span style='letter-spacing:-.05pt'> </span>third persons, in<span
style='letter-spacing:-.05pt'> </span>the circumstance where one of<span
style='letter-spacing:-.45pt'> </span>the conditions stated under this Policy
or one of the conditions specified below.</p>

<p class=MsoBodyText align=left style='margin-top:2.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>In<span style='letter-spacing:
-.35pt'> </span>case<span style='letter-spacing:-.1pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>Data<span style='letter-spacing:-.1pt'> </span>Owner<span
style='letter-spacing:-.05pt'> </span>grants<span style='letter-spacing:-.15pt'>
</span>her/his<span style='letter-spacing:-.15pt'> </span>explicit<span
style='letter-spacing:.2pt'> </span><span style='letter-spacing:-.1pt'>consent,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.85pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>In<span style='letter-spacing:
-.35pt'> </span>case the transfer<span style='letter-spacing:.1pt'> </span>of<span
style='letter-spacing:-.35pt'> </span>Personal<span style='letter-spacing:-.4pt'>
</span>Data<span style='letter-spacing:.2pt'> </span>is<span style='letter-spacing:
-.05pt'> </span>regulated<span style='letter-spacing:.05pt'> </span>explicitly<span
style='letter-spacing:-.4pt'> </span>under<span style='letter-spacing:.1pt'> </span>the
<span style='letter-spacing:-.1pt'>Laws,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:5.85pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In case the
transfer of Personal<span style='letter-spacing:-.05pt'> </span>Data is
mandatory<span style='letter-spacing:-.05pt'> </span>for the protection of life
or physical<span style='letter-spacing:-.25pt'> </span>integrity<span
style='letter-spacing:-.5pt'> </span>of<span style='letter-spacing:-.4pt'> </span>the<span
style='letter-spacing:-.05pt'> </span>Data<span style='letter-spacing:-.05pt'> </span>Owner<span
style='letter-spacing:-.15pt'> </span>or<span style='letter-spacing:-.15pt'> </span>any<span
style='letter-spacing:-.5pt'> </span>other persons<span style='letter-spacing:
-.1pt'> </span>and the<span style='letter-spacing:-.05pt'> </span>Data<span
style='letter-spacing:-.05pt'> </span>Owner is incapable<span style='letter-spacing:
-.15pt'> </span>of<span style='letter-spacing:-.5pt'> </span>giving her/his
consent due<span style='letter-spacing:-.4pt'> </span>to<span style='letter-spacing:
-.1pt'> </span>the actual<span style='letter-spacing:-.35pt'> </span>impossibility<span
style='letter-spacing:-.55pt'> </span>or<span style='letter-spacing:-.05pt'> </span>her/his
consent is not deemed legally valid,</span></p>

<p class=MsoListParagraph style='margin-top:.4pt;margin-right:5.95pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In case the
transfer of Personal Data belonging to the parties of an agreement is<span
style='letter-spacing:-.75pt'> </span>necessary<span style='letter-spacing:
-.75pt'> </span>provided<span style='letter-spacing:-.75pt'> </span>that<span
style='letter-spacing:-.75pt'> </span>it<span style='letter-spacing:-.6pt'> </span>is<span
style='letter-spacing:-.75pt'> </span>directly<span style='letter-spacing:-.75pt'>
</span>related<span style='letter-spacing:-.65pt'> </span>to<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>establishment<span
style='letter-spacing:-.45pt'> </span>or<span style='letter-spacing:-.6pt'> </span>execution
of such agreement,</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.85pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In<span
style='letter-spacing:-.25pt'> </span>case<span style='letter-spacing:-.05pt'> </span>the<span
style='letter-spacing:-.05pt'> </span>transfer of<span style='letter-spacing:
-.4pt'> </span>Personal<span style='letter-spacing:-.45pt'> </span>Data is
mandatory<span style='letter-spacing:-.5pt'> </span>for DentisToday<span
style='letter-spacing:-.5pt'> </span>to perform its legal obligations,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In case Personal
Data is made available to the public by the Data Owner, <span style='letter-spacing:
-.1pt'>her/himself,</span></span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:111%'><span
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>In case the
transfer of Personal Data is mandatory for the establishment, execution or
protection of a right,</span></p>

<p class=MsoListParagraph style='margin-top:.5pt;margin-right:5.9pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In<span
style='letter-spacing:-.35pt'> </span>case<span style='letter-spacing:-.15pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>transfer<span style='letter-spacing:-.05pt'>
</span>of<span style='letter-spacing:-.45pt'> </span>Personal<span
style='letter-spacing:-.5pt'> </span>Data<span style='letter-spacing:-.15pt'> </span>is
mandatory<span style='letter-spacing:-.55pt'> </span>for<span style='letter-spacing:
-.25pt'> </span>the legitimate<span style='letter-spacing:-.15pt'> </span>interest<span
style='letter-spacing:-.1pt'> </span>of DentisToday,<span style='letter-spacing:
-.75pt'> </span>provided<span style='letter-spacing:-.75pt'> </span>that<span
style='letter-spacing:-.75pt'> </span>this<span style='letter-spacing:-.75pt'> </span>transfer<span
style='letter-spacing:-.75pt'> </span>shall<span style='letter-spacing:-.75pt'>
</span>not<span style='letter-spacing:-.75pt'> </span>violate<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>fundamental<span
style='letter-spacing:-.75pt'> </span>rights and freedoms of the Data Owner.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.7pt;text-align:left'>&nbsp;</p>

<h1 style='margin-left:26.85pt;text-indent:-21.05pt'>3.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span>TRANSFER<span
style='letter-spacing:-.15pt'> </span>OF<span style='letter-spacing:-.1pt'> </span>PERSONAL<span
style='letter-spacing:-.15pt'> </span>DATA<span style='letter-spacing:-.1pt'>
ABROAD</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.8pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>As<span
style='letter-spacing:-.3pt'> </span>a<span style='letter-spacing:-.2pt'> </span>rule,<span
style='letter-spacing:-.05pt'> </span>DentisToday<span style='letter-spacing:
-.65pt'> </span>does<span style='letter-spacing:-.25pt'> </span>not<span
style='letter-spacing:-.35pt'> </span>transfer<span style='letter-spacing:-.1pt'>
</span>Personal<span style='letter-spacing:-.6pt'> </span>Data<span
style='letter-spacing:-.45pt'> </span>abroad.<span style='letter-spacing:-.3pt'>
</span>However,<span style='letter-spacing:-.05pt'> </span>it declares<span
style='letter-spacing:-.25pt'> </span>that<span style='letter-spacing:-.15pt'> </span>it
can make transfers in the event of the following situations, provided that it
takes the necessary precautions<span style='letter-spacing:-.75pt'> </span>and<span
style='letter-spacing:-.75pt'> </span>precautions<span style='letter-spacing:
-.75pt'> </span>and<span style='letter-spacing:-.75pt'> </span>complies<span
style='letter-spacing:-.75pt'> </span>with<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>legislation.<span style='letter-spacing:
-.75pt'> </span>In<span style='letter-spacing:-.75pt'> </span>line<span
style='letter-spacing:-.75pt'> </span>with<span style='letter-spacing:-.75pt'> </span>legitimate<span
style='letter-spacing:-.75pt'> </span>and<span style='letter-spacing:-.75pt'> </span>lawful
Personal<span style='letter-spacing:-.45pt'> </span>Data<span style='letter-spacing:
-.1pt'> </span>processing<span style='letter-spacing:-.05pt'> </span>purposes,
the<span style='letter-spacing:-.1pt'> </span>Data<span style='letter-spacing:
-.1pt'> </span>Owner's<span style='letter-spacing:-.15pt'> </span>personal<span
style='letter-spacing:-.3pt'> </span>data may<span style='letter-spacing:-.05pt'>
</span>be<span style='letter-spacing:-.1pt'> </span>transferred if<span
style='letter-spacing:-.2pt'> </span>there is explicit consent or if<span
style='letter-spacing:-.25pt'> </span>the Data<span style='letter-spacing:-.15pt'>
</span>Owner does not have explicit consent, if<span style='letter-spacing:
-.25pt'> </span>there is an<span style='letter-spacing:-.1pt'> </span>adequacy
decision<span style='letter-spacing:-.5pt'> </span>regarding<span
style='letter-spacing:-.25pt'> </span>the<span style='letter-spacing:-.3pt'> </span>country<span
style='letter-spacing:-.7pt'> </span>to<span style='letter-spacing:-.25pt'> </span>which<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:-.3pt'> </span>transfer<span
style='letter-spacing:-.15pt'> </span>will<span style='letter-spacing:-.2pt'> </span>be<span
style='letter-spacing:-.05pt'> </span>made,<span style='letter-spacing:-.15pt'>
</span>sectors<span style='letter-spacing:-.35pt'> </span>within<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:-.3pt'> </span>country<span
style='letter-spacing:-.7pt'> </span>or international organizations.</p>

<p class=MsoBodyText align=left style='margin-top:2.1pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.3pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>All data
collected through DentisToday's mobile application and website are stored in a
domestic cloud system, and there is no international data transfer in this
data.</p>

<p class=MsoBodyText align=left style='margin-top:2.25pt;text-align:left'>&nbsp;</p>

<h1 style='margin-right:5.95pt;line-height:115%'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>PRINCIPLES<span style='letter-spacing:4.0pt'> </span>REGARDING<span
style='letter-spacing:2.0pt'> </span>THE<span style='letter-spacing:2.0pt'> </span>RETENTION<span
style='letter-spacing:2.0pt'> </span>PERIODS<span style='letter-spacing:4.0pt'>
</span>OF<span style='letter-spacing:2.0pt'> </span>PERSONAL <span
style='letter-spacing:-.2pt'>DATA</span></h1>

<p class=MsoBodyText align=left style='margin-top:1.75pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Personal data is
retained by DentisToday, for the period of time specified under the related
legislation<span style='letter-spacing:-.1pt'> </span>and in<span
style='letter-spacing:-.1pt'> </span>accordance with its legal<span
style='letter-spacing:-.3pt'> </span>obligations. The<span style='letter-spacing:
-.15pt'> </span>retention<span style='letter-spacing:-.1pt'> </span>periods are
shown<span style='letter-spacing:-.15pt'> </span>under the Data Controller
Registry Information (Verbis) system, publicly.</p>

</div>

<span style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection15>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:5.65pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>In case the
period of time for the retention of personal data has not been regulated under
the related<span style='letter-spacing:-.75pt'> </span>legislation,<span
style='letter-spacing:-.75pt'> </span>in<span style='letter-spacing:-.75pt'> </span>connection<span
style='letter-spacing:-.75pt'> </span>with<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>activity<span style='letter-spacing:-.75pt'>
</span>that<span style='letter-spacing:-.5pt'> </span>DentisToday<span
style='letter-spacing:-.75pt'> </span>conducted<span style='letter-spacing:
-.65pt'> </span>while<span style='letter-spacing:-.75pt'> </span>processing
such<span style='letter-spacing:-.25pt'> </span>data,<span style='letter-spacing:
-.35pt'> </span>the<span style='letter-spacing:-.05pt'> </span>personal<span
style='letter-spacing:-.45pt'> </span>data<span style='letter-spacing:-.3pt'> </span>is<span
style='letter-spacing:-.1pt'> </span>processed by<span style='letter-spacing:
-.2pt'> </span>DentisToday<span style='letter-spacing:-.15pt'> </span>for a<span
style='letter-spacing:-.3pt'> </span>period<span style='letter-spacing:-.25pt'>
</span>of<span style='letter-spacing:-.4pt'> </span>time<span style='letter-spacing:
-.05pt'> </span>that requires it to be processed in line with<span
style='letter-spacing:-.1pt'> </span>the practices of<span style='letter-spacing:
-.15pt'> </span>DentisToday<span style='letter-spacing:-.2pt'> </span>and
commercial<span style='letter-spacing:-.3pt'> </span>practices and then it is
erased, destructed or anonymized. Personal data of which processing purpose is
disappeared and<span style='letter-spacing:-.75pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.75pt'> </span>that<span
style='letter-spacing:-.75pt'> </span>is<span style='letter-spacing:-.75pt'> </span>required<span
style='letter-spacing:-.75pt'> </span>to<span style='letter-spacing:-.75pt'> </span>be<span
style='letter-spacing:-.75pt'> </span>erased/anonymized<span style='letter-spacing:
-.75pt'> </span>by<span style='letter-spacing:-.75pt'> </span>its<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.75pt'> </span>owners<span
style='letter-spacing:-.75pt'> </span>and<span style='letter-spacing:-.75pt'> </span>if<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>retention
period<span style='letter-spacing:-.1pt'> </span>of<span style='letter-spacing:
-.2pt'> </span>time determined by<span style='letter-spacing:-.1pt'> </span>the
related legislation<span style='letter-spacing:-.1pt'> </span>and DentisToday
has lapsed, it is retained only<span style='letter-spacing:-.05pt'> </span>to
constitute evidence in the potential legal<span style='letter-spacing:-.05pt'> </span>disputes
or to claim the relevant right related to<span style='letter-spacing:-.05pt'> </span>personal<span
style='letter-spacing:-.5pt'> </span>data<span style='letter-spacing:-.35pt'> </span>or<span
style='letter-spacing:-.45pt'> </span>to establish<span style='letter-spacing:
-.3pt'> </span>a<span style='letter-spacing:-.1pt'> </span>defence.
DentisToday, while<span style='letter-spacing:-.1pt'> </span>determining<span
style='letter-spacing:-.05pt'> </span>the<span style='letter-spacing:-.1pt'> </span>retention<span
style='letter-spacing:-.3pt'> </span>period of<span style='letter-spacing:-.75pt'>
</span>time<span style='letter-spacing:-.75pt'> </span>of<span
style='letter-spacing:-.75pt'> </span>personal<span style='letter-spacing:-.75pt'>
</span>data,<span style='letter-spacing:-.75pt'> </span>base<span
style='letter-spacing:-.6pt'> </span>on<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.55pt'> </span>statute<span style='letter-spacing:-.75pt'>
</span>of<span style='letter-spacing:-.65pt'> </span>limitations<span
style='letter-spacing:-.6pt'> </span>specified<span style='letter-spacing:-.5pt'>
</span>under<span style='letter-spacing:-.65pt'> </span>the<span
style='letter-spacing:-.55pt'> </span>related<span style='letter-spacing:-.5pt'>
</span>legislation. Personal data retained for such purpose can be accessed
only by limited persons only when it is required to be used in a legal dispute
and such data cannot be used for purposes other than this. At the end of such
period of time, personal data is erased, destructed or anonymized.</p>

<p class=MsoBodyText align=left style='text-align:left'>&nbsp;</p>

<p class=MsoBodyText align=left style='margin-top:4.5pt;text-align:left'>&nbsp;</p>

<h1 style='margin-left:.25in;text-indent:-12.2pt'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span>DELETION,<span style='letter-spacing:-.1pt'> </span>DESTRUCTION<span
style='letter-spacing:-.15pt'> </span>AND<span style='letter-spacing:-.2pt'> </span>ANONYMIZATION<span
style='letter-spacing:-.15pt'> </span>OF<span style='letter-spacing:-.25pt'> </span>PERSONAL<span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.2pt'>DATA</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.8pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:6.25pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Although Personal
Data has been processed in accordance with the provisions of the relevant law<span
style='letter-spacing:-.3pt'> </span>as<span style='letter-spacing:-.4pt'> </span>regulated<span
style='letter-spacing:-.3pt'> </span>in<span style='letter-spacing:-.55pt'> </span>Article<span
style='letter-spacing:-.35pt'> </span>7<span style='letter-spacing:-.3pt'> </span>of<span
style='letter-spacing:-.7pt'> </span>the<span style='letter-spacing:-.35pt'> </span>Law<span
style='letter-spacing:-.3pt'> </span>and<span style='letter-spacing:-.3pt'> </span>Article<span
style='letter-spacing:-.15pt'> </span>138<span style='letter-spacing:-.55pt'> </span>of<span
style='letter-spacing:-.7pt'> </span>the<span style='letter-spacing:-.35pt'> </span>Turkish<span
style='letter-spacing:-.55pt'> </span>Penal<span style='letter-spacing:-.5pt'> </span>Code,<span
style='letter-spacing:-.25pt'> </span>it<span style='letter-spacing:-.1pt'> </span>is<span
style='letter-spacing:-.4pt'> </span>deleted by DentisToday ex officio or upon
the request of the personal data owner, if the reasons requiring processing are
eliminated. , is destroyed or made anonymous.</p>

<p class=MsoBodyText align=left style='margin-top:2.15pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.15pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Article<span
style='letter-spacing:-.4pt'> </span>7<span style='letter-spacing:-.55pt'> </span>of<span
style='letter-spacing:-.7pt'> </span>KVKK<span style='letter-spacing:-.6pt'> </span>also<span
style='letter-spacing:-.1pt'> </span>regulates<span style='letter-spacing:-.4pt'>
</span>the<span style='letter-spacing:-.4pt'> </span>deletion,<span
style='letter-spacing:-.25pt'> </span>destruction<span style='letter-spacing:
-.55pt'> </span>and<span style='letter-spacing:-.35pt'> </span>anonymization<span
style='letter-spacing:-.55pt'> </span>of<span style='letter-spacing:-.7pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data. Accordingly, even<span
style='letter-spacing:-.05pt'> </span>though<span style='letter-spacing:-.05pt'>
</span>personal<span style='letter-spacing:-.25pt'> </span>data has been
processed in<span style='letter-spacing:-.05pt'> </span>accordance with<span
style='letter-spacing:-.05pt'> </span>the law, if the reasons requiring
processing are eliminated, these data may be processed ex officio or in the <span
style='letter-spacing:-.1pt'>relevant</span></p>

<p class=MsoBodyText align=left style='margin-top:1.95pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:18.95pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:230%'>It<span
style='letter-spacing:-.05pt'> </span>is<span style='letter-spacing:-.15pt'> </span>deleted,
destroyed<span style='letter-spacing:-.05pt'> </span>or<span style='letter-spacing:
-.2pt'> </span>made<span style='letter-spacing:-.1pt'> </span>anonymous by<span
style='letter-spacing:-.55pt'> </span>our company<span style='letter-spacing:
-.55pt'> </span>upon<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>request<span style='letter-spacing:-.05pt'>
</span>of<span style='letter-spacing:-.45pt'> </span>the<span style='letter-spacing:
-.1pt'> </span>person. According to this;</p>

<p class=MsoListParagraph style='margin-right:5.95pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Amendment or
abolition of<span style='letter-spacing:-.05pt'> </span>the relevant
legislative provisions that constitute the basis for processing personal data,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>The contract
between the parties has never been established, the contract is not valid, the
contract terminates automatically, the contract is terminated or the contract
is <span style='letter-spacing:-.1pt'>withdrawn,</span></span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:6.3pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:111%'><span style='font-size:12.0pt;line-height:111%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>The purpose
requiring the processing of personal data is eliminated, It is determined that
processing personal data is against the law or the rule of honesty,</span></p>

<p class=MsoListParagraph style='margin-top:.75pt;margin-right:6.3pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:111%'><span style='font-size:12.0pt;line-height:111%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>In cases where
personal data is processed only on the basis of explicit consent, the relevant
person withdraws his/her consent,</span></p>

<p class=MsoListParagraph style='margin-top:.5pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Our company
accepts the application made by the relevant person regarding the processing<span
style='letter-spacing:-.25pt'> </span>of<span style='letter-spacing:-.65pt'> </span>personal<span
style='letter-spacing:-.7pt'> </span>data<span style='letter-spacing:-.3pt'> </span>within<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:-.05pt'> </span>framework<span
style='letter-spacing:-.5pt'> </span>of<span style='letter-spacing:-.4pt'> </span>his<span
style='letter-spacing:-.35pt'> </span>rights<span style='letter-spacing:-.1pt'>
</span>in<span style='letter-spacing:-.25pt'> </span>subparagraphs<span
style='letter-spacing:-.35pt'> </span>(e)<span style='letter-spacing:-.15pt'> </span>and</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0in;margin-bottom:
0in;margin-left:41.8pt;margin-bottom:.0001pt'>(f) of<span style='letter-spacing:
-.35pt'> </span>paragraph<span style='letter-spacing:-.2pt'> </span>1<span
style='letter-spacing:.05pt'> </span>of<span style='letter-spacing:-.1pt'> </span>Article
11<span style='letter-spacing:.05pt'> </span>of<span style='letter-spacing:
-.35pt'> </span>the<span style='letter-spacing:.05pt'> </span><span
style='letter-spacing:-.2pt'>Law,</span></p>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection16>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:5.8pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>In cases where
our company rejects the application made by the relevant person requesting the
deletion or destruction of his personal data, his response is found
insufficient,<span style='letter-spacing:-.75pt'> </span>or<span
style='letter-spacing:-.75pt'> </span>he<span style='letter-spacing:-.75pt'> </span>does<span
style='letter-spacing:-.75pt'> </span>not<span style='letter-spacing:-.75pt'> </span>respond<span
style='letter-spacing:-.75pt'> </span>within<span style='letter-spacing:-.75pt'>
</span>the<span style='letter-spacing:-.75pt'> </span>time<span
style='letter-spacing:-.75pt'> </span>period<span style='letter-spacing:-.75pt'>
</span>stipulated<span style='letter-spacing:-.65pt'> </span>in<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'> </span>Law;<span
style='letter-spacing:-.75pt'> </span>Making a complaint to the Board and this
request being approved by the Board,</span></p>

<p class=MsoListParagraph style='margin-top:.4pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Although<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.5pt'> </span>maximum<span
style='letter-spacing:-.75pt'> </span>period<span style='letter-spacing:-.5pt'>
</span>requiring<span style='letter-spacing:-.5pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.75pt'> </span>to<span
style='letter-spacing:-.25pt'> </span>be<span style='letter-spacing:-.55pt'> </span>stored<span
style='letter-spacing:-.5pt'> </span>has<span style='letter-spacing:-.6pt'> </span>passed,<span
style='letter-spacing:-.4pt'> </span>there<span style='letter-spacing:-.55pt'> </span>are
no conditions that justify storing personal data for a longer period of time,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.75pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Elimination<span
style='letter-spacing:-.55pt'> </span>of<span style='letter-spacing:-.7pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>conditions<span style='letter-spacing:
-.4pt'> </span>requiring<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>processing<span style='letter-spacing:
-.3pt'> </span>of<span style='letter-spacing:-.7pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.35pt'> </span>in<span
style='letter-spacing:-.55pt'> </span>Articles<span style='letter-spacing:-.4pt'>
</span>5<span style='letter-spacing:-.3pt'> </span>and 6 of the Law,</span></p>

<p class=MsoBodyText align=left style='margin-top:2.1pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-left:12.05pt'>In<span style='letter-spacing:
-.4pt'> </span>such<span style='letter-spacing:-.25pt'> </span>cases,<span
style='letter-spacing:.1pt'> </span>personal<span style='letter-spacing:-.45pt'>
</span>data<span style='letter-spacing:-.05pt'> </span>is<span
style='letter-spacing:-.15pt'> </span>deleted,<span style='letter-spacing:.1pt'>
</span>destroyed or<span style='letter-spacing:-.15pt'> </span><span
style='letter-spacing:-.1pt'>anonymized.</span></p>

<p class=MsoBodyText align=left style='margin-top:4.3pt;text-align:left'>&nbsp;</p>

<h1>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>OBLIGATIONS<span style='letter-spacing:-.15pt'> </span>AND<span
style='letter-spacing:-.1pt'> RIGHTS</span></h1>

<p class=MsoBodyText align=left style='margin-top:4.35pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:5.6pt;
margin-bottom:0in;margin-left:34.15pt;margin-bottom:.0001pt;text-align:left;
text-indent:-28.35pt;line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:115%'>CLARIFICATION<span
style='letter-spacing:4.0pt'> </span>AND<span style='letter-spacing:4.0pt'> </span>PROVIDING<span
style='letter-spacing:4.0pt'> </span>INFORMATION<span style='letter-spacing:
4.0pt'> </span>OBLIGATION<span style='letter-spacing:4.0pt'> </span>OF <span
style='letter-spacing:-.1pt'>DENTISTODAY</span></span></b></p>

<p class=MsoBodyText align=left style='margin-top:1.75pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>In accordance
with Article 10 of the Law, DentisToday clarifies Data Owners during the
acquisition<span style='letter-spacing:-.55pt'> </span>of<span
style='letter-spacing:-.7pt'> </span>personal<span style='letter-spacing:-.75pt'>
</span>data.<span style='letter-spacing:-.4pt'> </span>Within<span
style='letter-spacing:-.55pt'> </span>this<span style='letter-spacing:-.4pt'> </span>context,<span
style='letter-spacing:-.4pt'> </span>DentisToday<span style='letter-spacing:
-.75pt'> </span>provides<span style='letter-spacing:-.2pt'> </span>information<span
style='letter-spacing:-.55pt'> </span>to<span style='letter-spacing:-.5pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>Data Owner on;</p>

<p class=MsoListParagraph align=left style='margin-top:.05pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>The<span style='letter-spacing:
.05pt'> </span>identity<span style='letter-spacing:-.55pt'> </span>of<span
style='letter-spacing:-.45pt'> </span>the<span style='letter-spacing:-.15pt'> </span>Data<span
style='letter-spacing:-.15pt'> </span>Controller<span style='letter-spacing:
-.05pt'> </span>and<span style='letter-spacing:.1pt'> </span>its<span
style='letter-spacing:-.15pt'> </span>representative,<span style='letter-spacing:
.2pt'> </span>if<span style='letter-spacing:.05pt'> </span><span
style='letter-spacing:-.2pt'>any,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>For<span style='letter-spacing:
.05pt'> </span>purpose<span style='letter-spacing:-.3pt'> </span>of<span
style='letter-spacing:-.35pt'> </span>processing Personal<span
style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.2pt'>Data,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.1pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:left;
line-height:111%'><span style='font-size:12.0pt;line-height:111%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>To<span
style='letter-spacing:3.65pt'> </span>whom<span style='letter-spacing:2.0pt'> </span>and<span
style='letter-spacing:3.9pt'> </span>for<span style='letter-spacing:3.75pt'> </span>what<span
style='letter-spacing:3.7pt'> </span>purposes<span style='letter-spacing:3.4pt'>
</span>the<span style='letter-spacing:3.65pt'> </span>processed<span
style='letter-spacing:3.65pt'> </span>Personal<span style='letter-spacing:2.0pt'>
</span>Data<span style='letter-spacing:3.65pt'> </span>shall<span
style='letter-spacing:3.7pt'> </span>be <span style='letter-spacing:-.1pt'>transferred,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:.45pt;margin-right:0in;
margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>The<span style='letter-spacing:
.05pt'> </span>method and<span style='letter-spacing:.2pt'> </span>legal<span
style='letter-spacing:-.45pt'> </span>ground of<span style='letter-spacing:
-.4pt'> </span>processing Personal<span style='letter-spacing:-.4pt'> </span>Data<span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.25pt'>and</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.15pt;margin-right:
0in;margin-bottom:0in;margin-left:83.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-21.35pt'><span style='font-size:12.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Rights of<span style='letter-spacing:
-.25pt'> </span>the<span style='letter-spacing:.1pt'> </span>Data<span
style='letter-spacing:.1pt'> </span><span style='letter-spacing:-.1pt'>Owner.</span></span></p>

<p class=MsoBodyText align=left style='margin-top:4.0pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.9pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>It is stated
under Article 20 of<span style='letter-spacing:-.2pt'> </span>the Constitution<span
style='letter-spacing:-.05pt'> </span>that everybody<span style='letter-spacing:
-.05pt'> </span>has the right to be informed on their personal<span
style='letter-spacing:-.1pt'> </span>data. Accordingly, the right “to request
information” is stated among the rights of personal data owners under Article
11 of the Law. In this direction, DentisToday provides information<span
style='letter-spacing:-.75pt'> </span>to<span style='letter-spacing:-.55pt'> </span>the<span
style='letter-spacing:-.55pt'> </span>Data<span style='letter-spacing:-.55pt'> </span>Owner<span
style='letter-spacing:-.4pt'> </span>upon<span style='letter-spacing:-.7pt'> </span>her/his<span
style='letter-spacing:-.6pt'> </span>request<span style='letter-spacing:-.25pt'>
</span>of<span style='letter-spacing:-.65pt'> </span>information<span
style='letter-spacing:-.7pt'> </span>pursuant<span style='letter-spacing:-.25pt'>
</span>to<span style='letter-spacing:-.25pt'> </span>Article<span
style='letter-spacing:-.55pt'> </span>20<span style='letter-spacing:-.5pt'> </span>of<span
style='letter-spacing:-.75pt'> </span>the Constitution<span style='letter-spacing:
-.05pt'> </span>and Article 11 of<span style='letter-spacing:-.2pt'> </span>the
Law. You may<span style='letter-spacing:-.3pt'> </span>reach<span
style='letter-spacing:-.05pt'> </span>further information<span
style='letter-spacing:-.05pt'> </span>with<span style='letter-spacing:-.05pt'> </span>regard<span
style='letter-spacing:-.05pt'> </span>to<span style='letter-spacing:-.05pt'> </span>this
subject from the Rights of the Data Owner in the Policy and the Clarification
Text.</p>

<p class=MsoBodyText align=left style='margin-top:2.35pt;text-align:left'>&nbsp;</p>

<h1>5.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>RIGHTS<span style='letter-spacing:-.1pt'> </span>OF<span
style='letter-spacing:-.2pt'> </span>PERSONAL<span style='letter-spacing:-.15pt'>
</span>DATA<span style='letter-spacing:-.1pt'> OWNER</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-left:5.8pt'>Personal<span style='letter-spacing:
-.55pt'> </span>Data<span style='letter-spacing:-.2pt'> </span>Owners<span
style='letter-spacing:-.05pt'> </span>have<span style='letter-spacing:-.15pt'> </span>the<span
style='letter-spacing:.05pt'> </span>following<span style='letter-spacing:-.1pt'>
rights:</span></p>

<p class=MsoBodyText align=left style='margin-top:4.2pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph align=left style='margin-top:0in;margin-right:5.75pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:left;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>To<span
style='letter-spacing:1.35pt'> </span>learn<span style='letter-spacing:1.15pt'>
</span>as<span style='letter-spacing:1.25pt'> </span>to<span style='letter-spacing:
1.4pt'> </span>whether<span style='letter-spacing:1.45pt'> </span>personal data<span
style='letter-spacing:1.1pt'> </span>of Data<span style='letter-spacing:1.35pt'>
</span>Owner<span style='letter-spacing:1.65pt'> </span>is<span
style='letter-spacing:1.25pt'> </span>processed<span style='letter-spacing:
1.15pt'> </span>or<span style='letter-spacing:1.2pt'> </span>not<span
style='letter-spacing:1.6pt'> </span>by <span style='letter-spacing:-.1pt'>DentisToday,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:.15pt;margin-right:6.05pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;text-align:left;
line-height:113%'><span style='font-size:12.0pt;line-height:113%;font-family:
Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>To request
information on personal<span style='letter-spacing:-.05pt'> </span>processing
activity, if such Personal<span style='letter-spacing:-.05pt'> </span>Data is
processed before DentisToday,</span></p>

</div>

<span style='font-size:12.0pt;line-height:113%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection17>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:5.75pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>To<span
style='letter-spacing:-.3pt'> </span>lease<span style='letter-spacing:-.35pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>purpose<span style='letter-spacing:-.55pt'>
</span>of<span style='letter-spacing:-.65pt'> </span>her/his<span
style='letter-spacing:-.4pt'> </span>personal<span style='letter-spacing:-.7pt'>
</span>data<span style='letter-spacing:-.35pt'> </span>processing<span
style='letter-spacing:-.3pt'> </span>and<span style='letter-spacing:-.3pt'> </span>whether<span
style='letter-spacing:-.2pt'> </span>such<span style='letter-spacing:-.5pt'> </span>data
is used for the intended purpose, if such Personal Data is processed before <span
style='letter-spacing:-.1pt'>DentisToday,</span></span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>To<span
style='letter-spacing:-.6pt'> </span>request<span style='letter-spacing:-.3pt'>
</span>information<span style='letter-spacing:-.6pt'> </span>on<span
style='letter-spacing:-.6pt'> </span>third<span style='letter-spacing:-.35pt'> </span>parties<span
style='letter-spacing:-.45pt'> </span>to<span style='letter-spacing:-.3pt'> </span>whom<span
style='letter-spacing:-.75pt'> </span>Personal<span style='letter-spacing:-.75pt'>
</span>Data<span style='letter-spacing:-.4pt'> </span>is<span style='letter-spacing:
-.45pt'> </span>transferred<span style='letter-spacing:-.35pt'> </span>at home
or abroad,</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:0in;margin-bottom:
0in;margin-left:83.75pt;margin-bottom:.0001pt;text-indent:-21.3pt'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To<span style='letter-spacing:
-.45pt'> </span>request<span style='letter-spacing:-.05pt'> </span>rectification<span
style='letter-spacing:-.55pt'> </span>of<span style='letter-spacing:-.7pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>incomplete<span style='letter-spacing:
-.35pt'> </span>and<span style='letter-spacing:-.1pt'> </span>inaccurate<span
style='letter-spacing:-.55pt'> </span>Personal<span style='letter-spacing:-.75pt'>
</span>Data,<span style='letter-spacing:-.25pt'> </span>if<span
style='letter-spacing:-.65pt'> </span><span style='letter-spacing:-.2pt'>any,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.1pt;margin-right:5.6pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>To request the
notification for the incomplete and inaccurate Personal Data processed before<span
style='letter-spacing:-.05pt'> </span>DentisToday<span style='letter-spacing:
-.45pt'> </span>to third persons<span style='letter-spacing:-.1pt'> </span>to
whom<span style='letter-spacing:-.4pt'> </span>such<span style='letter-spacing:
-.2pt'> </span>Personal<span style='letter-spacing:-.25pt'> </span>Data is <span
style='letter-spacing:-.1pt'>transferred,</span></span></p>

<p class=MsoListParagraph style='margin-top:.3pt;margin-right:5.5pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>To<span
style='letter-spacing:-.75pt'> </span>request<span style='letter-spacing:-.5pt'>
</span>the<span style='letter-spacing:-.55pt'> </span>erasure<span
style='letter-spacing:-.75pt'> </span>or<span style='letter-spacing:-.4pt'> </span>destruction<span
style='letter-spacing:-.7pt'> </span>or<span style='letter-spacing:-.65pt'> </span>anonymization<span
style='letter-spacing:-.7pt'> </span>of<span style='letter-spacing:-.75pt'> </span>Personal<span
style='letter-spacing:-.75pt'> </span>Data<span style='letter-spacing:-.55pt'> </span>in<span
style='letter-spacing:-.7pt'> </span>case the reasons of processing such
Personal Data are disappeared although such Personal Data is processed in
accordance with the provisions of the Law and other related laws,</span></p>

<p class=MsoListParagraph style='margin-top:.4pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>To request the
notification of such circumstance where the persons of processing Personal Data
is disappeared to third persons to whom Personal Data has been transferred,</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.9pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:112%'><span
style='font-size:12.0pt;line-height:112%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:112%'>To object to the
analysing results that are against the Personal Data Owner whose<span
style='letter-spacing:-.4pt'> </span>Personal<span style='letter-spacing:-.75pt'>
</span>Data<span style='letter-spacing:-.4pt'> </span>processed<span
style='letter-spacing:-.35pt'> </span>by<span style='letter-spacing:-.75pt'> </span>DentisToday<span
style='letter-spacing:-.75pt'> </span>has<span style='letter-spacing:-.45pt'> </span>been<span
style='letter-spacing:-.55pt'> </span>analysed<span style='letter-spacing:-.35pt'>
</span>exclusively by automatic means,</span></p>

<p class=MsoListParagraph style='margin-top:.7pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:111%'><span
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>To request
compensation for the damages arising out of<span style='letter-spacing:-.2pt'> </span>the
illegal<span style='letter-spacing:-.05pt'> </span>processing of Personal Data,</span></p>

<p class=MsoBodyText align=left style='margin-top:2.65pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.6pt;margin-bottom:
0in;margin-left:23.8pt;margin-bottom:.0001pt;line-height:115%'>Data Owners can
send their requests concerning the execution of the Law, in writing, via the<span
style='letter-spacing:-.75pt'> </span>“Application<span style='letter-spacing:
-.75pt'> </span>Form<span style='letter-spacing:-.75pt'> </span>related<span
style='letter-spacing:-.75pt'> </span>to<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>Application<span style='letter-spacing:
-.75pt'> </span>to<span style='letter-spacing:-.75pt'> </span>be<span
style='letter-spacing:-.6pt'> </span>Made<span style='letter-spacing:-.6pt'> </span>by<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.6pt'> </span>Person<span
style='letter-spacing:-.75pt'> </span>Concerned<span style='letter-spacing:
-.55pt'> </span>(Data Owner)<span style='letter-spacing:-.05pt'> </span>to<span
style='letter-spacing:-.25pt'> </span>the<span style='letter-spacing:-.15pt'> </span>Data<span
style='letter-spacing:-.15pt'> </span>Controller<span style='letter-spacing:
-.05pt'> </span>as<span style='letter-spacing:-.2pt'> </span>per<span
style='letter-spacing:-.25pt'> </span>the<span style='letter-spacing:-.15pt'> </span>Protection<span
style='letter-spacing:-.3pt'> </span>of<span style='letter-spacing:-.45pt'> </span>Personal<span
style='letter-spacing:-.5pt'> </span>Data<span style='letter-spacing:-.15pt'> </span>Law<span
style='letter-spacing:-.15pt'> </span>numbered<span style='letter-spacing:-.1pt'>
</span>6698” and<span style='letter-spacing:-.55pt'> </span>other<span
style='letter-spacing:-.5pt'> </span>methods<span style='letter-spacing:-.65pt'>
</span>determined<span style='letter-spacing:-.55pt'> </span>by<span
style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.4pt'> </span>Board<span
style='letter-spacing:-.75pt'> </span>to<span style='letter-spacing:-.35pt'> </span>DentisToday.<span
style='letter-spacing:-.5pt'> </span>Please<span style='letter-spacing:-.6pt'> </span>click<span
style='letter-spacing:-.35pt'> </span>here<span style='letter-spacing:-.4pt'> </span>for<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:-.6pt'> </span>Form.</p>

<p class=MsoBodyText align=left style='margin-top:2.2pt;text-align:left'>&nbsp;</p>

<h1>5.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='letter-spacing:-.1pt'>EXCEPTIONS</span></h1>

<p class=MsoBodyText align=left style='margin-top:3.85pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText align=left style='margin-top:.05pt;margin-right:5.2pt;
margin-bottom:0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:left;
line-height:116%'>Since the following circumstances are excluded from<span
style='letter-spacing:-.05pt'> </span>the scope of the Law as per Articles 28
of the KVKK, data owners cannot claim their aforementioned rights:</p>

<p class=MsoBodyText align=left style='margin-top:1.75pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.85pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Personal<span
style='letter-spacing:-.2pt'> </span>data is processed by<span
style='letter-spacing:-.2pt'> </span>real<span style='letter-spacing:-.2pt'> </span>persons
within the scope of<span style='letter-spacing:-.15pt'> </span>purely personal
activities<span style='letter-spacing:-.1pt'> </span>of<span style='letter-spacing:
-.4pt'> </span>the<span style='letter-spacing:-.1pt'> </span>data<span
style='letter-spacing:-.3pt'> </span>owner or<span style='letter-spacing:-.15pt'>
</span>of<span style='letter-spacing:-.15pt'> </span>family<span
style='letter-spacing:-.25pt'> </span>members living<span style='letter-spacing:
-.05pt'> </span>together with<span style='letter-spacing:-.25pt'> </span>her/him
in<span style='letter-spacing:-.4pt'> </span>the<span style='letter-spacing:
-.25pt'> </span>same<span style='letter-spacing:-.25pt'> </span>residence<span
style='letter-spacing:-.25pt'> </span>provided<span style='letter-spacing:-.2pt'>
</span>that it is<span style='letter-spacing:-.1pt'> </span>not<span
style='letter-spacing:-.4pt'> </span>to<span style='letter-spacing:-.2pt'> </span>be<span
style='letter-spacing:-.25pt'> </span>disclosed<span style='letter-spacing:
-.2pt'> </span>to<span style='letter-spacing:-.2pt'> </span>third<span
style='letter-spacing:-.2pt'> </span>parties<span style='letter-spacing:-.3pt'>
</span>and the obligations on data security are to be complied with.</span></p>

<p class=MsoListParagraph style='margin-top:.4pt;margin-right:6.3pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%;letter-spacing:
-.1pt'>Personal</span><span style='font-size:12.0pt;line-height:113%;
letter-spacing:-.65pt'> </span><span style='font-size:12.0pt;line-height:113%;
letter-spacing:-.1pt'>data</span><span style='font-size:12.0pt;line-height:
113%;letter-spacing:-.35pt'> </span><span style='font-size:12.0pt;line-height:
113%;letter-spacing:-.1pt'>is</span><span style='font-size:12.0pt;line-height:
113%;letter-spacing:-.35pt'> </span><span style='font-size:12.0pt;line-height:
113%;letter-spacing:-.1pt'>processed for</span><span style='font-size:12.0pt;
line-height:113%;letter-spacing:-.4pt'> </span><span style='font-size:12.0pt;
line-height:113%;letter-spacing:-.1pt'>the</span><span style='font-size:12.0pt;
line-height:113%;letter-spacing:-.2pt'> </span><span style='font-size:12.0pt;
line-height:113%;letter-spacing:-.1pt'>purposes</span><span style='font-size:
12.0pt;line-height:113%;letter-spacing:-.35pt'> </span><span style='font-size:
12.0pt;line-height:113%;letter-spacing:-.1pt'>of</span><span style='font-size:
12.0pt;line-height:113%;letter-spacing:-.65pt'> </span><span style='font-size:
12.0pt;line-height:113%;letter-spacing:-.1pt'>official</span><span
style='font-size:12.0pt;line-height:113%;letter-spacing:-.65pt'> </span><span
style='font-size:12.0pt;line-height:113%;letter-spacing:-.1pt'>statistics</span><span
style='font-size:12.0pt;line-height:113%;letter-spacing:-.35pt'> </span><span
style='font-size:12.0pt;line-height:113%;letter-spacing:-.1pt'>and for
research, </span><span style='font-size:12.0pt;line-height:113%'>planning and
statistical purposes after having been anonymized.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.85pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>Personal data is
processed with artistic, historical, literary or scientific purposes,<span
style='letter-spacing:-.2pt'> </span>or<span style='letter-spacing:-.2pt'> </span>within<span
style='letter-spacing:-.05pt'> </span>the<span style='letter-spacing:-.1pt'> </span>scope<span
style='letter-spacing:-.1pt'> </span>of<span style='letter-spacing:-.2pt'> </span>freedom<span
style='letter-spacing:-.45pt'> </span>of<span style='letter-spacing:-.4pt'> </span>expression,
provided<span style='letter-spacing:-.05pt'> </span>that national defence,
national security, public security, public order, economic security, right<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.3pt'> </span>privacy<span
style='letter-spacing:-.75pt'> </span>or<span style='letter-spacing:-.25pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>rights<span style='letter-spacing:-.45pt'>
</span>are<span style='letter-spacing:-.4pt'> </span>not<span style='letter-spacing:
-.3pt'> </span>violated<span style='letter-spacing:-.6pt'> </span>or<span
style='letter-spacing:-.25pt'> </span>personal<span style='letter-spacing:-.75pt'>
</span>data<span style='letter-spacing:-.4pt'> </span>is<span style='letter-spacing:
-.45pt'> </span>processed so as not to constitute a crime.</span></p>

</div>

<span style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection18>

<p class=MsoListParagraph style='margin-top:.05in;margin-right:6.0pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Personal data is
processed within the scope of preventive, protective and intelligence
activities<span style='letter-spacing:-.05pt'> </span>carried out by<span
style='letter-spacing:-.4pt'> </span>public institutions<span style='letter-spacing:
-.05pt'> </span>and organizations<span style='letter-spacing:-.05pt'> </span>duly
authorized and assigned to ensure national defence, national security, public
security, public order or economic security.</span></p>

<p class=MsoListParagraph style='margin-top:.4pt;margin-right:5.85pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:113%'><span
style='font-size:12.0pt;line-height:113%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:113%'>Personal data is
processed by judicial authorities or enforcement authorities with regard to the
investigation, prosecution, criminal proceedings or enforcement proceedings.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.2pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.0pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Clarification<span
style='letter-spacing:-.3pt'> </span>Text<span style='letter-spacing:-.1pt'> </span>of<span
style='letter-spacing:-.45pt'> </span>DentisToday<span style='letter-spacing:
-.55pt'> </span>shall<span style='letter-spacing:-.05pt'> </span>not<span
style='letter-spacing:-.1pt'> </span>be<span style='letter-spacing:-.15pt'> </span>applied<span
style='letter-spacing:-.1pt'> </span>as per<span style='letter-spacing:-.05pt'>
</span>Article<span style='letter-spacing:-.15pt'> </span>28/2<span
style='letter-spacing:-.25pt'> </span>of<span style='letter-spacing:-.45pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>KVKK. under the following circumstances:</p>

<p class=MsoBodyText align=left style='margin-top:2.3pt;text-align:left'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.15pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:111%'><span
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>Personal<span
style='letter-spacing:-.05pt'> </span>data processing is required for the
prevention of a crime or criminal <span style='letter-spacing:-.1pt'>investigation.</span></span></p>

<p class=MsoListParagraph style='margin-top:.75pt;margin-right:6.15pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:111%'><span
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>Personal<span
style='letter-spacing:-.7pt'> </span>data<span style='letter-spacing:-.3pt'> </span>processing<span
style='letter-spacing:-.05pt'> </span>is<span style='letter-spacing:-.35pt'> </span>carried<span
style='letter-spacing:-.25pt'> </span>out<span style='letter-spacing:-.25pt'> </span>on<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:-.3pt'> </span>data<span
style='letter-spacing:-.55pt'> </span>which<span style='letter-spacing:-.25pt'>
</span>is<span style='letter-spacing:-.1pt'> </span>made<span style='letter-spacing:
-.3pt'> </span>public<span style='letter-spacing:-.1pt'> </span>by<span
style='letter-spacing:-.5pt'> </span>the data owner her/himself.</span></p>

<p class=MsoListParagraph style='margin-top:.5pt;margin-right:5.9pt;margin-bottom:
0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:115%'><span
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:115%'>Personal data
processing is required for inspection or regulatory duties and disciplinary
investigation or prosecution to be carried out by the public institutions and
organizations and by professional associations having the status of a public
institution, assigned and authorised for such actions, in accordance with the
authority granted to the same by the law.</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:6.25pt;
margin-bottom:0in;margin-left:83.85pt;margin-bottom:.0001pt;line-height:111%'><span
style='font-size:12.0pt;line-height:111%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:111%'>Personal<span
style='letter-spacing:-.5pt'> </span>data<span style='letter-spacing:-.1pt'> </span>processing
is<span style='letter-spacing:-.2pt'> </span>required for the<span
style='letter-spacing:-.1pt'> </span>protection<span style='letter-spacing:
-.3pt'> </span>of<span style='letter-spacing:-.45pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>State’s<span style='letter-spacing:-.2pt'>
</span>economic and financial interests with regard to budget, tax-related and
financial issues.</span></p>

<p class=MsoBodyText align=left style='margin-top:2.65pt;text-align:left'>&nbsp;</p>

<h1>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>CATEGORIZATION<span style='letter-spacing:-.15pt'> </span>OF<span
style='letter-spacing:-.25pt'> </span>PERSONAL<span style='letter-spacing:-.2pt'>
DATA</span></h1>

<p class=MsoBodyText align=left style='margin-top:4.1pt;text-align:left'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>Personal<span
style='letter-spacing:-.35pt'> </span>data in<span style='letter-spacing:-.1pt'>
</span>the following categories are processed before DentisToday, for<span
style='letter-spacing:-.05pt'> </span>the legitimate and legal personal data
processing purposes, based on and limited to one or more than one conditions<span
style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.75pt'> </span>personal<span
style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.6pt'> </span>processing<span
style='letter-spacing:-.5pt'> </span>conditions<span style='letter-spacing:
-.6pt'> </span>stated<span style='letter-spacing:-.5pt'> </span>under<span
style='letter-spacing:-.4pt'> </span>Article<span style='letter-spacing:-.55pt'>
</span>5<span style='letter-spacing:-.5pt'> </span>of<span style='letter-spacing:
-.75pt'> </span>the<span style='letter-spacing:-.3pt'> </span>KVKK,<span
style='letter-spacing:-.15pt'> </span>by<span style='letter-spacing:-.7pt'> </span>being
in compliance with the general principles stated under the KVKK, particularly
principles specified under Article 5 related to the processing personal data
and all obligations regulated under the KVKK<span style='letter-spacing:-.05pt'>
</span>and by being limited to the subjects (Customers, Users, Patients and
Patient candidates, Visitors, Employee Candidates, Company Representatives,
Suppliers, Business Partners,<span style='letter-spacing:-.2pt'> </span>etc.)<span
style='letter-spacing:-.2pt'> </span>within<span style='letter-spacing:-.55pt'>
</span>the<span style='letter-spacing:-.35pt'> </span>scope<span
style='letter-spacing:-.35pt'> </span>of<span style='letter-spacing:-.7pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>Policy,<span style='letter-spacing:-.2pt'>
</span>by<span style='letter-spacing:-.55pt'> </span>providing<span
style='letter-spacing:-.05pt'> </span>information<span style='letter-spacing:
-.55pt'> </span>to<span style='letter-spacing:-.55pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>person's<span style='letter-spacing:-.4pt'>
</span>concerns as per Article 10 of the KVKK.</p>

</div>

<span style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection19>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:50.1pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:50.1pt'>
  <p class=TableParagraph style='margin-top:9.15pt;margin-right:0in;margin-bottom:
  0in;margin-left:11.75pt;margin-bottom:.0001pt;text-indent:4.8pt;line-height:
  115%'><b><span style='font-size:12.0pt;line-height:115%;letter-spacing:-.1pt'>CATEGORIZATION
  </span></b><b><span style='font-size:12.0pt;line-height:115%'>OF<span
  style='letter-spacing:-.75pt'> </span>PERSONAL<span style='letter-spacing:
  -.75pt'> </span>DATA</span></b></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:50.1pt'>
  <p class=TableParagraph style='margin-top:3.3pt'><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-left:.6pt;text-align:center'><b><span
  style='font-size:12.0pt;letter-spacing:-.1pt'>EXPLANATION</span></b></p>
  </td>
 </tr>
 <tr style='height:163.2pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:163.2pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:4.3pt'><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span style='font-size:
  12.0pt'>Identity<span style='letter-spacing:-.45pt'> </span><span
  style='letter-spacing:-.1pt'>Information</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:163.2pt'>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:2.65pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real<span
  style='letter-spacing:-.75pt'> </span>person;<span style='letter-spacing:
  -.75pt'> </span>processed<span style='letter-spacing:-.3pt'> </span>fully<span
  style='letter-spacing:-.65pt'> </span>or<span style='letter-spacing:-.6pt'> </span>partially<span
  style='letter-spacing:-.75pt'> </span>through<span style='letter-spacing:
  -.4pt'> </span>automatic<span style='letter-spacing:-.3pt'> </span>means or<span
  style='letter-spacing:-.15pt'> </span>provided that the<span
  style='letter-spacing:-.05pt'> </span>process is<span style='letter-spacing:
  -.1pt'> </span>a<span style='letter-spacing:-.05pt'> </span>part of<span
  style='letter-spacing:-.4pt'> </span>any<span style='letter-spacing:-.5pt'> </span>data<span
  style='letter-spacing:-.05pt'> </span>registry<span style='letter-spacing:
  -.5pt'> </span>system, through non-automatic means; that contains information
  on the identity<span style='letter-spacing:-.75pt'> </span>of<span
  style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.75pt'>
  </span>person;<span style='letter-spacing:-.75pt'> </span>documents<span
  style='letter-spacing:-.75pt'> </span>such<span style='letter-spacing:-.75pt'>
  </span>as<span style='letter-spacing:-.75pt'> </span>driving<span
  style='letter-spacing:-.75pt'> </span>license,<span style='letter-spacing:
  -.75pt'> </span>identity card and passports that include information such as
  name- surname,<span style='letter-spacing:-.75pt'> </span>T.R.<span
  style='letter-spacing:-.75pt'> </span>identity<span style='letter-spacing:
  -.75pt'> </span>number,<span style='letter-spacing:-.75pt'> </span>nationality<span
  style='letter-spacing:-.75pt'> </span>information,<span style='letter-spacing:
  -.75pt'> </span>mother’s name- father’s name, place of birth, date of birth,
  gender and information such as tax number, SGK number, signature information,
  vehicle plate, etc.</span></p>
  </td>
 </tr>
 <tr style='height:84.0pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:6.3pt'><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span style='font-size:
  12.0pt'>Contact<span style='letter-spacing:.1pt'> </span><span
  style='letter-spacing:-.1pt'>Information</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:3.35pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real<span
  style='letter-spacing:-.75pt'> </span>person;<span style='letter-spacing:
  -.75pt'> </span>processed<span style='letter-spacing:-.7pt'> </span>fully<span
  style='letter-spacing:-.75pt'> </span>or<span style='letter-spacing:-.4pt'> </span>partially<span
  style='letter-spacing:-.75pt'> </span>through<span style='letter-spacing:
  -.7pt'> </span>automatic<span style='letter-spacing:-.3pt'> </span>means or<span
  style='letter-spacing:-.2pt'> </span>provided<span style='letter-spacing:
  -.05pt'> </span>that<span style='letter-spacing:-.05pt'> </span>the<span
  style='letter-spacing:-.1pt'> </span>process<span style='letter-spacing:-.15pt'>
  </span>is<span style='letter-spacing:-.15pt'> </span>a<span style='letter-spacing:
  -.1pt'> </span>part<span style='letter-spacing:-.05pt'> </span>of<span
  style='letter-spacing:-.45pt'> </span>any<span style='letter-spacing:-.3pt'> </span>data<span
  style='letter-spacing:-.1pt'> </span>registry<span style='letter-spacing:
  -.55pt'> </span>system, through non-automatic means; information such as
  telephone number, address, e-mail address, fax number, IP address.</span></p>
  </td>
 </tr>
 <tr style='height:163.45pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:163.45pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:4.5pt'><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span style='font-size:
  12.0pt'>Location<span style='letter-spacing:-.35pt'> </span><span
  style='letter-spacing:-.2pt'>Data</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:163.45pt'>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:2.55pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real<span
  style='letter-spacing:-.75pt'> </span>person;<span style='letter-spacing:
  -.7pt'> </span>processed<span style='letter-spacing:-.2pt'> </span>fully<span
  style='letter-spacing:-.6pt'> </span>or<span style='letter-spacing:-.55pt'> </span>partially<span
  style='letter-spacing:-.75pt'> </span>through<span style='letter-spacing:
  -.65pt'> </span>automatic<span style='letter-spacing:-.25pt'> </span>means or<span
  style='letter-spacing:-.15pt'> </span>provided that the<span
  style='letter-spacing:-.05pt'> </span>process is<span style='letter-spacing:
  -.1pt'> </span>a<span style='letter-spacing:-.05pt'> </span>part of<span
  style='letter-spacing:-.4pt'> </span>any<span style='letter-spacing:-.5pt'> </span>data<span
  style='letter-spacing:-.05pt'> </span>registry<span style='letter-spacing:
  -.5pt'> </span>system, through non-automatic means; that determines the
  location data of the personal data owner during the usage of products and
  services of DentisToday within the operations conducted by the business units
  of DentisToday or the location of the employees of<span style='letter-spacing:
  -.55pt'> </span>entities<span style='letter-spacing:-.25pt'> </span>that<span
  style='letter-spacing:-.35pt'> </span>our<span style='letter-spacing:-.3pt'> </span>Company<span
  style='letter-spacing:-.4pt'> </span>is<span style='letter-spacing:-.1pt'> </span>incorporation<span
  style='letter-spacing:-.4pt'> </span>with<span style='letter-spacing:-.4pt'> </span>while<span
  style='letter-spacing:-.2pt'> </span>they<span style='letter-spacing:-.2pt'> </span>are
  driving the vehicles of DentisToday; GPS location, travel data, <span
  style='letter-spacing:-.2pt'>etc.</span></span></p>
  </td>
 </tr>
 <tr style='height:163.2pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:163.2pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:10.15pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>Information on Family Members<span
  style='letter-spacing:-.75pt'> </span>and<span style='letter-spacing:-.75pt'>
  </span>Relatives</span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:163.2pt'>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:2.5pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real<span
  style='letter-spacing:-.75pt'> </span>person;<span style='letter-spacing:
  -.65pt'> </span>processed<span style='letter-spacing:-.15pt'> </span>fully<span
  style='letter-spacing:-.6pt'> </span>or<span style='letter-spacing:-.55pt'> </span>partially<span
  style='letter-spacing:-.75pt'> </span>through<span style='letter-spacing:
  -.65pt'> </span>automatic<span style='letter-spacing:-.2pt'> </span>means or<span
  style='letter-spacing:-.15pt'> </span>provided that the<span
  style='letter-spacing:-.05pt'> </span>process is<span style='letter-spacing:
  -.1pt'> </span>a<span style='letter-spacing:-.05pt'> </span>part of<span
  style='letter-spacing:-.4pt'> </span>any<span style='letter-spacing:-.5pt'> </span>data<span
  style='letter-spacing:-.05pt'> </span>registry<span style='letter-spacing:
  -.5pt'> </span>system, through non-automatic means; regarding the products
  and services<span style='letter-spacing:-.75pt'> </span>offered<span
  style='letter-spacing:-.5pt'> </span>by<span style='letter-spacing:-.65pt'> </span>DentisToday<span
  style='letter-spacing:-.75pt'> </span>within<span style='letter-spacing:-.6pt'>
  </span>the<span style='letter-spacing:-.65pt'> </span>operations<span
  style='letter-spacing:-.7pt'> </span>conducted by the business units of
  DentisToday or regarding the family members (for instance, spouse, mother,
  father, children), relatives<span style='letter-spacing:-.5pt'> </span>of<span
  style='letter-spacing:-.75pt'> </span>the<span style='letter-spacing:-.4pt'> </span>personal<span
  style='letter-spacing:-.75pt'> </span>data<span style='letter-spacing:-.65pt'>
  </span>owner<span style='letter-spacing:-.3pt'> </span>and<span
  style='letter-spacing:-.6pt'> </span>other<span style='letter-spacing:-.3pt'>
  </span>persons<span style='letter-spacing:-.45pt'> </span>that<span
  style='letter-spacing:-.15pt'> </span>can<span style='letter-spacing:-.6pt'> </span>be
  contacted for emergency for the purpose of<span style='letter-spacing:-.05pt'>
  </span>protecting legal<span style='letter-spacing:-.1pt'> </span>and other
  interests of DentisToday and personal data owner.</span></p>
  </td>
 </tr>
 <tr style='height:68.15pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:68.15pt'>
  <p class=TableParagraph style='margin-top:9.9pt;margin-right:25.7pt;
  margin-bottom:0in;margin-left:3.35pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:12.0pt;line-height:115%'>Physical<span style='letter-spacing:
  -.75pt'> </span>Environment Security Information</span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:68.15pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.55pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real<span
  style='letter-spacing:-.75pt'> </span>person;<span style='letter-spacing:
  -.75pt'> </span>processed<span style='letter-spacing:-.2pt'> </span>fully<span
  style='letter-spacing:-.6pt'> </span>or<span style='letter-spacing:-.35pt'> </span>partially<span
  style='letter-spacing:-.75pt'> </span>through<span style='letter-spacing:
  -.65pt'> </span>automatic<span style='letter-spacing:-.25pt'> </span>means or<span
  style='letter-spacing:-.15pt'> </span>provided that the<span
  style='letter-spacing:-.05pt'> </span>process is<span style='letter-spacing:
  -.1pt'> </span>a<span style='letter-spacing:-.05pt'> </span>part of<span
  style='letter-spacing:-.4pt'> </span>any<span style='letter-spacing:-.25pt'> </span>data<span
  style='letter-spacing:-.05pt'> </span>registry<span style='letter-spacing:
  -.5pt'> </span>system, through<span style='letter-spacing:3.5pt'> </span>non-automatic<span
  style='letter-spacing:3.85pt'> </span>means;<span style='letter-spacing:3.55pt'>
  </span>regarding<span style='letter-spacing:3.7pt'> </span>the<span
  style='letter-spacing:3.65pt'> </span>recordings<span style='letter-spacing:
  3.65pt'> </span><span style='letter-spacing:-.25pt'>and</span></span></p>
  </td>
 </tr>
</table>

</div>

<span style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection20>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:68.15pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:68.15pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:68.15pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.5pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>documents<span
  style='letter-spacing:-.15pt'> </span>obtained<span style='letter-spacing:
  -.05pt'> </span>at<span style='letter-spacing:-.2pt'> </span>the<span
  style='letter-spacing:-.05pt'> </span>entrance<span style='letter-spacing:
  -.05pt'> </span>to<span style='letter-spacing:-.2pt'> </span>the<span
  style='letter-spacing:-.05pt'> </span>physical environment, during the stay
  in the physical environment; camera recordings, fingerprint recordings and
  recording obtained at the security point, etc.</span></p>
  </td>
 </tr>
 <tr style='height:163.2pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:163.2pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:4.25pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:3.35pt;margin-bottom:.0001pt'><span style='font-size:12.0pt'>Financial<span
  style='letter-spacing:-.45pt'> </span><span style='letter-spacing:-.1pt'>Information</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:163.2pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.4pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real<span
  style='letter-spacing:-.75pt'> </span>person;<span style='letter-spacing:
  -.65pt'> </span>processed<span style='letter-spacing:-.15pt'> </span>fully<span
  style='letter-spacing:-.55pt'> </span>or<span style='letter-spacing:-.55pt'> </span>partially<span
  style='letter-spacing:-.75pt'> </span>through<span style='letter-spacing:
  -.6pt'> </span>automatic<span style='letter-spacing:-.2pt'> </span>means or<span
  style='letter-spacing:-.1pt'> </span>provided that the<span style='letter-spacing:
  -.05pt'> </span>process is<span style='letter-spacing:-.05pt'> </span>a<span
  style='letter-spacing:-.05pt'> </span>part of<span style='letter-spacing:
  -.35pt'> </span>any<span style='letter-spacing:-.45pt'> </span>data<span
  style='letter-spacing:-.05pt'> </span>registry<span style='letter-spacing:
  -.45pt'> </span>system, through non-automatic means; processed regarding the
  information, document and recordings reflecting all kinds of financial
  results created according to the legal relationship that DentisToday has
  established with the personal data owner and information related to the bank
  account number, IBAN number, credit card information, financial profile,
  assets data, income <span style='letter-spacing:-.1pt'>information.</span></span></p>
  </td>
 </tr>
 <tr style='height:84.0pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph style='margin-top:11.9pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:25.7pt;
  margin-bottom:0in;margin-left:3.35pt;margin-bottom:.0001pt;line-height:116%'><span
  style='font-size:12.0pt;line-height:116%;letter-spacing:-.1pt'>Visual/Auditory
  Information</span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:84.0pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.6pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real person;
  photograph and camera recording (excluding the recording within the scope of
  Physical Environment Security Information),<span style='letter-spacing:-.7pt'>
  </span>voice<span style='letter-spacing:-.7pt'> </span>recordings<span
  style='letter-spacing:-.75pt'> </span>and<span style='letter-spacing:-.7pt'> </span>stated<span
  style='letter-spacing:-.7pt'> </span>under<span style='letter-spacing:-.6pt'>
  </span>documents<span style='letter-spacing:-.55pt'> </span>in<span
  style='letter-spacing:-.75pt'> </span>the nature of a document copy contains
  personal data.</span></p>
  </td>
 </tr>
 <tr style='height:115.65pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:115.65pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:8.1pt'><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt'><span style='font-size:
  12.0pt'>Personnel<span style='letter-spacing:-.45pt'> </span><span
  style='letter-spacing:-.1pt'>Information</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:115.65pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.45pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>All kinds
  of personal data that is explicitly belonging to an identified or
  identifiable real<span style='letter-spacing:-.35pt'> </span>person;<span
  style='letter-spacing:-.1pt'> </span>processed fully<span style='letter-spacing:
  -.1pt'> </span>or partially through<span style='letter-spacing:-.55pt'> </span>automatic<span
  style='letter-spacing:-.15pt'> </span>means<span style='letter-spacing:-.4pt'>
  </span>or<span style='letter-spacing:-.25pt'> </span>provided<span
  style='letter-spacing:-.3pt'> </span>that<span style='letter-spacing:-.3pt'> </span>the<span
  style='letter-spacing:-.35pt'> </span>process<span style='letter-spacing:
  -.2pt'> </span>is<span style='letter-spacing:-.2pt'> </span>a<span
  style='letter-spacing:-.35pt'> </span>part<span style='letter-spacing:-.3pt'>
  </span>of any data registry system, through non-automatic means; processed to
  obtain information that will form the basis for the personnel rights of real
  persons with whom DentisToday has employment relationship.</span></p>
  </td>
 </tr>
 <tr style='height:115.65pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:115.65pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:3.35pt;line-height:116%'><span
  style='font-size:12.0pt;line-height:116%'>Personal<span style='letter-spacing:
  -.75pt'> </span>Data<span style='letter-spacing:-.6pt'> </span>of<span
  style='letter-spacing:-.75pt'> </span>Special <span style='letter-spacing:
  -.1pt'>Nature</span></span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:115.65pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:2.65pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Data that
  is explicitly belonging to an identified or identifiable real<span
  style='letter-spacing:-.75pt'> </span>person;<span style='letter-spacing:
  -.75pt'> </span>processed<span style='letter-spacing:-.25pt'> </span>fully<span
  style='letter-spacing:-.65pt'> </span>or<span style='letter-spacing:-.35pt'> </span>partially<span
  style='letter-spacing:-.75pt'> </span>through<span style='letter-spacing:
  -.7pt'> </span>automatic<span style='letter-spacing:-.25pt'> </span>means or<span
  style='letter-spacing:-.15pt'> </span>provided that the<span
  style='letter-spacing:-.05pt'> </span>process is<span style='letter-spacing:
  -.1pt'> </span>a<span style='letter-spacing:-.05pt'> </span>part of<span
  style='letter-spacing:-.4pt'> </span>any<span style='letter-spacing:-.25pt'> </span>data<span
  style='letter-spacing:-.05pt'> </span>registry<span style='letter-spacing:
  -.5pt'> </span>system, through non-automatic means; stated under Article 6 of
  the DentisToday (for instance, such as health data including blood type,
  diagnosis and preliminary diagnosis, biometric data, religion and membership
  to associations)</span></p>
  </td>
 </tr>
 <tr style='height:99.85pt'>
  <td width=182 valign=top style='width:136.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph style='margin-top:11.95pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:0in;margin-right:9.05pt;margin-bottom:
  0in;margin-left:3.35pt;margin-bottom:.0001pt;line-height:116%'><span
  style='font-size:12.0pt;line-height:116%;letter-spacing:-.1pt'>Claim/Complaint
  </span><span style='font-size:12.0pt;line-height:116%'>Management<span
  style='letter-spacing:-.75pt'> </span>Information</span></p>
  </td>
  <td width=423 valign=top style='width:317.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:99.85pt'>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:2.5pt;
  margin-bottom:0in;margin-left:3.8pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Personal
  data that is explicitly belonging to an identified or identifiable real
  person; processed fully or partially through automatic<span style='letter-spacing:
  -.45pt'> </span>means<span style='letter-spacing:-.6pt'> </span>or<span
  style='letter-spacing:-.45pt'> </span>provided<span style='letter-spacing:
  -.55pt'> </span>that<span style='letter-spacing:-.3pt'> </span>the<span
  style='letter-spacing:-.55pt'> </span>process<span style='letter-spacing:
  -.4pt'> </span>is<span style='letter-spacing:-.6pt'> </span>a<span
  style='letter-spacing:-.55pt'> </span>part<span style='letter-spacing:-.5pt'>
  </span>of<span style='letter-spacing:-.75pt'> </span>any<span
  style='letter-spacing:-.7pt'> </span>data registry<span style='letter-spacing:
  -.75pt'> </span>system,<span style='letter-spacing:-.75pt'> </span>through<span
  style='letter-spacing:-.75pt'> </span>non-automatic<span style='letter-spacing:
  -.4pt'> </span>means;<span style='letter-spacing:-.75pt'> </span>with<span
  style='letter-spacing:-.75pt'> </span>regard<span style='letter-spacing:-.6pt'>
  </span>to<span style='letter-spacing:-.55pt'> </span>the receiving and
  evaluation of all kinds of claims or complaints addressed to DentisToday.</span></p>
  </td>
 </tr>
</table>

</div>

<span style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection21>

<p class=MsoBodyText style='margin-top:3.5pt;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>DentisToday<span
style='letter-spacing:-.4pt'> </span>may<span style='letter-spacing:-.7pt'> </span>transfer<span
style='letter-spacing:-.2pt'> </span>personal<span style='letter-spacing:-.7pt'>
</span>data<span style='letter-spacing:-.55pt'> </span>of<span
style='letter-spacing:-.65pt'> </span>data<span style='letter-spacing:-.75pt'> </span>owners<span
style='letter-spacing:-.15pt'> </span>managed<span style='letter-spacing:-.3pt'>
</span>by<span style='letter-spacing:-.7pt'> </span>the<span style='letter-spacing:
-.35pt'> </span>Policy<span style='letter-spacing:-.7pt'> </span>according<span
style='letter-spacing:-.3pt'> </span>to<span style='letter-spacing:-.45pt'> </span>the
issues stated under the Policy<span style='letter-spacing:-.05pt'> </span>and
Articles 8 and 9 of the KVKK, to the categories of persons stated below:</p>

<p class=MsoListParagraph align=left style='margin-top:.15pt;margin-right:0in;
margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>DentisToday’s<span
style='letter-spacing:-.3pt'> </span>business<span style='letter-spacing:-.4pt'>
</span><span style='letter-spacing:-.1pt'>partners,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;letter-spacing:-.1pt'>DentisToday’ssuppliers,</span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Dentists,<span style='letter-spacing:
-.1pt'> </span>physicians<span style='letter-spacing:-.2pt'> </span>and<span
style='letter-spacing:-.05pt'> </span>health<span style='letter-spacing:-.3pt'>
</span>clinics<span style='letter-spacing:-.2pt'> </span>that<span
style='letter-spacing:.15pt'> </span>work<span style='letter-spacing:-.3pt'> </span>with<span
style='letter-spacing:.1pt'> </span><span style='letter-spacing:-.1pt'>DentisToday,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>DentisToday’s<span
style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.1pt'>shareholders,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>DentisToday’s<span
style='letter-spacing:-.3pt'> </span>company<span style='letter-spacing:-.35pt'>
</span><span style='letter-spacing:-.1pt'>representatives,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Legally<span style='letter-spacing:
-.6pt'> </span>authorized<span style='letter-spacing:-.1pt'> </span>public<span
style='letter-spacing:.1pt'> </span>institutions<span style='letter-spacing:
-.2pt'> </span>and<span style='letter-spacing:-.05pt'> </span><span
style='letter-spacing:-.1pt'>organizations,</span></span></p>

<p class=MsoListParagraph align=left style='margin-top:2.05pt;margin-right:
0in;margin-bottom:0in;margin-left:76.6pt;margin-bottom:.0001pt;text-align:left;
text-indent:-17.95pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>Legally<span style='letter-spacing:
-.55pt'> </span>authorized<span style='letter-spacing:-.05pt'> </span>private<span
style='letter-spacing:.1pt'> </span>legal<span style='letter-spacing:-.25pt'> </span><span
style='letter-spacing:-.1pt'>entities.</span></span></p>

<p class=MsoBodyText align=left style='margin-top:4.3pt;text-align:left'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.3pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'>The scope of<span
style='letter-spacing:-.2pt'> </span>the above-mentioned persons to whom<span
style='letter-spacing:-.05pt'> </span>the transfer is made and the data<span
style='letter-spacing:-.1pt'> </span>transfer purposes are stated below.</p>

<p class=MsoBodyText align=left style='margin-top:4.7pt;text-align:left'><span
style='font-size:10.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:65.7pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:65.7pt'>
  <p class=TableParagraph align=center style='margin-top:.55pt;margin-right:
  7.4pt;margin-bottom:0in;margin-left:7.9pt;margin-bottom:.0001pt;text-align:
  center;text-indent:.05pt;line-height:15.8pt'><b><span style='font-size:12.0pt'>Persons
  to whom Data Transfer<span style='letter-spacing:-.75pt'> </span>can<span
  style='letter-spacing:-.75pt'> </span>be <span style='letter-spacing:-.2pt'>made</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:65.7pt'>
  <p class=TableParagraph style='margin-top:11.9pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-top:.05pt;margin-right:
  0in;margin-bottom:0in;margin-left:2.75pt;margin-bottom:.0001pt;text-align:
  center'><b><span style='font-size:12.0pt;letter-spacing:-.1pt'>Definition</span></b></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:65.7pt'>
  <p class=TableParagraph style='margin-top:11.9pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:24.45pt;margin-bottom:.0001pt'><b><span style='font-size:
  12.0pt'>Purpose<span style='letter-spacing:-.1pt'> </span>of<span
  style='letter-spacing:-.15pt'> </span>Data <span style='letter-spacing:-.1pt'>Transfer</span></span></b></p>
  </td>
 </tr>
 <tr style='height:144.95pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:144.95pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:10.15pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:5.5pt'><b><span style='font-size:
  12.0pt'>Business<span style='letter-spacing:-.2pt'> </span><span
  style='letter-spacing:-.1pt'>Partner</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:144.95pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.9pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>It defines
  the parties that DentisToday established business partnerships such as
  conducting various projects solely or with Group Company while it carries out
  its commercial activities, to receive services.</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:144.95pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.85pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Limited to
  the purpose of the provision of the fulfilment of the establishment purpose
  for which the business partnership has been <span style='letter-spacing:-.1pt'>established.</span></span></p>
  </td>
 </tr>
 <tr style='height:113.25pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:113.25pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:8.1pt'><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:.05pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><b><span style='font-size:12.0pt;
  letter-spacing:-.1pt'>Supplier</span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:113.25pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.15pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>It defines
  the parties that provide services to DentisToday on a contractual basis, in
  compliance with the orders and instructions of DentisToday while DentisToday
  carries<span style='letter-spacing:2.95pt'>   </span>out<span
  style='letter-spacing:3.05pt'>   </span>its<span style='letter-spacing:3.0pt'>  
  </span><span style='letter-spacing:-.1pt'>commercial</span></span></p>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><span style='font-size:12.0pt;
  letter-spacing:-.1pt'>activities.</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:113.25pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.3pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Limited to
  the purpose of the provision of required services that DentisToday outsourced
  from the supplier<span style='letter-spacing:-.55pt'> </span>and<span
  style='letter-spacing:-.65pt'> </span>to<span style='letter-spacing:-.55pt'> </span>DentisToday<span
  style='letter-spacing:-.75pt'> </span>for<span style='letter-spacing:-.75pt'>
  </span>the fulfilment of its commercial <span style='letter-spacing:-.1pt'>activities.</span></span></p>
  </td>
 </tr>
 <tr style='height:113.3pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:113.3pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:8.35pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:5.5pt'><b><span style='font-size:
  12.0pt;letter-spacing:-.1pt'>Shareholders</span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:113.3pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.15pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Real person
  shareholders of DentisToday and the Group <span style='letter-spacing:-.1pt'>Companies.</span></span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:113.3pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.7pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Limited to
  the purposes of activities conducted within the scope of the company law,
  event management and corporate communication procedures of DentisToday<span
  style='letter-spacing:3.25pt'> </span>as<span style='letter-spacing:3.5pt'> </span>per<span
  style='letter-spacing:3.7pt'> </span>the<span style='letter-spacing:3.6pt'> </span><span
  style='letter-spacing:-.1pt'>related</span></span></p>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify'><span
  style='font-size:12.0pt'>legislation<span style='letter-spacing:-.55pt'> </span><span
  style='letter-spacing:-.1pt'>provisions.</span></span></p>
  </td>
 </tr>
</table>

</div>

<span style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:auto'>
</span>

<div class=WordSection22>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:110.85pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:110.85pt'>
  <p class=TableParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:13.0pt'><span style='font-size:
  12.0pt'>&nbsp;</span></p>
  <p class=TableParagraph style='margin-left:5.5pt;line-height:115%'><b><span
  style='font-size:12.0pt;line-height:115%;letter-spacing:-.1pt'>Company
  representatives</span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:110.85pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.85pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Members of<span
  style='letter-spacing:-.05pt'> </span>the board of<span style='letter-spacing:
  -.05pt'> </span>directors and other authorized real persons of DentisToday.</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:110.85pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:2.3pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Limited<span
  style='letter-spacing:-.1pt'> </span>to<span style='letter-spacing:-.3pt'> </span>the<span
  style='letter-spacing:-.1pt'> </span>purposes<span style='letter-spacing:
  -.35pt'> </span>of<span style='letter-spacing:-.4pt'> </span>auditing and
  designing strategies regarding the DentisToday ensuring its management at the
  highest level according to the provisions of the related legislation.</span></p>
  </td>
 </tr>
 <tr style='height:97.4pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:97.4pt'>
  <p class=TableParagraph style='margin-top:1.95pt;margin-right:3.15pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  style='font-size:12.0pt;line-height:115%;letter-spacing:-.1pt'>Legally
  Authorized Public </span></b><b><span style='font-size:12.0pt;line-height:
  115%'>Institutions<span style='letter-spacing:-.75pt'> </span>and <span
  style='letter-spacing:-.1pt'>Organizations</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:97.4pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.95pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%;letter-spacing:
  -.1pt'>Public</span><span style='font-size:12.0pt;line-height:115%'>           <span
  style='letter-spacing:-.1pt'>institutions</span>           <span
  style='letter-spacing:-.2pt'>and </span>organizations<span style='letter-spacing:
  -.75pt'> </span>that<span style='letter-spacing:-.75pt'> </span>are<span
  style='letter-spacing:-.75pt'> </span>authorized<span style='letter-spacing:
  -.75pt'> </span>to obtain information<span style='letter-spacing:-.1pt'> </span>and
  documents from DentisToday as per the provisions<span style='letter-spacing:
  3.6pt'>   </span>of<span style='letter-spacing:3.55pt'>   </span>the<span
  style='letter-spacing:3.7pt'>   </span><span style='letter-spacing:-.1pt'>related</span></span></p>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><span style='font-size:12.0pt;
  letter-spacing:-.1pt'>legislation.</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:97.4pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.5pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Limited to
  the purpose requested by<span style='letter-spacing:-.75pt'> </span>the<span
  style='letter-spacing:-.75pt'> </span>related<span style='letter-spacing:
  -.75pt'> </span>public<span style='letter-spacing:-.75pt'> </span>institution<span
  style='letter-spacing:-.75pt'> </span>and organization within their legal <span
  style='letter-spacing:-.1pt'>authorization.</span></span></p>
  </td>
 </tr>
 <tr style='height:81.6pt'>
  <td width=130 valign=top style='width:97.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:81.6pt'>
  <p class=TableParagraph style='margin-top:2.0pt;margin-right:22.0pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;line-height:115%'><b><span
  style='font-size:12.0pt;line-height:115%;letter-spacing:-.1pt'>Legally
  Authorized </span></b><b><span style='font-size:12.0pt;line-height:115%'>Private<span
  style='letter-spacing:-.75pt'> </span>Legal <span style='letter-spacing:-.1pt'>Entities</span></span></b></p>
  </td>
  <td width=238 valign=top style='width:178.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:81.6pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.75pt;
  margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Private
  legal entities that are authorized to obtain information and documents from
  DentisToday as<span style='letter-spacing:.15pt'> </span>per<span
  style='letter-spacing:.4pt'> </span>the<span style='letter-spacing:.25pt'> </span>provisions<span
  style='letter-spacing:.2pt'> </span>of<span style='letter-spacing:-.05pt'> </span>the<span
  style='letter-spacing:.3pt'> </span><span style='letter-spacing:-.1pt'>related</span></span></p>
  <p class=TableParagraph style='margin-top:.15pt;margin-right:0in;margin-bottom:
  0in;margin-left:5.5pt;margin-bottom:.0001pt'><span style='font-size:12.0pt;
  letter-spacing:-.1pt'>legislation.</span></p>
  </td>
  <td width=237 valign=top style='width:177.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:81.6pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:4.9pt;
  margin-bottom:0in;margin-left:5.25pt;margin-bottom:.0001pt;text-align:justify;
  line-height:115%'><span style='font-size:12.0pt;line-height:115%'>Limited to
  the purpose requested by<span style='letter-spacing:-.25pt'> </span>the<span
  style='letter-spacing:-.05pt'> </span>related<span style='letter-spacing:
  -.05pt'> </span>private legal<span style='letter-spacing:-.25pt'> </span>entities
  within their legal authorization.</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>

  `

  return (
    <div
      style={{
        padding: '20px',
      }}
      dangerouslySetInnerHTML={{
        __html: i18n.language === 'en' ? en : tr,
      }}
    >
    </div>
  )
}
