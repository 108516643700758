import { Box, SxProps } from '@mui/material';
import React from 'react';

type PageViewProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

export default function PageView({ children, sx }: PageViewProps) {
  return (
    <Box
      sx={{
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',
        px: { xs: 2, sm: 2, md: 4, lg: 5 },
        ...sx,
      }}>
      {children}
    </Box>
  );
}
