import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import { CampaignType } from './Campaigns';
import { useTranslation } from 'react-i18next';

type CampaignItemProps = {
  campaign: CampaignType;
}

export default function CampaignItem({ campaign }: CampaignItemProps) {

  const theme = useTheme();
  const { i18n } = useTranslation();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      onClick={() => { campaign.link && window.open(campaign.link, '_blank') }}
      sx={{ flex: '1', textAlign: isSmallScreen ? 'center' : 'left' }}>
      <img src={campaign.image} alt="" style={{ width: '100%', minWidth: 300 }} />
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '28.8px',
          color: 'rgba(15,23,42,1)',
          pt: '24px',
        }}>
        {JSON.parse(campaign.title)[i18n.language.includes('tr') ? 'tr' : 'en']}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '28.8px',
          color: 'rgba(15,23,42,1)',
          pt: '8px',
        }}>
        {JSON.parse(campaign.description)[i18n.language.includes('tr') ? 'tr' : 'en']}
      </Typography>
    </Box>
  )
}
