import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Fab } from '@mui/material';

export default function WhatsAppFab() {
  return (
    <Fab
      href="https://wa.me/905330557384"
      target="_blank"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
        color: 'white',
        backgroundColor: '#25D366',
        '&:hover': {
          backgroundColor: '#25D366',
        },
      }}
    >
      <WhatsAppIcon />
    </Fab>
  )
}
