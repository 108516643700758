import { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import logo from '../assets/logo.svg';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';

const languages = [
  { code: 'en', label: 'EN' },
  { code: 'tr', label: 'TR' },
];

export default function Appbar() {
  const { t, i18n } = useTranslation();

  const pages = [
    { name: t('appbar.home'), href: '' },
    { name: t('appbar.ai'), href: '#yapay-zeka' },
    { name: t('appbar.period'), href: '#surec' },
    { name: t('appbar.campaign'), href: '#kampanyalar' },
    { name: t('appbar.contact'), href: '#iletisim' },
  ];

  const [drawerOpen, setDrawerOpen] = useState(false);
  const loc = useLocation();
  const nav = useNavigate();

  const isMobile = useMediaQuery('(max-width:600px)');

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const selectedLanguage = event.target.value as string;
    i18n.changeLanguage(selectedLanguage);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (href: string) => {
    if (loc.pathname !== '/') {
      if(href === '') {
      nav('/');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        nav(`/${href}`);
      }
    } else {
      nav('/');
      if (href) {
        const element = document.querySelector(href);
        if (element) {
          const yOffset = -110;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (isMobile) {
        setDrawerOpen(false);
      }
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          background: 'white',
          position: 'fixed',
        }}>
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '100%', md: '1440px', lg: '1440px' },
            margin: 'auto',
            height: '100%',
          }}>
          <Toolbar
            sx={{
              justifyContent: isMobile ? 'center' : 'space-between',
              padding: isMobile ? '12px' : '24px 80px',
              gap: '25px',
            }}>
            {isMobile && (
              <IconButton onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => handleNavigation('')}>
              <img
                src={logo}
                alt="Dentistoday Logo"
                style={{ maxWidth: isMobile ? '150px' : '280px' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '24px',
              }}>
              {!isMobile &&
                pages.map((page) => (
                  <Button
                    onClick={() => handleNavigation(page.href)}
                    key={page.name}
                    sx={{
                      color: 'white',
                      height: '100%',
                      '&:hover': {
                        background: 'white',
                      },
                    }}>
                    <Typography
                      noWrap
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'none',
                        fontWeight: 500,
                        fontSize: '18px',
                        lineHeight: '24px',
                        color: 'rgba(50, 53, 62, 1)',
                        '&:hover': {
                          color: 'rgba(87, 210, 215, 1)',
                        },
                      }}>
                      {page.name}
                    </Typography>
                  </Button>
                ))}
              <FormControl>
                <Select
                  value={i18n.language}
                  onChange={handleLanguageChange}
                  size='small'
                >
                  {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                      {lang.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            width: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: '24px',
          }}>
          {pages.map((page) => (
            <Button
              key={page.name}
              onClick={() => handleNavigation(page.href)}
              sx={{
                color: 'rgba(50, 53, 62, 1)',
                textTransform: 'none',
                fontWeight: 500,
                mb: '16px',
                '&:hover': {
                  color: 'rgba(87, 210, 215, 1)',
                },
              }}>
              {page.name}
            </Button>
          ))}
        </Box>
      </Drawer>
    </>
  );
}
