import { useTranslation } from 'react-i18next';

export default function CookiePolicy() {

  const { i18n } = useTranslation();

  const en = `
  <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
h1
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:24.3pt;
	text-indent:-18.95pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:28.4pt;
	text-indent:-23.1pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.TableParagraph, li.TableParagraph, div.TableParagraph
	{mso-style-name:"Table Paragraph";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.45pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{text-autospace:none;}
@page WordSection1
	{size:595.0pt 842.0pt;
	margin:52.0pt 63.0pt 14.0pt 64.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:595.0pt 842.0pt;
	margin:56.0pt 63.0pt 14.0pt 64.0pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:595.0pt 842.0pt;
	margin:96.0pt 63.0pt 14.0pt 64.0pt;}
div.WordSection3
	{page:WordSection3;}
@page WordSection4
	{size:595.0pt 842.0pt;
	margin:55.0pt 63.0pt 14.0pt 64.0pt;}
div.WordSection4
	{page:WordSection4;}
@page WordSection5
	{size:595.0pt 842.0pt;
	margin:51.0pt 63.0pt 14.0pt 64.0pt;}
div.WordSection5
	{page:WordSection5;}
@page WordSection6
	{size:595.0pt 842.0pt;
	margin:55.0pt 63.0pt 14.0pt 64.0pt;}
div.WordSection6
	{page:WordSection6;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class=WordSection1>

<h1 align=center style='margin-top:3.05pt;margin-right:187.8pt;margin-bottom:
0in;margin-left:189.05pt;margin-bottom:.0001pt;text-align:center;text-indent:
-.05pt;line-height:253%'><a name=DENTISTODAY></a><span style='letter-spacing:
-.1pt'>DENTISTODAY </span><a name="COOKIE_POLICY"></a>COOKIE<span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>POLICY</span></h1>

<p class=MsoBodyText style='margin-top:4.5pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.0pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:138%'>As<span style='letter-spacing:-.7pt'> </span>Aıdent<span
style='letter-spacing:-.55pt'> </span>Sağlık<span style='letter-spacing:-.55pt'>
</span>ve<span style='letter-spacing:-.7pt'> </span>Diş<span style='letter-spacing:
-.5pt'> </span>Teknolojileri<span style='letter-spacing:-.5pt'> </span>Anonim<span
style='letter-spacing:-.7pt'> </span>Şirketi<span style='letter-spacing:2.0pt'>
</span>(<b>“DentisToday”<span style='letter-spacing:-.5pt'> </span></b>and/or<span
style='letter-spacing:-.4pt'> </span>the<span style='letter-spacing:-.7pt'> </span>“<b>Company</b>”),<span
style='letter-spacing:-.4pt'> </span>we<span style='letter-spacing:-.7pt'> </span>use
cookies on DentisToday Application in order to enable users of our website to
carry out their visits appropriately and to improve visitor experiences. This
Cookie Policy (the <b>“Policy”</b>) has been prepared as<span style='letter-spacing:
-.55pt'> </span>per<span style='letter-spacing:-.25pt'> </span>the<span
style='letter-spacing:-.7pt'> </span>Protection<span style='letter-spacing:
-.45pt'> </span>of<span style='letter-spacing:-.5pt'> </span>Personal<span
style='letter-spacing:-.6pt'> </span>Data<span style='letter-spacing:-.3pt'> </span>Law<span
style='letter-spacing:-.7pt'> </span>numbered<span style='letter-spacing:-.65pt'>
</span>6698<span style='letter-spacing:-.45pt'> </span>(the<span
style='letter-spacing:-.7pt'> </span>“<b>Law</b>”)<span style='letter-spacing:
-.5pt'> </span>and<span style='letter-spacing:-.65pt'> </span>as<span
style='letter-spacing:-.4pt'> </span>per<span style='letter-spacing:-.25pt'> </span>the<span
style='letter-spacing:-.7pt'> </span>related<span style='letter-spacing:-.45pt'>
</span>legislation, in<span style='letter-spacing:-.5pt'> </span>order<span
style='letter-spacing:-.1pt'> </span>to<span style='letter-spacing:-.5pt'> </span>inform<span
style='letter-spacing:-.65pt'> </span>you<span style='letter-spacing:-.25pt'> </span>about<span
style='letter-spacing:-.2pt'> </span>how<span style='letter-spacing:-.5pt'> </span>your<span
style='letter-spacing:-.1pt'> </span>personal<span style='letter-spacing:-.4pt'>
</span>data<span style='letter-spacing:-.1pt'> </span>collected<span
style='letter-spacing:-.25pt'> </span>during<span style='letter-spacing:-.5pt'>
</span>the<span style='letter-spacing:-.6pt'> </span>use<span style='letter-spacing:
-.3pt'> </span>of<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>website<span style='letter-spacing:-.6pt'>
</span>is<span style='letter-spacing:-.2pt'> </span>processed. This<span
style='letter-spacing:-.65pt'> </span>Policy<span style='letter-spacing:-.7pt'>
</span>includes<span style='letter-spacing:-.5pt'> </span>all<span
style='letter-spacing:-.7pt'> </span>real<span style='letter-spacing:-.7pt'> </span>persons<span
style='letter-spacing:-.5pt'> </span>who<span style='letter-spacing:-.7pt'> </span>visit<span
style='letter-spacing:-.25pt'> </span>DentisToday<span style='letter-spacing:
-.7pt'> </span>Mobile<span style='letter-spacing:-.65pt'> </span>Application<span
style='letter-spacing:-.6pt'> </span>including<span style='letter-spacing:-.7pt'>
</span>our<span style='letter-spacing:-.35pt'> </span>customers, our business
partners, suppliers. <b>DentisToday </b>has the right to update the new version
of this Policy, when necessary, by publishing the same on the Application.</p>

<p class=MsoBodyText>&nbsp;</p>

<p class=MsoBodyText style='margin-top:6.2pt'>&nbsp;</p>

<h1 style='margin-left:26.25pt;text-indent:-20.9pt'><a
name="1._What_is_a_Cookie?"></a><span style='font-size:12.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>What is<span style='letter-spacing:-.1pt'> </span>a<span
style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>Cookie?</span></h1>

<p class=MsoBodyText style='margin-top:7.1pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.2pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>Cookies are small text files that are stored
on your device or a network server through browsers by websites that you visit,
in order to ensure that the website functions properly, to improve the user
experience, to<span style='letter-spacing:-.15pt'> </span>develop and<span
style='letter-spacing:-.15pt'> </span>optimize<span style='letter-spacing:-.2pt'>
</span>the website, to<span style='letter-spacing:-.15pt'> </span>provide more<span
style='letter-spacing:-.2pt'> </span>relevant, interest-specific content and
advertisements, to provide<span style='letter-spacing:-.05pt'> </span>an
interesting and personalized website<span style='letter-spacing:-.05pt'> </span>and
advertising portfolio for visitors and customers and to generate traffic
statistics.</p>

<p class=MsoBodyText style='margin-top:2.3pt'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.05pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>Cookies<span style='letter-spacing:-.6pt'> </span>do<span
style='letter-spacing:-.7pt'> </span>not<span style='letter-spacing:-.5pt'> </span>contain<span
style='letter-spacing:-.7pt'> </span>any<span style='letter-spacing:-.7pt'> </span>personal<span
style='letter-spacing:-.45pt'> </span>data<span style='letter-spacing:-.65pt'> </span>such<span
style='letter-spacing:-.7pt'> </span>as<span style='letter-spacing:-.5pt'> </span>name,<span
style='letter-spacing:-.45pt'> </span>gender<span style='letter-spacing:-.4pt'>
</span>and<span style='letter-spacing:-.7pt'> </span>address<span
style='letter-spacing:-.5pt'> </span>information<span style='letter-spacing:
-.55pt'> </span>of<span style='letter-spacing:-.6pt'> </span>users.<span
style='letter-spacing:-.45pt'> </span>In<span style='letter-spacing:-.7pt'> </span>order
to gain more detailed information on cookies, you may visit <a
href="http://www.aboutcookies.org/"><span style='color:windowtext'>www.aboutcookies.org</span></a>
and <a href="http://www.allaboutcookies.org/"><span style='color:windowtext'>www.allaboutcookies.org</span></a>
websites.</p>

<p class=MsoBodyText style='margin-top:1.2pt'>&nbsp;</p>

<h1 style='margin-left:26.25pt;text-indent:-20.9pt'><a
name="2._How_do_Cookies_Operate?"></a><span style='font-size:12.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>How<span style='letter-spacing:-.3pt'> </span>do<span
style='letter-spacing:-.2pt'> </span>Cookies<span style='letter-spacing:-.2pt'>
</span><span style='letter-spacing:-.1pt'>Operate?</span></h1>

<p class=MsoBodyText style='margin-top:7.15pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.25pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>Content of Cookies is determined<span
style='letter-spacing:-.1pt'> </span>by<span style='letter-spacing:-.1pt'> </span>the<span
style='letter-spacing:-.2pt'> </span>server and<span style='letter-spacing:
-.1pt'> </span>such<span style='letter-spacing:-.1pt'> </span>cookies, which<span
style='letter-spacing:-.1pt'> </span>are<span style='letter-spacing:-.2pt'> </span>located<span
style='letter-spacing:-.1pt'> </span>on<span style='letter-spacing:-.1pt'> </span>devices
of users when<span style='letter-spacing:-.15pt'> </span>they visit the
website, are<span style='letter-spacing:-.25pt'> </span>used<span
style='letter-spacing:-.15pt'> </span>by<span style='letter-spacing:-.15pt'> </span>the<span
style='letter-spacing:-.25pt'> </span>server. Cookies can<span
style='letter-spacing:-.15pt'> </span>be<span style='letter-spacing:-.25pt'> </span>considered<span
style='letter-spacing:-.15pt'> </span>as an<span style='letter-spacing:-.15pt'>
</span>identity<span style='letter-spacing:-.15pt'> </span>card<span
style='letter-spacing:-.15pt'> </span>of the internet user who is indicated
that s/he has visited the website again.</p>

<p class=MsoBodyText style='margin-top:2.35pt'>&nbsp;</p>

<p class=MsoNormal style='margin-top:0in;margin-right:5.1pt;margin-bottom:0in;
margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
line-height:135%'>Cookies<span style='letter-spacing:-.25pt'> </span>can<span
style='letter-spacing:-.5pt'> </span>be<span style='letter-spacing:-.6pt'> </span>used<span
style='letter-spacing:-.5pt'> </span>as<span style='letter-spacing:-.45pt'> </span>“<b>First<span
style='letter-spacing:-.1pt'> </span>Party<span style='letter-spacing:-.3pt'> </span>Cookies”<span
style='letter-spacing:-.2pt'> </span></b>and<span style='letter-spacing:-.5pt'>
</span>“<b>Third<span style='letter-spacing:-.4pt'> </span>Party<span
style='letter-spacing:-.25pt'> </span></b>Cookies”,<span style='letter-spacing:
-.2pt'> </span>depending<span style='letter-spacing:-.5pt'> </span>on<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:-.6pt'> </span>party<span
style='letter-spacing:-.5pt'> </span>who locates the Cookies. While Platform
Cookies are created by <b>DentisToday</b>, third party cookies are managed by
different companies in cooperation with <b>DentisToday</b>.</p>

<p class=MsoBodyText style='margin-top:4.3pt'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:4.95pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>Depending on the duration of cookies being
active, these are classified into two groups as “<b>Session Cookies</b>”<span
style='letter-spacing:-.7pt'> </span>and<span style='letter-spacing:-.7pt'> </span>“<b>Persistent<span
style='letter-spacing:-.7pt'> </span>Cookies</b>”<span style='letter-spacing:
-.6pt'> </span>according<span style='letter-spacing:-.7pt'> </span>to<span
style='letter-spacing:-.65pt'> </span>the<span style='letter-spacing:-.7pt'> </span>time<span
style='letter-spacing:-.7pt'> </span>they<span style='letter-spacing:-.7pt'> </span>are<span
style='letter-spacing:-.65pt'> </span>active.<span style='letter-spacing:-.35pt'>
</span>Session<span style='letter-spacing:-.7pt'> </span>Cookies<span
style='letter-spacing:-.45pt'> </span>are<span style='letter-spacing:-.7pt'> </span>temporary
cookies that are kept until leaving the website and these contain information
remembered and used by the website while the visitor shuffles among internet
pages.</p>

</div>

<span style='font-size:11.0pt;line-height:135%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection2>

<p class=MsoBodyText style='margin-top:3.15pt;margin-right:5.5pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>Although<span style='letter-spacing:-.35pt'> </span>Session<span
style='letter-spacing:-.35pt'> </span>Cookies are<span style='letter-spacing:
-.45pt'> </span>erased<span style='letter-spacing:-.35pt'> </span>when<span
style='letter-spacing:-.35pt'> </span>the<span style='letter-spacing:-.45pt'> </span>visitor
leaves<span style='letter-spacing:-.15pt'> </span>the<span style='letter-spacing:
-.45pt'> </span>webpage,<span style='letter-spacing:-.05pt'> </span>Persistent<span
style='letter-spacing:-.1pt'> </span>Cookies<span style='letter-spacing:-.15pt'>
</span>are<span style='letter-spacing:-.45pt'> </span>stored cookies<span
style='letter-spacing:-.1pt'> </span>on<span style='letter-spacing:-.35pt'> </span>devices<span
style='letter-spacing:-.1pt'> </span>of<span style='letter-spacing:-.2pt'> </span>visitors<span
style='letter-spacing:-.3pt'> </span>for<span style='letter-spacing:-.2pt'> </span>various<span
style='letter-spacing:-.3pt'> </span>durations<span style='letter-spacing:-.3pt'>
</span>depending<span style='letter-spacing:-.55pt'> </span>on<span
style='letter-spacing:-.55pt'> </span>the<span style='letter-spacing:-.65pt'> </span>usage<span
style='letter-spacing:-.4pt'> </span>field.<span style='letter-spacing:-.25pt'>
</span>In<span style='letter-spacing:-.55pt'> </span>order<span
style='letter-spacing:-.2pt'> </span>to<span style='letter-spacing:-.35pt'> </span>enhance<span
style='letter-spacing:-.65pt'> </span>the functionality<span style='letter-spacing:
-.7pt'> </span>of<span style='letter-spacing:-.7pt'> </span>the<span
style='letter-spacing:-.7pt'> </span>Website,<span style='letter-spacing:-.65pt'>
</span>these<span style='letter-spacing:-.7pt'> </span>are<span
style='letter-spacing:-.7pt'> </span>the<span style='letter-spacing:-.7pt'> </span>cookie<span
style='letter-spacing:-.65pt'> </span>types<span style='letter-spacing:-.7pt'> </span>that<span
style='letter-spacing:-.7pt'> </span>are<span style='letter-spacing:-.7pt'> </span>used<span
style='letter-spacing:-.65pt'> </span>to<span style='letter-spacing:-.7pt'> </span>provide<span
style='letter-spacing:-.7pt'> </span>quicker<span style='letter-spacing:-.7pt'>
</span>and<span style='letter-spacing:-.65pt'> </span>better<span
style='letter-spacing:-.7pt'> </span>services to users. Such kind of cookies
ensure that the web server remembers the user if s/he visits the website again
and do not contain any information that identifies the user.</p>

<p class=MsoBodyText style='margin-top:2.3pt'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.1pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>In order to provide<span style='letter-spacing:
-.1pt'> </span>better service<span style='letter-spacing:-.1pt'> </span>to our
visitors on our Website, we<span style='letter-spacing:-.1pt'> </span>use<span
style='letter-spacing:-.1pt'> </span>third party cookies for analytic purposes
in order to measure the number of page views, number of visits, different
transactions performed<span style='letter-spacing:-.25pt'> </span>by<span
style='letter-spacing:-.25pt'> </span>visitors on<span style='letter-spacing:
-.05pt'> </span>our Website, geographic<span style='letter-spacing:-.1pt'> </span>locations
of visitors and<span style='letter-spacing:-.25pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>number of<span style='letter-spacing:-.1pt'>
</span>return visitors to our Website.</p>

<p class=MsoBodyText style='margin-top:2.35pt'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>Furthermore,<span style='letter-spacing:-.1pt'>
</span>Cookies<span style='letter-spacing:-.15pt'> </span>are<span
style='letter-spacing:-.5pt'> </span>divided<span style='letter-spacing:-.15pt'>
</span>into<span style='letter-spacing:-.4pt'> </span>different<span
style='letter-spacing:-.1pt'> </span>categories<span style='letter-spacing:
-.15pt'> </span>depending<span style='letter-spacing:-.15pt'> </span>on<span
style='letter-spacing:-.4pt'> </span>their<span style='letter-spacing:-.05pt'> </span>usage<span
style='letter-spacing:-.5pt'> </span>purposes.<span style='letter-spacing:-.05pt'>
</span>You may find the related explanations on which cookie categories are
used for what purposes in the chart given <span style='letter-spacing:-.1pt'>below:</span></p>

<p class=MsoBodyText><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.9pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:5.5pt;border-collapse:collapse;border:none'>
 <tr style='height:59.75pt'>
  <td width=258 valign=top style='width:193.5pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:59.75pt'>
  <p class=TableParagraph style='margin-top:10.4pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt'>&nbsp;</p>
  <p class=TableParagraph align=center style='margin-top:.05pt;margin-right:
  0in;margin-bottom:0in;margin-left:.4pt;margin-bottom:.0001pt;text-align:center'><b>Cookie<span
  style='letter-spacing:-.45pt'> </span><span style='letter-spacing:-.1pt'>Categories</span></b></p>
  </td>
  <td width=351 valign=top style='width:263.6pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:59.75pt'>
  <p class=TableParagraph style='margin-top:10.4pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt'>&nbsp;</p>
  <p class=TableParagraph align=center style='margin-top:.05pt;margin-right:
  .6pt;margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:center'><b><span
  style='letter-spacing:-.1pt'>Explanations</span></b></p>
  </td>
 </tr>
 <tr style='height:73.85pt'>
  <td width=258 valign=top style='width:193.5pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:73.85pt'>
  <p class=TableParagraph style='margin-left:0in'>&nbsp;</p>
  <p class=TableParagraph style='margin-top:4.95pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt'>&nbsp;</p>
  <p class=TableParagraph align=center style='margin-top:0in;margin-right:.05pt;
  margin-bottom:0in;margin-left:.4pt;margin-bottom:.0001pt;text-align:center'><b><span
  style='letter-spacing:-.1pt'>Technical/Mandatory</span><span
  style='letter-spacing:1.0pt'> </span><span style='letter-spacing:-.1pt'>Cookies</span></b></p>
  </td>
  <td width=351 valign=top style='width:263.6pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:73.85pt'>
  <p class=TableParagraph style='margin-top:3.4pt;margin-right:.75pt;
  margin-bottom:0in;margin-left:.2pt;margin-bottom:.0001pt;text-align:justify;
  text-justify:inter-ideograph;line-height:135%'>These cookies are required for
  the usage and operability of the Website. In case technical cookies are
  deactivated, it may<span style='letter-spacing:-.05pt'> </span>be<span
  style='letter-spacing:-.15pt'> </span>possible not to<span style='letter-spacing:
  -.05pt'> </span>access the<span style='letter-spacing:-.15pt'> </span>Website<span
  style='letter-spacing:-.15pt'> </span>in<span style='letter-spacing:-.05pt'> </span>part
  or in<span style='letter-spacing:-.05pt'> </span>full.</p>
  </td>
 </tr>
 <tr style='height:108.95pt'>
  <td width=258 valign=top style='width:193.5pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:108.95pt'>
  <p class=TableParagraph style='margin-left:0in'>&nbsp;</p>
  <p class=TableParagraph style='margin-left:0in'>&nbsp;</p>
  <p class=TableParagraph style='margin-top:2.9pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt'>&nbsp;</p>
  <p class=TableParagraph style='margin-top:0in;margin-right:41.8pt;margin-bottom:
  0in;margin-left:42.7pt;margin-bottom:.0001pt;text-indent:10.3pt;line-height:
  136%'><b>Analytical Cookies (Performance<span style='letter-spacing:-.7pt'> </span>Cookies)</b></p>
  </td>
  <td width=351 valign=top style='width:263.6pt;border:none;border-bottom:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:108.95pt'>
  <p class=TableParagraph style='margin-top:3.4pt;margin-right:.65pt;
  margin-bottom:0in;margin-left:.2pt;margin-bottom:.0001pt;text-align:justify;
  text-justify:inter-ideograph;line-height:135%'>These cookies are used in
  order to obtain information on how the visitors of the Website use the
  Website and to develop/improve<span style='letter-spacing:-.7pt'> </span>visitor<span
  style='letter-spacing:-.45pt'> </span>experiences.<span style='letter-spacing:
  -.45pt'> </span>These<span style='letter-spacing:-.7pt'> </span>cookies<span
  style='letter-spacing:-.55pt'> </span>contain information<span
  style='letter-spacing:-.3pt'> </span>such<span style='letter-spacing:-.3pt'> </span>as<span
  style='letter-spacing:-.1pt'> </span>the<span style='letter-spacing:-.4pt'> </span>pages<span
  style='letter-spacing:-.1pt'> </span>reviewed<span style='letter-spacing:
  -.1pt'> </span>by<span style='letter-spacing:-.3pt'> </span>visitors, number
  of clicks and times of entering and exiting such pages.</p>
  </td>
 </tr>
</table>

<p class=MsoBodyText><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoBodyText><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='margin-left:6.75pt;border-collapse:collapse'>
 <tr style='height:91.65pt'>
  <td width=256 valign=top style='width:192.3pt;border-top:solid black 1.0pt;
  border-left:none;border-bottom:none;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:91.65pt'>
  <p class=TableParagraph style='margin-left:0in'>&nbsp;</p>
  <p class=TableParagraph style='margin-left:0in'>&nbsp;</p>
  <p class=TableParagraph style='margin-top:2.65pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt'>&nbsp;</p>
  <p class=TableParagraph style='margin-left:5.0pt'><b>Functionality<span
  style='letter-spacing:-.7pt'> </span>/Personalization<span style='letter-spacing:
  -.65pt'> </span><span style='letter-spacing:-.1pt'>Cookies</span></b></p>
  </td>
  <td width=352 valign=top style='width:263.65pt;border:none;border-top:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:91.65pt'>
  <p class=TableParagraph style='margin-left:0in'>&nbsp;</p>
  <p class=TableParagraph style='margin-top:1.4pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt'>&nbsp;</p>
  <p class=TableParagraph style='margin-top:0in;margin-right:.4pt;margin-bottom:
  0in;margin-left:.2pt;margin-bottom:.0001pt;text-align:justify;text-justify:
  inter-ideograph;line-height:135%'>These cookies are used in order to provide
  a personalized experience<span style='letter-spacing:-.15pt'> </span>to<span
  style='letter-spacing:-.1pt'> </span>visitors of the<span style='letter-spacing:
  -.15pt'> </span>Website. These<span style='letter-spacing:-.15pt'> </span>cookies
  ensure that<span style='letter-spacing:.1pt'> </span>your<span
  style='letter-spacing:.2pt'> </span>preferences<span style='letter-spacing:
  .05pt'> </span>such<span style='letter-spacing:-.15pt'> </span>as<span
  style='letter-spacing:.05pt'> </span>your<span style='letter-spacing:.2pt'> </span>language<span
  style='letter-spacing:-.25pt'> </span>preference<span style='letter-spacing:
  .25pt'> </span><span style='letter-spacing:-.25pt'>and</span></p>
  <p class=TableParagraph style='margin-top:.1pt;margin-right:0in;margin-bottom:
  0in;margin-left:.2pt;margin-bottom:.0001pt;text-align:justify;text-justify:
  inter-ideograph;line-height:12.65pt'>your username<span style='letter-spacing:
  -.4pt'> </span>are<span style='letter-spacing:-.4pt'> </span>remembered<span
  style='letter-spacing:-.35pt'> </span>when<span style='letter-spacing:-.1pt'>
  </span>you<span style='letter-spacing:-.1pt'> </span>visit<span
  style='letter-spacing:-.05pt'> </span>the<span style='letter-spacing:-.15pt'>
  </span><span style='letter-spacing:-.1pt'>website.</span></p>
  </td>
 </tr>
</table>

</div>

<span style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection3>

<h1 style='margin-top:12.1pt;margin-right:0in;margin-bottom:0in;margin-left:
26.5pt;margin-bottom:.0001pt;text-indent:-21.15pt'><a
name="3._Why_are_Cookies_Used?"></a><span style='font-size:12.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Why<span style='letter-spacing:-.15pt'> </span>are<span
style='letter-spacing:-.35pt'> </span>Cookies<span style='letter-spacing:-.25pt'>
</span><span style='letter-spacing:-.2pt'>Used?</span></h1>

<p class=MsoBodyText style='margin-top:7.15pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0in;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph'>Cookies<span style='letter-spacing:.2pt'> </span>on<span
style='letter-spacing:-.3pt'> </span>the<span style='letter-spacing:-.35pt'> </span>Website<span
style='letter-spacing:-.35pt'> </span>are<span style='letter-spacing:-.4pt'> </span>used<span
style='letter-spacing:-.25pt'> </span>for<span style='letter-spacing:.1pt'> </span>the<span
style='letter-spacing:-.35pt'> </span>following<span style='letter-spacing:
-.25pt'> </span><span style='letter-spacing:-.1pt'>purposes:</span></p>

<p class=MsoBodyText style='margin-top:6.4pt'>&nbsp;</p>

<p class=MsoListParagraph style='margin-right:5.75pt;text-align:justify;
text-justify:inter-ideograph;line-height:130%'><span style='font-size:12.0pt;
line-height:130%'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To realize elementary functions required for the operation of the
Website. For instance, members who log in do not need to enter passwords again
while visiting different pages of the Website.</p>

<p class=MsoListParagraph style='margin-right:5.15pt;text-align:justify;
text-justify:inter-ideograph;line-height:132%'><span style='font-size:12.0pt;
line-height:132%'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To analyse the Website and enhance the performance of the Website.
For instance, the integration of<span style='letter-spacing:-.4pt'> </span>different<span
style='letter-spacing:-.3pt'> </span>servers<span style='letter-spacing:-.3pt'>
</span>that<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:-.65pt'> </span>Website<span style='letter-spacing:-.65pt'>
</span>runs<span style='letter-spacing:-.3pt'> </span>on,<span
style='letter-spacing:-.25pt'> </span>determination<span style='letter-spacing:
-.55pt'> </span>of<span style='letter-spacing:-.4pt'> </span>the<span
style='letter-spacing:-.45pt'> </span>number<span style='letter-spacing:-.2pt'>
</span>of<span style='letter-spacing:-.2pt'> </span>visitors<span
style='letter-spacing:-.3pt'> </span>of<span style='letter-spacing:-.4pt'> </span>the<span
style='letter-spacing:-.65pt'> </span>Website and<span style='letter-spacing:
-.55pt'> </span>adjusting<span style='letter-spacing:-.55pt'> </span>performance<span
style='letter-spacing:-.65pt'> </span>accordingly<span style='letter-spacing:
-.3pt'> </span>or<span style='letter-spacing:-.15pt'> </span>making<span
style='letter-spacing:-.55pt'> </span>it easier<span style='letter-spacing:
-.15pt'> </span>for<span style='letter-spacing:-.15pt'> </span>visitors<span
style='letter-spacing:-.25pt'> </span>to<span style='letter-spacing:-.55pt'> </span>find<span
style='letter-spacing:-.3pt'> </span>what<span style='letter-spacing:-.25pt'> </span>they<span
style='letter-spacing:-.55pt'> </span>are<span style='letter-spacing:-.65pt'> </span>looking
<span style='letter-spacing:-.2pt'>for.</span></p>

<p class=MsoListParagraph style='margin-right:5.5pt;text-align:justify;
text-justify:inter-ideograph;line-height:132%'><span style='font-size:12.0pt;
line-height:132%'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To enhance the functionality of the Website and provide ease of
use. For instance, sharing third party social media channels on the Website,
remembering the username information and search queries on the next visit of
the visitor to the Website.</p>

<h1 style='margin-top:11.15pt;margin-right:0in;margin-bottom:0in;margin-left:
26.5pt;margin-bottom:.0001pt;text-indent:-21.15pt'><a
name="4._How_Can_You_Manage_Your_Cookie_Prefer"></a><span style='font-size:
12.0pt'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>How<span style='letter-spacing:-.1pt'> </span>Can<span
style='letter-spacing:-.45pt'> </span>You<span style='letter-spacing:-.45pt'> </span>Manage<span
style='letter-spacing:-.1pt'> </span>Your<span style='letter-spacing:-.15pt'> </span>Cookie<span
style='letter-spacing:.1pt'> </span><span style='letter-spacing:-.1pt'>Preferences?</span></h1>

<p class=MsoBodyText style='margin-top:7.15pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.05pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'><b>DentisToday<span style='letter-spacing:
-.25pt'> </span></b>pay<span style='letter-spacing:-.6pt'> </span>uttermost<span
style='letter-spacing:-.25pt'> </span>attention<span style='letter-spacing:
-.6pt'> </span>to<span style='letter-spacing:-.6pt'> </span>the<span
style='letter-spacing:-.7pt'> </span>visitor’s<span style='letter-spacing:-.55pt'>
</span>ability<span style='letter-spacing:-.6pt'> </span>to<span
style='letter-spacing:-.6pt'> </span>us<span style='letter-spacing:-.3pt'> </span>their<span
style='letter-spacing:-.25pt'> </span>preferences<span style='letter-spacing:
-.05pt'> </span>on<span style='letter-spacing:-.6pt'> </span>their<span
style='letter-spacing:-.25pt'> </span>personal<span style='letter-spacing:-.5pt'>
</span>data. By<span style='letter-spacing:-.1pt'> </span>changing<span
style='letter-spacing:-.1pt'> </span>settings of your browser, you can<span
style='letter-spacing:-.1pt'> </span>enable<span style='letter-spacing:-.2pt'> </span>or
disable<span style='letter-spacing:-.2pt'> </span>cookies. However, the<span
style='letter-spacing:-.2pt'> </span>management of preference cannot be possible
for some cookies that are mandatory for the operation of the Website.
Furthermore, we would like to remind you that in the event where some cookies
are disabled, various functions of the Website may not be operated.</p>

<p class=MsoBodyText style='margin-top:.1pt;margin-right:5.8pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>You may obtain assistance<span
style='letter-spacing:-.1pt'> </span>on as to how<span style='letter-spacing:
-.05pt'> </span>you can disable<span style='letter-spacing:-.1pt'> </span>cookies
by clicking the<span style='letter-spacing:-.1pt'> </span>following link
specific to your browser:</p>

</div>

<span style='font-size:11.0pt;line-height:135%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection4>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:7.55pt;border-collapse:collapse;border:none'>
 <tr style='height:23.0pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:23.0pt'>
  <p class=TableParagraph style='margin-top:10.35pt;line-height:11.65pt'>Google<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>Chrome</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:23.0pt'>
  <p class=TableParagraph style='margin-top:10.35pt;line-height:11.65pt'><span
  style='letter-spacing:-.1pt'>support.google.com/chrome/answer/95647</span></p>
  </td>
 </tr>
 <tr style='height:39.55pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:39.55pt'>
  <p class=TableParagraph style='line-height:11.95pt'>Internet<span
  style='letter-spacing:-.5pt'> </span><span style='letter-spacing:-.1pt'>Explorer</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:39.55pt'>
  <p class=TableParagraph style='margin-top:8.55pt;margin-right:63.85pt;
  margin-bottom:0in;margin-left:.45pt;margin-bottom:.0001pt;line-height:15.0pt'><span
  style='letter-spacing:-.1pt'>support.microsft.com/tr/help/17442/windos-internet-explorer-
  delete-manage-cookies</span></p>
  </td>
 </tr>
 <tr style='height:39.8pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:39.8pt'>
  <p class=TableParagraph style='line-height:12.0pt'>Mozilla<span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>Firefox</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:39.8pt'>
  <p class=TableParagraph style='margin-right:7.55pt;line-height:18.0pt'><span
  style='letter-spacing:-.1pt'>support.mozilla.org/tr/kb/çerezleri-silme-web-sitelerinin-bilgilerini-
  kaldirma</span></p>
  </td>
 </tr>
 <tr style='height:22.5pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:22.5pt'>
  <p class=TableParagraph style='margin-top:9.85pt;line-height:11.65pt'><span
  style='letter-spacing:-.1pt'>Safari</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:22.5pt'>
  <p class=TableParagraph style='margin-top:9.85pt;line-height:11.65pt'><span
  style='letter-spacing:-.1pt'>support.apple.com.tr/tr-tr/guide/safari/sfri11471/mac</span></p>
  </td>
 </tr>
 <tr style='height:19.9pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:19.9pt'>
  <p class=TableParagraph style='margin-top:7.25pt;line-height:11.65pt'><span
  style='letter-spacing:-.1pt'>Opera</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:19.9pt'>
  <p class=TableParagraph style='margin-top:7.25pt;line-height:11.65pt'><a
  href="http://www.operaturkiye.net/arsiv/yardim/Windows/10.00/tr/cookies.html"><span
  style='color:windowtext;letter-spacing:-.1pt'>www.operaturkiye.net/arsiv/yardim/Windows/10.00/tr/cookies.html</span></a></p>
  </td>
 </tr>
 <tr style='height:24.95pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:24.95pt'>
  <p class=TableParagraph style='margin-top:12.3pt;line-height:11.65pt'>Google<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>Analytics</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:24.95pt'>
  <p class=TableParagraph style='margin-top:12.3pt;line-height:11.65pt'><a
  href="https://tools.google.com/dlpage/gaoptout"><span style='color:windowtext;
  letter-spacing:-.1pt'>https://tools.google.com/dlpage/gaoptout</span></a></p>
  </td>
 </tr>
 <tr style='height:22.5pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:22.5pt'>
  <p class=TableParagraph style='margin-top:9.85pt;line-height:11.65pt'>Google<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>AdWords</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:22.5pt'>
  <p class=TableParagraph style='margin-top:9.85pt;line-height:11.65pt'><a
  href="https://support.google.com/ads/answer/2662922?hl=en"><span
  style='color:windowtext;letter-spacing:-.1pt'>https://support.google.com/ads/answer/2662922?hl=en</span></a></p>
  </td>
 </tr>
 <tr style='height:22.75pt'>
  <td width=155 valign=top style='width:115.95pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:22.75pt'>
  <p class=TableParagraph style='margin-top:10.1pt;line-height:11.65pt'>Adobe<span
  style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>Analytics</span></p>
  </td>
  <td width=450 valign=top style='width:337.55pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:22.75pt'>
  <p class=TableParagraph style='margin-top:10.1pt;line-height:11.65pt'><a
  href="http://www.adobe.com/uk/privacy/opt-out.html"><span style='color:windowtext;
  letter-spacing:-.1pt'>http://www.adobe.com/uk/privacy/opt-out.html</span></a></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText>&nbsp;</p>

<p class=MsoBodyText>&nbsp;</p>

<p class=MsoBodyText>&nbsp;</p>

<p class=MsoBodyText>&nbsp;</p>

<p class=MsoBodyText style='margin-top:8.8pt'>&nbsp;</p>

<h1 style='margin-top:.05pt'><a name="5._Purpose_of_Processing_Your_Personal_D"></a><span
style='font-size:12.0pt'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Purpose<span style='letter-spacing:-.35pt'> </span>of<span
style='letter-spacing:-.4pt'> </span>Processing<span style='letter-spacing:
-.15pt'> </span>Your<span style='letter-spacing:.05pt'> </span>Personal<span
style='letter-spacing:-.35pt'> </span>Data<span style='letter-spacing:-.35pt'> </span>Collected<span
style='letter-spacing:-.25pt'> </span>via<span style='letter-spacing:-.35pt'> </span><span
style='letter-spacing:-.1pt'>Cookies</span></h1>

<p class=MsoBodyText style='margin-top:4.25pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-left:5.35pt;text-align:justify;text-justify:
inter-ideograph'>Your<span style='letter-spacing:-.05pt'> </span>personal<span
style='letter-spacing:-.35pt'> </span>data<span style='letter-spacing:-.05pt'> </span>collected<span
style='letter-spacing:-.4pt'> </span>via<span style='letter-spacing:-.05pt'> </span>Cookies<span
style='letter-spacing:-.15pt'> </span>are<span style='letter-spacing:-.5pt'> </span>processed<span
style='letter-spacing:-.15pt'> </span>for<span style='letter-spacing:-.05pt'> </span>the<span
style='letter-spacing:-.5pt'> </span>following<span style='letter-spacing:-.35pt'>
</span><span style='letter-spacing:-.1pt'>purposes:</span></p>

<p class=MsoBodyText style='margin-top:3.5pt'>&nbsp;</p>

<p class=MsoListParagraph style='margin-left:24.35pt;text-indent:-19.0pt'><span
style='font-size:12.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.5pt'> </span>monitor<span
style='letter-spacing:.05pt'> </span>movements<span style='letter-spacing:-.1pt'>
</span>of<span style='letter-spacing:-.35pt'> </span>Website’s<span
style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>visitors,</span></p>

<p class=MsoListParagraph style='margin-top:3.25pt;margin-right:48.9pt;
margin-bottom:0in;margin-left:28.4pt;margin-bottom:.0001pt;line-height:130%'><span
style='font-size:12.0pt;line-height:130%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.3pt'> </span>ensure<span
style='letter-spacing:-.4pt'> </span>that<span style='letter-spacing:-.05pt'> </span>they<span
style='letter-spacing:-.3pt'> </span>realize<span style='letter-spacing:-.4pt'>
</span>their visits<span style='letter-spacing:-.1pt'> </span>appropriately<span
style='letter-spacing:-.3pt'> </span>and<span style='letter-spacing:-.1pt'> </span>to<span
style='letter-spacing:-.3pt'> </span>ensure<span style='letter-spacing:-.4pt'> </span>that<span
style='letter-spacing:-.05pt'> </span>your information<span style='letter-spacing:
-.3pt'> </span>is remembered on your next visit,</p>

<p class=MsoListParagraph style='margin-left:24.35pt;text-indent:-19.0pt;
line-height:13.5pt'><span style='font-size:12.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.35pt'> </span>improve<span
style='letter-spacing:-.35pt'> </span>and<span style='letter-spacing:-.25pt'> </span>develop
the<span style='letter-spacing:-.3pt'> </span>structure<span style='letter-spacing:
-.35pt'> </span>and<span style='letter-spacing:-.25pt'> </span>content<span
style='letter-spacing:.05pt'> </span>of<span style='letter-spacing:-.1pt'> </span>the<span
style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>Website,</span></p>

<p class=MsoListParagraph style='margin-top:3.25pt;margin-right:0in;margin-bottom:
0in;margin-left:24.35pt;margin-bottom:.0001pt;text-indent:-19.0pt'><span
style='font-size:12.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.3pt'> </span>evaluate<span
style='letter-spacing:-.35pt'> </span>the<span style='letter-spacing:-.35pt'> </span>usages<span
style='letter-spacing:.15pt'> </span>of<span style='letter-spacing:-.1pt'> </span>visitors
<span style='letter-spacing:-.1pt'>statistically,</span></p>

<p class=MsoListParagraph style='margin-top:3.25pt;margin-right:0in;margin-bottom:
0in;margin-left:24.35pt;margin-bottom:.0001pt;text-indent:-19.0pt'><span
style='font-size:12.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.4pt'> </span>conduct<span
style='letter-spacing:-.15pt'> </span>online<span style='letter-spacing:-.5pt'>
</span>advertisement<span style='letter-spacing:-.1pt'> activities,</span></p>

<p class=MsoListParagraph style='margin-top:3.25pt;margin-right:16.65pt;
margin-bottom:0in;margin-left:28.4pt;margin-bottom:.0001pt;line-height:130%'><span
style='font-size:12.0pt;line-height:130%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.3pt'> </span>ensure<span
style='letter-spacing:-.4pt'> </span>that the<span style='letter-spacing:-.4pt'>
</span>content on<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>website<span style='letter-spacing:-.4pt'>
</span>is<span style='letter-spacing:-.05pt'> </span>disabled<span
style='letter-spacing:-.3pt'> </span>in<span style='letter-spacing:-.05pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>most effective<span style='letter-spacing:
-.15pt'> </span>manner for you<span style='letter-spacing:-.05pt'> </span>and<span
style='letter-spacing:-.3pt'> </span>for your computer,</p>

<p class=MsoListParagraph style='margin-left:24.35pt;text-indent:-19.0pt;
line-height:13.55pt'><span style='font-size:12.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.45pt'> </span>enable<span
style='letter-spacing:-.45pt'> </span>you<span style='letter-spacing:-.1pt'> </span>to<span
style='letter-spacing:-.35pt'> </span>take<span style='letter-spacing:-.45pt'> </span>advantage<span
style='letter-spacing:-.2pt'> </span>of<span style='letter-spacing:-.2pt'> </span>our
services,<span style='letter-spacing:.05pt'> </span>if you<span
style='letter-spacing:-.1pt'> </span>choose, <span style='letter-spacing:-.1pt'>interactively,</span></p>

<p class=MsoListParagraph style='margin-top:3.25pt;margin-right:6.2pt;
margin-bottom:0in;margin-left:28.4pt;margin-bottom:.0001pt;line-height:130%'><span
style='font-size:12.0pt;line-height:130%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.35pt'> </span>offer the<span
style='letter-spacing:-.2pt'> </span>most<span style='letter-spacing:-.05pt'> </span>appropriate<span
style='letter-spacing:-.45pt'> </span>service<span style='letter-spacing:-.45pt'>
</span>for you<span style='letter-spacing:-.1pt'> </span>by<span
style='letter-spacing:-.35pt'> </span>categorizing<span style='letter-spacing:
-.35pt'> </span>options<span style='letter-spacing:-.1pt'> </span>according<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.35pt'> </span>your
preferences and needs, if you use our shopping and online market services,</p>

<p class=MsoListParagraph style='margin-left:24.35pt;text-indent:-19.0pt;
line-height:13.5pt'><span style='font-size:12.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.45pt'> </span>offer<span
style='letter-spacing:.1pt'> </span>services<span style='letter-spacing:-.1pt'>
</span>customized<span style='letter-spacing:-.3pt'> </span>services<span
style='letter-spacing:-.1pt'> </span>in<span style='letter-spacing:-.3pt'> </span>line<span
style='letter-spacing:-.4pt'> </span>with<span style='letter-spacing:-.3pt'> </span>the<span
style='letter-spacing:-.2pt'> </span>visitor<span style='letter-spacing:.1pt'> </span>preference<span
style='letter-spacing:-.4pt'> </span>and<span style='letter-spacing:-.3pt'> </span><span
style='letter-spacing:-.1pt'>needs.</span></p>

<p class=MsoBodyText style='margin-top:5.45pt'>&nbsp;</p>

<h1><a name="6._Legal_Ground_of_Collecting_Personal_D"></a><span
style='font-size:12.0pt'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Legal<span style='letter-spacing:-.25pt'> </span>Ground<span
style='letter-spacing:-.2pt'> </span>of<span style='letter-spacing:-.35pt'> </span>Collecting
Personal<span style='letter-spacing:-.25pt'> </span>Data<span style='letter-spacing:
-.3pt'> </span>via<span style='letter-spacing:-.25pt'> </span><span
style='letter-spacing:-.1pt'>Cookies</span></h1>

<p class=MsoBodyText style='margin-top:4.25pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.3pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'><b>DentisToday </b>processes your personal data
collected via cookies by basing on the legal ground of increasing the
performance and effectiveness of the Website and that personal data processing
is mandatory for the legitimate interests of <b>DentisToday </b>due to its
legitimate interest in improving the experience of the Website users.</p>

</div>

<span style='font-size:11.0pt;line-height:135%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection5>

<h1 style='margin-top:3.1pt;text-align:justify;text-justify:inter-ideograph'><a
name="7._Sharing_Personal_Data_Collected_via_C"></a><span style='font-size:
12.0pt'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Sharing<span style='letter-spacing:.05pt'> </span>Personal<span
style='letter-spacing:-.35pt'> </span>Data<span style='letter-spacing:-.4pt'> </span>Collected<span
style='letter-spacing:-.35pt'> </span>via<span style='letter-spacing:-.35pt'> </span><span
style='letter-spacing:-.1pt'>Cookies</span></h1>

<p class=MsoBodyText style='margin-top:3.25pt;margin-right:5.05pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>We share your personal data that we collected
via cookies and/or that we obtained by Processing the same,<span
style='letter-spacing:-.65pt'> </span>in<span style='letter-spacing:-.7pt'> </span>order<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.7pt'> </span>conduct<span
style='letter-spacing:-.5pt'> </span>personal<span style='letter-spacing:-.7pt'>
</span>data<span style='letter-spacing:-.4pt'> </span>processing<span
style='letter-spacing:-.7pt'> </span>purposes<span style='letter-spacing:-.5pt'>
</span>stated<span style='letter-spacing:-.7pt'> </span>under<span
style='letter-spacing:-.35pt'> </span>the<span style='letter-spacing:-.7pt'> </span>related<span
style='letter-spacing:-.7pt'> </span>section<span style='letter-spacing:-.55pt'>
</span>of<span style='letter-spacing:-.6pt'> </span>this<span style='letter-spacing:
-.5pt'> </span>Policy with our suppliers and business partners in and outside
the country that we obtain support and service with<span style='letter-spacing:
-.4pt'> </span>regard<span style='letter-spacing:-.45pt'> </span>to<span
style='letter-spacing:-.4pt'> </span>the<span style='letter-spacing:-.55pt'> </span>publication<span
style='letter-spacing:-.2pt'> </span>and<span style='letter-spacing:-.2pt'> </span>operation<span
style='letter-spacing:-.15pt'> </span>of<span style='letter-spacing:-.05pt'> </span>our<span
style='letter-spacing:-.05pt'> </span>website.<span style='letter-spacing:-.1pt'>
</span>In<span style='letter-spacing:-.4pt'> </span>addition<span
style='letter-spacing:-.45pt'> </span>to<span style='letter-spacing:-.4pt'> </span>this,<span
style='letter-spacing:-.05pt'> </span>your<span style='letter-spacing:-.05pt'> </span>personal<span
style='letter-spacing:-.3pt'> </span>data<span style='letter-spacing:-.05pt'> </span>is<span
style='letter-spacing:-.15pt'> </span>also shared<span style='letter-spacing:
-.25pt'> </span>with<span style='letter-spacing:-.25pt'> </span>such<span
style='letter-spacing:-.25pt'> </span>parties for the<span style='letter-spacing:
-.35pt'> </span>purpose<span style='letter-spacing:-.1pt'> </span>of<span
style='letter-spacing:-.1pt'> </span>improving<span style='letter-spacing:-.25pt'>
</span>the<span style='letter-spacing:-.35pt'> </span>visitor experience<span
style='letter-spacing:-.35pt'> </span>and<span style='letter-spacing:-.25pt'> </span>recovery<span
style='letter-spacing:-.25pt'> </span>from<span style='letter-spacing:-.45pt'> </span>software
bugs of the<span style='letter-spacing:-.1pt'> </span>Website<span
style='letter-spacing:-.1pt'> </span>and the<span style='letter-spacing:-.1pt'>
</span>user of the<span style='letter-spacing:-.1pt'> </span>Website. Your
personal data can be<span style='letter-spacing:-.35pt'> </span>also shared
with authorized institutions and entities, judicial and administrative bodies
for the purpose of fulfilling our legal obligations<span style='letter-spacing:
-.3pt'> </span>upon<span style='letter-spacing:-.6pt'> </span>a<span
style='letter-spacing:-.25pt'> </span>legal<span style='letter-spacing:-.5pt'> </span>request.<span
style='letter-spacing:-.3pt'> </span>Your<span style='letter-spacing:-.25pt'> </span>personal<span
style='letter-spacing:-.5pt'> </span>data<span style='letter-spacing:-.25pt'> </span>can<span
style='letter-spacing:-.6pt'> </span>be<span style='letter-spacing:-.7pt'> </span>retained<span
style='letter-spacing:-.6pt'> </span>on<span style='letter-spacing:-.6pt'> </span>servers<span
style='letter-spacing:-.3pt'> </span>in<span style='letter-spacing:-.6pt'> </span>various<span
style='letter-spacing:-.3pt'> </span>countries<span style='letter-spacing:-.3pt'>
</span>when the<span style='letter-spacing:-.55pt'> </span>third<span
style='letter-spacing:-.45pt'> </span>party<span style='letter-spacing:-.45pt'>
</span>cookies<span style='letter-spacing:-.15pt'> </span>are<span
style='letter-spacing:-.55pt'> </span>used.<span style='letter-spacing:-.1pt'> </span>If<span
style='letter-spacing:-.25pt'> </span>you<span style='letter-spacing:-.2pt'> </span>would<span
style='letter-spacing:-.45pt'> </span>like<span style='letter-spacing:-.55pt'> </span>to<span
style='letter-spacing:-.45pt'> </span>prevent<span style='letter-spacing:-.15pt'>
</span>the<span style='letter-spacing:-.55pt'> </span>usage<span
style='letter-spacing:-.3pt'> </span>of<span style='letter-spacing:-.25pt'> </span>cookies,<span
style='letter-spacing:-.05pt'> </span>we<span style='letter-spacing:-.3pt'> </span>kindly<span
style='letter-spacing:-.45pt'> </span>request you to review the part with the
heading “<b>How Can You Manage Your Cookie Preferences?</b>” under this <span
style='letter-spacing:-.1pt'>Policy.</span></p>

<p class=MsoBodyText style='margin-top:1.15pt'>&nbsp;</p>

<h1 style='margin-top:.05pt;text-align:justify;text-justify:inter-ideograph'><a
name="8._Principles_that_We_Obey_While_Process"></a><span style='font-size:
12.0pt'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Principles<span style='letter-spacing:-.3pt'> </span>that<span
style='letter-spacing:-.25pt'> </span>We<span style='letter-spacing:-.25pt'> </span>Obey<span
style='letter-spacing:-.15pt'> </span>While Processing<span style='letter-spacing:
-.15pt'> </span>Personal<span style='letter-spacing:-.35pt'> </span>Data<span
style='letter-spacing:-.4pt'> </span>Collected<span style='letter-spacing:-.3pt'>
</span>via<span style='letter-spacing:-.35pt'> </span><span style='letter-spacing:
-.1pt'>Cookies</span></h1>

<p class=MsoBodyText style='margin-top:7.35pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:136%'>As <b>DentisToday</b>, we<span
style='letter-spacing:-.1pt'> </span>take into account all principles stated
below<span style='letter-spacing:-.05pt'> </span>in all processes that we<span
style='letter-spacing:-.1pt'> </span>process your personal data and process
your personal data in line with such principles:</p>

<p class=MsoBodyText style='margin-top:1.2pt'>&nbsp;</p>

<p class=MsoListParagraph style='margin-left:44.25pt;text-indent:-17.75pt'><b><span
style='font-size:12.0pt'>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b>Lawfulness<span style='letter-spacing:-.15pt'> </span>and<span
style='letter-spacing:-.4pt'> </span>conformity<span style='letter-spacing:
-.15pt'> </span>with<span style='letter-spacing:-.4pt'> </span>rules<span
style='letter-spacing:-.2pt'> </span>of<span style='letter-spacing:-.25pt'> </span>bona
<span style='letter-spacing:-.1pt'>fides,</span></p>

<p class=MsoListParagraph style='margin-top:3.3pt;margin-right:0in;margin-bottom:
0in;margin-left:44.15pt;margin-bottom:.0001pt;text-indent:-17.65pt'><b><span
style='font-size:12.0pt'>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b>Being<span style='letter-spacing:-.35pt'> </span>accuracy<span
style='letter-spacing:-.2pt'> </span>and<span style='letter-spacing:-.3pt'> </span>up<span
style='letter-spacing:-.1pt'> </span>to<span style='letter-spacing:-.3pt'> </span>date,<span
style='letter-spacing:.25pt'> </span>where<span style='letter-spacing:-.15pt'> </span><span
style='letter-spacing:-.1pt'>necessary,</span></p>

<p class=MsoListParagraph style='margin-top:3.25pt;margin-right:0in;margin-bottom:
0in;margin-left:45.1pt;margin-bottom:.0001pt;text-indent:-18.6pt'><b><span
style='font-size:12.0pt'>iii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b>Being<span style='letter-spacing:-.5pt'> </span>processed<span
style='letter-spacing:-.45pt'> </span>for<span style='letter-spacing:-.15pt'> </span>specific,<span
style='letter-spacing:.1pt'> </span>explicit<span style='letter-spacing:-.2pt'>
</span>and<span style='letter-spacing:-.25pt'> </span>legitimate<span
style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.1pt'>purposes,</span></p>

<p class=MsoListParagraph style='margin-top:3.2pt;margin-right:0in;margin-bottom:
0in;margin-left:43.25pt;margin-bottom:.0001pt;text-indent:-16.75pt'><b><span
style='font-size:12.0pt'>iv.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b>Being<span style='letter-spacing:-.45pt'> </span>relevant<span
style='letter-spacing:-.05pt'> </span>with,<span style='letter-spacing:.05pt'> </span>limited<span
style='letter-spacing:-.35pt'> </span>to<span style='letter-spacing:-.3pt'> </span>and<span
style='letter-spacing:-.3pt'> </span>proportionate<span style='letter-spacing:
-.4pt'> </span>to<span style='letter-spacing:-.35pt'> </span>the<span
style='letter-spacing:-.15pt'> </span>purposes<span style='letter-spacing:-.1pt'>
</span>for<span style='letter-spacing:.05pt'> </span>which<span
style='letter-spacing:-.3pt'> </span>they<span style='letter-spacing:-.3pt'> </span>are<span
style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.1pt'>processed,</span></p>

<p class=MsoListParagraph style='margin-top:3.25pt;margin-right:27.3pt;
margin-bottom:0in;margin-left:43.3pt;margin-bottom:.0001pt;text-indent:-16.85pt;
line-height:130%'><b><span style='font-size:12.0pt;line-height:130%'>v.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b>Being<span
style='letter-spacing:-.3pt'> </span>retained<span style='letter-spacing:-.3pt'>
</span>for the<span style='letter-spacing:-.4pt'> </span>period<span
style='letter-spacing:-.05pt'> </span>of<span style='letter-spacing:-.15pt'> </span>time<span
style='letter-spacing:-.15pt'> </span>determined<span style='letter-spacing:
-.3pt'> </span>by<span style='letter-spacing:-.3pt'> </span>relevant
legislation<span style='letter-spacing:-.3pt'> </span>or required<span
style='letter-spacing:-.3pt'> </span>for the purpose for which they are
processed.</p>

<p class=MsoBodyText style='margin-top:2.2pt'>&nbsp;</p>

<h1 style='text-align:justify;text-justify:inter-ideograph'><a
name="9._Your_Rights_under_the_Usage_of_Cookie"></a><span style='font-size:
12.0pt'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Your<span style='letter-spacing:-.25pt'> </span>Rights<span
style='letter-spacing:.1pt'> </span>under<span style='letter-spacing:-.2pt'> </span>the<span
style='letter-spacing:-.2pt'> </span>Usage<span style='letter-spacing:-.25pt'> </span>of<span
style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>Cookie</span></h1>

<p class=MsoBodyText style='margin-top:7.1pt'><b>&nbsp;</b></p>

<p class=MsoBodyText style='margin-left:5.35pt;text-align:justify;text-justify:
inter-ideograph'>You<span style='letter-spacing:-.2pt'> </span>have<span
style='letter-spacing:-.4pt'> </span>the<span style='letter-spacing:-.4pt'> </span>following<span
style='letter-spacing:-.3pt'> </span>rights<span style='letter-spacing:-.1pt'> </span>with<span
style='letter-spacing:-.3pt'> </span>regard<span style='letter-spacing:-.3pt'> </span>to<span
style='letter-spacing:-.3pt'> </span>your<span style='letter-spacing:.05pt'> </span>personal<span
style='letter-spacing:-.25pt'> </span>data<span style='letter-spacing:.05pt'> </span>collected<span
style='letter-spacing:-.3pt'> </span>via<span style='letter-spacing:.05pt'> </span>cookies<span
style='letter-spacing:-.1pt'> </span>by<span style='letter-spacing:.3pt'> </span><b><span
style='letter-spacing:-.1pt'>DentisToday</span></b><span style='letter-spacing:
-.1pt'>:</span></p>

<p class=MsoListParagraph style='margin-top:3.5pt;margin-right:5.4pt;
margin-bottom:0in;margin-left:26.5pt;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-21.15pt;line-height:130%'><span
style='font-size:12.0pt;line-height:130%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.7pt'> </span>learn<span
style='letter-spacing:-.7pt'> </span>as<span style='letter-spacing:-.6pt'> </span>to<span
style='letter-spacing:-.7pt'> </span>whether<span style='letter-spacing:-.35pt'>
</span>your<span style='letter-spacing:-.35pt'> </span>personal<span
style='letter-spacing:-.45pt'> </span>data<span style='letter-spacing:-.4pt'> </span>is<span
style='letter-spacing:-.5pt'> </span>processed<span style='letter-spacing:-.55pt'>
</span>or<span style='letter-spacing:-.35pt'> </span>not<span style='letter-spacing:
-.5pt'> </span>and<span style='letter-spacing:-.7pt'> </span>if<span
style='letter-spacing:-.6pt'> </span>processed,<span style='letter-spacing:
-.4pt'> </span>to<span style='letter-spacing:-.7pt'> </span>request<span
style='letter-spacing:-.2pt'> </span>information in this regard,</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:26.5pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:-21.15pt;line-height:130%'><span style='font-size:
12.0pt;line-height:130%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.7pt'> </span>learn<span
style='letter-spacing:-.7pt'> </span>the<span style='letter-spacing:-.7pt'> </span>purpose<span
style='letter-spacing:-.65pt'> </span>of<span style='letter-spacing:-.7pt'> </span>processing<span
style='letter-spacing:-.7pt'> </span>your<span style='letter-spacing:-.7pt'> </span>personal<span
style='letter-spacing:-.65pt'> </span>data<span style='letter-spacing:-.7pt'> </span>and<span
style='letter-spacing:-.7pt'> </span>as<span style='letter-spacing:-.7pt'> </span>to<span
style='letter-spacing:-.65pt'> </span>whether<span style='letter-spacing:-.7pt'>
</span>such<span style='letter-spacing:-.7pt'> </span>data<span
style='letter-spacing:-.7pt'> </span>is<span style='letter-spacing:-.65pt'> </span>used<span
style='letter-spacing:-.7pt'> </span>for<span style='letter-spacing:-.7pt'> </span>intended
<span style='letter-spacing:-.1pt'>purposes,</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.35pt;margin-bottom:
0in;margin-left:26.5pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:-21.15pt;line-height:130%'><span style='font-size:
12.0pt;line-height:130%'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.7pt'> </span>learn<span
style='letter-spacing:-.7pt'> </span>information<span style='letter-spacing:
-.7pt'> </span>on<span style='letter-spacing:-.65pt'> </span>the<span
style='letter-spacing:-.7pt'> </span>third<span style='letter-spacing:-.7pt'> </span>parties<span
style='letter-spacing:-.7pt'> </span>to<span style='letter-spacing:-.65pt'> </span>whom<span
style='letter-spacing:-.7pt'> </span>your<span style='letter-spacing:-.7pt'> </span>personal<span
style='letter-spacing:-.7pt'> </span>data<span style='letter-spacing:-.65pt'> </span>is<span
style='letter-spacing:-.7pt'> </span>transferred<span style='letter-spacing:
-.7pt'> </span>at<span style='letter-spacing:-.7pt'> </span>home<span
style='letter-spacing:-.65pt'> </span>or<span style='letter-spacing:-.7pt'> </span>abroad,
if any,</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:26.5pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:-21.15pt;line-height:131%'><span style='font-size:
12.0pt;line-height:131%'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To<span style='letter-spacing:-.7pt'> </span>request<span
style='letter-spacing:-.7pt'> </span>the<span style='letter-spacing:-.7pt'> </span>rectification<span
style='letter-spacing:-.6pt'> </span>of<span style='letter-spacing:-.4pt'> </span>your<span
style='letter-spacing:-.4pt'> </span>personal<span style='letter-spacing:-.5pt'>
</span>data<span style='letter-spacing:-.45pt'> </span>in<span
style='letter-spacing:-.7pt'> </span>case<span style='letter-spacing:-.6pt'> </span>it<span
style='letter-spacing:-.55pt'> </span>is<span style='letter-spacing:-.55pt'> </span>processed<span
style='letter-spacing:-.6pt'> </span>incompletely<span style='letter-spacing:
-.6pt'> </span>or<span style='letter-spacing:-.4pt'> </span>inaccurately, and
to notify third parties to whom we transmit your personal data, if any, of
transaction realized within this scope,</p>

<p class=MsoListParagraph style='margin-right:5.2pt;text-align:justify;
text-justify:inter-ideograph;line-height:132%'><span style='font-size:12.0pt;
line-height:132%'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>In case<span style='letter-spacing:-.1pt'> </span>the legal
ground for such processing have disappeared, to request the erasure<span
style='letter-spacing:-.1pt'> </span>or destruction of your personal<span
style='letter-spacing:-.05pt'> </span>data, despite<span style='letter-spacing:
-.15pt'> </span>the<span style='letter-spacing:-.15pt'> </span>fact that we<span
style='letter-spacing:-.15pt'> </span>have<span style='letter-spacing:-.15pt'> </span>processed<span
style='letter-spacing:-.05pt'> </span>the<span style='letter-spacing:-.15pt'> </span>same<span
style='letter-spacing:-.15pt'> </span>under the<span style='letter-spacing:
-.15pt'> </span>provisions of the Law<span style='letter-spacing:-.55pt'> </span>and<span
style='letter-spacing:-.55pt'> </span>the<span style='letter-spacing:-.65pt'> </span>related<span
style='letter-spacing:-.55pt'> </span>Legislation,<span style='letter-spacing:
-.2pt'> </span>and<span style='letter-spacing:-.55pt'> </span>to<span
style='letter-spacing:-.55pt'> </span>notify<span style='letter-spacing:-.25pt'>
</span>third<span style='letter-spacing:-.55pt'> </span>parties<span
style='letter-spacing:-.25pt'> </span>to<span style='letter-spacing:-.55pt'> </span>whom<span
style='letter-spacing:-.5pt'> </span>we<span style='letter-spacing:-.65pt'> </span>transmit<span
style='letter-spacing:-.25pt'> </span>your<span style='letter-spacing:-.15pt'> </span>personal<span
style='letter-spacing:-.45pt'> </span>data, if any, of transaction realized
within this scope,</p>

</div>

<span style='font-size:11.0pt;line-height:132%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection6>

<p class=MsoListParagraph style='margin-top:3.45pt;margin-right:5.95pt;
margin-bottom:0in;margin-left:28.4pt;margin-bottom:.0001pt;line-height:131%'><span
style='font-size:12.0pt;line-height:131%'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To object to consequences against you in the event where your
personal data that we processed is analyzed exclusively by automatic means,</p>

<p class=MsoListParagraph style='margin-right:6.05pt;line-height:133%'><span
style='font-size:12.0pt;line-height:133%'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>To request<span style='letter-spacing:1.4pt'> </span>compensation<span
style='letter-spacing:1.1pt'> </span>for<span style='letter-spacing:1.5pt'> </span>the<span
style='letter-spacing:1.0pt'> </span>damage<span style='letter-spacing:1.0pt'> </span>that<span
style='letter-spacing:1.4pt'> </span>you<span style='letter-spacing:1.35pt'> </span>suffer<span
style='letter-spacing:1.5pt'> </span>due<span style='letter-spacing:1.0pt'> </span>to<span
style='letter-spacing:1.1pt'> </span>the<span style='letter-spacing:1.0pt'> </span>unlawful<span
style='letter-spacing:1.15pt'> </span>processing<span style='letter-spacing:
1.1pt'> </span>of<span style='letter-spacing:1.25pt'> </span>your personal
data.</p>

<p class=MsoBodyText style='margin-top:2.2pt'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>In<span style='letter-spacing:-.7pt'> </span>case<span
style='letter-spacing:-.7pt'> </span>you<span style='letter-spacing:-.7pt'> </span>would<span
style='letter-spacing:-.6pt'> </span>like<span style='letter-spacing:-.7pt'> </span>to<span
style='letter-spacing:-.55pt'> </span>exercise<span style='letter-spacing:-.65pt'>
</span>one<span style='letter-spacing:-.65pt'> </span>or<span style='letter-spacing:
-.35pt'> </span>more<span style='letter-spacing:-.7pt'> </span>of<span
style='letter-spacing:-.6pt'> </span>your<span style='letter-spacing:-.35pt'> </span>rights<span
style='letter-spacing:-.5pt'> </span>stated<span style='letter-spacing:-.7pt'> </span>above,<span
style='letter-spacing:-.4pt'> </span>you<span style='letter-spacing:-.3pt'> </span>may<span
style='letter-spacing:-.7pt'> </span>transmit<span style='letter-spacing:-.25pt'>
</span>your<span style='letter-spacing:-.35pt'> </span>request by filling in
the “Data Owner Application Form” given under the address of <a
href="http://www.theaident.com/kisiselerilerinkorunmas%c4%b1"><span
style='color:windowtext'>http://www.theaident.com/kisiselerilerinkorunması</span></a>
and<span style='letter-spacing:-.2pt'> </span>by<span style='letter-spacing:
-.2pt'> </span>sending<span style='letter-spacing:-.2pt'> </span>the<span
style='letter-spacing:-.3pt'> </span>same<span style='letter-spacing:-.3pt'> </span>via
the<span style='letter-spacing:-.3pt'> </span>following methods:</p>

<p class=MsoBodyText style='margin-top:1.6pt'>&nbsp;</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:19.8pt;margin-bottom:
0in;margin-left:41.4pt;margin-bottom:.0001pt;text-indent:-18.05pt;line-height:
131%'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>By<span style='letter-spacing:-.35pt'> </span>sending<span
style='letter-spacing:-.1pt'> </span>it<span style='letter-spacing:-.05pt'> </span>in<span
style='letter-spacing:-.35pt'> </span>writing<span style='letter-spacing:-.1pt'>
</span>via post<span style='letter-spacing:-.05pt'> </span>to<span
style='letter-spacing:-.35pt'> </span>the<span style='letter-spacing:-.45pt'> </span>address<span
style='letter-spacing:-.05pt'> </span>at<span style='letter-spacing:-.05pt'> </span>Rasimpaşa
Mahallesi<span style='letter-spacing:-.25pt'> </span>Tayyareci<span
style='letter-spacing:-.3pt'> </span>Sami<span style='letter-spacing:-.3pt'> </span>Sok.
No: 13/B İç Kapı No:125 Kadıköy/Istanbul.</p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:26.75pt;
margin-bottom:0in;margin-left:41.4pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:133%'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>By<span style='letter-spacing:-.25pt'> </span>sending<span
style='letter-spacing:-.25pt'> </span>an e-mail<span style='letter-spacing:
-.2pt'> </span>to<span style='letter-spacing:-.25pt'> </span>the<span
style='letter-spacing:-.1pt'> </span>e-mail<span style='letter-spacing:-.2pt'> </span>address<a
href="mailto:info@theaident.com"><span style='color:windowtext'>
info@theaident.com</span></a><u><span style='letter-spacing:-.05pt'> </span></u>or
to<span style='letter-spacing:-.25pt'> </span>the<span style='letter-spacing:
-.35pt'> </span>registered<span style='letter-spacing:-.25pt'> </span>e- mail
address (REM)<a href="mailto:aident@hs01.kep.tr"><span style='color:windowtext'>
aident@hs01.kep.tr</span></a>by signing it secure electronic signature or
mobile <span style='letter-spacing:-.1pt'>signature.</span></p>

<p class=MsoListParagraph style='margin-left:41.4pt;text-indent:-.25in;
line-height:12.65pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>For<span style='letter-spacing:-.2pt'> </span>other<span
style='letter-spacing:-.05pt'> </span>application<span style='letter-spacing:
-.2pt'> </span>methods,<span style='letter-spacing:-.05pt'> </span>please<span
style='letter-spacing:-.5pt'> </span>review<span style='letter-spacing:-.45pt'>
</span>the<span style='letter-spacing:-.5pt'> </span>“Data<span
style='letter-spacing:-.3pt'> </span>Owner<span style='letter-spacing:-.05pt'> </span>Application<span
style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.1pt'>Form.’’</span></p>

<p class=MsoBodyText style='margin-top:6.15pt'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.05pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'><b>DentisToday </b>shall<span
style='letter-spacing:-.2pt'> </span>reply<span style='letter-spacing:-.25pt'> </span>to<span
style='letter-spacing:-.25pt'> </span>your application<span style='letter-spacing:
-.25pt'> </span>as soon<span style='letter-spacing:-.25pt'> </span>as<span
style='letter-spacing:-.2pt'> </span>possible<span style='letter-spacing:-.35pt'>
</span>and<span style='letter-spacing:-.25pt'> </span>at the<span
style='letter-spacing:-.1pt'> </span>latest within 30 (thirty) days. In<span
style='letter-spacing:-.5pt'> </span>principle,<span style='letter-spacing:
-.15pt'> </span>no<span style='letter-spacing:-.5pt'> </span>fee<span
style='letter-spacing:-.6pt'> </span>is<span style='letter-spacing:-.2pt'> </span>charged<span
style='letter-spacing:-.5pt'> </span>for<span style='letter-spacing:-.1pt'> </span>the<span
style='letter-spacing:-.6pt'> </span>necessary<span style='letter-spacing:-.5pt'>
</span>transactions<span style='letter-spacing:-.2pt'> </span>regarding<span
style='letter-spacing:-.5pt'> </span>the<span style='letter-spacing:-.6pt'> </span>requests.<span
style='letter-spacing:-.1pt'> </span>However,<span style='letter-spacing:-.15pt'>
</span>in<span style='letter-spacing:-.5pt'> </span>case<span style='letter-spacing:
-.55pt'> </span>the transaction requests any cost, a fee stated on the tariff
determined as per Article 13 of the Law by the Protection of the Personal Data
Board can be requested. The right of <b>DentisToday </b>to request the fee
stated on the fee tariff (if any) determined by the Protection of Personal Data
Board with regard to the request is preserved.</p>

<p class=MsoBodyText style='margin-top:2.3pt'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.7pt;margin-bottom:
0in;margin-left:5.35pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:135%'>In<span style='letter-spacing:-.7pt'> </span>order<span
style='letter-spacing:-.7pt'> </span>to<span style='letter-spacing:-.7pt'> </span>determine<span
style='letter-spacing:-.65pt'> </span>as<span style='letter-spacing:-.7pt'> </span>to<span
style='letter-spacing:-.7pt'> </span>whether<span style='letter-spacing:-.35pt'>
</span>you<span style='letter-spacing:-.55pt'> </span>are<span
style='letter-spacing:-.7pt'> </span>authorized<span style='letter-spacing:
-.45pt'> </span>for<span style='letter-spacing:-.4pt'> </span>the<span
style='letter-spacing:-.7pt'> </span>application<span style='letter-spacing:
-.7pt'> </span>or<span style='letter-spacing:-.35pt'> </span>in<span
style='letter-spacing:-.7pt'> </span>order<span style='letter-spacing:-.35pt'> </span>to<span
style='letter-spacing:-.7pt'> </span>reply<span style='letter-spacing:-.55pt'> </span>your<span
style='letter-spacing:-.4pt'> </span>request faster, you may be asked for some
additional information. Further information on this subject is stated under our
Data Owner Application Form.</p>

</div>

</body>

</html>

  `

  const tr = `
  <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
h1
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:27.15pt;
	text-indent:-21.35pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:27.15pt;
	text-indent:-.25in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.TableParagraph, li.TableParagraph, div.TableParagraph
	{mso-style-name:"Table Paragraph";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:5.25pt;
	line-height:12.45pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{text-autospace:none;}
@page WordSection1
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 14.0pt 65.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 14.0pt 65.0pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 14.0pt 65.0pt;}
div.WordSection3
	{page:WordSection3;}
@page WordSection4
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 14.0pt 65.0pt;}
div.WordSection4
	{page:WordSection4;}
@page WordSection5
	{size:595.5pt 842.0pt;
	margin:66.0pt 65.0pt 14.0pt 65.0pt;}
div.WordSection5
	{page:WordSection5;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class=WordSection1>

<h1 align=center style='margin-top:3.95pt;margin-right:179.2pt;margin-bottom:
0in;margin-left:179.65pt;margin-bottom:.0001pt;text-align:center;text-indent:
-.4pt;line-height:230%'><span lang=TR style='letter-spacing:-.1pt'>DENTISTODAY </span><span
lang=TR>ÇEREZ<span style='letter-spacing:-.7pt'> </span>POLİTİKASI</span></h1>

<p class=MsoBodyText style='margin-top:1.65pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Aıdent Sağlık ve Diş
Teknolojileri Anonim Şirketi (<b>“DentisToday” </b>ve/veya “<b>Şirket</b>”)<span
style='letter-spacing:-.05pt'> </span>olarak, DentisToday mobil uygulaması
kullanıcıların ziyaretlerini uygun bir şekilde gerçekleştirmelerini sağlamak ve
ziyaretçi deneyimlerini iyileştirmek amacıyla internet sitemizde çerezler
kullanmaktayız. İşbu<span style='letter-spacing:-.25pt'> </span>Çerez<span
style='letter-spacing:-.4pt'> </span>Politikası<span style='letter-spacing:
-.45pt'> </span>(“<b>Politika</b>”),<span style='letter-spacing:-.2pt'> </span>6698<span
style='letter-spacing:-.3pt'> </span>sayılı<span style='letter-spacing:-.45pt'>
</span>Kişisel<span style='letter-spacing:-.25pt'> </span>Verilerin<span
style='letter-spacing:-.55pt'> </span>Korunması<span style='letter-spacing:
-.45pt'> </span>Kanunu<span style='letter-spacing:-.3pt'> </span>(“<b>Kanun</b>”)<span
style='letter-spacing:-.35pt'> </span>ve<span style='letter-spacing:-.4pt'> </span>ilgili
mevzuat uyarınca, internet sitesinin kullanımı sırasında toplanan kişisel
verilerinizin nasıl işlendiği hakkında<span style='letter-spacing:-.55pt'> </span>sizleri<span
style='letter-spacing:-.7pt'> </span>bilgilendirmek<span style='letter-spacing:
-.65pt'> </span>amacıyla<span style='letter-spacing:-.5pt'> </span>hazırlanmıştır.<span
style='letter-spacing:-.5pt'> </span>İşbu<span style='letter-spacing:-.6pt'> </span>Politika,<span
style='letter-spacing:-.5pt'> </span>müşterilerimiz,<span style='letter-spacing:
-.5pt'> </span>iş<span style='letter-spacing:-.35pt'> </span>ortaklarımız<span
style='letter-spacing:-.5pt'> </span>ve tedarikçilerimiz<span style='letter-spacing:
-.25pt'> </span>dahil<span style='letter-spacing:-.2pt'> </span>Mobil<span
style='letter-spacing:-.35pt'> </span>Uygulamamızı<span style='letter-spacing:
-.25pt'> </span>ziyaret<span style='letter-spacing:-.1pt'> </span>eden<span
style='letter-spacing:-.4pt'> </span>ve/veya site<span style='letter-spacing:
-.45pt'> </span>üzerinde<span style='letter-spacing:-.25pt'> </span>işlem<span
style='letter-spacing:-.35pt'> </span>gerçekleştiren<span style='letter-spacing:
-.4pt'> </span>tüm gerçek kişileri kapsamaktadır. <b>DentisToday</b>,
Politika’yı gerekli olduğu zamanlarda, sitede yeni versiyonunu yayınlayarak
güncelleme hakkına sahiptir.</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:4.15pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerez<span style='letter-spacing:-.5pt'> </span>(“Cookie”)<span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>Nedir?</span></span></h1>

<p class=MsoBodyText style='margin-top:3.5pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.6pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Çerezler, ziyaret ettiğiniz
internet siteleri tarafından internet sitesinin düzgün biçimde çalışması,
kullanıcı<span style='letter-spacing:-.7pt'> </span>deneyiminin<span
style='letter-spacing:-.7pt'> </span>iyileştirilmesi,<span style='letter-spacing:
-.7pt'> </span>sitenin<span style='letter-spacing:-.65pt'> </span>geliştirilmesi<span
style='letter-spacing:-.7pt'> </span>ve<span style='letter-spacing:-.7pt'> </span>optimize<span
style='letter-spacing:-.7pt'> </span>edilmesi,<span style='letter-spacing:-.65pt'>
</span>daha<span style='letter-spacing:-.7pt'> </span>uygun,<span
style='letter-spacing:-.7pt'> </span>ilgi<span style='letter-spacing:-.7pt'> </span>alanına
uygun hale getirilmiş içerik ve reklam sunulması, ziyaretçi ve müşteriler için
ilgi çekici ve kişiselleştirilmiş bir internet sitesi ve reklam portföyü
sunulması ve trafik istatistikleri oluşturulması amacıyla tarayıcılar
aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin dosyalarıdır.</span></p>

<p class=MsoBodyText style='margin-top:2.0pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.6pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Çerezler, ziyaretçilere ilişkin
isim, cinsiyet veya adres gibi kişisel verileri içermezler. Çerezler konusunda
daha detaylı bilgi için <a href="http://www.aboutcookies.org/"><span
style='color:blue'>www.aboutcookies.org</span></a><span style='color:blue'> </span>ve
<a href="http://www.allaboutcookies.org/"><span style='color:blue'>www.allaboutcookies.org</span></a><span
style='color:blue'> </span>adreslerini ziyaret edebilirsiniz.</span></p>

<p class=MsoBodyText style='margin-top:2.05pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerezler<span style='letter-spacing:-.25pt'> </span>Nasıl<span
style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>Çalışır?</span></span></h1>

<p class=MsoBodyText style='margin-top:3.75pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.65pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Çerezlerin içeriği, sunucu
tarafında belirlenir ve site ziyaret edildiğinde kullanıcıların cihazlarına
yerleştirilen tanımlama bilgileri sunucu tarafından kullanılabilir. Çerezler,
web sitesine kullanıcının tekrar ziyaret ettiğini belirten internet
kullanıcısının kimlik kartı olarak düşünülebilir.</span></p>

<p class=MsoBodyText style='margin-top:1.85pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;
margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
line-height:115%'><span lang=TR>Çerezler yerleştiren tarafa göre, “<b>Birinci
Taraf Çerezler</b>” ve “<b>Üçüncü Taraf Çerezler</b>” olarak kullanılmaktadır.
Platform Çerezleri, <b>DentisToday </b>tarafından oluşturulurken, üçüncü taraf
çerezlerini <b>DentisToday </b>ile iş birliği içerisinde olan farklı firmalar
yönetmektedir.</span></p>

<p class=MsoBodyText style='margin-top:1.8pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.5pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Aktif olduğu süreye göre
çerezler “<b>Oturum Çerezleri</b>” ve “<b>Kalıcı Çerezler</b>” olarak iki
grupta sınıflandırılır. Oturum Çerezleri, internet sitesinden ayrılana kadar
tutulan geçici çerezler olup ziyaretçinin<span style='letter-spacing:-.45pt'> </span>sayfalar<span
style='letter-spacing:-.25pt'> </span>arasında gezdiği<span style='letter-spacing:
-.35pt'> </span>sırada<span style='letter-spacing:-.1pt'> </span>web<span
style='letter-spacing:-.25pt'> </span>sitesi<span style='letter-spacing:-.35pt'>
</span>tarafından<span style='letter-spacing:-.25pt'> </span>hatırlanarak<span
style='letter-spacing:-.45pt'> </span>kullanılan<span style='letter-spacing:
-.45pt'> </span>bilgileri<span style='letter-spacing:-.35pt'> </span>içerir.
Oturum Çerezleri ziyaretçinin internet sitesini terk etmesiyle birlikte
silinirken, Kalıcı Çerezler ise kullanım alanına bağlı olarak çeşitli sürelerle
ziyaretçilerin cihazlarında saklanan çerezlerdir. Site’nin işlevselliğini
arttırmak, kullanıcılara daha hızlı ve iyi bir hizmet sunmak amacıyla
kullanılan çerez türleridir. Bu tür çerezler web sitesinin kullanıcı tarafından<span
style='letter-spacing:-.05pt'> </span>tekrar ziyaret edilmesi halinde<span
style='letter-spacing:-.15pt'> </span>kullanıcının web sunucusu tarafından
hatırlanmasını sağlar ve kullanıcıyı tanımlayıcı bilgileri içermez.</span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection2>

<p class=MsoBodyText style='margin-top:3.7pt;margin-right:5.6pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Sitemizde ziyaretçilerimize
daha iyi hizmet sunmak için sayfa görüntüleme sayısını, ziyaret sayısını,
sitemizde ziyaretçiler tarafından gerçekleştirilen farklı işlemleri,
ziyaretçilerin coğrafi konumlarını ve ziyaretçilerin siteye dönüş sayısını
ölçmek amacıyla analitik amaçlı üçüncü şahıs çerezleri <span style='letter-spacing:
-.1pt'>kullanmaktayız.</span></span></p>

<p class=MsoBodyText style='margin-top:1.9pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:6.1pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Çerezler ayrıca, kullanım
amaçlarına göre farklı kategorilere ayrılmaktadır. Aşağıdaki tabloda hangi
çerez kategorilerinin hangi amaçlarla kullanıldığına ilişkin açıklamaları
bulabilirsiniz:</span></p>

<p class=MsoBodyText><span lang=TR style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:6.15pt'><span lang=TR style='font-size:
10.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.15pt;border-collapse:collapse;border:none'>
 <tr style='height:43.65pt'>
  <td width=255 valign=top style='width:191.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:43.65pt'>
  <p class=TableParagraph style='margin-top:1.8pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt;line-height:normal'><span lang=TR>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-left:.35pt;text-align:
  center;line-height:normal'><b><u><span lang=TR>Çerez<span style='letter-spacing:
  -.45pt'> </span><span style='letter-spacing:-.1pt'>Kategorileri</span></span></u></b></p>
  </td>
  <td width=350 valign=top style='width:262.15pt;border:none;border-bottom:
  solid black 1.0pt;padding:0in 0in 0in 0in;height:43.65pt'>
  <p class=TableParagraph style='margin-top:1.8pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt;line-height:normal'><span lang=TR>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-top:0in;margin-right:.25pt;
  margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:center;
  line-height:normal'><b><u><span lang=TR style='letter-spacing:-.1pt'>Açıklamalar</span></u></b></p>
  </td>
 </tr>
 <tr style='height:58.05pt'>
  <td width=255 valign=top style='width:191.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:58.05pt'>
  <p class=TableParagraph style='margin-top:1.75pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt;line-height:normal'><span lang=TR>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-top:.05pt;margin-right:
  .15pt;margin-bottom:0in;margin-left:.35pt;margin-bottom:.0001pt;text-align:
  center;line-height:normal'><b><span lang=TR style='letter-spacing:-.1pt'>Teknik/Zorunlu</span><span
  lang=TR style='letter-spacing:.4pt'> </span><span lang=TR style='letter-spacing:
  -.1pt'>Çerezler</span></b></p>
  </td>
  <td width=350 valign=top style='width:262.15pt;border:none;border-bottom:
  solid black 1.0pt;padding:0in 0in 0in 0in;height:58.05pt'>
  <p class=TableParagraph style='margin-right:5.55pt;text-align:justify;
  text-justify:inter-ideograph;line-height:115%'><span lang=TR>Site’nin
  kullanımı ve işlerliği için gerekli olan çerezlerdir.<span style='letter-spacing:
  -.7pt'> </span>Teknik<span style='letter-spacing:-.7pt'> </span>çerezler<span
  style='letter-spacing:-.7pt'> </span>devre<span style='letter-spacing:-.65pt'>
  </span>dışı<span style='letter-spacing:-.7pt'> </span>bırakıldığı<span
  style='letter-spacing:-.7pt'> </span>takdirde Site’nin<span style='letter-spacing:
  3.55pt'> </span>bir<span style='letter-spacing:3.95pt'> </span>kısmına<span
  style='letter-spacing:3.9pt'> </span>veya<span style='letter-spacing:3.9pt'> </span>tümüne<span
  style='letter-spacing:3.7pt'> </span>erişim<span style='letter-spacing:3.65pt'>
  </span><span style='letter-spacing:-.1pt'>mümkün</span></span></p>
  <p class=TableParagraph style='line-height:12.5pt'><span lang=TR
  style='letter-spacing:-.1pt'>olmayabilir.</span></p>
  </td>
 </tr>
 <tr style='height:87.35pt'>
  <td width=255 valign=top style='width:191.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:87.35pt'>
  <p class=TableParagraph style='margin-left:0in;line-height:normal'><span
  lang=TR>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:3.75pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt;line-height:normal'><span lang=TR>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:0in;margin-right:40.95pt;
  margin-bottom:0in;margin-left:41.05pt;margin-bottom:.0001pt;text-indent:13.65pt;
  line-height:115%'><b><span lang=TR>Analitik Çerezler (Performans<span
  style='letter-spacing:-.7pt'> </span>Çerezleri)</span></b></p>
  </td>
  <td width=350 valign=top style='width:262.15pt;border:none;border-bottom:
  solid black 1.0pt;padding:0in 0in 0in 0in;height:87.35pt'>
  <p class=TableParagraph style='margin-right:5.25pt;text-align:justify;
  text-justify:inter-ideograph;line-height:115%'><span lang=TR>Site
  ziyaretçilerinin internet sitesini nasıl kullandığı hakkında bilgi almak ve
  ziyaretçi deneyimlerini iyileştirmek/geliştirmek amacıyla kullanılan
  çerezlerdir. Bu<span style='letter-spacing:-.7pt'> </span>çerezler,<span
  style='letter-spacing:-.7pt'> </span>ziyaretçiler<span style='letter-spacing:
  -.7pt'> </span>tarafından<span style='letter-spacing:-.65pt'> </span>görüntülenen<span
  style='letter-spacing:-.7pt'> </span>sayfalar, tıkanma<span style='letter-spacing:
  .75pt'> </span>sayıları<span style='letter-spacing:.5pt'> </span>ve<span
  style='letter-spacing:.3pt'> </span>bu<span style='letter-spacing:.65pt'> </span>sayfalara<span
  style='letter-spacing:.55pt'> </span>giriş<span style='letter-spacing:.7pt'> </span>ve<span
  style='letter-spacing:.55pt'> </span>çıkış<span style='letter-spacing:.7pt'> </span><span
  style='letter-spacing:-.1pt'>zamanları</span></span></p>
  <p class=TableParagraph style='text-align:justify;text-justify:inter-ideograph;
  line-height:normal'><span lang=TR>gibi<span style='letter-spacing:-.3pt'> </span>bilgileri<span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>içerir.</span></span></p>
  </td>
 </tr>
 <tr style='height:58.05pt'>
  <td width=255 valign=top style='width:191.1pt;border:none;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:58.05pt'>
  <p class=TableParagraph style='margin-left:0in;line-height:normal'><span
  lang=TR>&nbsp;</span></p>
  <p class=TableParagraph style='margin-top:3.75pt;margin-right:0in;margin-bottom:
  0in;margin-left:0in;margin-bottom:.0001pt;line-height:normal'><span lang=TR>&nbsp;</span></p>
  <p class=TableParagraph align=center style='margin-top:0in;margin-right:.35pt;
  margin-bottom:0in;margin-left:.35pt;margin-bottom:.0001pt;text-align:center;
  line-height:normal'><b><span lang=TR style='letter-spacing:-.1pt'>İşlevsellik/Kişiselleştirme</span><span
  lang=TR style='letter-spacing:1.3pt'> </span><span lang=TR style='letter-spacing:
  -.1pt'>Çerezleri</span></b></p>
  </td>
  <td width=350 valign=top style='width:262.15pt;border:none;padding:0in 0in 0in 0in;
  height:58.05pt'>
  <p class=TableParagraph style='margin-right:5.65pt;text-align:justify;
  text-justify:inter-ideograph;line-height:115%'><span lang=TR>Site<span
  style='letter-spacing:-.45pt'> </span>ziyaretçilerine<span style='letter-spacing:
  -.2pt'> </span>kişiselleştirilmiş<span style='letter-spacing:-.15pt'> </span>bir
  deneyim<span style='letter-spacing:-.5pt'> </span>sunmak amacıyla kullanılan
  çerezlerdir. Bu çerezler dil tercihleriniz<span style='letter-spacing:1.45pt'> 
  </span>ve<span style='letter-spacing:1.45pt'>  </span>kullanıcı<span
  style='letter-spacing:1.45pt'>  </span>adınız<span style='letter-spacing:
  1.45pt'>  </span>gibi<span style='letter-spacing:1.45pt'>  </span><span
  style='letter-spacing:-.1pt'>tercihlerinizin</span></span></p>
  <p class=TableParagraph style='text-align:justify;text-justify:inter-ideograph;
  line-height:normal'><span lang=TR>internet<span style='letter-spacing:-.25pt'>
  </span>sitesini<span style='letter-spacing:-.5pt'> </span>ziyaret<span
  style='letter-spacing:-.3pt'> </span>edişinizde<span style='letter-spacing:
  -.4pt'> </span>hatırlanmasını<span style='letter-spacing:-.5pt'> </span><span
  style='letter-spacing:-.1pt'>sağlar.</span></span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.9pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Neden<span style='letter-spacing:-.5pt'> </span>Çerezler<span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>Kullanılmaktadır?</span></span></h1>

<p class=MsoBodyText style='margin-top:3.5pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-left:5.8pt;text-align:justify;text-justify:
inter-ideograph'><span lang=TR>Site’de<span style='letter-spacing:-.6pt'> </span>çerezler<span
style='letter-spacing:-.3pt'> </span>aşağıdaki<span style='letter-spacing:-.6pt'>
</span>amaçlar<span style='letter-spacing:-.3pt'> </span>kapsamında<span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>kullanılmaktadır:</span></span></p>

<p class=MsoBodyText style='margin-top:3.95pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-21.4pt;line-height:112%'><span
lang=TR style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Site’nin<span style='letter-spacing:-.35pt'> </span>çalışması<span
style='letter-spacing:-.3pt'> </span>için<span style='letter-spacing:-.35pt'> </span>gerekli<span
style='letter-spacing:-.3pt'> </span>temel<span style='letter-spacing:-.3pt'> </span>fonksiyonları<span
style='letter-spacing:-.3pt'> </span>gerçekleştirmek.<span style='letter-spacing:
-.05pt'> </span>Örneğin,<span style='letter-spacing:-.05pt'> </span>oturum<span
style='letter-spacing:-.55pt'> </span>açan<span style='letter-spacing:-.35pt'> </span>üyelerin
Site’nin farklı sayfaları ziyaret ederken tekrar şifre girmelerine gerek
kalmaması.</span></p>

<p class=MsoListParagraph style='margin-top:.25pt;margin-right:5.65pt;
margin-bottom:0in;margin-left:27.15pt;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-21.4pt;line-height:115%'><span
lang=TR style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Site’yi<span style='letter-spacing:-.25pt'> </span>analiz<span
style='letter-spacing:-.15pt'> </span>etmek<span style='letter-spacing:-.05pt'>
</span>ve<span style='letter-spacing:-.4pt'> </span>Site’nin<span
style='letter-spacing:-.3pt'> </span>performansını<span style='letter-spacing:
-.25pt'> </span>arttırmak. Örneğin, Site’nin<span style='letter-spacing:-.3pt'>
</span>üzerinde<span style='letter-spacing:-.4pt'> </span>çalıştığı<span
style='letter-spacing:-.25pt'> </span>farklı sunucuların entegrasyonu, Site’yi
ziyaret edenlerin sayısının tespit edilmesi ve buna göre performans ayarlarının
yapılması ya da ziyaretçilerin aradıklarını bulmalarının kolaylaştırılması.</span></p>

<p class=MsoListParagraph style='margin-right:6.05pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-21.4pt;line-height:115%'><span
lang=TR style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Site’nin işlevselliğini arttırmak ve kullanım
kolaylığı sağlamak. Örneğin, Site üzerinde üçüncü taraf sosyal medya
mecralarına paylaşımda bulunmak, Site’yi ziyaret eden ziyaretçinin daha sonraki
ziyaretinde kullanıcı adı bilgisinin ya da arama sorgularının hatırlanması.</span></p>

<p class=MsoBodyText style='margin-top:1.9pt'><span lang=TR>&nbsp;</span></p>

<h1 style='margin-top:.05pt'><span lang=TR>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerez<span style='letter-spacing:-.45pt'> </span>Tercihlerinizi<span
style='letter-spacing:-.3pt'> </span>Nasıl<span style='letter-spacing:-.3pt'> </span><span
style='letter-spacing:-.1pt'>Yönetebilirsiniz?</span></span></h1>

<p class=MsoBodyText style='margin-top:3.55pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.9pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%'>DentisToday</span></b><span lang=TR>,<span style='letter-spacing:
-.7pt'> </span>ziyaretçilerin<span style='letter-spacing:-.7pt'> </span>kendilerine<span
style='letter-spacing:-.7pt'> </span>ait<span style='letter-spacing:-.65pt'> </span>kişisel<span
style='letter-spacing:-.7pt'> </span>veriler<span style='letter-spacing:-.7pt'>
</span>üzerindeki<span style='letter-spacing:-.7pt'> </span>tercihlerini<span
style='letter-spacing:-.65pt'> </span>kullanabilmelerini<span style='letter-spacing:
-.7pt'> </span>son derece önemsemektedir. Tarayıcınızın ayarlarını değiştirerek
çerezleri etkinleştirebilir veya devre dışı bırakabilirsiniz.<span
style='letter-spacing:1.05pt'> </span>Bununla<span style='letter-spacing:1.0pt'>
</span>birlikte,<span style='letter-spacing:.95pt'> </span>Site’nin çalışması<span
style='letter-spacing:.95pt'> </span>için zorunlu olan bazı çerezler<span
style='letter-spacing:1.0pt'> </span>konusunda<span style='letter-spacing:1.0pt'>
</span>tercih</span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection3>

<p class=MsoBodyText style='margin-top:3.7pt;margin-right:6.05pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>yönetimi mümkün olmamaktadır.
Ayrıca bazı çerezlerin kapatılması halinde Site’nin çeşitli fonksiyonlarının
çalışamayabileceğini de hatırlatmak isteriz.</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.8pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Çerezleri nasıl devre dışı
bırakacağınız hakkında, aşağıda tarayıcınıza özgü linke tıklayarak yardım <span
style='letter-spacing:-.1pt'>alabilirsiniz:</span></span></p>

<p class=MsoBodyText style='margin-top:2.75pt'><span lang=TR style='font-size:
10.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:6.3pt;border-collapse:collapse;border:none'>
 <tr style='height:14.6pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:14.6pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR>Google<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>Chrome</span></span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border:solid black 1.0pt;
  border-left:none;padding:0in 0in 0in 0in;height:14.6pt'>
  <p class=TableParagraph><span lang=TR style='letter-spacing:-.1pt'>support.google.com/chrome/answer/95647</span></p>
  </td>
 </tr>
 <tr style='height:29.0pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:29.0pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR>Internet<span
  style='letter-spacing:-.5pt'> </span><span style='letter-spacing:-.1pt'>Explorer</span></span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:29.0pt'>
  <p class=TableParagraph><span lang=TR style='letter-spacing:-.1pt'>support.microsft.com/tr/help/17442/windos-internet-explorer-delete-</span></p>
  <p class=TableParagraph style='margin-top:2.0pt;line-height:normal'><span
  lang=TR style='letter-spacing:-.1pt'>manage-cookies</span></p>
  </td>
 </tr>
 <tr style='height:29.25pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:29.25pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR>Mozilla<span
  style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.1pt'>Firefox</span></span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:29.25pt'>
  <p class=TableParagraph><span lang=TR style='letter-spacing:-.1pt'>support.mozilla.org/tr/kb/çerezleri-silme-web-sitelerinin-bilgilerini-</span></p>
  <p class=TableParagraph style='margin-top:2.0pt;line-height:normal'><span
  lang=TR style='letter-spacing:-.1pt'>kaldirma</span></p>
  </td>
 </tr>
 <tr style='height:14.35pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:14.35pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR
  style='letter-spacing:-.1pt'>Safari</span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:14.35pt'>
  <p class=TableParagraph><span lang=TR style='letter-spacing:-.1pt'>support.apple.com.tr/tr-tr/guide/safari/sfri11471/mac</span></p>
  </td>
 </tr>
 <tr style='height:14.6pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:14.6pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR
  style='letter-spacing:-.1pt'>Opera</span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:14.6pt'>
  <p class=TableParagraph><span lang=TR><a
  href="http://www.operaturkiye.net/arsiv/yardim/Windows/10.00/tr/cookies.html"><span
  style='color:blue;letter-spacing:-.1pt'>www.operaturkiye.net/arsiv/yardim/Windows/10.00/tr/cookies.html</span></a></span></p>
  </td>
 </tr>
 <tr style='height:14.6pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:14.6pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR>Google<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>Analytics</span></span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:14.6pt'>
  <p class=TableParagraph><span lang=TR style='letter-spacing:-.1pt'>https://tools.google.com/dlpage/gaoptout</span></p>
  </td>
 </tr>
 <tr style='height:14.35pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:14.35pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR>Google<span
  style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>AdWords</span></span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:14.35pt'>
  <p class=TableParagraph><span lang=TR style='letter-spacing:-.1pt'>https://support.google.com/ads/answer/2662922?hl=en</span></p>
  </td>
 </tr>
 <tr style='height:14.65pt'>
  <td width=170 valign=top style='width:127.5pt;border:solid black 1.0pt;
  border-top:none;padding:0in 0in 0in 0in;height:14.65pt'>
  <p class=TableParagraph style='margin-left:5.5pt'><span lang=TR>Adobe<span
  style='letter-spacing:-.15pt'> </span><span style='letter-spacing:-.1pt'>Analytics</span></span></p>
  </td>
  <td width=434 valign=top style='width:325.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 0in 0in 0in;height:14.65pt'>
  <p class=TableParagraph><span lang=TR><a
  href="http://www.adobe.com/uk/privacy/opt-out.html"><span style='color:windowtext;
  letter-spacing:-.1pt;text-decoration:none'>http://www.adobe.com/uk/privacy/opt-out.html</span></a></span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText style='margin-top:2.15pt'><span lang=TR>&nbsp;</span></p>

<h1 style='margin-top:.05pt'><span lang=TR>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerezler<span style='letter-spacing:-.55pt'> </span>Vasıtasıyla<span
style='letter-spacing:-.5pt'> </span>Toplanan<span style='letter-spacing:-.45pt'>
</span>Kişisel<span style='letter-spacing:-.5pt'> </span>Verilerinizin<span
style='letter-spacing:-.45pt'> </span>İşlenme<span style='letter-spacing:-.4pt'>
</span><span style='letter-spacing:-.1pt'>Amaçları</span></span></h1>

<p class=MsoBodyText style='margin-top:3.45pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0in;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph'><span lang=TR>Çerezler<span style='letter-spacing:-.4pt'> </span>vasıtasıyla<span
style='letter-spacing:-.25pt'> </span>toplanan<span style='letter-spacing:-.4pt'>
</span>kişisel<span style='letter-spacing:-.55pt'> </span>verileriniz,<span
style='letter-spacing:-.3pt'> </span>aşağıdaki<span style='letter-spacing:-.55pt'>
</span>amaçlarla<span style='letter-spacing:-.25pt'> </span><span
style='letter-spacing:-.1pt'>işlenmektedir:</span></span></p>

<p class=MsoBodyText style='margin-top:3.75pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='text-indent:-21.35pt'><span lang=TR>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Site<span style='letter-spacing:-.6pt'> </span>ziyaretçilerinin<span
style='letter-spacing:-.5pt'> </span>hareketlerini<span style='letter-spacing:
-.45pt'> </span>takip<span style='letter-spacing:-.05pt'> </span><span
style='letter-spacing:-.1pt'>etmek,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.0pt;margin-right:5.85pt;
margin-bottom:0in;margin-left:27.15pt;margin-bottom:.0001pt;line-height:113%'><span
lang=TR>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Ziyaretlerini<span style='letter-spacing:2.0pt'> </span>uygun<span
style='letter-spacing:2.0pt'> </span>bir<span style='letter-spacing:2.0pt'> </span>şekilde<span
style='letter-spacing:2.0pt'> </span>gerçekleştirmelerini<span
style='letter-spacing:2.0pt'> </span>temin<span style='letter-spacing:2.0pt'> </span>etmek<span
style='letter-spacing:2.0pt'> </span>ve<span style='letter-spacing:2.0pt'> </span>bir<span
style='letter-spacing:2.0pt'> </span>sonraki<span style='letter-spacing:2.0pt'>
</span>ziyaretinizde bilgilerinizin hatırlanmasını sağlamak,</span></p>

<p class=MsoListParagraph style='margin-top:.2pt'><span lang=TR>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Site<span style='letter-spacing:-.2pt'> </span>yapısını<span
style='letter-spacing:-.3pt'> </span>ve<span style='letter-spacing:-.2pt'> </span>içeriğini<span
style='letter-spacing:-.3pt'> </span>iyileştirmek<span style='letter-spacing:
-.35pt'> </span>ve<span style='letter-spacing:-.15pt'> </span><span
style='letter-spacing:-.1pt'>geliştirmek,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.95pt'><span lang=TR>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Ziyaretçi<span style='letter-spacing:-.35pt'> </span>kullanımlarını<span
style='letter-spacing:-.3pt'> </span>istatistiksel<span style='letter-spacing:
-.3pt'> </span>olarak<span style='letter-spacing:-.55pt'> </span><span
style='letter-spacing:-.1pt'>değerlendirmek,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.75pt;margin-right:6.05pt;
margin-bottom:0in;margin-left:27.15pt;margin-bottom:.0001pt;line-height:115%'><span
lang=TR>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>İnternet<span style='letter-spacing:2.0pt'> </span>sitesinde<span
style='letter-spacing:2.0pt'> </span>yer<span style='letter-spacing:2.0pt'> </span>alan<span
style='letter-spacing:2.0pt'> </span>içeriğin<span style='letter-spacing:2.0pt'>
</span>sizin<span style='letter-spacing:2.0pt'> </span>ve<span
style='letter-spacing:2.0pt'> </span>bilgisayarınız<span style='letter-spacing:
2.0pt'> </span>için<span style='letter-spacing:2.0pt'> </span>en<span
style='letter-spacing:2.0pt'> </span>etkili<span style='letter-spacing:2.0pt'> </span>şekilde<span
style='letter-spacing:2.0pt'> </span>sunulmasını <span style='letter-spacing:
-.1pt'>sağlamak,</span></span></p>

<p class=MsoListParagraph style='line-height:12.35pt'><span lang=TR>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Seçmeniz<span style='letter-spacing:-.4pt'> </span>durumunda<span
style='letter-spacing:-.3pt'> </span>hizmetlerimizden<span style='letter-spacing:
-.45pt'> </span>interaktif<span style='letter-spacing:-.45pt'> </span>olarak<span
style='letter-spacing:-.65pt'> </span>yararlanmanızı<span style='letter-spacing:
-.55pt'> </span><span style='letter-spacing:-.1pt'>sağlamak,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.0pt'><span lang=TR>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Ziyaretçi<span style='letter-spacing:-.65pt'> </span>tercih<span
style='letter-spacing:-.3pt'> </span>ve<span style='letter-spacing:-.65pt'> </span>ihtiyaçları<span
style='letter-spacing:-.5pt'> </span>doğrultusunda<span style='letter-spacing:
-.2pt'> </span>özelleştirilmiş<span style='letter-spacing:-.15pt'> </span>hizmetler<span
style='letter-spacing:.15pt'> </span><span style='letter-spacing:-.1pt'>sunabilmek.</span></span></p>

<p class=MsoBodyText style='margin-top:4.0pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerezler<span style='letter-spacing:-.4pt'> </span>Vasıtasıyla<span
style='letter-spacing:-.4pt'> </span>Kişisel<span style='letter-spacing:-.35pt'>
</span>Veri<span style='letter-spacing:-.35pt'> </span>Toplamanın<span
style='letter-spacing:-.5pt'> </span>Hukuki<span style='letter-spacing:-.35pt'>
</span><span style='letter-spacing:-.1pt'>Sebebi</span></span></h1>

<p class=MsoBodyText style='margin-top:3.5pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.6pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><b><span lang=TR>DentisToday</span></b><span
lang=TR>, çerezler vasıtasıyla toplanan kişisel verilerinizi, Site’nin
performans ve işlevselliğinin arttırılması ve internet sitesi kullanıcılarının
Site tecrübesini iyileştirmede bulunan meşru menfaati nedeniyle<span
style='letter-spacing:-.8pt'> </span>kişisel<span style='letter-spacing:-.7pt'>
</span>veri<span style='letter-spacing:-.7pt'> </span>işlemenin<span
style='letter-spacing:-.65pt'> </span><b>DentisToday</b>’<span
style='letter-spacing:-.7pt'> </span>in<span style='letter-spacing:-.7pt'> </span>meşru<span
style='letter-spacing:-.7pt'> </span>menfaatleri<span style='letter-spacing:
-.65pt'> </span>için<span style='letter-spacing:-.7pt'> </span>zorunlu<span
style='letter-spacing:-.7pt'> </span>olması<span style='letter-spacing:-.7pt'> </span>hukuki<span
style='letter-spacing:-.65pt'> </span>sebebine dayanarak işlemektedir.</span></p>

<p class=MsoBodyText style='margin-top:2.35pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerezler<span style='letter-spacing:-.35pt'> </span>Vasıtasıyla<span
style='letter-spacing:-.45pt'> </span>Toplanan<span style='letter-spacing:-.35pt'>
</span>Kişisel<span style='letter-spacing:-.4pt'> </span>Verilerin<span
style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.1pt'>Paylaşılması</span></span></h1>

<p class=MsoBodyText style='margin-top:3.5pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Çerezler vasıtasıyla
topladığımız ve/veya bunları işleyerek elde ettiğimiz kişisel verilerinizi, bu
Politika’nın<span style='letter-spacing:-.25pt'> </span>ilgili<span
style='letter-spacing:-.4pt'> </span>bölümünde<span style='letter-spacing:-.35pt'>
</span>yer<span style='letter-spacing:-.1pt'> </span>alan<span
style='letter-spacing:-.25pt'> </span>kişisel<span style='letter-spacing:-.2pt'>
</span>veri<span style='letter-spacing:-.4pt'> </span>işleme<span
style='letter-spacing:-.6pt'> </span>amaçlarının<span style='letter-spacing:
-.25pt'> </span>gerçekleştirilebilmesi<span style='letter-spacing:-.4pt'> </span>için<span
style='letter-spacing:-.25pt'> </span>internet sitemizin<span style='letter-spacing:
-.7pt'> </span>yayını<span style='letter-spacing:-.7pt'> </span>ve<span
style='letter-spacing:-.7pt'> </span>işletilmesi<span style='letter-spacing:
-.65pt'> </span>konularında<span style='letter-spacing:-.7pt'> </span>destek<span
style='letter-spacing:-.7pt'> </span>ve<span style='letter-spacing:-.7pt'> </span>hizmet<span
style='letter-spacing:-.65pt'> </span>aldığımız<span style='letter-spacing:
-.7pt'> </span>yurt<span style='letter-spacing:-.7pt'> </span>içinde<span
style='letter-spacing:-.7pt'> </span>ve<span style='letter-spacing:-.65pt'> </span>yurtdışında<span
style='letter-spacing:-.7pt'> </span>bulunan tedarikçiler<span
style='letter-spacing:-.7pt'> </span>ve<span style='letter-spacing:-.7pt'> </span>iş<span
style='letter-spacing:-.7pt'> </span>ortaklarımızla<span style='letter-spacing:
-.65pt'> </span>paylaşmaktayız.<span style='letter-spacing:-.7pt'> </span>Bununla<span
style='letter-spacing:-.7pt'> </span>birlikte,<span style='letter-spacing:-.7pt'>
</span>Site’nin<span style='letter-spacing:-.65pt'> </span>ve<span
style='letter-spacing:-.7pt'> </span>site<span style='letter-spacing:-.7pt'> </span>kullanıcılarının<span
style='letter-spacing:-.7pt'> </span>ziyaret deneyiminin geliştirilmesi ve
yazılımsal hatalardan kurtarılması amacıyla da kişisel verileriniz söz konusu<span
style='letter-spacing:-.15pt'> </span>taraflarla paylaşılmaktadır.<span
style='letter-spacing:-.05pt'> </span>Kişisel<span style='letter-spacing:-.1pt'>
</span>verileriniz, yasal<span style='letter-spacing:-.35pt'> </span>bir
talepte<span style='letter-spacing:-.45pt'> </span>bulunulması<span
style='letter-spacing:-.05pt'> </span>durumunda hukuki yükümlülüğümüzün<span
style='letter-spacing:-.3pt'> </span>yerine<span style='letter-spacing:-.15pt'>
</span>getirilmesi<span style='letter-spacing:-.25pt'> </span>amacıyla yetkili<span
style='letter-spacing:-.25pt'> </span>kurum<span style='letter-spacing:-.45pt'>
</span>ve<span style='letter-spacing:-.4pt'> </span>kuruluşlarla,<span
style='letter-spacing:-.2pt'> </span>adli<span style='letter-spacing:-.25pt'> </span>ve<span
style='letter-spacing:-.4pt'> </span>idari<span style='letter-spacing:-.25pt'> </span>makamlarla
da paylaşılabilecektir.<span style='letter-spacing:-.05pt'> </span>Kişisel<span
style='letter-spacing:-.35pt'> </span>verileriniz,<span style='letter-spacing:
-.05pt'> </span>üçüncü<span style='letter-spacing:-.15pt'> </span>taraf<span
style='letter-spacing:-.25pt'> </span>çerezleri<span style='letter-spacing:
-.1pt'> </span>kullanımı<span style='letter-spacing:-.35pt'> </span>söz<span
style='letter-spacing:-.25pt'> </span>konusu<span style='letter-spacing:-.15pt'>
</span>olduğunda çeşitli</span></p>

</div>

<span lang=TR style='font-size:11.0pt;line-height:115%;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection4>

<p class=MsoNormal style='margin-top:3.7pt;margin-right:0in;margin-bottom:0in;
margin-left:5.8pt;margin-bottom:.0001pt;line-height:115%'><span lang=TR>ülkelerde<span
style='letter-spacing:1.5pt'> </span>bulunan<span style='letter-spacing:1.55pt'>
</span>sunucularda<span style='letter-spacing:1.95pt'> </span>saklanabilecektir.<span
style='letter-spacing:1.95pt'> </span>Çerezlerin<span style='letter-spacing:
1.55pt'> </span>kullanımını<span style='letter-spacing:1.85pt'> </span>engellemek<span
style='letter-spacing:1.8pt'> </span>istiyorsanız<span style='letter-spacing:
1.7pt'> </span>bu Politika’nın “<b>Çerez Tercihlerinizi Nasıl Yönetebilirsiniz?</b>”
başlıklı kısmına göz atmanızı rica ederiz</span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:3.95pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerezler<span style='letter-spacing:-.45pt'> </span>Vasıtasıyla<span
style='letter-spacing:-.45pt'> </span>Toplanan<span style='letter-spacing:-.35pt'>
</span>Kişisel<span style='letter-spacing:-.4pt'> </span>Verileri<span
style='letter-spacing:-.4pt'> </span>İşlerken<span style='letter-spacing:-.6pt'>
</span>Uyduğumuz<span style='letter-spacing:-.3pt'> </span><span
style='letter-spacing:-.1pt'>İlkeler</span></span></h1>

<p class=MsoBodyText style='margin-top:3.5pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-left:5.8pt;line-height:113%'><b><span
lang=TR>DentisToday </span></b><span lang=TR>olarak, kişisel verilerinizi
işlediğimiz tüm<span style='letter-spacing:-.05pt'> </span>süreçlerde aşağıdaki
ilkeleri dikkate alıyor ve kişisel verilerinizi bu ilkelere uygun olarak
işliyoruz:</span></p>

<p class=MsoBodyText style='margin-top:2.2pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:41.8pt'><b><span lang=TR
style='letter-spacing:-.2pt'>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR>Hukuka<span style='letter-spacing:-.1pt'> </span>ve<span
style='letter-spacing:-.5pt'> </span>dürüstlük<span style='letter-spacing:-.2pt'>
</span>kurallarına<span style='letter-spacing:-.05pt'> </span>uygun<span
style='letter-spacing:-.2pt'> olma,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.75pt;margin-right:0in;margin-bottom:
0in;margin-left:41.75pt;margin-bottom:.0001pt;text-indent:-17.95pt'><b><span
lang=TR style='letter-spacing:-.2pt'>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR>Doğru<span style='letter-spacing:-.15pt'> </span>ve<span
style='letter-spacing:-.4pt'> </span>gerektiğinde<span style='letter-spacing:
-.25pt'> </span>güncel<span style='letter-spacing:-.25pt'> </span><span
style='letter-spacing:-.2pt'>olma,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
0in;margin-left:41.7pt;margin-bottom:.0001pt;text-indent:-17.9pt'><b><span
lang=TR style='letter-spacing:-.2pt'>iii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR>Belirli,<span style='letter-spacing:-.15pt'> </span>açık<span
style='letter-spacing:-.2pt'> </span>ve<span style='letter-spacing:-.3pt'> </span>meşru<span
style='letter-spacing:-.2pt'> </span>amaçlar<span style='letter-spacing:-.1pt'>
</span>için<span style='letter-spacing:-.4pt'> </span><span style='letter-spacing:
-.1pt'>işlenme,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.0pt;margin-right:0in;margin-bottom:
0in;margin-left:41.75pt;margin-bottom:.0001pt;text-indent:-17.95pt'><b><span
lang=TR style='letter-spacing:-.2pt'>iv.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR>İşlendikleri<span style='letter-spacing:-.45pt'>
</span>amaçla<span style='letter-spacing:-.15pt'> </span>bağlantılı,<span
style='letter-spacing:-.2pt'> </span>sınırlı<span style='letter-spacing:-.25pt'>
</span>ve<span style='letter-spacing:-.55pt'> </span>ölçülü<span
style='letter-spacing:-.3pt'> </span>olma<span style='letter-spacing:-.1pt'>
ilkesi,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.75pt;margin-right:5.6pt;
margin-bottom:0in;margin-left:41.8pt;margin-bottom:.0001pt;text-indent:-18.05pt;
line-height:115%'><b><span lang=TR style='letter-spacing:-.2pt'>v.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
lang=TR>İlgili mevzuatta öngörülen ve işlendikleri amaç için gerekli olan süre
kadar muhafaza edilme <span style='letter-spacing:-.1pt'>ilkesi.</span></span></p>

<p class=MsoBodyText><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:3.95pt'><span lang=TR>&nbsp;</span></p>

<h1><span lang=TR>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR>Çerez<span style='letter-spacing:-.6pt'> </span>Kullanımı<span
style='letter-spacing:-.2pt'> </span>Kapsamında<span style='letter-spacing:
-.45pt'> </span>Sahip<span style='letter-spacing:-.4pt'> </span>Olduğunuz<span
style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.1pt'>Haklar</span></span></h1>

<p class=MsoBodyText style='margin-top:3.5pt'><b><span lang=TR>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-left:5.8pt;line-height:113%'><b><span
lang=TR>DentisToday<span style='letter-spacing:2.0pt'> </span></span></b><span
lang=TR>tarafından,<span style='letter-spacing:2.0pt'> </span>çerezler<span
style='letter-spacing:2.0pt'> </span>vasıtasıyla<span style='letter-spacing:
2.0pt'> </span>toplanan<span style='letter-spacing:2.0pt'> </span>kişisel<span
style='letter-spacing:2.0pt'> </span>verilerinize<span style='letter-spacing:
2.0pt'> </span>ilişkin<span style='letter-spacing:2.0pt'> </span>aşağıda<span
style='letter-spacing:2.0pt'> </span>belirtilen haklara sahipsiniz:</span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:0in;margin-bottom:
0in;margin-left:27.1pt;margin-bottom:.0001pt;text-indent:-17.95pt'><b><span
lang=TR>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR>Kişisel<span style='letter-spacing:-.2pt'> </span>verilerinizin<span
style='letter-spacing:-.4pt'> </span>işlenip işlenmediğini öğrenme<span
style='letter-spacing:-.3pt'> </span>ve<span style='letter-spacing:-.3pt'> </span>işleniyor<span
style='letter-spacing:-.05pt'> </span>ise<span style='letter-spacing:-.5pt'> </span>bunlara<span
style='letter-spacing:-.3pt'> </span>ilişkin<span style='letter-spacing:-.4pt'>
</span>bilgi<span style='letter-spacing:-.15pt'> </span><span style='letter-spacing:
-.1pt'>edinme,</span></span></p>

<p class=MsoListParagraph style='margin-top:2.0pt;margin-right:6.1pt;
margin-bottom:0in;margin-left:27.15pt;margin-bottom:.0001pt;line-height:113%'><b><span
lang=TR>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR>Kişisel<span style='letter-spacing:2.0pt'> </span>verilerinizin<span
style='letter-spacing:2.0pt'> </span>işlenme<span style='letter-spacing:2.0pt'>
</span>amacını<span style='letter-spacing:2.0pt'> </span>ve<span
style='letter-spacing:2.0pt'> </span>bunların<span style='letter-spacing:2.0pt'>
</span>amacına<span style='letter-spacing:2.0pt'> </span>uygun<span
style='letter-spacing:2.0pt'> </span>kullanılıp<span style='letter-spacing:
2.0pt'> </span>kullanılmadığını <span style='letter-spacing:-.1pt'>öğrenme,</span></span></p>

<p class=MsoListParagraph style='margin-top:.2pt;margin-right:0in;margin-bottom:
0in;margin-left:27.1pt;margin-bottom:.0001pt;text-indent:-17.95pt'><b><span
lang=TR>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang=TR>Yurt<span style='letter-spacing:-.25pt'> </span>içinde<span
style='letter-spacing:-.3pt'> </span>veya<span style='letter-spacing:-.05pt'> </span>yurt<span
style='letter-spacing:-.15pt'> </span>dışında<span style='letter-spacing:-.05pt'>
</span>kişisel<span style='letter-spacing:-.35pt'> </span>verilerinizi<span
style='letter-spacing:-.15pt'> </span>–<span style='letter-spacing:-.15pt'> </span>var<span
style='letter-spacing:-.3pt'> </span>ise-<span style='letter-spacing:-.2pt'> </span>aktardığımız<span
style='letter-spacing:-.3pt'> </span>üçüncü<span style='letter-spacing:-.2pt'> </span>kişileri<span
style='letter-spacing:-.1pt'> öğrenme,</span></span></p>

<p class=MsoListParagraph style='margin-top:1.75pt;margin-right:5.8pt;
margin-bottom:0in;margin-left:27.15pt;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;line-height:115%'><b><span lang=TR>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
lang=TR>Kişisel<span style='letter-spacing:-.3pt'> </span>verilerinizin<span
style='letter-spacing:-.35pt'> </span>eksik<span style='letter-spacing:-.6pt'> </span>veya<span
style='letter-spacing:-.25pt'> </span>yanlış<span style='letter-spacing:-.3pt'>
</span>işlenmiş<span style='letter-spacing:-.15pt'> </span>olması<span
style='letter-spacing:-.5pt'> </span>hâlinde<span style='letter-spacing:-.7pt'>
</span>bunların<span style='letter-spacing:-.35pt'> </span>düzeltilmesini<span
style='letter-spacing:-.3pt'> </span>isteme<span style='letter-spacing:-.25pt'>
</span>ve<span style='letter-spacing:-.7pt'> </span>bu kapsamda yapılan işlemin
-var ise- kişisel verileri aktardığımız üçüncü kişilere bildirmemizi <span
style='letter-spacing:-.1pt'>isteme,</span></span></p>

<p class=MsoListParagraph style='margin-right:5.65pt;text-align:justify;
text-justify:inter-ideograph;line-height:115%'><b><span lang=TR>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
lang=TR>Kanun<span style='letter-spacing:-.7pt'> </span>ve<span
style='letter-spacing:-.7pt'> </span>ilgili<span style='letter-spacing:-.7pt'> </span>mevzuata<span
style='letter-spacing:-.65pt'> </span>uygun<span style='letter-spacing:-.7pt'> </span>olarak<span
style='letter-spacing:-.7pt'> </span>işlemiş<span style='letter-spacing:-.7pt'>
</span>olmamıza<span style='letter-spacing:-.65pt'> </span>rağmen,<span
style='letter-spacing:-.5pt'> </span>işlenmesini<span style='letter-spacing:
-.7pt'> </span>gerektiren<span style='letter-spacing:-.65pt'> </span>sebeplerin
ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini
isteme ve bu kapsamda yapılan işlemin -var ise- kişisel verilerinizi
aktardığımız üçüncü kişilere bildirmemizi <span style='letter-spacing:-.1pt'>isteme,</span></span></p>

<p class=MsoListParagraph style='margin-right:5.8pt;text-align:justify;
text-justify:inter-ideograph;line-height:113%'><b><span lang=TR>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
lang=TR>İşlediğimiz<span style='letter-spacing:-.25pt'> </span>kişisel<span
style='letter-spacing:-.55pt'> </span>verilerinizin<span style='letter-spacing:
-.4pt'> </span>münhasıran<span style='letter-spacing:-.65pt'> </span>otomatik<span
style='letter-spacing:-.65pt'> </span>sistemler<span style='letter-spacing:
-.25pt'> </span>vasıtasıyla<span style='letter-spacing:-.25pt'> </span>analiz<span
style='letter-spacing:-.25pt'> </span>edilmesi<span style='letter-spacing:-.55pt'>
</span>suretiyle aleyhine bir sonucun ortaya çıktığı durumlar var ise bunlara
itiraz etme,</span></p>

<p class=MsoListParagraph style='margin-top:.05pt;margin-right:5.5pt;
margin-bottom:0in;margin-left:27.15pt;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;line-height:115%'><b><span lang=TR>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
lang=TR>Kişisel verilerinizin<span style='letter-spacing:-.3pt'> </span>kanuna
aykırı<span style='letter-spacing:-.25pt'> </span>olarak<span style='letter-spacing:
-.3pt'> </span>işlenmesi<span style='letter-spacing:-.25pt'> </span>sebebiyle<span
style='letter-spacing:-.4pt'> </span>zarara uğramanız<span style='letter-spacing:
-.15pt'> </span>hâlinde<span style='letter-spacing:-.4pt'> </span>zararınızın
giderilmesini talep etme.</span></p>

<p class=MsoBodyText style='margin-top:1.7pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-left:9.15pt'><span lang=TR>Yukarıda<span
style='letter-spacing:-.3pt'> </span>belirtilen<span style='letter-spacing:
-.3pt'> </span>haklardan<span style='letter-spacing:-.5pt'> </span>bir<span
style='letter-spacing:-.2pt'> </span>veya<span style='letter-spacing:-.15pt'> </span>birkaçını<span
style='letter-spacing:-.25pt'> </span>kullanmak<span style='letter-spacing:
-.5pt'> </span><span style='letter-spacing:-.1pt'>isterseniz,</span></span></p>

<p class=MsoBodyText style='margin-top:1.75pt;margin-right:0in;margin-bottom:
0in;margin-left:9.15pt;margin-bottom:.0001pt;line-height:115%'><span lang=TR>Mobil<span
style='letter-spacing:-.8pt'> </span>Uygulamamızda<span style='letter-spacing:
-.7pt'> </span>yer<span style='letter-spacing:-.7pt'> </span>alan<span
style='letter-spacing:-.85pt'> </span>“Veri<span style='letter-spacing:-.65pt'>
</span>Sahibi<span style='letter-spacing:-.7pt'> </span>Başvuru<span
style='letter-spacing:-.7pt'> </span>Formu”nu<span style='letter-spacing:-.7pt'>
</span>doldurarak<span style='letter-spacing:-.85pt'> </span>aşağıdaki<span
style='letter-spacing:-.65pt'> </span>yöntemlerle<span style='letter-spacing:
-.7pt'> </span>bize <span style='letter-spacing:-.1pt'>iletebilirsiniz:</span></span></p>

<p class=MsoBodyText style='margin-top:1.7pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-right:5.5pt;line-height:107%'><span
lang=TR style='font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=TR>Rasimpaşa Mahallesi Tayyareci Sami Sok. No: 13/B İç
Kapı No:125 Kadıköy/İstanbul adresine yazılı olarak posta yoluyla gönderebilirsiniz.</span></p>

<p class=MsoListParagraph style='margin-top:.8pt;margin-right:5.45pt;
margin-bottom:0in;margin-left:27.15pt;margin-bottom:.0001pt;line-height:107%'><span
lang=TR style='font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=TR>Güvenli<span style='letter-spacing:-.6pt'> </span>elektronik<span
style='letter-spacing:-.7pt'> </span>imza<span style='letter-spacing:-.3pt'> </span>ya<span
style='letter-spacing:-.3pt'> </span>da<span style='letter-spacing:-.55pt'> </span>mobil<span
style='letter-spacing:-.6pt'> </span>imzayla<span style='letter-spacing:-.3pt'>
</span>e-posta<span style='letter-spacing:-.3pt'> </span>yolu<span
style='letter-spacing:-.45pt'> </span>ile<span style='letter-spacing:-.7pt'> </span><a
href="mailto:info@theaident.com"><span style='color:blue'>info@theaident.com</span></a><span
style='color:blue;letter-spacing:-.55pt'> </span>e-posta<span style='letter-spacing:
-.3pt'> </span>adresine veya <a href="mailto:aident@hs01.kep.tr"><span
style='color:blue'>aident@hs01.kep.tr</span></a><span style='color:blue'> </span>kayıtlı
elektronik posta (KEP) yolu ile e-posta gönderebilirsiniz.</span></p>

<p class=MsoListParagraph style='margin-top:1.05pt;margin-right:0in;margin-bottom:
0in;margin-left:27.1pt;margin-bottom:.0001pt;text-indent:-17.95pt'><span
lang=TR style='font-family:"Courier New"'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=TR>Diğer<span style='letter-spacing:-.2pt'> </span>başvuru<span
style='letter-spacing:-.4pt'> </span>yöntemleri<span style='letter-spacing:
-.35pt'> </span>için<span style='letter-spacing:-.45pt'> </span>“Veri<span
style='letter-spacing:-.35pt'> </span>Sahibi<span style='letter-spacing:-.4pt'>
</span>Başvuru<span style='letter-spacing:-.15pt'> </span>Formu”na<span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>bakabilirsiniz.</span></span></p>

</div>

<span lang=TR style='font-size:11.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection5>

<p class=MsoBodyText style='margin-top:3.7pt;margin-right:5.45pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><b><span lang=TR>DentisToday</span></b><span
lang=TR>, başvurunuzu en kısa sürede ve en geç 30 (otuz) gün içinde
yanıtlayacaktır. Taleplere ilişkin<span style='letter-spacing:-.05pt'> </span>gerekli
işlemler için<span style='letter-spacing:-.05pt'> </span>herhangi bir ücret
talep edilmemesi esastır. Bununla birlikte, işlemlerin<span style='letter-spacing:
-.05pt'> </span>bir maliyet<span style='letter-spacing:-.5pt'> </span>gerektirmesi<span
style='letter-spacing:-.5pt'> </span>halinde,<span style='letter-spacing:-.5pt'>
</span>Kişisel<span style='letter-spacing:-.55pt'> </span>Verileri<span
style='letter-spacing:-.7pt'> </span>Koruma<span style='letter-spacing:-.45pt'>
</span>Kurulu<span style='letter-spacing:-.6pt'> </span>tarafından,<span
style='letter-spacing:-.5pt'> </span>Kanun’un<span style='letter-spacing:-.7pt'>
</span>13.<span style='letter-spacing:-.45pt'> </span>Maddesine<span
style='letter-spacing:-.7pt'> </span>göre belirlenen tarife üzerinden ücret
talep edilmesi mümkündür. <b>DentisToday</b>’in taleplere ilişkin olarak
Kişisel Verileri Koruma Kurulu tarafından belirlenen (varsa) ücret tarifesi
üzerinden ücret talep etme hakkı saklıdır.</span></p>

<p class=MsoBodyText style='margin-top:1.85pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.95pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><span lang=TR>Başvuruya yetkili<span
style='letter-spacing:-.1pt'> </span>olup olmadığınızı<span style='letter-spacing:
-.1pt'> </span>tespit etmek<span style='letter-spacing:-.15pt'> </span>veya
talebinizi<span style='letter-spacing:-.1pt'> </span>daha hızlı<span
style='letter-spacing:-.1pt'> </span>yanıtlayabilmek<span style='letter-spacing:
-.15pt'> </span>için<span style='letter-spacing:-.15pt'> </span>sizden bazı ek
bilgiler istenebilecektir. Bu konuda detaylı açıklamalar Veri Sahibi Başvuru
Formumuzda yer <span style='letter-spacing:-.1pt'>almaktadır.</span></span></p>

<p class=MsoBodyText style='margin-top:1.65pt'><span lang=TR>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'><b><span lang=TR>DentisToday</span></b><span
lang=TR>’in faaliyet ve operasyonlarını sürdürürken kişisel verilerinizin
işlenmesine ilişkin takip ettiği genel prensiplerle ilgili bilgi almak için
mobil uygulamada yer alan ana Aıdent Sağlık ve Diş Teknolojileri Anonim<span
style='letter-spacing:-.3pt'> </span>Şirketi Kişisel Verilerin<span
style='letter-spacing:-.1pt'> </span>Korunması ve<span style='letter-spacing:
-.2pt'> </span>İşlenmesi Politikası’nı inceleyebilirsiniz.</span></p>

</div>

</body>

</html>

  `

  return (
    <div

      style={{
        padding: '20px',
        fontSize: '16px',
        lineHeight: '1.5',
        fontFamily: 'Arial, sans-serif',
      }}
      dangerouslySetInnerHTML={{
        __html: i18n.language === 'en' ? en : tr,
      }}
    >

    </div>
  )
}
