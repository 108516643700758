import { Box, Grid, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import picture from '../assets/Picture.svg';
import xray from '../assets/X-ray.svg';
import icons from '../assets/icons.svg';
import upload from '../assets/upload.svg';
import PageView from './PageView';

export default function DentistAI() {
  const { t } = useTranslation();

  const links = [
    {
      title: t('dentistai.linktitleone'),
      subtitle: t('dentistai.linksubtitleone'),
      image: icons,
    },
    {
      title: t('dentistai.linktitletwo'),
      subtitle: t('dentistai.linksubtitletwo'),
      image: upload,
    },
    {
      title: t('dentistai.linktitlethree'),
      subtitle: t('dentistai.linksubtitlethree'),
      image: xray,
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box id="yapay-zeka">
      <PageView>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: { xs: '32px', md: '56px' },
            lineHeight: { xs: '36px', md: '61.6px' },
            textAlign: 'center',
            color: 'rgba(15,23,42,1)',
          }}>
          {t('dentistai.title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            pt: { xs: '16px', md: '32px' },
            alignItems: 'center',
          }}>
          <img
            src={picture}
            alt="pictures"
            style={{
              width: matches ? '100%' : '60%',
              maxWidth: '680px',
              height: 'auto'
            }}
          />
          <Box
            sx={{
              border: '1px solid rgba(226, 232, 240, 1)',
              borderRadius: '16px',
              width: { xs: '90%', md: '100%' },
              mt: { xs: '-16px' },
              ml: { xs: 0, md: -6 },
              background: 'white',
              padding: { xs: '16px', md: '32px' }
            }}>
            <Box
              component="ul"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <Typography
                component="li"
                sx={{
                  color: 'rgba(15, 23, 42, 1)',
                  fontWeight: 400,
                  fontSize: { xs: '16px', md: '22px' },
                  lineHeight: { xs: '24px', md: '30.8px' },
                }}>
                {t('dentistai.listone')}
              </Typography>
              <Typography
                component="li"
                sx={{
                  color: 'rgba(15, 23, 42, 1)',
                  fontWeight: 400,
                  fontSize: { xs: '16px', md: '22px' },
                  lineHeight: { xs: '24px', md: '30.8px' },
                }}>
                {t('dentistai.listtwo')}
              </Typography>
              <Typography
                component="li"
                sx={{
                  color: 'rgba(15, 23, 42, 1)',
                  fontWeight: 400,
                  fontSize: { xs: '16px', md: '22px' },
                  lineHeight: { xs: '24px', md: '30.8px' },
                }}>
                {t('dentistai.listthree')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: '28px', md: '36px' },
              lineHeight: { xs: '32px', md: '46px' },
              textAlign: 'center',
              color: 'rgba(15,23,42,1)',
              pt: { xs: '32px', md: '80px' },
            }}>
            {t('dentistai.subtitle')}
          </Typography>
          <Box
            sx={{
              pt: '56px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Grid container spacing={3}>
              {links.map((link) => (
                <Grid item xs={12} sm={6} md={4} key={link.title}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      gap: '8px',
                    }}>
                    <img src={link.image} alt="icons" width={96} height={96} />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '26.4px',
                        color: 'rgba(15,23,42,1)',
                      }}>
                      {link.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: 'rgba(15,23,42,1)',
                      }}>
                      {link.subtitle}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </PageView>
    </Box>
  );
}
