import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import appEN from '../assets/app-en.svg';
import appTR from '../assets/app-tr.svg';
import child from '../assets/child.svg';
import dentis from '../assets/dentis.svg';
import health from '../assets/health.svg';
import smile from '../assets/smile.svg';
import PageView from './PageView';

export default function Dentis() {
  const { t, i18n } = useTranslation();

  const options = [
    {
      title: t('dentis.optionstitleone'),
      subtitle: t('dentis.optionssubtitleone'),
      image: smile,
    },
    {
      title: t('dentis.optionstitletwo'),
      subtitle: t('dentis.optionssubtitletwo'),
      image: dentis,
    },
    {
      title: t('dentis.optionstitlethree'),
      subtitle: t('dentis.optionssubtitlethree'),
      image: child,
    },
    {
      title: t('dentis.optionstitlefour'),
      subtitle: t('dentis.optionssubtitlefour'),
      image: health,
    },
  ];

  return (
    <Box>
      <PageView>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '160px',
            py: { xs: '10px', md: '80px' },
            flexDirection: { xs: 'column', md: 'row' },
          }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: '36px', md: '56px' },
                lineHeight: '61.6px',
                color: 'rgba(15,23,42,1)',
                textAlign: 'center',
              }}>
              {t('dentis.title')}
            </Typography>
            <Grid
              sx={{
                py: '94px',
              }}
              container
              spacing={12}>
              {options.map((option) => (
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    sx={{
                      flexDirection: 'column',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      textAlign: 'left',
                    }}>
                    <img
                      src={option.image}
                      alt="icons"
                      width={96}
                      height={96}
                    />
                    <Box
                      sx={{
                        textTransform: 'none',
                        pt: '16px',
                        justifyContent: 'flex-start',
                      }}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '24px',
                          lineHeight: '26.4px',
                          color: 'rgba(15,23,42,1)',
                          textAlign: 'center',
                        }}>
                        {option.title}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '22.4px',
                        color: 'rgba(15,23,42,1)',
                        pt: '16px',
                      }}>
                      {option.subtitle}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Button
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              sx={{
                background: 'rgba(255, 133, 10, 1)',
                borderRadius: '99999px',
                width: '100%',
                height: '64px',
                '&:hover': {
                  background: 'rgba(255, 133, 10, 1)',
                },
              }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '18px',
                  color: 'rgba(255, 255, 255, 1)',
                }}>
                {t('dentis.button')}
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <img
              src={i18n.language === 'tr' ? appTR : appEN}
              alt=""
              style={{
                borderRadius: '20px',
                maxWidth: '400px',
                width: '100%',
              }}
            />
          </Box>
        </Box>
      </PageView>
    </Box>
  );
}
